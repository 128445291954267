import React, { useState } from 'react';
import { Image, View } from '@scriptx-com/xtv-toolkit';

export interface ImageProps {
    props?: any;
    source?: any;
    deviceContext?: any;
    isLocal?: boolean;
    isCache?: boolean;
}
const Image_ = ({ isLocal, isCache, deviceContext, source, ...props }: ImageProps) => {
    const [isLoading, setLoading] = useState(true);
    function onLoadStart() {
        setLoading(true);
    } 
    function onLoadEnd() {
        setLoading(false);
    }
    var propStyle = props as any;
    var uri = source.uri;

    if (isLocal  || source.uri.indexOf('file://') > -1) {
            return <Image {...props} source={source} />;
    } else {
        if (uri == '' || uri == undefined) {
            return <View></View>;
        } else {
            if (uri.indexOf('preview.jpg') >= 0) {
                uri = uri;
            } else {
                if (deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwa) {
                    const lastItem = 'mobile_' + uri.substring(uri.lastIndexOf('/') + 1);
                    const restOfUri = uri.substring(0, uri.lastIndexOf('/'));
                    const totalNewUri = restOfUri + '/' + lastItem;
                    if (deviceContext.isApple || deviceContext.isKaiOs) {
                        uri = totalNewUri;
                    } else {
                        uri = totalNewUri + '.webp';
                    }
                } else {
                    if (deviceContext.isAppleTV) {
                        uri = uri;
                    } else {
                        uri = uri + '.webp';
                    }
                }
            }
            // if (isCache) {
            //     return (
            //         <View style={[{ justifyContent: 'center' }, { ...propStyle.style }]}>
            //             <FastImage
            //                 {...props}
            //                 fallback={true}
            //                 //onLoadEnd={onLoadEnd}
            //                 //onLoadStart={onLoadStart}
            //                 source={{
            //                     uri: uri,
            //                     priority: FastImage.priority.high,
            //                 }}
            //                 resizeMode={FastImage.resizeMode.contain}
            //             />
            //             {/* {isLoading && (
            //                 <View style={{ position: 'absolute', flex: 1, alignSelf: 'center', zIndex: -1 }}>
            //                     <ActivityIndicator size={'small'} color={'#999999'} />
            //                 </View>
            //             )} */}
            //         </View>
            //     );
            // } else {
                return <Image resizeMethod={'resize'} resizeMode={'contain'} {...props} source={{ uri: uri }} />;
            // }
        }
    }
};
export default Image_;
