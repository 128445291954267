import * as React from 'react';
import { View, Image } from '@scriptx-com/xtv-toolkit';
import Pressable from '../../components/pressable/pressable';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { DeviceContext } from '../../../context/deviceContext';
import { AppContext } from '../../../context/appContext';
import { getFocusStyling, getPressableStyling } from '../../components/helpers/helper';
import { ContentContext } from '../../../context/contentContext';
import utils from '../../../general/utils';
import Text from '../../components/text';
import { getFontSize } from '../../../styling/fontSizes';

export interface Program {
    n: string;
    s: any;
    e: any;
}
export interface Data {
    name: string;
    number: string;
    images: {
        widescreen?: string;
        square?: string;
        tile?: string;
        background?: string;
        poster?: string;
        logo?: string;
    };
    _id: string;
    progress?: number;
    catchuptv?: boolean;
    program?: Program;
    color?: string;
    package?: string;
    image?: string;
}

export interface Props {
    focusContext?: any;
    focusRepeatContext?: any;
    focusOptions?: any;
    noMargin?: boolean;
    height: number;
    width: number;
    onPress: any;
    styling: any;
    data: Data;
    type: string;
    rails?: boolean;
    onLongPress: any;
    heroIsPlayer: boolean;
    elementType: string;
    onFocus?: () => void;
    onBlur?: () => void;
    isFirst?: boolean;
    isLast?: boolean;
}

export const Actor = ({ focusContext, focusRepeatContext, noMargin, width, onPress, styling, data, onLongPress, rails, type, heroIsPlayer, elementType, isFirst, isLast, ...rest }: Props) => {
    const deviceContext = React.useContext(DeviceContext);
    const appContext = React.useContext(AppContext);
    const contentContext = React.useContext(ContentContext);
    const timeout = React.useRef<NodeJS.Timeout>();

    const onFocus = (data) => {
        timeout.current = setTimeout(() => {
            if (styling.content?.link_to_hero != undefined && styling.content?.link_to_hero && heroIsPlayer) {
                contentContext.setHero({
                    type: type,
                    data: [data],
                });
            }
        }, 1000);
    };

    const onBlur = () => {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
    };
    //margin bottom weg
    const getCorrectScaleStyling = () => {
        return getFocusStyling(elementType, appContext).type.indexOf('scale') > -1 && elementType == 'Rails' && deviceContext.formFactor == 'Television';
    };

    if (data == undefined) {
        return null;
    } else {
        return (
            <View style={getCorrectScaleStyling() ? { height: width * 1.3, justifyContent: 'center' } : {}}>
                <Pressable
                    style={[
                        {
                            ...getPressableStyling(styling.components?.button._id, appContext),
                            borderRadius: width - getCorrectHeight(deviceContext, appContext.application.theme.images.margin * 2),
                            marginRight: getCorrectWidth(deviceContext, 10),
                            height: width - getCorrectWidth(deviceContext, 5),
                            width: width - getCorrectWidth(deviceContext, 5),
                            justifyContent: 'center',
                            alignContent: 'center',
                            alignItems: 'center',
                        },
                    ]}
                    focusContext={focusContext}
                    focusRepeatContext={focusRepeatContext}
                    onFocus={() => (rest.onFocus ? rest.onFocus() : onFocus(data))}
                    onBlur={() => (rest.onBlur ? rest.onBlur() : onBlur())}
                    animatorOptions={getFocusStyling(elementType, appContext)}
                    onPress={() => onPress(data?._id)}
                    onLongPress={() => onLongPress(data?._id)}
                    focusOptions={rest.focusOptions}
                    isFirst={isFirst}
                    isLast={isLast}
                >
                    <View>
                        <View
                            style={{
                                padding: getCorrectHeight(deviceContext, appContext.application.theme.images.margin),
                                backgroundColor: appContext.application.theme.images?.background,
                                borderRadius: width,
                                height: width - getCorrectHeight(deviceContext, +(appContext.application.theme.images.margin * 2) + 5),
                                justifyContent: 'center',
                                alignContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <View>
                                <Image
                                    source={{ uri: utils.getCorrectStoragePrefix(appContext, true, data.image) }}
                                    resizeMethod={'scale'}
                                    resizeMode={'cover'}
                                    style={{
                                        height: width * 0.8,
                                        width: width * 0.8,
                                        borderRadius: width * 0.8,
                                    }}
                                />
                            </View>
                        </View>
                    </View>
                </Pressable>
                <View
                    style={{
                        marginHorizontal: getCorrectHeight(deviceContext, appContext.application.theme.images.margin),
                        width: width - getCorrectWidth(deviceContext, 10),
                        alignItems: 'center',
                    }}
                >
                    <Text
                        numberOfLines={1}
                        style={{
                            color: styling.texts.title_color == '' ? '#ffffff' : styling.texts.title_color,
                            fontWeight: styling.texts.title_bold == true ? 'bold' : 'normal',
                            fontFamily: deviceContext.fontType,
                            fontSize: getFontSize(deviceContext, 'Normal'),
                            lineHeight: getFontSize(deviceContext, 'Normal'),
                            textShadowColor: 'rgba(0, 0, 0, 0.2)',
                            textShadowOffset: { width: -1, height: 1 },
                            textShadowRadius: 2,
                            paddingTop: 10,
                        }}
                    >
                        {data?.name}
                    </Text>
                </View>
            </View>
        );
    }
};
export default Actor;
