import { FocusContext, View, Image, FlashList, CreateListRenderItemInfo, TouchableOpacity, TouchableOpacityProps, ViewProps } from '@scriptx-com/xtv-toolkit';
import { faChromecast } from '@fortawesome/free-brands-svg-icons';
import {
    faCog,
    faHistory,
    faAirplay,
    faChevronDown,
    faArrowLeft,
    faArrowDown,
    faArrowRight,
    faArrowUp,
    faHeart,
    faPause,
    faPlay,
    faStepBackward,
    faStepForward,
    faRectangleHistory,
    faChevronDoubleLeft,
    faChevronDoubleRight,
    faCoins,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import * as React from 'react';
import { AppContext } from '../../../context/appContext';
import { ContentContext } from '../../../context/contentContext';
import { DeviceContext } from '../../../context/deviceContext';
import { PlayerContext } from '../../../context/playerContext';
import { UserContext } from '../../../context/userContext';
import { getCurrentScreenName } from '../../../hooks/getScreenName';
import lang from '../../../languages/languages';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { getFontSize } from '../../../styling/fontSizes';
import Pressable from '../../components/pressable/pressable';
import { getAgeRating, getImage, getLogo, getSubSubText, getSubSubTextNext, getSubText, getSubTextNext, getTitle, getTitleNext } from '../../elements/players/helpers/functions';
import { ProgressBars } from './helpers/progress';
import Text from '../../components/text';
import { getFocusStyling, getPressableStyling, getPressableTextStyling } from '../../components/helpers/helper';
import { getLiveTag, getReplayTag } from './helpers/helpers';
import { faHeart as faHeartSolid } from '@fortawesome/free-solid-svg-icons';
import { RemoteImageHeightWidth } from '../../components/remoteimage/remoteimage';
import { getRealHeight, getRealWidth } from '../../../styling/realDeviceSizes';
import moment from 'moment';
import { payperviewStatus } from '../../../data/account';
import { useNetInfo } from '@react-native-community/netinfo';
import utils from '../../../general/utils';
import useSharedEPG from './helpers/useSharedEPG';
import { FlashList as FlashListComp } from '@scriptx-com/flash-list';

export interface Props {
    navigation: any;
    focusContext?: FocusContext;
    styling: any;
    onPrevious: any;
    onNext: any;
    showChannels: any;
    showEpisodes: any;
    showLessons: any;
    showShorts: any;
    showPayPerView: any;
    showSettings: any;
    openChromecast: any;
    openAirplay: any;
    manageFavorite: any;
    onPlayPause: any;
    startPiP: any;
    showSupport: any;
    onScrubberDrag: any;
    onRestart: any;
    onForward: any;
    onRewind: any;
    isFavorite: boolean;
    isRestart: boolean;
    onBack: any;
    onClose: any;
    onPreviousCatchupTV: any;
    paused: boolean;
    onTest: any;
    playerType: string;
    session: any;
    clearControlsTimeout?: () => void;
    resetControlsTimeout?: () => void;
    playChannelIndex?: (index: number, channel: any) => void;
    onPressPlay: (channel: any, program: any, programs: any[]) => void;
    onPressPurchase: () => void;
    onPressPlayRecording: (channel: any, program: any, programs: any) => void;
    onPressPlayCatchupTV: (channel: any, program: any, programs: any, startType: any) => void;
}

export const Player_Standard = ({
    navigation,
    styling,
    onPrevious,
    onNext,
    onRewind,
    onForward,
    showChannels,
    showEpisodes,
    showShorts,
    showLessons,
    showSettings,
    showPayPerView,
    openChromecast,
    openAirplay,
    manageFavorite,
    onPlayPause,
    startPiP,
    onScrubberDrag,
    onRestart,
    isRestart,
    isFavorite,
    onBack,
    onClose,
    focusContext,
    paused,
    onTest,
    playerType,
    session,
    clearControlsTimeout,
    resetControlsTimeout,
    playChannelIndex,
    onPressPlay,
    onPressPlayCatchupTV,
    onPressPlayRecording,
    onPressPurchase,
}: Props) => {
    const appContext = React.useContext(AppContext);
    const playerContext = React.useContext(PlayerContext);
    const contentContext = React.useContext(ContentContext);
    const deviceContext = React.useContext(DeviceContext);
    const userContext = React.useContext(UserContext);
    const screenName = getCurrentScreenName();
    const netInfo = useNetInfo();

    const channelRef = React.useRef<FlashListComp<any>>(null);
    const programRef = React.useRef<FlashListComp<any>>(null);
    const channelScrollIndex = React.useRef(contentContext.channelData.channelIndex);
    const programScrollIndex = React.useRef(0);
    const { channelSelected, channelEpgSelected, channels, onPressChannel, programSelectedIndex, ProgramModal, showProgramDetails, setProgramSelected, setShowProgramDetails } = useSharedEPG({
        forceTvBehaviour: true,
        playChannelIndex,
        initialChannel: contentContext.channelData.channel,
        focusContext,
        navigation,
        onPressPlay,
        onPressPlayRecording,
        onPressPlayCatchupTV,
        onPressPurchase,
    });

    const estimatedChannelSize = getCorrectWidth(deviceContext, 38) + getCorrectWidth(deviceContext, 10);
    const estimatedProgramSize = ((deviceContext.isPhone ? getRealHeight(deviceContext) : getRealWidth(deviceContext) * 0.8) - getCorrectWidth(deviceContext, 25)) / (deviceContext.isPhone ? 1.5 : 2);

    React.useEffect(() => {
        if (showProgramDetails) clearControlsTimeout();
        else resetControlsTimeout();
    }, [showProgramDetails]);

    React.useEffect(() => {
        programScrollIndex.current = programSelectedIndex;
    }, [programSelectedIndex]);

    const [showNextView, setShowNextView] = React.useState(false);
    const [showPreviousView, setShowPreviousView] = React.useState(false);

    const showPrevious = () => {
        setShowPreviousView(true);
    };
    const hidePrevious = () => {
        setShowPreviousView(false);
    };
    const showNext = () => {
        setShowNextView(true);
    };
    const hideNext = () => {
        setShowNextView(false);
    };

    const getDescription = (item) => {
        var test = item.translations?.find((trans) => trans.name == userContext.selectedLanguage);
        if (test != undefined) {
            return test?.description;
        } else {
            if (item?.translations != undefined) {
                return item?.translations[0]?.description;
            } else {
                return '';
            }
        }
    };

    const ScrollerWrapper: React.FC<{ children: React.ReactNode; onPress: () => void; onLongPress: () => void; type: 'horizontal' | 'vertical' }> = ({ children, onPress, type, onLongPress }) => {
        const style: TouchableOpacityProps['style'] | ViewProps['style'] = {
            backgroundColor: deviceContext.isWebTV ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            ...(type === 'vertical' ? { height: getCorrectWidth(deviceContext, 12) } : { width: getCorrectWidth(deviceContext, 20) }),
        };
        if (deviceContext.isWebTV)
            return (
                <TouchableOpacity
                    onPress={() => {
                        resetControlsTimeout();
                        onPress();
                    }}
                    onLongPress={() => {
                        resetControlsTimeout();
                        onLongPress();
                    }}
                    style={style}
                >
                    {children}
                </TouchableOpacity>
            );
        else return <View style={style}>{children}</View>;
    };

    const getPrevious = () => {
        switch (screenName) {
            case 'Channel_Player':
                var index = contentContext.channelData.channelIndex == 0 ? contentContext.channelData.channelList.length - 1 : contentContext.channelData.channelIndex - 1;
                var channel = contentContext.channelData.channelList[index];
                return (
                    <View style={{ flex: 1, marginTop: getCorrectHeight(deviceContext, 40) }}>
                        <Text
                            numberOfLines={1}
                            style={{
                                textAlign: 'right',
                                opacity: 0.6,
                                color: styling.texts.subtext_color,
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                lineHeight: getFontSize(deviceContext, 'Small'),
                                fontWeight: 'bold',
                            }}
                        >
                            {lang.getTranslation(userContext, 'previous_channel')}
                        </Text>
                        <View
                            style={{
                                width: getRealWidth(deviceContext) * 0.2,
                                height: getCorrectHeight(deviceContext, 60),
                                flexDirection: 'row',
                                alignItems: 'center',
                                padding: getCorrectHeight(deviceContext, 10),
                                marginTop: getCorrectHeight(deviceContext, 3),
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                            }}
                        >
                            <Image
                                resizeMethod={'scale'}
                                resizeMode={'contain'}
                                source={{ uri: utils.getCorrectStoragePrefix(appContext, true, channel.images?.square) }}
                                style={{
                                    width: getCorrectHeight(deviceContext, 30),
                                    height: getCorrectHeight(deviceContext, 30),
                                }}
                            ></Image>
                            <View style={{ marginLeft: 20, width: getRealWidth(deviceContext) * 0.12 }}>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {channel.number}
                                </Text>
                                <Text
                                    numberOfLines={2}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {channel.name}
                                </Text>
                            </View>
                        </View>
                    </View>
                );
                break;
            case 'CatchupTV_Player':
                var index = contentContext.channelData.programIndex == 0 ? contentContext.channelData.programList.length - 1 : contentContext.channelData.programIndex - 1;
                var program = contentContext.channelData.programList[index];
                var channel = contentContext.channelData.channel;
                return (
                    <View style={{ flex: 1, marginTop: getCorrectHeight(deviceContext, 40) }}>
                        <Text
                            numberOfLines={1}
                            style={{
                                textAlign: 'right',
                                opacity: 0.6,
                                color: styling.texts.subtext_color,
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                lineHeight: getFontSize(deviceContext, 'Small'),
                                fontWeight: 'bold',
                            }}
                        >
                            {lang.getTranslation(userContext, 'previous_programs')}
                        </Text>
                        <View
                            style={{
                                width: getRealWidth(deviceContext) * 0.22,
                                flexDirection: 'row',
                                alignItems: 'center',
                                padding: getCorrectHeight(deviceContext, 10),
                                marginTop: getCorrectHeight(deviceContext, 3),
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                            }}
                        >
                            <Image
                                resizeMethod={'scale'}
                                resizeMode={'contain'}
                                source={{ uri: utils.getCorrectStoragePrefix(appContext, true, channel.images?.square) }}
                                style={{
                                    width: getCorrectHeight(deviceContext, 30),
                                    height: getCorrectHeight(deviceContext, 30),
                                }}
                            ></Image>
                            <View style={{ marginLeft: 20, width: getRealWidth(deviceContext) * 0.15 }}>
                                <Text
                                    numberOfLines={2}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {program.n}
                                </Text>
                                <Text
                                    numberOfLines={2}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {moment.unix(program?.s).format('ddd ' + deviceContext.clockSetting)} - {moment.unix(program?.e).format('ddd ' + deviceContext.clockSetting)}
                                </Text>
                            </View>
                        </View>
                    </View>
                );
                break;
            case 'Cam_Player':
                var index = contentContext.camData.camIndex == 0 ? contentContext.camData.camList.length - 1 : contentContext.camData.camIndex - 1;
                var cam = contentContext.camData.camList[index];
                return (
                    <View style={{ flex: 1, marginTop: getCorrectHeight(deviceContext, 40) }}>
                        <Text
                            numberOfLines={1}
                            style={{
                                textAlign: 'right',
                                opacity: 0.6,
                                color: styling.texts.subtext_color,
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                lineHeight: getFontSize(deviceContext, 'Small'),
                                fontWeight: 'bold',
                            }}
                        >
                            {lang.getTranslation(userContext, 'previous_cam')}
                        </Text>
                        <View
                            style={{
                                width: getRealWidth(deviceContext) * 0.2,
                                flexDirection: 'row',
                                alignItems: 'center',
                                padding: getCorrectHeight(deviceContext, 10),
                                marginTop: getCorrectHeight(deviceContext, 3),
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                            }}
                        >
                            <Image
                                resizeMethod={'scale'}
                                resizeMode={'contain'}
                                source={{ uri: utils.getCorrectStoragePrefix(appContext, true, cam.images?.square) }}
                                style={{
                                    width: getCorrectHeight(deviceContext, 30),
                                    height: getCorrectHeight(deviceContext, 30),
                                }}
                            ></Image>
                            <View style={{ marginLeft: 20, width: getRealWidth(deviceContext) * 0.12 }}>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {cam.number}
                                </Text>
                                <Text
                                    numberOfLines={2}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {cam.name}
                                </Text>
                            </View>
                        </View>
                    </View>
                );
                break;
            case 'Series_Player':
                var index = contentContext.seriesData.episodeIndex - 1;
                if (index < 0) {
                    return null;
                }
                var episode = contentContext.seriesData.episodeList[index];
                return (
                    <View style={{ flex: 1 }}>
                        <Text
                            numberOfLines={1}
                            style={{
                                textAlign: 'right',
                                opacity: 0.6,
                                color: styling.texts.subtext_color,
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                lineHeight: getFontSize(deviceContext, 'Small'),
                                fontWeight: 'bold',
                            }}
                        >
                            {lang.getTranslation(userContext, 'previous_episode')}
                        </Text>
                        <View
                            style={{
                                width: getRealWidth(deviceContext) * 0.4,
                                flexDirection: 'row',
                                padding: 10,
                                marginTop: getCorrectHeight(deviceContext, 3),
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                            }}
                        >
                            <Image
                                resizeMethod={'scale'}
                                resizeMode={'cover'}
                                source={{ uri: utils.getCorrectStoragePrefix(appContext, true, episode.images?.widescreen) }}
                                style={{
                                    width: getCorrectWidth(deviceContext, 130),
                                    height: getCorrectWidth(deviceContext, 72),
                                }}
                            ></Image>
                            <View style={{ flex: 1, marginHorizontal: 20, justifyContent: 'flex-start' }}>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Large'),
                                        fontWeight: 'normal',
                                        marginBottom: 5,
                                    }}
                                >
                                    {episode.number}. {episode.name}
                                </Text>
                                <Text
                                    numberOfLines={3}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {getDescription(episode)}
                                </Text>
                            </View>
                        </View>
                    </View>
                );
                break;
            case 'Shorts_Player':
                var index = contentContext.shortData.shortIndex - 1;
                if (index < 0) {
                    return null;
                }
                var short = contentContext.shortData.shortList[index];
                return (
                    <View style={{ flex: 1 }}>
                        <Text
                            numberOfLines={1}
                            style={{
                                textAlign: 'right',
                                opacity: 0.6,
                                color: styling.texts.subtext_color,
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                lineHeight: getFontSize(deviceContext, 'Small'),
                                fontWeight: 'bold',
                            }}
                        >
                            {lang.getTranslation(userContext, 'previous_short')}
                        </Text>
                        <View
                            style={{
                                width: getRealWidth(deviceContext) * 0.4,
                                flexDirection: 'row',
                                padding: 10,
                                marginTop: getCorrectHeight(deviceContext, 3),
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                            }}
                        >
                            <Image
                                resizeMethod={'scale'}
                                resizeMode={'cover'}
                                source={{ uri: utils.getCorrectStoragePrefix(appContext, true, short.images?.widescreen) }}
                                style={{
                                    width: getCorrectWidth(deviceContext, 130),
                                    height: getCorrectWidth(deviceContext, 72),
                                }}
                            ></Image>
                            <View style={{ flex: 1, marginHorizontal: 20, justifyContent: 'flex-start' }}>
                                <Text
                                    numberOfLines={2}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Large'),
                                        fontWeight: 'normal',
                                        marginBottom: 5,
                                    }}
                                >
                                    {short.name}
                                </Text>
                                <Text
                                    numberOfLines={3}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {getDescription(short)}
                                </Text>
                            </View>
                        </View>
                    </View>
                );
                break;
            case 'Course_Player':
                var index = contentContext.courseData.lessonIndex - 1;
                if (index < 0) {
                    return null;
                }
                var lesson = contentContext.courseData.lessonList[index];
                return (
                    <View style={{ flex: 1 }}>
                        <Text
                            numberOfLines={1}
                            style={{
                                textAlign: 'right',
                                opacity: 0.6,
                                color: styling.texts.subtext_color,
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                lineHeight: getFontSize(deviceContext, 'Small'),
                                fontWeight: 'bold',
                            }}
                        >
                            {lang.getTranslation(userContext, 'previous_lesson')}
                        </Text>
                        <View
                            style={{
                                width: getRealWidth(deviceContext) * 0.4,
                                flexDirection: 'row',
                                padding: 10,
                                marginTop: getCorrectHeight(deviceContext, 3),
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                            }}
                        >
                            <Image
                                resizeMethod={'scale'}
                                resizeMode={'cover'}
                                source={{ uri: utils.getCorrectStoragePrefix(appContext, true, lesson.images?.widescreen) }}
                                style={{
                                    width: getCorrectWidth(deviceContext, 130),
                                    height: getCorrectWidth(deviceContext, 72),
                                }}
                            ></Image>
                            <View style={{ flex: 1, marginHorizontal: 20, justifyContent: 'flex-start' }}>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Large'),
                                        fontWeight: 'normal',
                                        marginBottom: 5,
                                    }}
                                >
                                    {lesson.name}
                                </Text>
                                <Text
                                    numberOfLines={3}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {getDescription(lesson)}
                                </Text>
                            </View>
                        </View>
                    </View>
                );
                break;
            default:
                return '';
                break;
        }
    };
    const getNext = () => {
        switch (screenName) {
            case 'Channel_Player':
                var index = contentContext.channelData.channelIndex == contentContext.channelData.channelList.length - 1 ? 0 : contentContext.channelData.channelIndex + 1;
                var channel = contentContext.channelData.channelList[index];
                return (
                    <View style={{ flex: 1, marginTop: getCorrectHeight(deviceContext, 40) }}>
                        <Text
                            numberOfLines={1}
                            style={{
                                textAlign: 'right',
                                opacity: 0.6,
                                color: styling.texts.subtext_color,
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                lineHeight: getFontSize(deviceContext, 'Small'),
                                fontWeight: 'bold',
                            }}
                        >
                            {lang.getTranslation(userContext, 'next_channel')}
                        </Text>
                        <View
                            style={{
                                width: getRealWidth(deviceContext) * 0.2,
                                flexDirection: 'row',
                                height: getCorrectHeight(deviceContext, 60),
                                alignItems: 'center',
                                padding: getCorrectHeight(deviceContext, 10),
                                marginTop: getCorrectHeight(deviceContext, 3),
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                            }}
                        >
                            <Image
                                resizeMethod={'scale'}
                                resizeMode={'contain'}
                                source={{ uri: utils.getCorrectStoragePrefix(appContext, true, channel.images?.square) }}
                                style={{
                                    width: getCorrectHeight(deviceContext, 30),
                                    height: getCorrectHeight(deviceContext, 30),
                                }}
                            ></Image>
                            <View style={{ marginLeft: 20, width: getRealWidth(deviceContext) * 0.12 }}>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {channel.number}
                                </Text>
                                <Text
                                    numberOfLines={2}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {channel.name}
                                </Text>
                            </View>
                        </View>
                    </View>
                );
                break;
            case 'CatchupTV_Player':
                var index = contentContext.channelData.programIndex == contentContext.channelData.programList.length - 1 ? 0 : contentContext.channelData.programIndex + 1;
                var program = contentContext.channelData.programList[index];
                var channel = contentContext.channelData.channel;
                return (
                    <View style={{ flex: 1, marginTop: getCorrectHeight(deviceContext, 40) }}>
                        <Text
                            numberOfLines={1}
                            style={{
                                textAlign: 'right',
                                opacity: 0.6,
                                color: styling.texts.subtext_color,
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                lineHeight: getFontSize(deviceContext, 'Small'),
                                fontWeight: 'bold',
                            }}
                        >
                            {lang.getTranslation(userContext, 'next_programs')}
                        </Text>
                        <View
                            style={{
                                width: getRealWidth(deviceContext) * 0.2,
                                flexDirection: 'row',
                                alignItems: 'center',
                                padding: getCorrectHeight(deviceContext, 10),
                                marginTop: getCorrectHeight(deviceContext, 3),
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                            }}
                        >
                            <Image
                                resizeMethod={'scale'}
                                resizeMode={'contain'}
                                source={{ uri: utils.getCorrectStoragePrefix(appContext, true, channel.images?.square) }}
                                style={{
                                    width: getCorrectHeight(deviceContext, 30),
                                    height: getCorrectHeight(deviceContext, 30),
                                }}
                            ></Image>
                            <View style={{ marginLeft: 20, width: getRealWidth(deviceContext) * 0.15 }}>
                                <Text
                                    numberOfLines={2}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {program.n}
                                </Text>
                                <Text
                                    numberOfLines={2}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {moment.unix(program?.s).format('ddd ' + deviceContext.clockSetting)} - {moment.unix(program?.e).format('ddd ' + deviceContext.clockSetting)}
                                </Text>
                            </View>
                        </View>
                    </View>
                );
                break;
            case 'Cam_Player':
                var index = contentContext.camData.camIndex == contentContext.camData.camList.length - 1 ? 0 : contentContext.camData.camIndex + 1;
                var cam = contentContext.camData.camList[index];
                return (
                    <View style={{ flex: 1, marginTop: getCorrectHeight(deviceContext, 40) }}>
                        <Text
                            numberOfLines={1}
                            style={{
                                textAlign: 'right',
                                opacity: 0.6,
                                color: styling.texts.subtext_color,
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                lineHeight: getFontSize(deviceContext, 'Small'),
                                fontWeight: 'bold',
                            }}
                        >
                            {lang.getTranslation(userContext, 'next_cam')}
                        </Text>
                        <View
                            style={{
                                width: getRealWidth(deviceContext) * 0.2,
                                flexDirection: 'row',
                                alignItems: 'center',
                                padding: getCorrectHeight(deviceContext, 10),
                                marginTop: getCorrectHeight(deviceContext, 3),
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                            }}
                        >
                            <Image
                                resizeMethod={'scale'}
                                resizeMode={'contain'}
                                source={{ uri: utils.getCorrectStoragePrefix(appContext, true, cam.images?.square) }}
                                style={{
                                    width: getCorrectHeight(deviceContext, 30),
                                    height: getCorrectHeight(deviceContext, 30),
                                }}
                            ></Image>
                            <View style={{ marginLeft: 20, width: getRealWidth(deviceContext) * 0.12 }}>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {cam.number}
                                </Text>
                                <Text
                                    numberOfLines={2}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {cam.name}
                                </Text>
                            </View>
                        </View>
                    </View>
                );
                break;
            case 'Series_Player':
                var index = contentContext.seriesData.episodeIndex + 1 <= contentContext.seriesData.episodeList.length - 1 ? contentContext.seriesData.episodeIndex + 1 : -1;
                if (index == -1) {
                    return null;
                }
                var episode = contentContext.seriesData.episodeList[index];
                if (episode == undefined) {
                    return null;
                }
                return (
                    <View style={{ flex: 1 }}>
                        <Text
                            numberOfLines={1}
                            style={{
                                textAlign: 'right',
                                opacity: 0.6,
                                color: styling.texts.subtext_color,
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                lineHeight: getFontSize(deviceContext, 'Small'),
                                fontWeight: 'bold',
                            }}
                        >
                            {lang.getTranslation(userContext, 'next_episode')}
                        </Text>
                        <View
                            style={{
                                width: getRealWidth(deviceContext) * 0.4,
                                flexDirection: 'row',
                                padding: 10,
                                marginTop: getCorrectHeight(deviceContext, 3),
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                            }}
                        >
                            <Image
                                resizeMethod={'scale'}
                                resizeMode={'cover'}
                                source={{ uri: utils.getCorrectStoragePrefix(appContext, true, episode.images?.widescreen) }}
                                style={{
                                    width: getCorrectWidth(deviceContext, 130),
                                    height: getCorrectWidth(deviceContext, 72),
                                }}
                            ></Image>
                            <View style={{ flex: 1, marginHorizontal: 20, justifyContent: 'flex-start' }}>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Large'),
                                        fontWeight: 'normal',
                                        marginBottom: 5,
                                    }}
                                >
                                    {episode.number}. {episode.name}
                                </Text>
                                <Text
                                    numberOfLines={3}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {getDescription(episode)}
                                </Text>
                            </View>
                        </View>
                    </View>
                );
                break;
            case 'Shorts_Player':
                var index = contentContext.shortData.shortIndex + 1 <= contentContext.shortData.shortList.length - 1 ? contentContext.shortData.shortIndex + 1 : -1;
                if (index == -1) {
                    return null;
                }
                var short = contentContext.shortData.shortList[index];
                if (short == undefined) {
                    return null;
                }
                return (
                    <View style={{ flex: 1 }}>
                        <Text
                            numberOfLines={1}
                            style={{
                                textAlign: 'right',
                                opacity: 0.6,
                                color: styling.texts.subtext_color,
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                lineHeight: getFontSize(deviceContext, 'Small'),
                                fontWeight: 'bold',
                            }}
                        >
                            {lang.getTranslation(userContext, 'next_shorts')}
                        </Text>
                        <View
                            style={{
                                width: getRealWidth(deviceContext) * 0.4,
                                flexDirection: 'row',
                                padding: 10,
                                marginTop: getCorrectHeight(deviceContext, 3),
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                            }}
                        >
                            <Image
                                resizeMethod={'scale'}
                                resizeMode={'cover'}
                                source={{ uri: utils.getCorrectStoragePrefix(appContext, true, short.images?.widescreen) }}
                                style={{
                                    width: getCorrectWidth(deviceContext, 130),
                                    height: getCorrectWidth(deviceContext, 72),
                                }}
                            ></Image>
                            <View style={{ flex: 1, marginHorizontal: 20, justifyContent: 'flex-start' }}>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Large'),
                                        fontWeight: 'normal',
                                        marginBottom: 5,
                                    }}
                                >
                                    {short.name}
                                </Text>
                                <Text
                                    numberOfLines={3}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {getDescription(short)}
                                </Text>
                            </View>
                        </View>
                    </View>
                );
                break;
            case 'Course_Player':
                var index = contentContext.courseData.lessonIndex + 1 <= contentContext.courseData.lessonList.length - 1 ? contentContext.courseData.lessonIndex + 1 : -1;
                if (index == -1) {
                    return null;
                }
                var lesson = contentContext.courseData.lessonList[index];
                if (lesson == undefined) {
                    return null;
                }
                return (
                    <View style={{ flex: 1 }}>
                        <Text
                            numberOfLines={1}
                            style={{
                                textAlign: 'right',
                                opacity: 0.6,
                                color: styling.texts.subtext_color,
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                lineHeight: getFontSize(deviceContext, 'Small'),
                                fontWeight: 'bold',
                            }}
                        >
                            {lang.getTranslation(userContext, 'next_lessons')}
                        </Text>
                        <View
                            style={{
                                width: getRealWidth(deviceContext) * 0.4,
                                flexDirection: 'row',
                                padding: 10,
                                marginTop: getCorrectHeight(deviceContext, 3),
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                            }}
                        >
                            <Image
                                resizeMethod={'scale'}
                                resizeMode={'cover'}
                                source={{ uri: utils.getCorrectStoragePrefix(appContext, true, lesson.images?.widescreen) }}
                                style={{
                                    width: getCorrectWidth(deviceContext, 130),
                                    height: getCorrectWidth(deviceContext, 72),
                                }}
                            ></Image>
                            <View style={{ flex: 1, marginHorizontal: 20, justifyContent: 'flex-start' }}>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Large'),
                                        fontWeight: 'normal',
                                        marginBottom: 5,
                                    }}
                                >
                                    {lesson.name}
                                </Text>
                                <Text
                                    numberOfLines={3}
                                    style={{
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {getDescription(lesson)}
                                </Text>
                            </View>
                        </View>
                    </View>
                );
                break;
            default:
                return '';
                break;
        }
    };
    const onNextLocal = () => {
        onNext(screenName);
    };
    const onPreviousLocal = () => {
        onPrevious(screenName);
    };
    const showPayPerViewButton = () => {
        switch (screenName) {
            case 'Channel_Player':
                if (contentContext.channelData.channel.payperview.enable && !payperviewStatus('Channel', contentContext.channelData.channel._id, userContext).isPayPerView) {
                    return true;
                } else {
                    return false;
                }
                break;
            case 'Cam_Player':
                if (contentContext.camData.cam.payperview.enable && !payperviewStatus('Channel', contentContext.camData.cam._id, userContext).isPayPerView) {
                    return true;
                } else {
                    return false;
                }
                break;
            default:
                return false;
                break;
        }
    };

    const renderChannel = ({ item, focusRepeatContext, index }: CreateListRenderItemInfo<any>) => {
        return (
            <Pressable
                focusRepeatContext={focusRepeatContext}
                style={{ marginVertical: getCorrectWidth(deviceContext, 5) }}
                animatorOptions={getFocusStyling('Buttons', appContext)}
                hasPreferredFocus={index == contentContext.channelData.channelIndex}
                onPress={() => onPressChannel(item)}
            >
                <View style={{ backgroundColor: 'rgba(255, 255, 255, 0.1)', flex: 1, alignItems: 'center' }}>
                    <Image
                        source={{ uri: utils.getCorrectStoragePrefix(appContext, true, item?.images?.square) }}
                        resizeMethod={'scale'}
                        resizeMode={'cover'}
                        style={{
                            width: getCorrectWidth(deviceContext, 38),
                            height: getCorrectWidth(deviceContext, 38),
                        }}
                    />
                </View>
            </Pressable>
        );
    };
    const renderProgram = ({ item, focusRepeatContext, index }: CreateListRenderItemInfo<any>) => {
        var currentTime = moment().unix();
        var isLive = currentTime > item.s && currentTime < item.e;
        return (
            <Pressable
                style={{ margin: getCorrectWidth(deviceContext, 5) }}
                focusRepeatContext={focusRepeatContext}
                animatorOptions={getFocusStyling('Buttons', appContext)}
                hasPreferredFocus={index == contentContext.channelData.channelIndex}
                onPress={() => {
                    if (isLive) playChannelIndex(index, channelSelected);
                    else {
                        setProgramSelected(item);
                        global.showProgramDetails = true;
                        setShowProgramDetails(true);
                    }
                }}
            >
                <View
                    style={{
                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        height: getCorrectHeight(deviceContext, 70),
                        flexDirection: 'row',
                        width: estimatedProgramSize - getCorrectWidth(deviceContext, 10),
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <View style={{ justifyContent: 'center', width: getCorrectHeight(deviceContext, 124) }}>
                        <View style={{ position: 'absolute', top: 5, left: 5, zIndex: 999, flexDirection: 'row' }}>
                            {isLive && <View style={{ justifyContent: 'center', marginTop: getCorrectWidth(deviceContext, 1) }}>{getLiveTag(lang.getTranslation(userContext, 'live').toUpperCase(), appContext, deviceContext)}</View>}
                            {contentContext.channelData.channel.interactivetv.catchuptv_enabled == true && (
                                <View style={{ justifyContent: 'center', marginRight: 5, marginTop: getCorrectWidth(deviceContext, 1) }}>{getReplayTag(lang.getTranslation(userContext, 'replay').toUpperCase(), appContext, deviceContext)}</View>
                            )}
                        </View>
                        <Image
                            source={{ uri: utils.getCorrectStoragePrefix(appContext, true, item?.m) }}
                            resizeMethod={'scale'}
                            resizeMode={'cover'}
                            style={{
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                width: getCorrectHeight(deviceContext, 124),
                                height: getCorrectHeight(deviceContext, 70),
                            }}
                        />
                    </View>
                    <View style={{ paddingHorizontal: getCorrectWidth(deviceContext, 10), width: estimatedProgramSize - getCorrectWidth(deviceContext, 5) - getCorrectHeight(deviceContext, 124) }}>
                        <Text
                            numberOfLines={1}
                            style={{
                                color: styling.texts.subtext_color,
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Large'),
                                lineHeight: getFontSize(deviceContext, 'Large') * 1.1 + (deviceContext.isAndroid ? 0 : 5),
                                fontWeight: 'normal',
                            }}
                        >
                            {item.n}
                        </Text>
                        <View style={{ flexDirection: 'row', paddingBottom: getCorrectWidth(deviceContext, 5), alignItems: 'center' }}>
                            <Text
                                numberOfLines={1}
                                style={{
                                    color: styling.texts.subtext_color,
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                    lineHeight: getFontSize(deviceContext, 'Normal') * 1.1,
                                    fontWeight: 'normal',
                                }}
                            >
                                {moment.unix(item?.s).format('dddd ' + deviceContext.clockSetting) + ' - ' + moment.unix(item?.e).format(deviceContext.clockSetting)}
                            </Text>
                        </View>
                        <Text
                            numberOfLines={2}
                            style={{
                                color: styling.texts.subtext_color,
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Normal'),
                                lineHeight: getFontSize(deviceContext, 'Normal') * 1.1,
                                fontWeight: 'normal',
                            }}
                        >
                            {item.d}
                        </Text>
                    </View>
                </View>
            </Pressable>
        );
    };

    return (
        <View
            style={{ flex: 1, width: deviceContext.isPhone ? getRealHeight(deviceContext, 'screen') : null, alignSelf: deviceContext.isTablet ? 'center' : null, alignItems: deviceContext.isIos ? 'flex-start' : 'center' }}
            focusContext={focusContext}
        >
            {ProgramModal}
            <View style={{ flex: 1, width: deviceContext.isPhone ? getRealHeight(deviceContext) * (deviceContext.isIos ? 0.9 : 0.95) : getRealWidth(deviceContext) * 0.95 }} focusContext={focusContext}>
                <View style={{ flex: 2, flexDirection: 'row' }}>
                    <View style={{ flex: 1, flexDirection: 'row' }}>
                        <View style={{ flexDirection: 'row', height: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 35 : 50), marginTop: deviceContext.isPhone ? 6 : 20 }}>
                            {(screenName == 'Channel_Player' || screenName == 'Cam_Player' || screenName == 'CatchupTV_Player' || screenName == 'Recording_Player') && (
                                <View>{getImage(contentContext, screenName, styling, deviceContext, appContext)}</View>
                            )}
                            {getLogo(contentContext, screenName) == '' && (
                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <Text
                                        numberOfLines={1}
                                        style={{
                                            marginLeft: getCorrectWidth(deviceContext, 10),
                                            color: styling.texts.title_color == '' ? '#ffffff' : styling.texts.title_color,
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, deviceContext.isPhone ? 'ExtraLarge' : 'ExtraExtraLarge'),
                                            fontWeight: 'normal',
                                            lineHeight: getFontSize(deviceContext, deviceContext.isPhone ? 'ExtraLarge' : 'ExtraExtraLarge') * 1.1,
                                        }}
                                    >
                                        {getTitle(contentContext, screenName)}
                                    </Text>
                                </View>
                            )}
                        </View>
                        {getLogo(contentContext, screenName) != '' && (
                            <View style={{ flexDirection: 'row', alignItems: 'center', height: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 35 : 50) }}>
                                <RemoteImageHeightWidth uri={utils.getCorrectStoragePrefix(appContext, true, getLogo(contentContext, screenName))} desiredWidth={0} desiredHeight={50} deviceContext={deviceContext} />
                            </View>
                        )}
                    </View>
                    <View style={{ flex: 1, alignItems: 'flex-end', justifyContent: 'center', height: getCorrectHeight(deviceContext, deviceContext.isKaiOs ? 35 : 55), marginRight: 20 }}>
                        <View style={{ flexDirection: 'row' }}>
                            {getAgeRating(contentContext, screenName) != '' && (
                                <View style={{ marginRight: getCorrectWidth(deviceContext, 5), backgroundColor: '#000', borderRadius: appContext.application.theme.tags.radius / 2 }}>
                                    <Text
                                        numberOfLines={1}
                                        style={{
                                            color: '#fff',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, deviceContext.isPhone ? 'Small' : 'Normal'),
                                            fontWeight: 'normal',
                                            lineHeight: getFontSize(deviceContext, deviceContext.isPhone ? 'Small' : 'Normal') * 1.1,
                                            margin: deviceContext.isPhone ? 5 : 5,
                                        }}
                                    >
                                        {getAgeRating(contentContext, screenName)}
                                    </Text>
                                </View>
                            )}
                            {!showNextView && !showPreviousView && screenName == 'CatchupTV_Player' && (
                                <View style={{ backgroundColor: '#4169e1', borderRadius: appContext.application.theme.tags.radius / 2 }}>
                                    <Text
                                        numberOfLines={1}
                                        style={{
                                            color: '#fff',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, deviceContext.isPhone ? 'Small' : 'Normal'),
                                            fontWeight: 'normal',
                                            lineHeight: getFontSize(deviceContext, deviceContext.isPhone ? 'Small' : 'Normal') * 1.1,
                                            margin: deviceContext.isPhone ? 5 : 5,
                                        }}
                                    >
                                        {lang.getTranslation(userContext, 'replay').toUpperCase()}
                                    </Text>
                                </View>
                            )}
                            {!showNextView && !showPreviousView && screenName == 'Channel_Player' && (
                                <View style={{ backgroundColor: 'crimson', borderRadius: appContext.application.theme.tags.radius / 2 }}>
                                    <Text
                                        numberOfLines={1}
                                        style={{
                                            color: '#fff',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, deviceContext.isPhone ? 'Small' : 'Normal'),
                                            fontWeight: 'normal',
                                            lineHeight: getFontSize(deviceContext, deviceContext.isPhone ? 'Small' : 'Normal') * 1.1,
                                            margin: deviceContext.isPhone ? 5 : 5,
                                        }}
                                    >
                                        {lang.getTranslation(userContext, 'live').toUpperCase()}
                                    </Text>
                                </View>
                            )}
                            {!showNextView && !showPreviousView && screenName == 'Recording_Player' && (
                                <View style={{ backgroundColor: 'crimson', borderRadius: appContext.application.theme.tags.radius / 2 }}>
                                    <Text
                                        numberOfLines={1}
                                        style={{
                                            color: '#fff',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, deviceContext.isPhone ? 'Small' : 'Normal'),
                                            fontWeight: 'normal',
                                            lineHeight: getFontSize(deviceContext, deviceContext.isPhone ? 'Small' : 'Normal') * 1.1,
                                            margin: deviceContext.isPhone ? 5 : 5,
                                        }}
                                    >
                                        {lang.getTranslation(userContext, 'recording').toUpperCase()}
                                    </Text>
                                </View>
                            )}
                            {!showNextView && !showPreviousView && getAgeRating(contentContext, screenName) != '' && (screenName == 'Movie_Player' || screenName == 'Series_Player' || screenName == 'Course_Player') && (
                                <View style={{ backgroundColor: '#000', borderRadius: appContext.application.theme.tags.radius / 2 }}>
                                    <Text
                                        numberOfLines={1}
                                        style={{
                                            color: '#fff',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, deviceContext.isPhone ? 'Normal' : 'Large'),
                                            fontWeight: 'normal',
                                            lineHeight: getFontSize(deviceContext, deviceContext.isPhone ? 'Normal' : 'Large') * 1.1,
                                            margin: getCorrectWidth(deviceContext, 4),
                                        }}
                                    >
                                        {getAgeRating(contentContext, screenName)}
                                    </Text>
                                </View>
                            )}
                            <View>
                                {showPreviousView ? getPrevious() : null}
                                {showNextView ? getNext() : null}
                            </View>
                        </View>
                    </View>
                </View>
                <View style={{ flex: 2 }}></View>
                {screenName === 'Channel_Player' || screenName === 'CatchupTV_Player' ? (
                    <View style={{ height: getCorrectHeight(deviceContext, 80), flexDirection: 'row', marginBottom: getCorrectHeight(deviceContext, 2), marginHorizontal: 5 }}>
                        <View style={{ flex: 1, marginRight: getCorrectWidth(deviceContext, 5) }}>
                            <ScrollerWrapper
                                onPress={() => {
                                    if (channelScrollIndex.current - 1 >= 0) channelRef.current.scrollToIndex({ animated: true, index: --channelScrollIndex.current });
                                }}
                                onLongPress={() => {
                                    if (channelScrollIndex.current === 0) return;
                                    channelRef.current.scrollToIndex({ animated: true, index: 0 });
                                    channelScrollIndex.current = 0;
                                }}
                                type="vertical"
                            >
                                <FontAwesomeIcon icon={faArrowUp} color={'#fff'} size={getFontSize(deviceContext, 'Small')}></FontAwesomeIcon>
                            </ScrollerWrapper>
                            <View style={{ flex: 1 }} focusOptions={{ group: 'channel_selector', focusKey: 'channel_selector', nextFocusRight: 'program_selector' }}>
                                <FlashList
                                    ref={channelRef}
                                    data={channels}
                                    estimatedItemSize={estimatedChannelSize}
                                    renderItem={renderChannel}
                                    decelerationRate="fast"
                                    horizontal={false}
                                    showsVerticalScrollIndicator={false}
                                    initialScrollIndex={contentContext.channelData.channelIndex}
                                    type={'row'}
                                    style={{ flex: 1 }}
                                    focusOptions={{ nextFocusDown: 'scrubber', verticalViewportOffset: 8 }}
                                    onScrollBeginDrag={clearControlsTimeout}
                                    onScrollEndDrag={resetControlsTimeout}
                                    snapToInterval={estimatedChannelSize}
                                    snapToAlignment="start"
                                />
                            </View>
                            <ScrollerWrapper
                                onPress={() => {
                                    if (channelScrollIndex.current + 1 < channels.length) channelRef.current.scrollToIndex({ animated: true, index: ++channelScrollIndex.current });
                                }}
                                onLongPress={() => {
                                    if (channelScrollIndex.current === channels.length - 1) return;
                                    channelRef.current.scrollToIndex({ animated: true, index: channels.length - 1 });
                                    channelScrollIndex.current = channels.length - 1;
                                }}
                                type="vertical"
                            >
                                <FontAwesomeIcon icon={faArrowDown} color={'#fff'} size={getFontSize(deviceContext, 'Small')}></FontAwesomeIcon>
                            </ScrollerWrapper>
                        </View>
                        <View style={{ flex: 8, flexDirection: 'row' }}>
                            <ScrollerWrapper
                                onPress={() => {
                                    if (programScrollIndex.current - 1 >= 0) programRef.current.scrollToIndex({ animated: true, index: --programScrollIndex.current });
                                }}
                                onLongPress={() => {
                                    if (programSelectedIndex === 0) return;
                                    programRef.current.scrollToIndex({ animated: true, index: 0 });
                                    programScrollIndex.current = 0;
                                }}
                                type="horizontal"
                            >
                                <FontAwesomeIcon icon={faArrowLeft} color={'#fff'} size={getFontSize(deviceContext, 'Small')}></FontAwesomeIcon>
                            </ScrollerWrapper>
                            <View style={{ flex: 6 }} focusOptions={{ focusKey: 'program_selector', group: 'program_selector', nextFocusDown: 'scrubber' }}>
                                <FlashList
                                    ref={programRef}
                                    key={programSelectedIndex}
                                    data={channelEpgSelected}
                                    estimatedItemSize={estimatedProgramSize}
                                    horizontal={true}
                                    decelerationRate="fast"
                                    style={{ flex: 1 }}
                                    showsHorizontalScrollIndicator={false}
                                    renderItem={renderProgram}
                                    initialScrollIndex={programSelectedIndex}
                                    type={'row'}
                                    focusOptions={{ nextFocusLeft: 'channel_selector' }}
                                    onScrollBeginDrag={clearControlsTimeout}
                                    onScrollEndDrag={resetControlsTimeout}
                                    snapToInterval={estimatedProgramSize}
                                    snapToAlignment="start"
                                />
                            </View>
                            <ScrollerWrapper
                                onPress={() => {
                                    if (programScrollIndex.current + 1 < channelEpgSelected.length) programRef.current.scrollToIndex({ animated: true, index: ++programScrollIndex.current });
                                }}
                                type="horizontal"
                                onLongPress={() => {
                                    if (programScrollIndex.current === channelEpgSelected.length - 1) return;
                                    programRef.current.scrollToIndex({ animated: true, index: channelEpgSelected.length - 1 });
                                    programScrollIndex.current = channelEpgSelected.length - 1;
                                }}
                            >
                                <FontAwesomeIcon icon={faArrowRight} color={'#fff'} size={getFontSize(deviceContext, 'Small')}></FontAwesomeIcon>
                            </ScrollerWrapper>
                        </View>
                    </View>
                ) : (
                    <View style={{ flex: 1.5, flexDirection: 'row', marginBottom: getCorrectHeight(deviceContext, 2), marginHorizontal: 5 }}>
                        {screenName == 'Cam_Player' && (
                            <View style={{ flex: 1.5, justifyContent: 'flex-end' }}>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        marginTop: getCorrectHeight(deviceContext, 3),
                                        opacity: 0.6,
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Small'),
                                        lineHeight: getFontSize(deviceContext, 'Small') * 1.1,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'current_program')}
                                </Text>
                                <View
                                    style={{
                                        justifyContent: 'center',
                                        padding: getCorrectHeight(deviceContext, 10),
                                        backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                        marginTop: getCorrectHeight(deviceContext, 3),
                                        borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                                    }}
                                >
                                    <View style={{ flexDirection: 'row' }}>
                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                color: styling.texts.subtext_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Large'),
                                                lineHeight: getFontSize(deviceContext, 'Large') * 1.1 + (deviceContext.isAndroid ? 0 : 5),
                                                fontWeight: 'normal',
                                            }}
                                        >
                                            {contentContext.camData.cam.name}
                                        </Text>
                                    </View>
                                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                        {contentContext.camData.cam.interactivetv.catchuptv_enabled == true && (
                                            <View style={{ justifyContent: 'center', marginTop: getCorrectWidth(deviceContext, 1) }}>{getLiveTag(lang.getTranslation(userContext, 'live').toUpperCase(), appContext, deviceContext)}</View>
                                        )}
                                        {contentContext.camData.cam.interactivetv.catchuptv_enabled == true && (
                                            <View style={{ justifyContent: 'center', marginRight: 5, marginTop: getCorrectWidth(deviceContext, 1) }}>
                                                {getReplayTag(lang.getTranslation(userContext, 'replay').toUpperCase(), appContext, deviceContext)}
                                            </View>
                                        )}
                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                color: styling.texts.subtext_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                lineHeight: getFontSize(deviceContext, 'Normal') * 1.1,
                                                fontWeight: 'normal',
                                            }}
                                        >
                                            {getSubText(contentContext, screenName, deviceContext)}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        )}
                        {screenName == 'Series_Player' && playerType !== 'trailer' && (
                            <View style={{ flex: 1.5, justifyContent: 'flex-end' }}>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        marginTop: getCorrectHeight(deviceContext, 3),
                                        opacity: 0.6,
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Small'),
                                        lineHeight: getFontSize(deviceContext, 'Small') * 1.1,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'current_episode')}
                                </Text>
                                <View
                                    style={{
                                        justifyContent: 'center',
                                        padding: getCorrectHeight(deviceContext, 10),
                                        backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                        marginTop: getCorrectHeight(deviceContext, 3),
                                        borderRadius: getPressableStyling(styling.components?.button?._id, appContext).borderRadius ?? 0,
                                    }}
                                >
                                    <View style={{ marginTop: getCorrectHeight(deviceContext, 3) }}>
                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                color: styling.texts.subtext_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Large'),
                                                lineHeight: getFontSize(deviceContext, 'Large') * 1.1 + (deviceContext.isAndroid ? 0 : 5),
                                                fontWeight: 'normal',
                                            }}
                                        >
                                            {getSubText(contentContext, screenName, deviceContext)}
                                        </Text>
                                        <Text
                                            numberOfLines={2}
                                            style={{
                                                color: styling.texts.subtext_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                lineHeight: getFontSize(deviceContext, 'Normal') * 1.1,
                                                fontWeight: 'normal',
                                            }}
                                        >
                                            {getDescription(contentContext.seriesData.episode)}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        )}
                        {screenName == 'Course_Player' && (
                            <View style={{ flex: 1.5, justifyContent: 'flex-end' }}>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        marginTop: getCorrectHeight(deviceContext, 3),
                                        opacity: 0.6,
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Small'),
                                        lineHeight: getFontSize(deviceContext, 'Small') * 1.1,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'current_lesson')}
                                </Text>
                                <View
                                    style={{
                                        justifyContent: 'center',
                                        padding: getCorrectHeight(deviceContext, 10),
                                        backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                        marginTop: getCorrectHeight(deviceContext, 3),
                                        borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                                    }}
                                >
                                    <View style={{ marginTop: getCorrectHeight(deviceContext, 3) }}>
                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                color: styling.texts.subtext_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Large'),
                                                lineHeight: getFontSize(deviceContext, 'Large') * 1.1 + (deviceContext.isAndroid ? 0 : 5),
                                                fontWeight: 'normal',
                                            }}
                                        >
                                            {getSubText(contentContext, screenName, deviceContext)}
                                        </Text>
                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                color: styling.texts.subtext_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                lineHeight: getFontSize(deviceContext, 'Normal') * 1.1,
                                                fontWeight: 'normal',
                                            }}
                                        >
                                            {getDescription(contentContext.courseData.lesson)}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        )}
                        {screenName == 'Short_Player' && (
                            <View style={{ flex: 1.5, justifyContent: 'flex-end' }}>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        marginTop: getCorrectHeight(deviceContext, 3),
                                        opacity: 0.6,
                                        color: styling.texts.subtext_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Small'),
                                        lineHeight: getFontSize(deviceContext, 'Small') * 1.1,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'current_short')}
                                </Text>
                                <View
                                    style={{
                                        justifyContent: 'center',
                                        padding: getCorrectHeight(deviceContext, 10),
                                        backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                        marginTop: getCorrectHeight(deviceContext, 3),
                                        borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                                    }}
                                >
                                    <View style={{ marginTop: getCorrectHeight(deviceContext, 3) }}>
                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                color: styling.texts.subtext_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Large'),
                                                lineHeight: getFontSize(deviceContext, 'Large') * 1.1 + (deviceContext.isAndroid ? 0 : 5),
                                                fontWeight: 'normal',
                                            }}
                                        >
                                            {getSubText(contentContext, screenName, deviceContext)}
                                        </Text>
                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                color: styling.texts.subtext_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                lineHeight: getFontSize(deviceContext, 'Normal') * 1.1,
                                                fontWeight: 'normal',
                                            }}
                                        >
                                            {getDescription(contentContext.shortData.short)}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        )}
                        {screenName == 'Cam_Player' && (
                            <View style={{ flex: 1, flexDirection: 'row', marginLeft: 10, alignItems: 'flex-end' }}>
                                <View style={{ flex: 1 }}>
                                    <Text
                                        numberOfLines={1}
                                        style={{
                                            opacity: 0.6,
                                            color: styling.texts.subtext_color,
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Small'),
                                            lineHeight: getFontSize(deviceContext, 'Small') * 1.1,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {lang.getTranslation(userContext, 'next_programs')}
                                    </Text>
                                    <View
                                        style={{
                                            justifyContent: 'center',
                                            padding: getCorrectHeight(deviceContext, 10),
                                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                            marginTop: getCorrectHeight(deviceContext, 3),
                                            borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                                        }}
                                    >
                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                color: styling.texts.subtext_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Large'),
                                                lineHeight: getFontSize(deviceContext, 'Large') * 1.1 + (deviceContext.isAndroid ? 0 : 5),
                                                fontWeight: 'normal',
                                            }}
                                        >
                                            {contentContext.camData.cam.name}
                                        </Text>
                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                marginTop: getCorrectHeight(deviceContext, 3),
                                                color: styling.texts.subtext_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                lineHeight: getFontSize(deviceContext, 'Normal') * 1.1,
                                                fontWeight: 'normal',
                                            }}
                                        >
                                            {getSubTextNext(contentContext, screenName, deviceContext)}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        )}
                        {screenName == 'Series_Player' && playerType !== 'trailer' && getTitleNext(contentContext, screenName, userContext) != '' && (
                            <View style={{ flex: 1, flexDirection: 'row', alignItems: 'flex-end', marginLeft: 10 }}>
                                <View style={{ flex: 1 }}>
                                    <Text
                                        numberOfLines={1}
                                        style={{
                                            marginTop: getCorrectHeight(deviceContext, 3),
                                            opacity: 0.6,
                                            color: styling.texts.subtext_color,
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Small'),
                                            lineHeight: getFontSize(deviceContext, 'Small') * 1.1,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {lang.getTranslation(userContext, 'next_episode').toUpperCase()}
                                    </Text>
                                    <View
                                        style={{
                                            justifyContent: 'center',
                                            padding: getCorrectHeight(deviceContext, 10),
                                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                            marginTop: getCorrectHeight(deviceContext, 3),
                                            borderRadius: getPressableStyling(styling.components?.button?._id, appContext).borderRadius ?? 0,
                                        }}
                                    >
                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                color: styling.texts.subtext_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                lineHeight: getFontSize(deviceContext, 'Normal') * 1.1,
                                            }}
                                        >
                                            {getTitleNext(contentContext, screenName, userContext)}
                                        </Text>
                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                marginTop: getCorrectHeight(deviceContext, 3),
                                                color: styling.texts.subtext_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Small'),
                                                lineHeight: getFontSize(deviceContext, 'Small') * 1.1,
                                            }}
                                        >
                                            {getSubSubTextNext(contentContext, screenName, userContext)}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        )}
                        {screenName == 'Course_Player' && getTitleNext(contentContext, screenName, userContext) != '' && (
                            <View style={{ flex: 1, flexDirection: 'row', marginLeft: 10, alignItems: 'flex-end' }}>
                                <View style={{ flex: 1 }}>
                                    <Text
                                        numberOfLines={1}
                                        style={{
                                            opacity: 0.6,
                                            color: styling.texts.subtext_color,
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Small'),
                                            lineHeight: getFontSize(deviceContext, 'Small') * 1.1,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {lang.getTranslation(userContext, 'next_lesson')}
                                    </Text>
                                    <View
                                        style={{
                                            justifyContent: 'center',
                                            padding: getCorrectHeight(deviceContext, 10),
                                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                            marginTop: getCorrectHeight(deviceContext, 3),
                                            borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                                        }}
                                    >
                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                color: styling.texts.subtext_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Large'),
                                                lineHeight: getFontSize(deviceContext, 'Large') * 1.1 + (deviceContext.isAndroid ? 0 : 5),
                                                fontWeight: 'normal',
                                            }}
                                        >
                                            {getTitleNext(contentContext, screenName, userContext)}
                                        </Text>
                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                marginTop: getCorrectHeight(deviceContext, 3),
                                                color: styling.texts.subtext_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                lineHeight: getFontSize(deviceContext, 'Normal') * 1.1,
                                                fontWeight: 'normal',
                                            }}
                                        >
                                            {getSubSubTextNext(contentContext, screenName, userContext)}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        )}
                        {screenName == 'Short_Player' && getTitleNext(contentContext, screenName, userContext) != '' && (
                            <View style={{ flex: 1, flexDirection: 'row', marginLeft: 10, alignItems: 'flex-end' }}>
                                <View style={{ flex: 1 }}>
                                    <Text
                                        numberOfLines={1}
                                        style={{
                                            opacity: 0.6,
                                            color: styling.texts.subtext_color,
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Small'),
                                            lineHeight: getFontSize(deviceContext, 'Small') * 1.1,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {lang.getTranslation(userContext, 'next_short')}
                                    </Text>
                                    <View
                                        style={{
                                            justifyContent: 'center',
                                            padding: getCorrectHeight(deviceContext, 10),
                                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                            marginTop: getCorrectHeight(deviceContext, 3),
                                            borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                                        }}
                                    >
                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                color: styling.texts.subtext_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Large'),
                                                lineHeight: getFontSize(deviceContext, 'Large') * 1.1 + (deviceContext.isAndroid ? 0 : 5),
                                                fontWeight: 'normal',
                                            }}
                                        >
                                            {getTitleNext(contentContext, screenName, userContext)}
                                        </Text>
                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                marginTop: getCorrectHeight(deviceContext, 3),
                                                color: styling.texts.subtext_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                lineHeight: getFontSize(deviceContext, 'Normal') * 1.1,
                                                fontWeight: 'normal',
                                            }}
                                        >
                                            {getSubSubTextNext(contentContext, screenName, userContext)}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        )}
                    </View>
                )}

                <View style={{ alignItems: 'center', justifyContent: 'center', marginBottom: getCorrectHeight(deviceContext, deviceContext.isPhone ? 3 : 5) }}>
                    <ProgressBars
                        onClose={() => {
                            onClose();
                        }}
                        playerContext={playerContext}
                        contentContext={contentContext}
                        onScrubberDrag={onScrubberDrag}
                        deviceContext={deviceContext}
                        userContext={userContext}
                        appContext={appContext}
                        focusContext={focusContext}
                    />
                </View>
                <View style={{ flexDirection: 'row', marginBottom: getCorrectHeight(deviceContext, 5) }}>
                    <View style={{ flex: 1, flexDirection: 'row', alignItems: 'flex-end' }}>
                        <ButtonIcon focusContext={focusContext} icon={faArrowLeft} text={'back'} deviceContext={deviceContext} styling={styling} appContext={appContext} theFunction={onBack} userContext={userContext} casting={undefined} />

                        <ButtonIcon icon={faChevronDown} text={'hide'} deviceContext={deviceContext} styling={styling} appContext={appContext} theFunction={onClose} userContext={userContext} casting={undefined} />

                        {screenName != 'Movie_Player' && screenName != 'Recording_Player' && playerType !== 'trailer' ? (
                            <ButtonIcon
                                focusContext={focusContext}
                                icon={faStepBackward}
                                text={'previous'}
                                deviceContext={deviceContext}
                                styling={styling}
                                appContext={appContext}
                                theFunction={onPreviousLocal}
                                userContext={userContext}
                                showFunction={showPrevious}
                                hideFunction={hidePrevious}
                                casting={undefined}
                            />
                        ) : null}

                        {screenName != 'Movie_Player' && screenName != 'Recording_Player' && playerType !== 'trailer' ? (
                            <ButtonIcon
                                focusContext={focusContext}
                                icon={faStepForward}
                                text={'next'}
                                deviceContext={deviceContext}
                                styling={styling}
                                appContext={appContext}
                                theFunction={onNextLocal}
                                userContext={userContext}
                                showFunction={showNext}
                                hideFunction={hideNext}
                                casting={undefined}
                            />
                        ) : null}
                        {screenName != 'Channel_Player' && screenName != 'Cam_Player' && !deviceContext.isPhone ? (
                            <ButtonIcon
                                focusContext={focusContext}
                                icon={faChevronDoubleLeft}
                                text={'rewind'}
                                deviceContext={deviceContext}
                                styling={styling}
                                appContext={appContext}
                                theFunction={onRewind}
                                userContext={userContext}
                                casting={undefined}
                            />
                        ) : null}

                        {screenName == 'Channel_Player' && contentContext.channelData.channel.interactivetv.pausetv_enabled && !deviceContext.isPhone ? (
                            <ButtonIcon
                                focusContext={focusContext}
                                icon={faChevronDoubleLeft}
                                text={'rewind'}
                                deviceContext={deviceContext}
                                styling={styling}
                                appContext={appContext}
                                theFunction={onRewind}
                                userContext={userContext}
                                casting={undefined}
                            />
                        ) : null}

                        {screenName != 'Channel_Player' && screenName != 'Cam_Player' ? (
                            <ButtonIcon
                                focusContext={focusContext}
                                icon={paused ? faPlay : faPause}
                                text={'play'}
                                deviceContext={deviceContext}
                                styling={styling}
                                appContext={appContext}
                                theFunction={onPlayPause}
                                userContext={userContext}
                                casting={undefined}
                            />
                        ) : null}
                        {screenName == 'Channel_Player' && contentContext.channelData.channel.interactivetv.pausetv_enabled ? (
                            <ButtonIcon
                                focusContext={focusContext}
                                icon={paused ? faPlay : faPause}
                                text={'play'}
                                deviceContext={deviceContext}
                                styling={styling}
                                appContext={appContext}
                                theFunction={onPlayPause}
                                userContext={userContext}
                                casting={undefined}
                            />
                        ) : null}

                        {screenName != 'Channel_Player' && screenName != 'Cam_Player' && !deviceContext.isPhone ? (
                            <ButtonIcon
                                focusContext={focusContext}
                                icon={faChevronDoubleRight}
                                text={'forward'}
                                deviceContext={deviceContext}
                                styling={styling}
                                appContext={appContext}
                                theFunction={onForward}
                                userContext={userContext}
                                casting={undefined}
                            />
                        ) : null}
                        {screenName == 'Channel_Player' && contentContext.channelData.channel.interactivetv.pausetv_enabled && !deviceContext.isPhone ? (
                            <ButtonIcon
                                focusContext={focusContext}
                                icon={faChevronDoubleRight}
                                text={'forward'}
                                deviceContext={deviceContext}
                                styling={styling}
                                appContext={appContext}
                                theFunction={onForward}
                                userContext={userContext}
                                casting={undefined}
                            />
                        ) : null}

                        {isRestart ? (
                            <ButtonIcon focusContext={focusContext} icon={faHistory} text={'restart'} deviceContext={deviceContext} styling={styling} appContext={appContext} theFunction={onRestart} userContext={userContext} casting={undefined} />
                        ) : null}

                        {((deviceContext.isPhone && netInfo.isInternetReachable) || !deviceContext.isPhone) && appContext.application.settings.allow_favorites && (
                            <ButtonIcon
                                focusContext={focusContext}
                                icon={isFavorite ? faHeartSolid : faHeart}
                                text={'favorite'}
                                deviceContext={deviceContext}
                                styling={styling}
                                appContext={appContext}
                                theFunction={manageFavorite}
                                userContext={userContext}
                                casting={undefined}
                            />
                        )}
                        {(deviceContext.isPhone && netInfo.isInternetReachable) || deviceContext.isPhone || deviceContext.isTablet || deviceContext.isWebTV ? (
                            <ButtonIcon
                                focusContext={focusContext}
                                icon={deviceContext.isIos ? faAirplay : faChromecast}
                                text={'casting'}
                                deviceContext={deviceContext}
                                styling={styling}
                                appContext={appContext}
                                theFunction={deviceContext.isIos ? openAirplay : openChromecast}
                                userContext={userContext}
                                casting={playerContext.casting}
                            />
                        ) : null}
                    </View>
                    <View style={{ flex: 1, justifyContent: 'flex-end', flexDirection: 'row' }}>
                        {showPayPerViewButton() && (
                            <Pressable
                                style={{
                                    height: getCorrectWidth(deviceContext, deviceContext.isPhone ? 35 : 40),
                                    padding: getCorrectHeight(deviceContext, deviceContext.isPhone ? 1 : 3),
                                    borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                                }}
                                animatorOptions={getFocusStyling('Buttons', appContext)}
                                focusOptions={{
                                    focusKey: 'ppv',
                                    nextFocusUp: 'scrubber',
                                    nextFocusLeft: ['casting', 'favorite', 'forward', 'play', 'rewind', 'next'],
                                }}
                                focusContext={focusContext}
                                hasPreferredFocus={false}
                                onPress={() => showPayPerView(true)}
                            >
                                <View
                                    style={{
                                        flex: 1,
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: 'rgba(255, 255,255, 0.1)',
                                        paddingHorizontal: getCorrectWidth(deviceContext, deviceContext.isPhone ? 10 : 20),
                                        borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                                    }}
                                >
                                    <FontAwesomeIcon icon={faCoins} color={getPressableTextStyling(styling.components?.button?._id, appContext).color} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>
                                    <Text
                                        numberOfLines={1}
                                        style={{
                                            marginLeft: 10,
                                            color: styling.texts.title_color == '' ? '#ffffff' : styling.texts.title_color,
                                            fontWeight: 'normal',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, deviceContext.isPhone ? 'Small' : 'Normal'),
                                            lineHeight: getFontSize(deviceContext, deviceContext.isPhone ? 'Small' : 'Normal') * 1.1,
                                            textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                            textShadowOffset: { width: -1, height: 1 },
                                            textShadowRadius: 2,
                                        }}
                                    >
                                        {lang.getTranslation(userContext, 'purchase_option')}
                                    </Text>
                                </View>
                            </Pressable>
                        )}
                        {(screenName == 'Channel_Player' || screenName == 'CatchupTV_Player') && (
                            <Pressable
                                style={{
                                    height: getCorrectWidth(deviceContext, deviceContext.isPhone ? 35 : 40),
                                    padding: getCorrectHeight(deviceContext, deviceContext.isPhone ? 1 : 3),
                                    borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                                }}
                                hasPreferredFocus={false}
                                focusContext={focusContext}
                                animatorOptions={getFocusStyling('Buttons', appContext)}
                                focusOptions={{
                                    focusKey: 'list',
                                    nextFocusUp: 'scrubber',
                                    nextFocusLeft: ['ppv', 'casting', 'favorite', 'forward', 'play', 'rewind', 'next'],
                                }}
                                onPress={() => showChannels()}
                            >
                                <View
                                    style={{
                                        flex: 1,
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: 'rgba(255, 255,255, 0.1)',
                                        paddingHorizontal: getCorrectWidth(deviceContext, deviceContext.isPhone ? 10 : 20),
                                        borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                                    }}
                                >
                                    <FontAwesomeIcon icon={faRectangleHistory} color={getPressableTextStyling(styling.components?.button?._id, appContext).color} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>
                                    <Text
                                        numberOfLines={1}
                                        style={{
                                            marginLeft: 10,
                                            color: styling.texts.title_color == '' ? '#ffffff' : styling.texts.title_color,
                                            fontWeight: 'normal',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, deviceContext.isPhone ? 'Small' : 'Normal'),
                                            lineHeight: getFontSize(deviceContext, deviceContext.isPhone ? 'Small' : 'Normal') * 1.1,
                                            textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                            textShadowOffset: { width: -1, height: 1 },
                                            textShadowRadius: 2,
                                        }}
                                    >
                                        {lang.getTranslation(userContext, 'channel_list')}
                                    </Text>
                                </View>
                            </Pressable>
                        )}
                        {screenName == 'Series_Player' && playerType !== 'trailer' && (
                            <Pressable
                                style={{
                                    height: getCorrectWidth(deviceContext, deviceContext.isPhone ? 35 : 40),
                                    padding: getCorrectHeight(deviceContext, deviceContext.isPhone ? 1 : 3),
                                    borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                                }}
                                animatorOptions={getFocusStyling('Buttons', appContext)}
                                focusOptions={{
                                    focusKey: 'list',
                                    nextFocusUp: 'scrubber',
                                    nextFocusLeft: ['ppv', 'casting', 'favorite', 'forward', 'play', 'rewind', 'next'],
                                }}
                                focusContext={focusContext}
                                hasPreferredFocus={false}
                                onPress={() => showEpisodes()}
                            >
                                <View
                                    style={{
                                        flex: 1,
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: 'rgba(255, 255,255, 0.1)',
                                        paddingHorizontal: getCorrectWidth(deviceContext, deviceContext.isPhone ? 10 : 20),
                                        borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                                    }}
                                >
                                    <FontAwesomeIcon icon={faRectangleHistory} color={getPressableTextStyling(styling.components?.button?._id, appContext).color} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>
                                    <Text
                                        numberOfLines={1}
                                        style={{
                                            marginLeft: 10,
                                            color: styling.texts.title_color == '' ? '#ffffff' : styling.texts.title_color,
                                            fontWeight: 'normal',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, deviceContext.isPhone ? 'Small' : 'Normal'),
                                            lineHeight: getFontSize(deviceContext, deviceContext.isPhone ? 'Small' : 'Normal') * 1.1,
                                            textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                            textShadowOffset: { width: -1, height: 1 },
                                            textShadowRadius: 2,
                                        }}
                                    >
                                        {lang.getTranslation(userContext, 'episodes')}
                                    </Text>
                                </View>
                            </Pressable>
                        )}
                        {screenName == 'Course_Player' && (
                            <Pressable
                                style={{
                                    height: getCorrectWidth(deviceContext, deviceContext.isPhone ? 35 : 40),
                                    padding: getCorrectHeight(deviceContext, deviceContext.isPhone ? 1 : 3),
                                    borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                                }}
                                focusContext={focusContext}
                                animatorOptions={getFocusStyling('Buttons', appContext)}
                                focusOptions={{
                                    focusKey: 'list',
                                    nextFocusUp: 'scrubber',
                                    nextFocusLeft: ['ppv', 'casting', 'favorite', 'forward', 'play', 'rewind', 'next'],
                                }}
                                hasPreferredFocus={false}
                                onPress={() => showLessons()}
                            >
                                <View
                                    style={{
                                        flex: 1,
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: 'rgba(255, 255,255, 0.1)',
                                        paddingHorizontal: getCorrectWidth(deviceContext, deviceContext.isPhone ? 10 : 20),
                                        borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                                    }}
                                >
                                    <FontAwesomeIcon icon={faRectangleHistory} color={getPressableTextStyling(styling.components?.button?._id, appContext).color} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>
                                    <Text
                                        numberOfLines={1}
                                        style={{
                                            marginLeft: 10,
                                            color: styling.texts.title_color == '' ? '#ffffff' : styling.texts.title_color,
                                            fontWeight: 'normal',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, deviceContext.isPhone ? 'Small' : 'Normal'),
                                            lineHeight: getFontSize(deviceContext, deviceContext.isPhone ? 'Small' : 'Normal') * 1.1,
                                            textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                            textShadowOffset: { width: -1, height: 1 },
                                            textShadowRadius: 2,
                                        }}
                                    >
                                        {lang.getTranslation(userContext, 'lessons')}
                                    </Text>
                                </View>
                            </Pressable>
                        )}
                        {screenName == 'Short_Player' && (
                            <Pressable
                                style={{
                                    height: getCorrectWidth(deviceContext, deviceContext.isPhone ? 35 : 40),
                                    padding: getCorrectHeight(deviceContext, deviceContext.isPhone ? 1 : 3),
                                    borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                                }}
                                animatorOptions={getFocusStyling('Buttons', appContext)}
                                focusOptions={{
                                    focusKey: 'list',
                                    nextFocusUp: 'scrubber',
                                    nextFocusLeft: ['ppv', 'casting', 'favorite', 'forward', 'play', 'rewind', 'next'],
                                }}
                                focusContext={focusContext}
                                hasPreferredFocus={false}
                                onPress={() => showShorts()}
                            >
                                <View
                                    style={{
                                        flex: 1,
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: 'rgba(255, 255,255, 0.1)',
                                        paddingHorizontal: getCorrectWidth(deviceContext, deviceContext.isPhone ? 10 : 20),
                                        borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                                    }}
                                >
                                    <FontAwesomeIcon icon={faRectangleHistory} color={getPressableTextStyling(styling.components?.button?._id, appContext).color} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>
                                    <Text
                                        numberOfLines={1}
                                        style={{
                                            marginLeft: 10,
                                            color: styling.texts.title_color == '' ? '#ffffff' : styling.texts.title_color,
                                            fontWeight: 'normal',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, deviceContext.isPhone ? 'Small' : 'Normal'),
                                            lineHeight: getFontSize(deviceContext, deviceContext.isPhone ? 'Small' : 'Normal') * 1.1,
                                            textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                            textShadowOffset: { width: -1, height: 1 },
                                            textShadowRadius: 2,
                                        }}
                                    >
                                        {lang.getTranslation(userContext, 'shorts')}
                                    </Text>
                                </View>
                            </Pressable>
                        )}
                        <Pressable
                            style={{
                                height: getCorrectWidth(deviceContext, deviceContext.isPhone ? 35 : 40),
                                padding: getCorrectHeight(deviceContext, deviceContext.isPhone ? 1 : 3),
                                borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                            }}
                            animatorOptions={getFocusStyling('Buttons', appContext)}
                            focusOptions={{
                                focusKey: 'list',
                                nextFocusUp: 'scrubber',
                            }}
                            focusContext={focusContext}
                            hasPreferredFocus={false}
                            onPress={() => showSettings()}
                        >
                            <View
                                style={{
                                    flex: 1,
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: 'rgba(255, 255,255, 0.1)',
                                    paddingHorizontal: getCorrectWidth(deviceContext, deviceContext.isPhone ? 10 : 20),
                                    borderRadius: getPressableStyling(styling?.components?.button?.button?._id, appContext).borderRadius,
                                }}
                            >
                                <FontAwesomeIcon icon={faCog} color={getPressableTextStyling(styling.components?.button?._id, appContext).color} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        marginLeft: 10,
                                        color: styling.texts.title_color == '' ? '#ffffff' : styling.texts.title_color,
                                        fontWeight: 'normal',
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, deviceContext.isPhone ? 'Small' : 'Normal'),
                                        lineHeight: getFontSize(deviceContext, deviceContext.isPhone ? 'Small' : 'Normal') * 1.1,
                                        textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                        textShadowOffset: { width: -1, height: 1 },
                                        textShadowRadius: 2,
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'settings')}
                                </Text>
                            </View>
                        </Pressable>
                    </View>
                </View>
            </View>
        </View>
    );
};

export default Player_Standard;

export const ButtonIcon = ({ icon, text, deviceContext, styling, appContext, theFunction, userContext, showFunction, hideFunction, focusContext, casting }: any) => {
    const [showText, setShowText] = React.useState(deviceContext.isWebTV || deviceContext.isPhone || deviceContext.isTablet ? true : false);
    return (
        <View focusContext={focusContext} style={{ width: getCorrectWidth(deviceContext, deviceContext.isPhone ? 35 : 40) }}>
            <Pressable
                focusContext={focusContext}
                style={{
                    width: getCorrectWidth(deviceContext, deviceContext.isPhone ? 35 : 40),
                    height: getCorrectWidth(deviceContext, deviceContext.isPhone ? 35 : 40),
                    padding: getCorrectHeight(deviceContext, deviceContext.isPhone ? 1 : 3),
                    borderRadius: 100,
                }}
                animatorOptions={getFocusStyling('Buttons', appContext)}
                focusOptions={{
                    nextFocusUp: 'scrubber',
                    focusKey: text,
                }}
                hasPreferredFocus={text == 'back' ? true : false}
                onPress={() => {
                    theFunction();
                }}
                onFocus={() => {
                    setShowText(true);
                    if (showFunction != null) {
                        showFunction();
                    }
                }}
                onMouseOver={() => {
                    setShowText(true);
                    if (showFunction != null) {
                        showFunction();
                    }
                }}
                onBlur={() => {
                    setShowText(false);
                    if (hideFunction != null) {
                        hideFunction();
                    }
                }}
                onMouseOut={() => {
                    setShowText(false);
                    if (hideFunction != null) {
                        hideFunction();
                    }
                }}
            >
                <View
                    style={{
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        borderRadius: 100,
                        flexDirection: 'row',
                    }}
                >
                    {text == 'forward' && <Image source={require('../../../../assets/images/ff.png')} resizeMode={'cover'} style={{ height: getCorrectWidth(deviceContext, 11), width: getCorrectWidth(deviceContext, 11) }}></Image>}
                    {text == 'rewind' && <Image source={require('../../../../assets/images/rew.png')} resizeMode={'cover'} style={{ height: getCorrectWidth(deviceContext, 11), width: getCorrectWidth(deviceContext, 11) }}></Image>}
                    {text != 'rewind' && text != 'forward' && (
                        <FontAwesomeIcon
                            icon={icon}
                            color={casting != undefined && (casting.type == 'Chromecast' || casting.type == 'Airplay') ? 'green' : getPressableTextStyling(styling.components?.button?._id, appContext).color}
                            size={getFontSize(deviceContext, 'Large')}
                        ></FontAwesomeIcon>
                    )}
                </View>
            </Pressable>
            <View style={{ alignItems: 'center', opacity: 0.5 }}>
                <Text
                    numberOfLines={1}
                    style={{
                        textAlign: 'center',
                        color: showText ? styling.texts.subtext_color : 'transparent',
                        fontFamily: deviceContext.fontType,
                        fontSize: getFontSize(deviceContext, deviceContext.isPhone ? 'X-Small' : 'Small'),
                        lineHeight: getFontSize(deviceContext, 'Small') * 1.1,
                        fontWeight: 'normal',
                    }}
                >
                    {lang.getTranslation(userContext, text)}
                </Text>
            </View>
        </View>
    );
};
