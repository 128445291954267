import { createStackNavigator } from '@react-navigation/stack';
import { isPlatformAndroidtv, isPlatformFiretv, isPlatformTvos } from '@rnv/renative';
import * as React from 'react';
import { createNativeStackNavigator } from '../../libs/@react-navigation';
import {
    About,
    Apps,
    Code,
    Connect,
    Details,
    Devices,
    Disclaimer,
    Forgot,
    Change,
    GetStarted,
    Language,
    Loader,
    Login,
    LoginCode,
    Logout,
    Onboarding,
    Profiles,
    Profile_Add,
    Profile_Edit,
    Pwa,
    Pwa_,
    Register,
    Renewal,
    Services,
    Subscription,
    Support,
    Updates,
    Messages,
    Manual,
    Registration,
    Anonymous,
    Wallet,
    Sharing,
    Redirect,
    Settings,
} from '../../screens/account';
import {
    Album,
    Albums,
    Album_Player,
    Cams,
    Cam_Player,
    CatchupTV_Player,
    Channels,
    Channel_Player,
    Course,
    Courses,
    Course_Player,
    Home,
    Movie,
    Movies,
    Movie_Player,
    Podcast,
    Podcasts,
    Podcast_Player,
    Program,
    Radios,
    Radio_Player,
    Recordings,
    Recording_Player,
    Search,
    Seasons,
    Series,
    Series_Player,
    Short,
    Shorts,
    Short_Player,
    TVGuide,
    Watchlist,
    Favorites,
    Rentals,
    Purchases,
    Downloads,
    Games,
    Educations,
    Game_Webview,
    Overview,
    Actors,
    Programs,
    Recording,
} from '../../screens/content';
import { AppContext } from '../context/appContext';

const App = isPlatformTvos || isPlatformAndroidtv || isPlatformFiretv ? createNativeStackNavigator() : createStackNavigator();
const MainStack = isPlatformTvos || isPlatformAndroidtv || isPlatformFiretv ? createNativeStackNavigator() : createStackNavigator();

function MainStackScreen() {
    const appContext = React.useContext(AppContext);
    return (
        <MainStack.Navigator screenOptions={{ cardStyle: { flex: 1 }, headerShown: false, gestureDirection: 'horizontal', gestureEnabled: true }}>
            <App.Screen
                name="Language"
                component={Language}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Services"
                component={Services}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Login"
                component={Login}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Pwa"
                component={Pwa}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Pwa_"
                component={Pwa_}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Loader"
                component={Loader}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Forgot"
                component={Forgot}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Change"
                component={Change}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="GetStarted"
                component={GetStarted}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Onboarding"
                component={Onboarding}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Register"
                component={Register}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Details"
                component={Details}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Connect"
                component={Connect}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="LoginCode"
                component={LoginCode}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Sharing"
                component={Sharing}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Redirect"
                component={Redirect}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />

            <App.Screen
                name="Code"
                component={Code}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Disclaimer"
                component={Disclaimer}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Profiles"
                component={Profiles}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Switch Profile"
                component={Profiles}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Profile_Edit"
                component={Profile_Edit}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Profile_Add"
                component={Profile_Add}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Home"
                component={Home}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Cams"
                component={Cams}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Channels"
                component={Channels}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Courses"
                component={Courses}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Course"
                component={Course}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Movies"
                component={Movies}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Movie"
                component={Movie}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Shorts"
                component={Shorts}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Short"
                component={Short}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Radio"
                component={Radios}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Search"
                component={Search}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Series"
                component={Series}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Seasons"
                component={Seasons}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Podcasts"
                component={Podcasts}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Podcast"
                component={Podcast}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Music"
                component={Albums}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Album"
                component={Album}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="TV Guide"
                component={TVGuide}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Program"
                component={Program}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Programs"
                component={Programs}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Recordings"
                component={Recordings}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Recording"
                component={Recording}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Watchlist"
                component={Watchlist}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Favorites"
                component={Favorites}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Rentals"
                component={Rentals}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Purchases"
                component={Purchases}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Downloads"
                component={Downloads}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Games"
                component={Games}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Education"
                component={Educations}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen
                name="Overview"
                component={Overview}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
               <App.Screen
                name="Actors"
                component={Actors}
                options={{
                    cardStyle: { flex: 1, backgroundColor: 'transparent' },
                    title: appContext.application.branding.name,
                }}
            />
            <App.Screen name="Subscription" component={Subscription} options={{ cardStyle: { flex: 1 }, title: appContext.application.branding.name }} />
            <App.Screen name="Registration" component={Registration} options={{ cardStyle: { flex: 1 }, title: appContext.application.branding.name }} />
            <App.Screen name="Anonymous" component={Anonymous} options={{ cardStyle: { flex: 1 }, title: appContext.application.branding.name }} />
            <App.Screen name="Apps" component={Apps} options={{ cardStyle: { flex: 1 }, title: appContext.application.branding.name }} />
            <App.Screen name="About" component={About} options={{ cardStyle: { flex: 1 }, title: appContext.application.branding.name }} />
            <App.Screen name="Wallet" component={Wallet} options={{ cardStyle: { flex: 1 }, title: appContext.application.branding.name }} />
            <App.Screen name="Devices" component={Devices} options={{ cardStyle: { flex: 1 }, title: appContext.application.branding.name }} />
            <App.Screen name="Updates" component={Updates} options={{ cardStyle: { flex: 1 }, title: appContext.application.branding.name }} />
            <App.Screen name="Logout" component={Logout} options={{ cardStyle: { flex: 1 }, title: appContext.application.branding.name }} />
            <App.Screen name="Support" component={Support} options={{ cardStyle: { flex: 1 }, title: appContext.application.branding.name }} />
            <App.Screen name="Renewal" component={Renewal} options={{ cardStyle: { flex: 1 }, title: appContext.application.branding.name }} />
            <App.Screen name="Messages" component={Messages} options={{ cardStyle: { flex: 1 }, title: appContext.application.branding.name }} />
            <App.Screen name="Manual" component={Manual} options={{ cardStyle: { flex: 1 }, title: appContext.application.branding.name }} />
            <App.Screen name="Settings" component={Settings} options={{ cardStyle: { flex: 1 }, title: appContext.application.branding.name }} />
        </MainStack.Navigator>
    );
}

export const AppStack = () => {
    const appContext = React.useContext(AppContext);

    return (
        <App.Navigator screenOptions={{ presentation: 'card', cardStyle: { flex: 1 }, gestureDirection: 'horizontal', gestureEnabled: false }}>
            <App.Screen
                name="Main"
                component={MainStackScreen}
                options={{
                    headerShown: false,
                    cardStyle: {
                        flex: 1,
                    },
                }}
            />
            <App.Screen name="Cam_Player" options={{ headerShown: false, title: appContext.application.branding.name }} component={Cam_Player} />
            <App.Screen name="Channel_Player" options={{ headerShown: false, title: appContext.application.branding.name }} component={Channel_Player} />
            <App.Screen name="CatchupTV_Player" options={{ headerShown: false, title: appContext.application.branding.name }} component={CatchupTV_Player} />
            <App.Screen name="Movie_Player" options={{ headerShown: false, title: appContext.application.branding.name }} component={Movie_Player} />
            <App.Screen name="Short_Player" options={{ headerShown: false, title: appContext.application.branding.name }} component={Short_Player} />
            <App.Screen name="Series_Player" options={{ headerShown: false, title: appContext.application.branding.name }} component={Series_Player} />
            <App.Screen name="Podcast_Player" options={{ headerShown: false, title: appContext.application.branding.name }} component={Podcast_Player} />
            <App.Screen name="Radio_Player" options={{ headerShown: false, title: appContext.application.branding.name }} component={Radio_Player} />
            <App.Screen name="Album_Player" options={{ headerShown: false, title: appContext.application.branding.name }} component={Album_Player} />
            <App.Screen name="Course_Player" options={{ headerShown: false, title: appContext.application.branding.name }} component={Course_Player} />
            <App.Screen name="Recording_Player" options={{ headerShown: false, title: appContext.application.branding.name }} component={Recording_Player} />
            <App.Screen name="Game_Webview" options={{ headerShown: false, title: appContext.application.branding.name }} component={Game_Webview} />
        </App.Navigator>
    );
};
