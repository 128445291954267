import { ActivityIndicator, setFocus, View, ViewGroup, ScrollView } from '@scriptx-com/xtv-toolkit';
import * as React from 'react';
import { BackHandler } from 'react-native';
import { useContext, useState } from 'react';
import { DeviceContext } from '../../../context/deviceContext';
import { UserContext } from '../../../context/userContext';
import lang from '../../../languages/languages';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { getFontSize } from '../../../styling/fontSizes';
import { getRealHeight, getRealWidth } from '../../../styling/realDeviceSizes';
import { ScreenFadeIn } from '../../animations/screenFadeIn';
import { AppContext } from '../../../context/appContext';
import { colorShade } from '../../../styling/colorShade';
import Text from '../text';
import { getPressableStyling, getFocusStyling, getPressableTextStyling } from '../helpers/helper';
import { faCoins } from '@fortawesome/pro-light-svg-icons';
import Pressable from '../../../../application/builder/components/pressable/pressable';
import Image from '../image/image';
import { ContentContext } from '../../../context/contentContext';
import { RemoteImageHeightWidth } from '../remoteimage/remoteimage';
import moment from 'moment';
import { managePayPerView, watchingStatus } from '../../../data/account';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { getGlobals } from '../../../../globals';
import { PayPerView as PPV } from '../../../../reporting/models/paypayview/payperview';
import { CustomerStatus } from '../../../../reporting/models/shared';
import utils from '../../../general/utils';

export interface ParentalProps {
    styling: any;
    stylingContent: any;
    setShowModal: any;
    startContent: any;
    data?: any;
    type?: any;
    focusContext: any;
}
const PayPerView = ({ focusContext, styling, setShowModal, data, type, stylingContent, startContent }: ParentalProps) => {
    const deviceContext = useContext(DeviceContext);
    const userContext = useContext(UserContext);
    const appContext = useContext(AppContext);
    const contentContext = useContext(ContentContext);

    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState('');

    const checkParentalCode = (transaction_type) => {
        if (transaction_type == 'rental') {
            requestPayPerViewRental(transaction_type);
        } else {
            requestPayPerViewPurchase(transaction_type);
        }
    };

    const width = getRealWidth(deviceContext) * (deviceContext.isPhone ? 1.5 : 0.6);

    const requestPayPerViewRental = (transaction_type) => {
        setLoading(true);
        if (userContext.user.wallet.balance < getPriceRenting()) {
            setResult(lang.getTranslation(userContext, 'not_enough_balance'));
            setLoading(false);
        } else {
            requestPayPerView(transaction_type);
        }
    };
    const requestPayPerViewPurchase = (transaction_type) => {
        setLoading(true);
        if (userContext.user.wallet.balance < getPricePurchase()) {
            setResult(lang.getTranslation(userContext, 'not_enough_balance'));
            setLoading(false);
        } else {
            requestPayPerView(transaction_type);
        }
    };

    const requestPayPerView = (transaction_type) => {
        setResult('');
        var reporting = {
            timestamp: moment().unix(),
            content: [] as any, ///invullen
            service: userContext.user.crm,
            app: {
                version: deviceContext.version,
                package: deviceContext.packageId,
            },
            location: {
                city: userContext.city,
                country: userContext.country,
                latitude: userContext.latitude,
                longitude: userContext.longitude,
            },
            device: {
                model: deviceContext.model,
                type: deviceContext.type,
                os: deviceContext.os,
                uuid: deviceContext.uniqueId,
                formFactor: deviceContext.formFactor,
            },
            network: {
                ipAddress: deviceContext.ipAddress,
                type: deviceContext.networkType ?? 'unknown',
                provider: deviceContext.provider ?? 'unknown',
            },
            customer: {
                username: userContext.userId,
                status: CustomerStatus.ACTIVE,
                zipcode: userContext.user.customer.zipcode,
                state: userContext.user.customer.state,
                city: userContext.user.customer.city,
                country: userContext.user.customer.country,
                shopping_cartName: userContext.user.customer.shopping_cartName,
                shopping_cartId: userContext.user.customer.shopping_cartId,
                credit_amount: userContext.user.customer.credit_amount,
                wallet_amount: userContext.user.customer.wallet_amount,
                billing_country: userContext.user.customer.billing_country,
                billing_currency: userContext.user.customer.billing_currency,
                billing_taxrate: userContext.user.customer.billing_taxrate,
                originalCreatedAt: userContext.user.customer.originalCreatedAt,
                is_deleted: false,
                productStatus: userContext.user.variant,
                subscriptionStatus: userContext.user.status,
                renewedDate: userContext.user.start,
                renewalDate: userContext.user.expire,
                payment_provider: userContext.user.customer.payment_provider_name,
                payment_auto_recurring: userContext.user.customer.payment_provider_id,

                plan: userContext.user.plan,
                baseSubscription: userContext.user.base_product,
                extraSubscriptions: userContext.user.extra_products,
            },
        } as PPV;
        var rental =
            getGlobals().AppApiUrl +
            '/payperview/rent?client=' +
            encodeURIComponent(appContext.application.client) +
            '&deployment=' +
            encodeURIComponent(appContext.application.deployment) +
            '&service=' +
            encodeURIComponent(appContext.application.branding.service);

        var purchase =
            getGlobals().AppApiUrl +
            '/payperview/purchase?client=' +
            encodeURIComponent(appContext.application.client) +
            '&deployment=' +
            encodeURIComponent(appContext.application.deployment) +
            '&service=' +
            encodeURIComponent(appContext.application.branding.service);

        fetch(transaction_type == 'rental' ? rental : purchase, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username: userContext.userId,
                currency: userContext.user.customer.billing_currency,
                content_id: data._id,
                content_type: type,
                reporting: reporting,
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.success == true) {
                    managePayPerView(
                        transaction_type,
                        data.payperview.rule,
                        moment().unix(),
                        moment().add(data.payperview?.rule?.access_length, data.payperview.rule.access_type.toLowerCase()).unix(),
                        type,
                        data,
                        type,
                        data.payperview.rule.access_type,
                        data.payperview?.rule?.access_length,
                        transaction_type == 'rental' ? getPriceRenting() : getPricePurchase(),
                        appContext.application,
                        userContext
                    );
                    userContext.setWalletBalance(userContext.walletBalance - (transaction_type == 'rental' ? getPriceRenting() : getPricePurchase()));
                    setResult(lang.getTranslation(userContext, response.reason));
                    setTimeout(() => {
                        setLoading(false);
                        startContent();
                    }, 4000);
                } else {
                    setResult(lang.getTranslation(userContext, response.reason));
                    setTimeout(() => {
                        setLoading(false);
                    }, 6000);
                }
            })
            .catch((result) => {
                setLoading(false);
                setResult(result);
            });
    };
    const getImage = () => {
        switch (type) {
            case 'Movie':
                return data.images.poster;
                break;
            case 'Series':
                return data.images.backdrop;
                break;
            case 'Seasons':
                return data.images.widescreen;
                break;
            case 'Short':
                return data.images.poster;
                break;
            case 'Channel':
                return data.images.square;
                break;
            case 'Program': //future use per program ppv
                return data.images.square;
                break;
            case 'Cam':
                return data.images.square;
                break;
            case 'Game':
                return data.images.widescreen;
                break;
            case 'Course':
                return data.images.poster;
                break;
            case 'Podcast':
                return data.images.square;
                break;
            case 'Radio':
                return data.images.square;
                break;
            case 'Album':
                return data.images.square;
                break;
            default:
                break;
        }
    };
    const getPriceRenting = () => {
        var test = data.payperview.currencies.find((t) => t.iso3 == userContext.user.customer.billing_currency);
        if (test != undefined) {
            return test.credits_rented;
        } else {
            return 0;
        }
    };
    const getPricePurchase = () => {
        var test = data.payperview.currencies.find((t) => t.iso3 == userContext.user.customer.billing_currency);
        if (test != undefined) {
            return test.credits_purchased;
        } else {
            return 0;
        }
    };

    const LogoRow = ({ item, type, deviceContext, appContext, contentContext, styling, channel }) => {
        if (type == 'Program') {
            var program = [] as any;

            if (contentContext.epgTv != undefined) {
                var epgChannel = contentContext.epgTv?.find((e) => e.i == item._id);
                if (epgChannel != undefined) {
                    var currentTime = moment().unix();
                    program = epgChannel.epg.find((e) => e.s <= currentTime && e.e >= currentTime);
                }
            }

            return (
                <View style={{ flexDirection: 'column' }}>
                    <View>
                        <Image
                            deviceContext={deviceContext}
                            resizeMethod={'scale'}
                            resizeMode={'contain'}
                            source={{ uri: utils.getCorrectStoragePrefix(appContext, channel != undefined ? channel.images?.square : contentContext.channelData.channel.images?.square) }}
                            style={{
                                width: getCorrectWidth(deviceContext, 35),
                                height: getCorrectWidth(deviceContext, 35),
                            }}
                        ></Image>
                    </View>
                    <View>
                        {program?.l != undefined && program?.l != '' && (
                            <View style={{ justifyContent: 'center', marginVertical: getCorrectHeight(deviceContext, 10), marginTop: -15 }}>
                                <RemoteImageHeightWidth uri={program?.l} desiredWidth={getRealWidth(deviceContext) * 0.15} desiredHeight={50} deviceContext={deviceContext} />
                            </View>
                        )}
                        {(program?.l == undefined || program?.l == '') && (
                            <View style={{ justifyContent: 'center' }}>
                                <Text
                                    numberOfLines={2}
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                        color: '#fff',
                                        includeFontPadding: false,
                                        textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                        textShadowOffset: { width: -1, height: 1 },
                                        textShadowRadius: 2,
                                    }}
                                >
                                    {program.n}
                                </Text>
                            </View>
                        )}
                    </View>
                </View>
            );
        } else if (type == 'Recording') {
            return (
                <View style={{ flexDirection: 'column' }}>
                    <View>
                        <Image
                            deviceContext={deviceContext}
                            resizeMethod={'scale'}
                            resizeMode={'contain'}
                            source={{ uri: utils.getCorrectStoragePrefix(appContext, contentContext.recordingData.channel.images?.square) }}
                            style={{
                                width: getCorrectWidth(deviceContext, 35),
                                height: getCorrectWidth(deviceContext, 35),
                            }}
                        ></Image>
                    </View>
                    <View>
                        {contentContext.recordingData.program?.l != undefined && contentContext.recordingData.program?.l != '' && (
                            <View style={{ justifyContent: 'center', marginVertical: getCorrectHeight(deviceContext, 10), marginTop: -15 }}>
                                <RemoteImageHeightWidth uri={contentContext.recordingData.program?.l} desiredWidth={getRealWidth(deviceContext) * 0.15} desiredHeight={50} deviceContext={deviceContext} />
                            </View>
                        )}
                        {(contentContext.recordingData.program?.l == undefined || contentContext.recordingData.program?.l == '') && (
                            <View style={{ justifyContent: 'center' }}>
                                <Text
                                    numberOfLines={2}
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'ExtraExtraExtraLarge'),
                                        color: '#fff',
                                        includeFontPadding: false,
                                        textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                        textShadowOffset: { width: -1, height: 1 },
                                        textShadowRadius: 2,
                                    }}
                                >
                                    {contentContext.recordingData.program.n}
                                </Text>
                            </View>
                        )}
                    </View>
                </View>
            );
        } else if (type == 'Movie' || type == 'Course' || type == 'Short' || type == 'Seasons') {
            return (
                <View style={{ flexDirection: 'column' }}>
                    <View>
                        {item.images.logo != undefined && item.images.logo != '' && (
                            <View style={{ justifyContent: 'center', marginBottom: getCorrectHeight(deviceContext, 10), marginTop: -15 }}>
                                <RemoteImageHeightWidth uri={utils.getCorrectStoragePrefix(appContext, true, item?.images.logo)} desiredWidth={getRealWidth(deviceContext) * 0.15} desiredHeight={50} deviceContext={deviceContext} />
                            </View>
                        )}
                        {(item.images.logo == undefined || item.images.logo == '') && (
                            <View style={{ justifyContent: 'center' }}>
                                <Text
                                    numberOfLines={2}
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'ExtraLarge'),

                                        color: '#fff',
                                        includeFontPadding: false,
                                        textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                        textShadowOffset: { width: -1, height: 1 },
                                        textShadowRadius: 2,
                                    }}
                                >
                                    {item.name}
                                </Text>
                            </View>
                        )}
                    </View>
                </View>
            );
        }
    };
    const StatusTimeRow = ({ item, type, deviceContext, appContext, userContext, contentContext, styling }) => {
        if (type == 'Program') {
            var program = [] as any;

            if (contentContext.epgTv != undefined) {
                var epgChannel = contentContext.epgTv?.find((e) => e.i == item._id);
                if (epgChannel != undefined) {
                    var currentTime = moment().unix();
                    program = epgChannel.epg.find((e) => e.s <= currentTime && e.e >= currentTime);
                }
            }

            var currentTime = moment().unix();
            var isLive = program.s < currentTime && program.e > currentTime;
            var isPast = program.s < currentTime && program.e < currentTime;
            var recordingDone = false;
            var isRecorded = false;
            var isReminded = false;
            var recordingPlanned = false;
            var recordingProgress = false;
            var currentTime = moment().unix();
            if (currentTime > program?.e) {
                recordingDone = true;
            } else if (currentTime > program?.s && currentTime < program?.e) {
                recordingProgress = true;
            } else {
                recordingPlanned = true;
            }

            return (
                <View style={{ flexDirection: 'column' }}>
                    <View style={{ flexDirection: 'row', marginBottom: getCorrectHeight(deviceContext, 5), marginTop: getCorrectHeight(deviceContext, 5) }}>
                        {isLive && (
                            <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                                <Text
                                    style={{
                                        backgroundColor: 'crimson',
                                        color: appContext.application.theme.tags.color,
                                        borderRadius: appContext.application.theme.tags.radius / 2,
                                        fontSize: getFontSize(deviceContext, 'Small'),
                                        padding: getCorrectWidth(deviceContext, 2),
                                        paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                        includeFontPadding: false,
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'live').toUpperCase()}
                                </Text>
                            </View>
                        )}
                        {isRecorded && recordingProgress && (
                            <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                                <Text
                                    style={{
                                        backgroundColor: 'crimson',
                                        color: appContext.application.theme.tags.color,
                                        borderRadius: appContext.application.theme.tags.radius / 2,
                                        fontSize: getFontSize(deviceContext, 'Small'),
                                        padding: getCorrectWidth(deviceContext, 2),
                                        paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                        includeFontPadding: false,
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'recording').toUpperCase()}
                                </Text>
                            </View>
                        )}
                        {isRecorded && recordingPlanned && (
                            <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                                <Text
                                    style={{
                                        backgroundColor: 'crimson',
                                        color: appContext.application.theme.tags.color,
                                        borderRadius: appContext.application.theme.tags.radius / 2,
                                        fontSize: getFontSize(deviceContext, 'Small'),
                                        padding: getCorrectWidth(deviceContext, 2),
                                        paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                        includeFontPadding: false,
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'planned').toUpperCase()}
                                </Text>
                            </View>
                        )}
                        {isRecorded && recordingDone && (
                            <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                                <Text
                                    style={{
                                        backgroundColor: 'crimson',
                                        color: appContext.application.theme.tags.color,
                                        borderRadius: appContext.application.theme.tags.radius / 2,
                                        fontSize: getFontSize(deviceContext, 'Small'),
                                        padding: getCorrectWidth(deviceContext, 2),
                                        paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                        includeFontPadding: false,
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'recorded').toUpperCase()}
                                </Text>
                            </View>
                        )}
                        {isReminded && (
                            <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                                <Text
                                    style={{
                                        backgroundColor: '#4169e1',
                                        color: appContext.application.theme.tags.color,
                                        borderRadius: appContext.application.theme.tags.radius / 2,
                                        fontSize: getFontSize(deviceContext, 'Small'),
                                        padding: getCorrectWidth(deviceContext, 2),
                                        paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                        includeFontPadding: false,
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'reminded').toUpperCase()}
                                </Text>
                            </View>
                        )}
                        {isPast && contentContext.channelData.channel.interactivetv.catchuptv_enabled && (
                            <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                                <Text
                                    style={{
                                        backgroundColor: '#4169e1',
                                        color: appContext.application.theme.tags.color,
                                        borderRadius: appContext.application.theme.tags.radius / 2,
                                        fontSize: getFontSize(deviceContext, 'Small'),
                                        padding: getCorrectWidth(deviceContext, 2),
                                        paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                        includeFontPadding: false,
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'replay').toUpperCase()}
                                </Text>
                            </View>
                        )}
                        {isLive && contentContext.channelData.channel.interactivetv.pausetv_enabled && (
                            <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                                <Text
                                    style={{
                                        backgroundColor: '#4169e1',
                                        color: appContext.application.theme.tags.color,
                                        borderRadius: appContext.application.theme.tags.radius / 2,
                                        fontSize: getFontSize(deviceContext, 'Small'),
                                        padding: getCorrectWidth(deviceContext, 2),
                                        paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                        includeFontPadding: false,
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'restart').toUpperCase()}
                                </Text>
                            </View>
                        )}

                        {(isLive || (isPast && contentContext.channelData.channel.interactivetv.catchuptv_enabled) || isReminded || isRecorded) && (
                            <View style={{ justifyContent: 'center', marginLeft: getCorrectWidth(deviceContext, 2) }}>
                                <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>
                            </View>
                        )}
                        <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 5), marginLeft: isLive || (isPast && contentContext.channelData.channel.interactivetv.catchuptv_enabled) ? 5 : 0 }}>
                            <Text
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    color: '#fff',
                                    includeFontPadding: false,
                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 2,
                                }}
                            >
                                {moment.unix(program?.s).format('ll ' + deviceContext.clockSetting) + ' - ' + moment.unix(program?.e).format(deviceContext.clockSetting)}
                            </Text>
                        </View>
                        <View style={{ flexDirection: 'row' }}>
                            {program.mt?.ye != undefined && program.mt?.ye != '' && (
                                <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 5) }}>
                                    <Text
                                        style={{
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Small'),
                                            color: '#fff',
                                            includeFontPadding: false,
                                            textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                            textShadowOffset: { width: -1, height: 1 },
                                            textShadowRadius: 2,
                                        }}
                                    >
                                        {program?.mt?.ye}
                                    </Text>
                                </View>
                            )}
                            {program.mt?.ye != undefined && program.mt?.ye != '' && program?.mt?.ge != undefined && program?.mt?.ge.length > 0 && <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>}
                            {program?.mt?.ge != undefined && program?.mt?.ge.length > 0 && <View>{getTagsChannels(program?.mt?.ge, appContext, deviceContext, userContext)}</View>}

                            {program?.p != undefined && program?.p != '' && <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>}
                            {program?.p != undefined && program?.p != '' && (
                                <View style={{ justifyContent: 'center' }}>
                                    <Text
                                        style={{
                                            backgroundColor: appContext.application.theme.tags.background,
                                            color: appContext.application.theme.tags.color,
                                            borderRadius: appContext.application.theme.tags.radius / 2,
                                            fontSize: getFontSize(deviceContext, 'Small'),
                                            margin: getCorrectWidth(deviceContext, 2),
                                            padding: getCorrectWidth(deviceContext, 2),
                                            paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                            includeFontPadding: false,
                                        }}
                                    >
                                        {program?.p}
                                    </Text>
                                </View>
                            )}
                        </View>
                    </View>
                </View>
            );
        } else if (type == 'Recording') {
            return (
                <View style={{ flexDirection: 'column' }}>
                    <View style={{ flexDirection: 'row', marginBottom: getCorrectHeight(deviceContext, 5), marginTop: getCorrectHeight(deviceContext, 5) }}>
                        <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                            <Text
                                style={{
                                    backgroundColor: 'crimson',
                                    color: appContext.application.theme.tags.color,
                                    borderRadius: appContext.application.theme.tags.radius / 2,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    padding: getCorrectWidth(deviceContext, 2),
                                    paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                    includeFontPadding: false,
                                }}
                            >
                                {lang.getTranslation(userContext, 'recorded').toUpperCase()}
                            </Text>
                        </View>

                        <View style={{ justifyContent: 'center', marginLeft: getCorrectWidth(deviceContext, 2) }}>
                            <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>
                        </View>

                        <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 5), marginLeft: 0 }}>
                            <Text
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    color: '#fff',
                                    includeFontPadding: false,
                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 2,
                                }}
                            >
                                {moment.unix(contentContext.recordingData.program?.s).format('ll ' + deviceContext.clockSetting) + ' - ' + moment.unix(contentContext.recordingData.program?.e).format(deviceContext.clockSetting)}
                            </Text>
                        </View>
                    </View>
                </View>
            );
        } else {
            return <></>;
        }
    };
    const MetaRow = ({ item, type, deviceContext, appContext, userContext, contentContext, styling }) => {
        if (type == 'Recording') {
            return (
                <View style={{ flexDirection: 'column' }}>
                    <View style={{ flexDirection: 'row', marginBottom: getCorrectHeight(deviceContext, 5) }}>
                        {contentContext.recordingData.program.mt?.ye != undefined && contentContext.recordingData.program.mt?.ye != '' && (
                            <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 5) }}>
                                <Text
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Small'),
                                        color: '#fff',
                                        includeFontPadding: false,
                                        textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                        textShadowOffset: { width: -1, height: 1 },
                                        textShadowRadius: 2,
                                    }}
                                >
                                    {contentContext.recordingData.program?.mt?.ye}
                                </Text>
                            </View>
                        )}
                        {contentContext.recordingData.program.mt?.ye != undefined &&
                            contentContext.recordingData.program.mt?.ye != '' &&
                            contentContext.recordingData.program?.mt?.ge != undefined &&
                            contentContext.recordingData.program?.mt?.ge.length > 0 && <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>}
                        {contentContext.recordingData.program?.mt?.ge != undefined && contentContext.recordingData.program?.mt?.ge.length > 0 && (
                            <View>{getTagsChannels(contentContext.recordingData.program?.mt?.ge, appContext, deviceContext, userContext)}</View>
                        )}

                        {contentContext.recordingData.program?.p != undefined && contentContext.recordingData.program?.p != '' && <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>}
                        {contentContext.recordingData.program?.p != undefined && contentContext.recordingData.program?.p != '' && (
                            <View style={{ justifyContent: 'center' }}>
                                <Text
                                    style={{
                                        backgroundColor: appContext.application.theme.tags.background,
                                        color: appContext.application.theme.tags.color,
                                        borderRadius: appContext.application.theme.tags.radius / 2,
                                        fontSize: getFontSize(deviceContext, 'Small'),
                                        margin: getCorrectWidth(deviceContext, 2),
                                        padding: getCorrectWidth(deviceContext, 2),
                                        paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                        includeFontPadding: false,
                                    }}
                                >
                                    {contentContext.recordingData.program?.p}
                                </Text>
                            </View>
                        )}
                    </View>
                </View>
            );
        } else if (type == 'Movie' || type == 'Course' || type == 'Short' || type == 'Seasons') {
            return (
                <View style={{ flexDirection: 'column' }}>
                    <View style={{ flexDirection: 'row', marginBottom: getCorrectHeight(deviceContext, 5) }}>
                        {item.released != undefined && item.released != '' && (
                            <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 5) }}>
                                <Text
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Small'),
                                        color: '#fff',
                                        includeFontPadding: false,
                                        textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                        textShadowOffset: { width: -1, height: 1 },
                                        textShadowRadius: 2,
                                    }}
                                >
                                    {item.released}
                                </Text>
                            </View>
                        )}
                        {item.released != undefined && item.released != '' && item.tags != undefined && <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>}
                        {item.tags != undefined && <View>{getTags(item.tags, appContext, deviceContext, userContext)}</View>}
                        {item.tags != undefined && item.runtime != undefined && item.runtime != '' && <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>}
                        {item.runtime != undefined && item.runtime != '' && (
                            <View style={{ justifyContent: 'center', marginHorizontal: getCorrectWidth(deviceContext, 5) }}>
                                <Text
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Small'),
                                        color: '#fff',
                                        includeFontPadding: false,
                                        textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                        textShadowOffset: { width: -1, height: 1 },
                                        textShadowRadius: 2,
                                    }}
                                >
                                    {moment.utc().startOf('day').add({ minutes: item.runtime }).format('H')}
                                    {'h:'}
                                    {moment.utc().startOf('day').add({ minutes: item.runtime }).format('mm')}
                                    {'m'}
                                </Text>
                            </View>
                        )}
                        {item.runtime != undefined && item.runtime != '' && item.rating != undefined && item.rating != '' && <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>}
                        {item.rating != undefined && item.rating != '' && (
                            <Text
                                style={{
                                    backgroundColor: appContext.application.theme.tags.background,
                                    color: appContext.application.theme.tags.color,
                                    borderRadius: appContext.application.theme.tags.radius / 2,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    margin: getCorrectWidth(deviceContext, 2),
                                    padding: getCorrectWidth(deviceContext, 2),
                                    paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                    includeFontPadding: false,
                                }}
                            >
                                {item.rating}
                            </Text>
                        )}
                    </View>
                </View>
            );
        }
    };
    const DescriptionRow = ({ item, type, deviceContext, userContext, contentContext, styling }) => {
        if (type == 'Program') {
            var program = [] as any;

            if (contentContext.epgTv != undefined) {
                var epgChannel = contentContext.epgTv?.find((e) => e.i == item._id);
                if (epgChannel != undefined) {
                    var currentTime = moment().unix();
                    program = epgChannel.epg.find((e) => e.s <= currentTime && e.e >= currentTime);
                }
            }

            return (
                <View style={{ flexDirection: 'column' }}>
                    <View>
                        <Text
                            numberOfLines={5}
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Normal'),
                                color: '#fff',
                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                textShadowOffset: { width: -1, height: 1 },
                                textShadowRadius: 2,
                            }}
                        >
                            {program?.d}
                        </Text>
                    </View>
                </View>
            );
        } else if (type == 'Recording') {
            return (
                <View style={{ flexDirection: 'column' }}>
                    <View>
                        <Text
                            numberOfLines={5}
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Normal'),
                                color: '#fff',
                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                textShadowOffset: { width: -1, height: 1 },
                                textShadowRadius: 2,
                            }}
                        >
                            {contentContext.recordingData.program?.d}
                        </Text>
                    </View>
                </View>
            );
        } else if (type == 'Movie' || type == 'Course' || type == 'Short') {
            return (
                <View style={{ flexDirection: 'column' }}>
                    <View>
                        {item.translations.find((trans) => trans.name == userContext.selectedLanguage) != undefined && (
                            <Text
                                numberOfLines={5}
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Normal'),

                                    color: '#fff',
                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 2,
                                }}
                            >
                                {item.translations.find((trans) => trans.name == userContext.selectedLanguage)?.description}
                            </Text>
                        )}
                        {item.translations.find((trans) => trans.name == userContext.selectedLanguage) == undefined && item.translations.length > 0 && (
                            <Text
                                numberOfLines={5}
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Normal'),

                                    color: '#fff',
                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 2,
                                }}
                            >
                                {item.translations[0]?.description}
                            </Text>
                        )}
                    </View>
                </View>
            );
        } else if (type == 'Seasons') {
            var watchlist = watchingStatus(type, item._id, userContext);
            var episodeIndex = 0;
            var seasonIndex = 0;
            if (watchlist != undefined) {
                episodeIndex = watchlist.continue.episode_index ?? 0;
                seasonIndex = watchlist.continue.season_index ?? 0;
            }
            return (
                <View style={{ flexDirection: 'column' }}>
                    <View>
                        {item.seasons[seasonIndex].translations.find((trans) => trans.name == userContext.selectedLanguage) != undefined && (
                            <Text
                                numberOfLines={5}
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Normal'),

                                    color: '#fff',
                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 2,
                                }}
                            >
                                {item.seasons[seasonIndex].translations.find((trans) => trans.name == userContext.selectedLanguage)?.description}
                            </Text>
                        )}
                        {item.seasons[seasonIndex].translations.find((trans) => trans.name == userContext.selectedLanguage) == undefined && item.translations.length > 0 && (
                            <Text
                                numberOfLines={5}
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Normal'),

                                    color: '#fff',
                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 2,
                                }}
                            >
                                {item.seasons[seasonIndex].translations[0]?.description}
                            </Text>
                        )}
                    </View>
                </View>
            );
        }
    };
    const DotInBetween = ({ deviceContext, styling }) => {
        return (
            <View style={{ justifyContent: 'center', marginHorizontal: getCorrectWidth(deviceContext, 5) }}>
                <View style={{ width: getCorrectWidth(deviceContext, 3), height: getCorrectWidth(deviceContext, 3), backgroundColor: '#fff', borderRadius: 100 }}></View>
            </View>
        );
    };
    const getTagsChannels = (tags, appContext, deviceContext, userContext) => {
        var tagsOut = [] as any;
        if (tags != undefined && tags.length > 0) {
            tags.map((tag, index) => {
                if (index < 3) {
                    tagsOut.push(
                        <Text
                            key={index}
                            style={{
                                backgroundColor: appContext.application.theme.tags.background,
                                borderRadius: appContext.application.theme.tags.radius / 2,
                                color: appContext.application.theme.tags.color,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                margin: getCorrectWidth(deviceContext, 2),
                                padding: getCorrectWidth(deviceContext, 2),
                                paddingHorizontal: getCorrectWidth(deviceContext, 4),
                                includeFontPadding: false,
                            }}
                        >
                            {tag}
                        </Text>
                    );
                }
            });
        }
        if (tagsOut.length > 0) {
            return <View style={{ flexGrow: 1, flexDirection: 'row' }}>{tagsOut}</View>;
        } else {
            return null;
        }
    };
    const getTags = (tags, appContext, deviceContext, userContext) => {
        var tagsOut = [] as any;
        if (tags != undefined && tags[0] != undefined && tags[0].tag != undefined) {
            tags.map((tag, index) => {
                if (tag.tag != undefined) {
                    var test = tag.tag.find((t) => t.name == userContext.selectedLanguage);
                    if (test == undefined) {
                        tag.name = tag[0]?.description;
                    } else {
                        tag.name = test?.description;
                    }
                }
                if (index < 3 && tag.name != '') {
                    tagsOut.push(
                        <Text
                            key={index}
                            style={{
                                backgroundColor: appContext.application.theme.tags.background,
                                color: appContext.application.theme.tags.color,
                                borderRadius: appContext.application.theme.tags.radius / 2,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                margin: getCorrectWidth(deviceContext, 2),
                                padding: getCorrectWidth(deviceContext, 2),
                                paddingHorizontal: getCorrectWidth(deviceContext, 4),
                                includeFontPadding: false,
                            }}
                        >
                            {tag.name}
                        </Text>
                    );
                }
            });
        }
        if (tagsOut.length > 0) {
            return <View style={{ flexGrow: 1, flexDirection: 'row' }}>{tagsOut}</View>;
        } else {
            return null;
        }
    };

    React.useEffect(() => {
        if (data?._id) {
            setFocus('modal-group');
        }
    }, [data?._id]);

    const onBackPress = React.useCallback(() => {
        setShowModal(false);

        return true;
    }, []);

    React.useEffect(() => {
        const handler = BackHandler.addEventListener('hardwareBackPress', onBackPress);

        return () => {
            handler.remove();
        };
    }, []);

    return (
        <ScreenFadeIn focusContext={focusContext}>
            <ViewGroup
                focusOptions={{ focusKey: 'modal-group', group: 'modal-group', modal: true }}
                style={{
                    position: 'absolute',
                    zIndex: 999,
                    width: getRealWidth(deviceContext),
                    height: getRealHeight(deviceContext),
                    alignItems: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.75)',
                    justifyContent: 'center',
                }}
            >
                {data?._id != undefined && (
                    <View
                        style={{
                            width: getCorrectWidth(deviceContext, 325),
                        }}
                    >
                        <ScrollView
                            contentContainerStyle={{
                                width: getCorrectWidth(deviceContext, 325),
                            }}
                            style={{
                                borderColor: styling.border_color,
                                borderWidth: styling.border_width,
                                borderRadius: getCorrectWidth(deviceContext, styling.border_radius),
                                backgroundColor: styling.background_color,
                                alignSelf: 'center',
                            }}
                        >
                            {loading && (
                                <View
                                    style={{
                                        zIndex: 2,
                                        position: 'absolute',
                                        flex: 1,
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignContent: 'center',
                                        alignItems: 'center',
                                        alignSelf: 'center',
                                        width: '100%',
                                        height: '100%',
                                        backgroundColor: 'rgba(0, 0, 0, 0.35)',
                                    }}
                                >
                                    <View
                                        style={{
                                            width: (deviceContext.isPhone ? getRealHeight(deviceContext) * 0.8 : getRealWidth(deviceContext) * 0.3) * 0.5,
                                            height: (deviceContext.isPhone ? getRealHeight(deviceContext) * 0.8 : getRealWidth(deviceContext) * 0.3) * 0.5,
                                            padding: 20,
                                            backgroundColor: styling.background_color,
                                            justifyContent: 'center',
                                            borderRadius: deviceContext.isPhone ? 0 : getCorrectWidth(deviceContext, styling.border_radius),
                                        }}
                                    >
                                        {result == '' && <ActivityIndicator size={'large'} color={'#ffffff'}></ActivityIndicator>}
                                        {result != '' && (
                                            <Text
                                                style={{
                                                    textAlign: 'center',
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    color: getPressableTextStyling(styling.components?.button?._id, appContext).color,
                                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                                    textShadowOffset: { width: -1, height: 1 },
                                                    textShadowRadius: 2,
                                                }}
                                            >
                                                {result}
                                            </Text>
                                        )}
                                    </View>
                                </View>
                            )}
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <View style={{ flex: 1, alignItems: 'center', flexDirection: 'column', padding: getCorrectWidth(deviceContext, 10) }}>
                                    <View style={{ flexDirection: 'row', width: '100%', marginVertical: 20 }}>
                                        <LogoRow channel={undefined} item={data} type={type} deviceContext={deviceContext} appContext={appContext} contentContext={contentContext} styling={stylingContent}></LogoRow>
                                        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
                                            {userContext?.product?.settings?.wallet_enabled && (
                                                <View>
                                                    <View
                                                        style={{
                                                            flexDirection: 'row',
                                                            backgroundColor: userContext.walletBalance < getPriceRenting() ? 'crimson' : userContext.walletBalance < getPricePurchase() ? 'orange' : 'mediumseagreen',
                                                            borderRadius: getPressableStyling(styling.components?.button?._id, appContext).borderRadius,
                                                            padding: getCorrectWidth(deviceContext, 5),
                                                        }}
                                                    >
                                                        <FontAwesomeIcon size={getFontSize(deviceContext, 'Normal') * 1.4} style={{ color: appContext.application.theme.icons.background }} icon={faCoins} />
                                                        <Text
                                                            numberOfLines={1}
                                                            style={{
                                                                fontFamily: deviceContext.fontType,
                                                                paddingLeft: getCorrectWidth(deviceContext, 5),
                                                                paddingRight: getCorrectWidth(deviceContext, 2),
                                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                                ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                            }}
                                                        >
                                                            {userContext.walletBalance}
                                                        </Text>
                                                    </View>
                                                </View>
                                            )}
                                        </View>
                                    </View>
                                    <View style={{ height: deviceContext.isPhone ? ((getRealHeight(deviceContext) * 0.32) / 16) * 9 : ((getRealWidth(deviceContext) * 0.27) / 16) * 9, width: '100%' }}>
                                        <View>
                                            <View
                                                style={{
                                                    position: 'absolute',
                                                    zIndex: 1,
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    width: '100%',

                                                    height: deviceContext.isPhone ? ((getRealHeight(deviceContext) * 0.32) / 16) * 9 : ((getRealWidth(deviceContext) * 0.27) / 16) * 9,
                                                }}
                                            >
                                                {type != 'Program' && type != 'Album' && type != 'Radio' && type != 'Cam' && (
                                                    <Image
                                                        isCache={false}
                                                        deviceContext={deviceContext}
                                                        source={{ uri: utils.getCorrectStoragePrefix(appContext, getImage()) }}
                                                        resizeMethod={'resize'}
                                                        resizeMode={'cover'}
                                                        style={{
                                                            borderRadius: getCorrectWidth(deviceContext, appContext.application.theme.images.radius) / 2,
                                                            width: width * 0.15,
                                                            height: width * 0.15 * 1.5,
                                                        }}
                                                    />
                                                )}
                                                {(type == 'Program' || type == 'Album' || type == 'Radio' || type == 'Cam') && (
                                                    <Image
                                                        isCache={false}
                                                        deviceContext={deviceContext}
                                                        source={{ uri: utils.getCorrectStoragePrefix(appContext, getImage()) }}
                                                        resizeMethod={'resize'}
                                                        resizeMode={'cover'}
                                                        style={{
                                                            borderRadius: getCorrectWidth(deviceContext, appContext.application.theme.images.radius) / 2,
                                                            width: width * 0.15,
                                                            height: width * 0.15,
                                                        }}
                                                    />
                                                )}
                                            </View>

                                            <View style={{ width: '100%', position: 'absolute', zIndex: 0, alignItems: 'center', justifyContent: 'center' }}>
                                                <Image
                                                    blurRadius={75}
                                                    isCache={false}
                                                    deviceContext={deviceContext}
                                                    source={{ uri: utils.getCorrectStoragePrefix(appContext, getImage()) }}
                                                    resizeMethod={'resize'}
                                                    resizeMode={'cover'}
                                                    style={{
                                                        borderRadius: getCorrectWidth(deviceContext, appContext.application.theme.images.radius) / 2,
                                                        width: '100%',
                                                        height: deviceContext.isPhone ? ((getRealHeight(deviceContext) * 0.32) / 16) * 9 : ((getRealWidth(deviceContext) * 0.27) / 16) * 9,
                                                    }}
                                                />
                                            </View>
                                        </View>
                                    </View>
                                    <View style={{ flex: 1, marginVertical: 20, width: '100%' }}>
                                        <StatusTimeRow item={data} type={type} deviceContext={deviceContext} appContext={appContext} userContext={userContext} contentContext={contentContext} styling={stylingContent}></StatusTimeRow>
                                        <MetaRow item={data} type={type} deviceContext={deviceContext} appContext={appContext} userContext={userContext} contentContext={contentContext} styling={stylingContent}></MetaRow>
                                        <DescriptionRow item={data} type={type} deviceContext={deviceContext} userContext={userContext} contentContext={contentContext} styling={stylingContent}></DescriptionRow>
                                    </View>

                                    <View style={{ width: '100%', justifyContent: 'center' }}>
                                        {getPriceRenting() != 0 && (
                                            <View style={{ marginTop: 10 }}>
                                                <Pressable
                                                    style={{
                                                        ...getPressableStyling(styling.components?.button?._id, appContext),
                                                        width: '100%',
                                                    }}
                                                    animatorOptions={getFocusStyling('Buttons', appContext)}
                                                    onPress={() => checkParentalCode('rental')}
                                                >
                                                    <View
                                                        style={{
                                                            alignItems: 'center',
                                                            height: getCorrectHeight(deviceContext, 30),
                                                            flexDirection: 'row',
                                                            // Do not add bg color here it will hide focusable button bg color
                                                            borderRadius: getPressableStyling(styling.components?.button?._id, appContext).borderRadius,
                                                        }}
                                                    >
                                                        <View style={{ marginLeft: 5 }}>
                                                            <Text
                                                                style={{
                                                                    padding: 10,
                                                                    fontFamily: deviceContext.fontType,
                                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                                    ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                                }}
                                                            >
                                                                {lang.getTranslation(userContext, 'rent')} {type} ({lang.getTranslation(deviceContext, data.payperview.rule.access_type.toLowerCase() + 's') + ' ' + data.payperview?.rule?.access_length}
                                                                )
                                                            </Text>
                                                        </View>
                                                        <View style={{ flex: 1, justifyContent: 'flex-end', alignContent: 'center', marginRight: 20, flexDirection: 'row' }}>
                                                            <Text
                                                                style={{
                                                                    textAlign: 'right',
                                                                    marginRight: 10,
                                                                    fontFamily: deviceContext.fontType,
                                                                    fontSize: getFontSize(deviceContext, 'Large'),
                                                                    ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                                }}
                                                            >
                                                                {getPriceRenting()}
                                                            </Text>
                                                            <FontAwesomeIcon size={getFontSize(deviceContext, 'Large')} style={{ color: appContext.application.theme.icons.background }} icon={faCoins} />
                                                        </View>
                                                    </View>
                                                </Pressable>
                                            </View>
                                        )}
                                        {getPricePurchase() != 0 && (
                                            <View style={{ marginTop: 10 }}>
                                                <Pressable
                                                    style={{
                                                        ...getPressableStyling(styling.components?.button?._id, appContext),
                                                        width: '100%',
                                                    }}
                                                    animatorOptions={getFocusStyling('Buttons', appContext)}
                                                    onPress={() => checkParentalCode('purchase')}
                                                >
                                                    <View
                                                        style={{
                                                            alignItems: 'center',
                                                            height: getCorrectHeight(deviceContext, 30),
                                                            flexDirection: 'row',
                                                            borderRadius: getPressableStyling(styling.components?.button?._id, appContext).borderRadius,
                                                            // Do not add bg color here it will hide focusable button bg color
                                                        }}
                                                    >
                                                        <View style={{ marginLeft: 5 }}>
                                                            <Text
                                                                style={{
                                                                    padding: 10,
                                                                    fontFamily: deviceContext.fontType,
                                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                                    ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                                }}
                                                            >
                                                                {lang.getTranslation(userContext, 'buy')} {type} ({lang.getTranslation(deviceContext, 'lifetime')})
                                                            </Text>
                                                        </View>
                                                        <View style={{ flex: 1, justifyContent: 'flex-end', alignContent: 'center', marginRight: 20, flexDirection: 'row' }}>
                                                            <Text
                                                                style={{
                                                                    textAlign: 'right',
                                                                    marginRight: 10,
                                                                    fontFamily: deviceContext.fontType,
                                                                    fontSize: getFontSize(deviceContext, 'Large'),
                                                                    ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                                }}
                                                            >
                                                                {getPricePurchase()}
                                                            </Text>
                                                            <FontAwesomeIcon size={getFontSize(deviceContext, 'Large')} style={{ color: appContext.application.theme.icons.background }} icon={faCoins} />
                                                        </View>
                                                    </View>
                                                </Pressable>
                                            </View>
                                        )}
                                        <View style={{ marginTop: 10 }}>
                                            <Pressable
                                                style={{
                                                    ...getPressableStyling(styling.components?.button?._id, appContext),
                                                    width: '100%',
                                                }}
                                                animatorOptions={getFocusStyling('Buttons', appContext)}
                                                onPress={() => setShowModal(false)}
                                            >
                                                <View
                                                    style={{
                                                        alignItems: 'center',
                                                        height: getCorrectHeight(deviceContext, 30),
                                                        flexDirection: 'row',
                                                        borderRadius: getPressableStyling(styling.components?.button?._id, appContext).borderRadius,
                                                        // Do not add bg color here it will hide focusable button bg color
                                                    }}
                                                >
                                                    <View style={{ marginLeft: 5 }}>
                                                        <Text
                                                            style={{
                                                                padding: 10,

                                                                fontFamily: deviceContext.fontType,
                                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                                ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                            }}
                                                        >
                                                            {lang.getTranslation(deviceContext, 'cancel')}
                                                        </Text>
                                                    </View>
                                                </View>
                                            </Pressable>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            {/* {!deviceContext.isWebTV && !deviceContext.isTablet && !deviceContext.isPhone && (
                                <View style={{ flex: 1, flexDirection: 'row' }}>{getKeyboard(appContext.application.theme.keyboard.search, deviceContext, appContext, setPincode)}</View>
                            )} */}
                        </ScrollView>
                    </View>
                )}
            </ViewGroup>
        </ScreenFadeIn>
    );
};

export default PayPerView;
