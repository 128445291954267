import { useFocusEffect } from '@react-navigation/native';
import { View, Image, Screen, FlashList, CreateListRenderItemInfo } from '@scriptx-com/xtv-toolkit';
import moment from 'moment';
import React, { useState } from 'react';
import { BackHandler } from 'react-native';
import { sendPageReport } from '../../../reporting/apis/page';
import { pageType } from '../../../reporting/models/apps';
import { AppContext } from '../../context/appContext';
import { DeviceContext } from '../../context/deviceContext';
import { UserContext } from '../../context/userContext';
import { getRealWidth } from '../../styling/realDeviceSizes';
import { getCorrectHeight, getCorrectWidth } from '../../styling/correctSizes';
import { ContentContext } from '../../context/contentContext';
import { getFocusStyling } from '../components/helpers/helper';
import Text from '../components/text';
import { getFontSize } from '../../styling/fontSizes';
import Rectangle from '../elements/shared/rectangle';
import { getDetailsMovie, getDetailsSeries } from '../elements/rails/helpers/helper';

const Screen_ = ({ navigation, route }: any): React.ReactElement => {
    const { actor, styling, focusContext } = route.params;
    const userContext = React.useContext(UserContext);
    const deviceContext = React.useContext(DeviceContext);
    const appContext = React.useContext(AppContext);
    const contentContext = React.useContext(ContentContext);
    const [data, setData] = useState([] as any);

    // contentContext.setActiveMenu('Actors');

    const getColumns = () => {
        if (deviceContext.isWebTV && !deviceContext.isPwaVertical) {
            return 8;
        } else if (deviceContext.isTablet) {
            return 6;
        } else if (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs) {
            return 3;
        } else if (deviceContext.isTelevision) {
            return 6;
        }
    };

    var marginLeft = styling?.placement?.margin_left != undefined ? styling?.placement?.margin_left : 0;
    var marginRight = styling?.placement?.margin_right != undefined ? styling?.placement?.margin_right : 0;
    var screenWidth = getRealWidth(deviceContext) - getCorrectWidth(deviceContext, marginLeft + marginRight) - getCorrectWidth(deviceContext, global.menuWidth);
    var itemWidth = screenWidth / getColumns();
    var width = itemWidth - 5;
    var height = itemWidth * 1.5;

    React.useEffect(() => {
        var listOut = [] as any;
        var actorMovieList = contentContext.actors.filter((a) => a.actor == actor.name && a.t == 'm');
        for (var category of contentContext.movies.categories) {
            for (var movie of category.movies) {
                for (var actorMovie of actorMovieList) {
                    if (movie._id == actorMovie._id) {
                        if (listOut.find((l) => l._id == actorMovie._id) == undefined) {
                            listOut.push({
                                ...movie,
                                t: 'Movies',
                            });
                        }
                    }
                }
            }
        }
        var actorSeriesList = contentContext.actors.filter((a) => a.actor == actor.name && a.t == 's');
        for (var category_ of contentContext.series.categories) {
            for (var serie of category_.series) {
                for (var actorSerie of actorSeriesList) {
                    if (serie._id == actorSerie._id) {
                        if (listOut.find((l) => l._id == actorSerie._id) == undefined) {
                            listOut.push({
                                ...serie,
                                t: 'Series',
                            });
                        }
                    }
                }
            }
        }
        setData(listOut);
    }, [navigation]);

    //For TV to exit with back button
    React.useEffect(() => {
        const TVBackHandler = (event) => {
            const { key, keyCode } = event;
            if (keyCode == 413 || keyCode == 10009 || keyCode == 27 || keyCode == 461) {
                navigation.goBack();
            }
        };
        if (deviceContext.isTizen || deviceContext.isWebos) window.addEventListener('keydown', TVBackHandler);
        return () => {
            if (deviceContext.isTizen || deviceContext.isWebos) {
                {
                    window.removeEventListener('keydown', TVBackHandler);
                }
            }
        };
    }, []);
    useFocusEffect(
        React.useCallback(() => {
            const start = moment().unix();
            return () => {
                let duration = moment().unix() - start;
                sendPageReport(appContext, deviceContext, userContext, pageType.GAMES_WEBVIEW, duration);
            };
        }, [])
    );
    const disableNativeHardwareBackPress = () => {
        navigation.goBack();
        return true;
    };
    React.useEffect(() => {
        BackHandler.addEventListener('hardwareBackPress', disableNativeHardwareBackPress);
        return () => BackHandler.removeEventListener('hardwareBackPress', disableNativeHardwareBackPress);
    }, []);

    const detailsItem = (item, index) => {
        if (item.t == 'Series') {
            contentContext.setActiveMenu('Seasons');
            getDetailsSeries(contentContext, item, navigation);
        }
        if (item.t == 'Movies') {
            contentContext.setActiveMenu('Movie Details');
            getDetailsMovie(contentContext, item, navigation);
        }
    };
    const renderItem = ({ item, focusRepeatContext, index }: CreateListRenderItemInfo<any>) => {
        return (
            <Rectangle
                elementType={'Grids'}
                heroIsPlayer={false}
                onLongPress={() => null}
                type={'screenName'}
                styling={styling}
                height={height}
                width={width}
                data={item}
                focusContext={focusContext}
                focusRepeatContext={focusRepeatContext}
                onPress={() => detailsItem(item, index)}
                focusOptions={{
                    focusKey: `grid-item-${index}`,
                }}
            ></Rectangle>
        );
    };

    return (
        //@ts-ignore
        <Screen style={{ flex: 1 }} focusOptions={{ focusKey: 'content-screen' }}>
            <View style={{ flex: 1, zIndex: 3 }}>
                <View
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        padding: getCorrectWidth(deviceContext, 15),
                        marginLeft: 5 + getCorrectWidth(deviceContext, global.menuWidth) + getCorrectWidth(deviceContext, styling?.placement?.margin_left ?? 0),
                        marginRight: 5 + getCorrectWidth(deviceContext, styling?.placement?.margin_right ?? 0),
                        backgroundColor: 'rgba(0, 0, 0, 0.3)',
                        borderRadius: appContext.application.theme.settings.border_radius,
                    }}
                >
                    <Image source={{ uri: actor.image }} resizeMode={'cover'} style={{ height: getCorrectWidth(deviceContext, 60), width: getCorrectWidth(deviceContext, 60), borderRadius: getCorrectWidth(deviceContext, 60) }}></Image>
                    <View style={{ flexDirection: 'column' }}>
                        <Text
                            numberOfLines={1}
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontWeight: styling?.placement?.title_bold ? 'bold' : null,
                                fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                color: styling?.placement?.title_color == '' ? '#ffffff' : styling?.placement?.title_color ?? '#ffffff',
                                marginLeft: 10,
                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                textShadowOffset: { width: -1, height: 1 },
                                textShadowRadius: 2,
                            }}
                        >
                            {actor.name}
                        </Text>
                    </View>
                </View>

                {data != undefined && data != null && data.length > 0 && (
                    <View
                        style={{
                            height: null,
                            width: screenWidth,
                            backgroundColor: styling?.placement?.background_color,
                            marginLeft: getCorrectWidth(deviceContext, global.menuWidth) + getCorrectWidth(deviceContext, styling?.placement?.margin_left ?? 0),
                            marginRight: getCorrectWidth(deviceContext, styling?.placement?.margin_right ?? 0),
                            marginTop: getCorrectHeight(deviceContext, styling?.placement?.margin_top == 0 ? 5 : styling?.placement?.margin_top ?? 5),
                            marginBottom: getCorrectHeight(deviceContext, styling?.placement?.margin_bottom ?? 0),
                        }}
                        focusContext={focusContext}
                    >
                        <FlashList
                            scrollEnabled={!contentContext.disableScroll}
                            data={data}
                            estimatedItemSize={getCorrectHeight(deviceContext, width)}
                            numColumns={getColumns()}
                            renderItem={renderItem}
                            horizontal={false}
                            showsVerticalScrollIndicator={false}
                            initialScrollIndex={0}
                            type={'grid'}
                            style={{ flex: 1 }}
                            // contentContainerStyle={{ paddingBottom }}
                            focusOptions={{
                                nextFocusUp: ['menu-item-active-1', 'menu-item-active-0'],
                                autoLayoutScaleAnimation: getFocusStyling('Grids', appContext).type.indexOf('scale') > -1 && deviceContext.formFactor == 'Television' ? true : false,
                                autoLayoutSize: getFocusStyling('Grids', appContext).type.indexOf('scale') > -1 && deviceContext.formFactor == 'Television' ? 10 : 0,
                                nextFocusLeft: ['menu-drawer'],
                            }}
                        />
                    </View>
                )}
            </View>
        </Screen>
    );
};
export default Screen_;
