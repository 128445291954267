import { View } from '@scriptx-com/xtv-toolkit';
import { DefaultTheme, NavigationContainer, NavigationContainerRef } from '@react-navigation/native';
import { CardStyleInterpolators, createStackNavigator } from '@react-navigation/stack';
import { isPlatformAndroidtv, isPlatformFiretv, isPlatformTvos } from '@rnv/renative';
import * as React from 'react';
import { createNativeStackNavigator } from '../../libs/@react-navigation';
import { AppContext } from '../context/appContext';
import { DeviceContext } from '../context/deviceContext';
import Bottom from '../navigators/bottom';
import Drawer from '../navigators/drawer';
import Left from '../navigators/left';
import Top from '../navigators/top';
import SplitBottom from '../navigators/split/bottom';
import SplitDrawer from '../navigators/split/drawer';

const Base = isPlatformTvos || isPlatformAndroidtv || isPlatformFiretv ? createNativeStackNavigator() : createStackNavigator();

//createNavigationContainerRef api is not available in this version so kindof a hacky way, to do it, but it works
export let navigationRef: React.MutableRefObject<NavigationContainerRef> | undefined = undefined;

export const NavigationBase = () => {
    const appContext = React.useContext(AppContext);
    const deviceContext = React.useContext(DeviceContext);

    var navigatorType = 'Bottom Menu';
    if (deviceContext.isWebTV && !deviceContext.isPwaVertical) {
        navigatorType = appContext.application.navigators?.browser.type;
    } else if (deviceContext.isTablet) {
        navigatorType = appContext.application.navigators?.tablet.type;
    } else if (deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical) {
        navigatorType = appContext.application.navigators?.mobile.type;
    } else if (deviceContext.isTelevision) {
        navigatorType = appContext.application.navigators?.television.type;
    }
  //  navigatorType = 'Split Menu';
    const navTheme = {
        ...DefaultTheme,
        colors: {
            ...DefaultTheme.colors,
            background: 'transparent',
        },
        flex: 1,
    };
    navigationRef = React.useRef<NavigationContainerRef>();

    return (
        <View style={{ flex: 1 }}>
            <NavigationContainer theme={navTheme} ref={navigationRef}>
                <View style={{ flex: 1 }}>
                    {navigatorType == 'Split Menu' && (deviceContext.isPhone || deviceContext.isTablet) && (
                        <Base.Navigator
                            screenOptions={{
                                gestureDirection: 'horizontal',
                                gestureEnabled: true,
                                cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
                                headerShown: false,
                                cardStyle: { backgroundColor: 'transparent' },
                            }}
                        >
                            <Base.Screen
                                options={{
                                    headerShown: false,
                                    cardStyle: { backgroundColor: 'transparent' },
                                }}
                                name="Menu_Split_Bottom"
                                component={SplitBottom}
                            />
                        </Base.Navigator>
                    )}
                    {navigatorType == 'Split Menu' && !deviceContext.isPhone && !deviceContext.isTablet && (
                        <Base.Navigator
                            screenOptions={{
                                gestureDirection: 'horizontal',
                                gestureEnabled: true,
                                cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
                                headerShown: false,
                                cardStyle: { backgroundColor: 'transparent' },
                            }}
                        >
                            <Base.Screen
                                options={{
                                    gestureDirection: 'horizontal',
                                    gestureEnabled: true,
                                    cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
                                    headerShown: false,
                                }}
                                name="Menu_Split_Drawer"
                                component={SplitDrawer}
                            />
                        </Base.Navigator>
                    )}
                    {navigatorType == 'Bottom Menu' && (
                        <Base.Navigator
                            screenOptions={{
                                gestureDirection: 'horizontal',
                                gestureEnabled: true,
                                cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
                                headerShown: false,
                                cardStyle: { backgroundColor: 'transparent' },
                            }}
                        >
                            <Base.Screen
                                options={{
                                    headerShown: false,
                                    cardStyle: { backgroundColor: 'transparent' },
                                }}
                                name="Menu_Bottom"
                                component={Bottom}
                            />
                        </Base.Navigator>
                    )}
                    {navigatorType == 'Top Menu' && (
                        <Base.Navigator
                            screenOptions={{
                                gestureDirection: 'horizontal',
                                gestureEnabled: true,
                                cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
                                headerShown: false,
                                cardStyle: { backgroundColor: 'transparent' },
                            }}
                        >
                            <Base.Screen
                                options={{
                                    headerShown: false,
                                    cardStyle: { backgroundColor: 'transparent' },
                                }}
                                name="Menu_Top"
                                component={Top}
                            />
                        </Base.Navigator>
                    )}
                    {navigatorType == 'Drawer' && (
                        <Base.Navigator screenOptions={{ headerShown: false }}>
                            <Base.Screen
                                options={{
                                    gestureDirection: 'horizontal',
                                    gestureEnabled: true,
                                    cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
                                    headerShown: false,
                                }}
                                name="Drawer"
                                component={Drawer}
                            />
                        </Base.Navigator>
                    )}
                    {navigatorType == 'Left Menu' && (
                        <Base.Navigator screenOptions={{ headerShown: false, cardStyle: { backgroundColor: 'transparent' } }}>
                            <Base.Screen
                                options={{
                                    gestureDirection: 'horizontal',
                                    gestureEnabled: true,
                                    cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
                                    headerShown: false,
                                    cardStyle: { backgroundColor: 'transparent' },
                                }}
                                name="Left Menu"
                                component={Left}
                            />
                        </Base.Navigator>
                    )}
                </View>
            </NavigationContainer>
        </View>
    );
};
export default NavigationBase;
