import * as React from 'react';
import { useState, Dispatch } from 'react';
import { Application } from '../models/application/application';
import { getGlobals } from '../../globals';

interface AppContext {
    cloudUrl: string;
    setCloudUrl: Dispatch<any>;

    application: Application;
    setApplication: Dispatch<any>;

    cart: any;
    setCart: Dispatch<any>;

    store: any;
    setStore: Dispatch<any>;

    safeAreaEdges: any;
    setSafeAreaEdges: Dispatch<any>;

    setModal: Dispatch<any>;
    modal: any;

    isMenuDrawerOpen: React.MutableRefObject<boolean>;
}
export const AppContext = React.createContext<AppContext>(null);

export function AppProvider({ children }: { children: any }) {
    const isMenuDrawerOpen = React.useRef(false);
    const [application, setApplication] = useState<Application>(global.Application);
    const [cart, setCart] = useState(global.Cart);
    const [store, setStore] = useState(global.Store);
    const [cloudUrl, setCloudUrl] = useState(getGlobals().AppData);
    const [safeAreaEdges, setSafeAreaEdges] = useState(['right', 'bottom', 'left', 'top']);
    const [modal, setModal] = useState(null);

    return (
        <AppContext.Provider
            value={{
                application,
                setApplication,

                cart,
                setCart,

                store,
                setStore,

                cloudUrl,
                setCloudUrl,

                safeAreaEdges,
                setSafeAreaEdges,

                modal,
                setModal,

                isMenuDrawerOpen,
            }}
        >
            {children}
        </AppContext.Provider>
    );
}

// export function useUser() {
//     const context = React.useContext(AppContext);
//     return context;
// }
