import moment from 'moment';
import { getGlobals } from '../../globals';
import { sendActionReport } from '../../reporting/apis/action';
import { actionType } from '../../reporting/models/apps';
import {
    getAlbumsFromSubscriptionAndRating,
    getCamsFromSubscriptionAndRating,
    getChannelsFromSubscriptionAndRating,
    getCoursesFromSubscriptionAndRating,
    getMoviesFromSubscriptionAndRating,
    getPodcastsFromSubscriptionAndRating,
    getRadiosFromSubscriptionAndRating,
    getSeriesFromSubscriptionAndRating,
    getShortsFromSubscriptionAndRating,
} from '../builder/elements/rails/helpers/helper';
import CryptoJS from '../general/cryptojs';
import utils from '../general/utils';
import lang from '../languages/languages';
import { languageCodes } from '../languages/language_codes';
import { getQueryString } from '../app/helpers/getQueryString';
import RNFetchBlob from 'rn-fetch-blob';
import { Platform } from 'react-native';
import NetInfo from '@react-native-community/netinfo';
import AWS from '../connectors/aws';

const fetchDataWithRetry = async (url, maxRetries = 5, delay = 1000) => {
    for (let i = 0; i < maxRetries; i++) {
        try {
            const response = await fetch(url);
            if (response.status === 200) {
                const data = await response.json();
                return data;
            }
        } catch (error) {}
        // Wait for the specified delay before retrying
        await new Promise((resolve) => setTimeout(resolve, delay));
    }
    throw new Error(`Failed to fetch data after ${maxRetries} attempts`);
};

export const getUserIp = async (username, password, appContext, contentContext, deviceContext, userContext, playerContext, checkUser) => {
    if (deviceContext.isPhone || deviceContext.isTablet) {
        const isConnectedToNetwork = (await NetInfo.fetch()).isConnected;
        if ((deviceContext.isPhone || deviceContext.isTablet) && !isConnectedToNetwork) {
            return await loginUser(username, password, appContext, contentContext, deviceContext, userContext, playerContext, checkUser, 'United States', null);
        }
    }
    try {
        let response = await fetch('https://cloudtv.akamaized.net/ip.php?_=' + moment().unix());
        let data = await response.json();
        if (data != undefined) {
            deviceContext.setIpAddress(data.ip);
            return await getUserLocation(username, password, appContext, contentContext, deviceContext, userContext, playerContext, checkUser, data.ip);
        } else {
            return checkUser ? 'success' : lang.getTranslation(userContext, 'error_ipaddress');
        }
    } catch (error) {
        return checkUser ? 'success' : lang.getTranslation(userContext, 'error_ipaddress');
    }
};
const getUserLocation = async (username, password, appContext, contentContext, deviceContext, userContext, playerContext, checkUser, ipaddress) => {
    try {
        let response = await fetch('https://cloudtv.akamaized.net/location.php?_=' + moment().unix());
        let data = await response.json();
        if (data != undefined) {
            var country = languageCodes.find((l) => l.code == data.country).name != undefined ? languageCodes.find((l) => l.code == data.country).name : 'United States';
            var city = data.city.toLowerCase();
            city = data.city.charAt(0).toUpperCase() + city.slice(1);
            userContext.setCity(city);
            userContext.setCountry(country);
            userContext.setLatitude(data.latitude);
            userContext.setLongitude(data.longitude);
            userContext.setIso2(data.country);
            return await loginUser(username, password, appContext, contentContext, deviceContext, userContext, playerContext, checkUser, country, ipaddress);
        } else {
            userContext.setCity('New York');
            userContext.setCountry('United States');
            userContext.setLatitude('40.7128');
            userContext.setLongitude('-74.0060');
            userContext.setIso2('us');
            return await loginUser(username, password, appContext, contentContext, deviceContext, userContext, playerContext, checkUser, 'United States', ipaddress);
        }
    } catch (error) {
        userContext.setCity('New York');
        userContext.setCountry('United States');
        userContext.setLatitude('40.7128');
        userContext.setLongitude('-74.0060');
        userContext.setIso2('us');
        return await loginUser(username, password, appContext, contentContext, deviceContext, userContext, playerContext, checkUser, 'United States', ipaddress);
    }
};
const loginUser = async (username, password, appContext, contentContext, deviceContext, userContext, playerContext, checkUser, country, ipaddress) => {
    if (deviceContext.isPhone || deviceContext.isTablet) {
        const isConnectedToNetwork = (await NetInfo.fetch()).isConnected;
        if ((deviceContext.isPhone || deviceContext.isTablet) && !isConnectedToNetwork) {
            const user = await utils.retrieveJson('UserID');
            const pass = await utils.retrieveJson('Pass');
            if (user === username && pass === password) {
                return 'success';
            }
        }
    }

    let userCryptedPassword = CryptoJS.AES.encrypt(password, CryptoJS.enc.Hex.parse(password), {
        iv: CryptoJS.enc.Hex.parse('fedcba9876543210'),
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
    }).toString();

    let path = utils.getCorrectStoragePrefix(appContext, false) + '/users/' + utils.toAlphaNumeric(username).split('').join('/') + '/' + utils.toAlphaNumeric(userCryptedPassword) + '.json?t=' + moment().unix();

    return await fetchDataWithRetry(path)
        .then(async (data) => {
            if (data != undefined && data.DELETED) {
                return lang.getTranslation(userContext, 'error_credentials');
            } else if (data != undefined && data.customer?.disabled == 'inactive') {
                return lang.getTranslation(userContext, 'error_disabled');
            } else if (data != undefined) {
                contentContext.setUser(data);
                contentContext.setAccountLoadedTimestamp(moment().unix());

                utils.storeJson('UserID', username);
                utils.storeJson('Pass', password);
                utils.storeJson('AutoLogin', true);
                userContext.setUserId(username);
                userContext.setPass(password);

                if (data.wallet != undefined && data.wallet.balance != undefined) {
                    userContext.setWalletBalance(data.wallet.balance);
                }

                var gracePeriod = data?.base_product?.product?.settings?.grace_period || 0;
                var hasExpired = data.status == 'expired' && moment.unix(data.expire).add(gracePeriod, 'day').unix() < moment().unix() ? true : false;

                if (hasExpired) {
                    return lang.getTranslation(userContext, 'error_expired') + ' (expired at: ' + moment.unix(data.expire).format('lll') + ')';
                } else if (data.status == 'active') {
                    if (deviceContext.isPhone || deviceContext.isTablet) {
                        var test = data.tokens?.find((d) => d.uuid == deviceContext.uniqueId);
                        if (test == undefined && test?.token != global.DeviceToken) {
                            await registerDeviceToken(global.DeviceToken, username, deviceContext.uniqueId, deviceContext.model, appContext);
                        }
                    }
                    return (deviceContext.isPhone || deviceContext.isTablet) && checkUser ? 'success' : await getProduct(appContext, contentContext, deviceContext, userContext, data, playerContext, country, ipaddress);
                } else if (data.status == 'pending') {
                    if (data.customer != undefined) {
                        if (deviceContext.isPhone || deviceContext.isTablet) {
                            await registerDeviceToken(global.DeviceToken, username, deviceContext.uniqueId, deviceContext.model, appContext);
                        }
                        await fetch(
                            getGlobals().AppApiUrl +
                                '/auth/announce?instance=' +
                                appContext.application.client +
                                '&cms=' +
                                appContext.application.deployment +
                                '&crm=' +
                                data.crm +
                                '&customer_id=' +
                                data.customer._id +
                                '&product_id=' +
                                data.base_product.product._id,
                            {
                                method: 'POST',
                                headers: {
                                    Accept: 'application/json',
                                    'Content-Type': 'application/json',
                                },
                            }
                        );
                    }
                    if (!checkUser) {
                        return await getProduct(appContext, contentContext, deviceContext, userContext, data, playerContext, country, ipaddress);
                    } else {
                        return 'success';
                    }
                } else {
                    return lang.getTranslation(userContext, 'error_expired') + ' (status: ' + data.status + ')';
                }
            } else {
                return checkUser ? 'success' : lang.getTranslation(userContext, 'error_credentials');
            }
            // } else {
            //     return checkUser ? 'success' : lang.getTranslation(userContext, 'error_credentials');
            // }
        })
        .catch((error) => {
            return checkUser ? 'success' : lang.getTranslation(userContext, 'error_credentials');
        });
};

const getProduct = async (appContext, contentContext, deviceContext, userContext, user, playerContext, country, ipaddress) => {
    let path = utils.getCorrectStoragePrefix(appContext, false) + '/products/' + user.base_product.product._id + '_product.json?t=' + moment().unix();
    let response = await fetch(path, { cache: 'reload' });
    if (response.status == 200) {
        let data = await response.json();
        if (data != undefined) {
            contentContext.setProduct(data);
            if (data.sec != undefined) {
                //new method
                var drm = data.sec;
                if (drm.buydrm != undefined) {
                    playerContext.setDrm({
                        licenseServer: drm.buydrm.license_url_widevine,
                        licenseServerApple: drm.buydrm.license_url_fairplay,
                    });
                }
                if (drm.irdeto != undefined) {
                    playerContext.setDrm({
                        certificateUrlIrdeto: drm.irdeto.license_url_widevine,
                        accountId: drm.irdeto.account_id,
                    });
                }
                if (drm.token != undefined) {
                    let akamai = '';
                    let legacy = '';
                    let flussonic = '';
                    if (drm.token.ak != undefined && drm.token.ak != '') {
                        let half1 = Math.floor(drm.token.ak.length / 2);
                        akamai = drm.token.ak.slice(half1, drm.token.ak.length) + drm.token.ak.slice(0, half1);
                    }
                    if (drm.token.akl != undefined && drm.token.akl != '') {
                        let half2 = Math.floor(drm.token.akl.length / 2);
                        legacy = drm.token.akl.slice(half2, drm.token.akl.length) + drm.token.akl.slice(0, half2);
                    }
                    if (drm.token.fl != undefined && drm.token.fl != '') {
                        let half3 = Math.floor(drm.token.fl.length / 2);
                        flussonic = drm.token.fl.slice(half3, drm.token.fl.length) + drm.token.fl.slice(0, half3);
                    }
                    playerContext.setTk({
                        ak: akamai,
                        fl: flussonic,
                        akl: legacy,
                        ua: drm.token.useragent,
                    });
                }
            }

            var testDevices = userContext.userId.indexOf('_qa') > -1 ? true : false;

            if (deviceContext.formFactor == 'Television') {
                if (deviceContext.deviceType == 'AndroidTV') {
                    testDevices = data.settings.devices.mediaplayers.androidtv;
                }
                if (deviceContext.deviceType == 'FireTV') {
                    testDevices = data.settings.devices.mediaplayers.firetv;
                }
                if (deviceContext.deviceType == 'SmartTV_Tizen') {
                    testDevices = data.settings.devices.smart_tvs.tizen;
                }
                if (deviceContext.deviceType == 'SmartTV_LG') {
                    testDevices = data.settings.devices.smart_tvs.webos;
                }
                if (deviceContext.deviceType == 'AppleTV') {
                    testDevices = data.settings.devices.mediaplayers.tvos;
                }
            }
            if (deviceContext.formFactor == 'Desktop') {
                if (deviceContext.deviceType == 'SmartTV_Tizen') {
                    testDevices = true;
                }
                if (deviceContext.deviceType == 'SmartTV_LG') {
                    testDevices = true;
                }
            }
            if (deviceContext.formFactor == 'Tablet') {
                if (deviceContext.deviceType == 'Android_Tablet') {
                    testDevices = data.settings.devices.tablets.android;
                }
                if (deviceContext.deviceType == 'iPad') {
                    testDevices = data.settings.devices.tablets.ios;
                }
            }
            if (deviceContext.formFactor == 'Phone') {
                if (deviceContext.deviceType == 'KaiOS') {
                    testDevices = data.settings.devices.phones.android;
                }
                if (deviceContext.deviceType == 'Android_Mobile') {
                    testDevices = data.settings.devices.phones.android;
                }
                if (deviceContext.deviceType == 'iOS') {
                    testDevices = data.settings.devices.phones.ios;
                }
            }
            if (deviceContext.formFactor == 'Desktop') {
                if (deviceContext.deviceType == 'WebTV') {
                    if (data.settings.devices.web.osx == true) {
                        testDevices = true;
                    } else if (data.settings.devices.web.windows == true) {
                        testDevices = true;
                    }
                }
                if (deviceContext.deviceType == 'PWA') {
                    testDevices = true;
                }
            }

            if (testDevices == false) {
                return await lang.getTranslation(userContext, 'error_devicetype');
            }

            if (deviceContext.isWebTV) {
                var webTvUrl = window.location.href;
                var queryStrings = getQueryString(webTvUrl) as any;
                if (webTvUrl.indexOf('connect=true') > -1 || queryStrings?.connect != undefined) {
                    return await checkGeoLock(appContext, deviceContext, userContext, data, country, ipaddress);
                }
            }

            var devices = await getDevices(appContext, contentContext, userContext);
            if (devices.length > 0) {
                var test = devices.filter((d) => d.timestamp > moment().unix() - 172800);
                if (test.length < 100) {
                    //yes add device and continue
                    addOrManageDevice(appContext, contentContext, deviceContext, userContext, test);
                    return await checkGeoLock(appContext, deviceContext, userContext, data, country, ipaddress);
                } else {
                    var search = test.find((d) => d.uuid == deviceContext.uniqueId);
                    if (search != undefined && test.length <= data.settings.max_allowed_devices) {
                        //yes update device and continue
                        addOrManageDevice(appContext, contentContext, deviceContext, userContext, devices);
                        return await checkGeoLock(appContext, deviceContext, userContext, data, country, ipaddress);
                    } else {
                        //no show error and stop
                        return lang.getTranslation(userContext, 'error_devices') + ' (' + data.settings.max_allowed_devices + ')';
                    }
                }
            } else {
                //yes no file found so add device and continue
                addOrManageDevice(appContext, contentContext, deviceContext, userContext, devices);
                return await checkGeoLock(appContext, deviceContext, userContext, data, country, ipaddress);
            }
        } else {
            return await lang.getTranslation(userContext, 'error_product');
        }
    }
};

const checkGeoLock = (appContext, deviceContext, userContext, product, country, ipaddress) => {
    if (product.settings.geolocks == undefined || product.settings.geolocks.countries.length == 0) {
        return checkIpLock(appContext, deviceContext, userContext, product, ipaddress);
    } else {
        var test = product.settings.geolocks.countries.find((c) => c == country);
        if (test == undefined) {
            return lang.getTranslation(userContext, 'error_country');
        } else {
            return checkIpLock(appContext, deviceContext, userContext, product, ipaddress);
        }
    }
};
const checkIpLock = async (appContext, deviceContext, userContext, product, ipaddress) => {
    if (product.settings.ip_access.length == 0) {
        utils.storeJson('AutoLogin', true);
        userContext.setAutoLogin(true);
        return 'success';
    } else {
        var test = product.settings.ip_access.find((i) => i.ip_address == ipaddress);
        if (test != undefined) {
            utils.storeJson('AutoLogin', true);
            userContext.setAutoLogin(true);
            return 'success';
        } else {
            return lang.getTranslation(userContext, 'error_network');
        }
    }
};

//JSON FUNCTIONS
export const getDevices = async (appContext, contentContext, userContext) => {
    let path = utils.getCorrectStoragePrefix(appContext, false) + '/users/' + utils.toAlphaNumeric(userContext.userId).split('').join('/') + '/devices.json?t=' + moment().unix();
    let response = await fetch(path, { cache: 'reload' });
    if (response.status == 200) {
        let data = await response.json();
        if (data != undefined) {
            contentContext.setDevices(data);
            return data;
        } else {
            contentContext.setDevices([]);
            return [];
        }
    } else {
        if (appContext.application.storage != undefined && appContext.application.settings.objectstorage != undefined && appContext.application.settings.objectstorage == true) {
            //when get get to this point is means we did not yet transfered the devices or its a new user to check this first get the old way
            let path = utils.getOldStoragePrefix(appContext, false) + '/users/' + utils.toAlphaNumeric(userContext.userId).split('').join('/') + '/devices.json?t=' + moment().unix();
            let response = await fetch(path, { cache: 'reload' });
            if (response.status == 200) {
                let data = await response.json();
                contentContext.setDevices(data);
                updateDevicesJson(appContext, userContext, data);
                return data;
            } else {
                contentContext.setDevices([]);
                return [];
            }
        }
        contentContext.setDevices([]);
        return [];
    }
};
export const addOrManageDevice = (appContext, contentContext, deviceContext, userContext, devices) => {
    var newDevice = {
        model: deviceContext.model,
        type: deviceContext.type,
        formfactor: deviceContext.formFactor,
        version: deviceContext.version,
        uuid: deviceContext.uniqueId,
        city: userContext.city,
        country: userContext.country,
        ipaddress: deviceContext.ipAddress,
        timestamp: moment().unix(),
        logout: false,
    };
    if (devices.length == 0) {
        devices.push(newDevice);
    } else {
        var test = devices.findIndex((d) => d.uuid == deviceContext.uniqueId);
        if (test == -1) {
            devices.push(newDevice);
        } else {
            devices[test].timestamp = moment().unix();
            devices[test].ipaddress = deviceContext.ipAddress;
            devices[test].formfactor = deviceContext.formFactor;
            devices[test].logout = false;
        }
    }
    //clear old devices from list
    var newDevices = devices.filter((d) => d.timestamp > moment().unix() - 172800);
    contentContext.setDevices(newDevices);
    updateDevicesJson(appContext, userContext, newDevices);
};
export const updateDevicesJson = (appContext, userContext, devices) => {
    if (appContext.application.storage != undefined && appContext.application.settings.objectstorage != undefined && appContext.application.settings.objectstorage == true) {
        //store on Object Storage
        let path = 'users/' + utils.toAlphaNumeric(userContext.userId).split('').join('/');
        AWS.uploadFile(path, 'devices.json', appContext.application.storage.service, devices);
    } else {
        let path = appContext.application.client + '/' + appContext.application.branding.deployment + '/' + appContext.application.branding.service + '/users/' + utils.toAlphaNumeric(userContext.userId).split('').join('/');
        return fetch('https://jsonstorage.io/upload?path=' + encodeURIComponent(path) + '&name=devices.json', {
            method: 'POST',

            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(devices),
        }).catch((error) => {});
    }
};

//FAVORITES
export const manageFavorite = (contentContext, type, data, application, isFavorite, userContext, deviceContext, appContext) => {
    if (isFavorite) {
        sendActionReport(appContext, deviceContext, userContext, actionType.ADD_FAVORITE);
    } else {
        sendActionReport(appContext, deviceContext, userContext, actionType.DELETE_FAVORITE);
    }

    if (type == 'Channels') {
        userContext.profile.favorites.channels = getChannelsFromSubscriptionAndRating(contentContext, userContext.profile.favorites?.channels);
        if (isFavorite) {
            var index = userContext.profile.favorites?.channels.findIndex((m) => m._id == data._id);
            userContext.profile.favorites?.channels.splice(index, 1);
        } else {
            userContext.profile.favorites?.channels.push(data);
        }
    }
    if (type == 'Program') {
        userContext.profile.favorites.channels = getChannelsFromSubscriptionAndRating(contentContext, userContext.profile.favorites?.channels);
        if (isFavorite) {
            var index = userContext.profile.favorites?.channels.findIndex((m) => m._id == data._id);
            userContext.profile.favorites?.channels.splice(index, 1);
        } else {
            userContext.profile.favorites?.channels.push(data);
        }
    }
    if (type == 'Cams') {
        userContext.profile.favorites.cams = getCamsFromSubscriptionAndRating(contentContext, userContext.profile.favorites?.cams);
        if (isFavorite) {
            var index = userContext.profile.favorites?.cams.findIndex((m) => m._id == data._id);
            userContext.profile.favorites?.cams.splice(index, 1);
        } else {
            userContext.profile.favorites?.cams.push(data);
        }
    }
    if (type == 'Radio') {
        userContext.profile.favorites.radios = getRadiosFromSubscriptionAndRating(contentContext, userContext.profile.favorites?.radios);
        if (isFavorite) {
            var index = userContext.profile.favorites?.radios.findIndex((m) => m._id == data._id);
            userContext.profile.favorites?.radios.splice(index, 1);
        } else {
            userContext.profile.favorites?.radios.push(data);
        }
    }
    if (type == 'Albums' || type == 'Album') {
        userContext.profile.favorites.music = getAlbumsFromSubscriptionAndRating(contentContext, userContext.profile.favorites?.music);
        if (isFavorite) {
            var index = userContext.profile.favorites?.music.findIndex((m) => m._id == data._id);
            userContext.profile.favorites?.music.splice(index, 1);
        } else {
            userContext.profile.favorites?.music.push(data);
        }
    }
    if (type == 'Podcasts' || type == 'Podcast') {
        userContext.profile.favorites.podcasts = getPodcastsFromSubscriptionAndRating(contentContext, userContext.profile.favorites?.podcasts);
        if (isFavorite) {
            var index = userContext.profile.favorites?.podcasts.findIndex((m) => m._id == data._id);
            userContext.profile.favorites?.podcasts.splice(index, 1);
        } else {
            userContext.profile.favorites?.podcasts.push(data);
        }
    }
    if (type == 'Movies' || type == 'Movie') {
        userContext.profile.favorites.movies = getMoviesFromSubscriptionAndRating(contentContext, userContext.profile.favorites?.movies);
        if (isFavorite) {
            var index = userContext.profile.favorites?.movies.findIndex((m) => m._id == data._id);
            userContext.profile.favorites?.movies.splice(index, 1);
        } else {
            userContext.profile.favorites?.movies.push(data);
        }
    }
    if (type == 'Shorts' || type == 'Short') {
        userContext.profile.favorites.shorts = getShortsFromSubscriptionAndRating(contentContext, userContext.profile.favorites?.shorts);
        if (isFavorite) {
            if (userContext.profile.favorites?.shorts == undefined) {
                userContext.profile.favorites['shorts'] = [data];
            } else {
                var index = userContext.profile.favorites?.shorts?.findIndex((m) => m._id == data._id);
                userContext.profile.favorites?.shorts?.splice(index, 1);
            }
        } else {
            userContext.profile.favorites?.shorts?.push(data);
        }
    }

    if (type == 'Series' || type == 'Seasons') {
        userContext.profile.favorites.series = getSeriesFromSubscriptionAndRating(contentContext, userContext.profile.favorites?.series);
        if (isFavorite) {
            var index = userContext.profile.favorites?.series.findIndex((m) => m._id == data._id);
            userContext.profile.favorites?.series.splice(index, 1);
        } else {
            userContext.profile.favorites?.series.push(data);
        }
    }
    if (type == 'Courses' || type == 'Course') {
        userContext.profile.favorites.courses = getCoursesFromSubscriptionAndRating(contentContext, userContext.profile.favorites?.courses);
        if (isFavorite) {
            var index = userContext.profile.favorites?.courses.findIndex((m) => m._id == data._id);
            userContext.profile.favorites?.courses.splice(index, 1);
        } else {
            userContext.profile.favorites?.courses.push(data);
        }
    }

    if (type == 'Games' || type == 'Game') {
        userContext.profile.favorites.games = getShortsFromSubscriptionAndRating(contentContext, userContext.profile.favorites?.games);
        if (isFavorite) {
            if (userContext.profile.favorites?.games == undefined) {
                userContext.profile.favorites['games'] = [data];
            } else {
                var index = userContext.profile.favorites?.games?.findIndex((m) => m._id == data._id);
                userContext.profile.favorites?.games?.splice(index, 1);
            }
        } else {
            userContext.profile.favorites?.games?.push(data);
        }
    }
    updateProfile('favorites', userContext, application);
};
export const favoriteStatus = (type, _id, userContext) => {
    if (userContext.profile.favorites?.length == 0) {
        return false;
    }
    var isFavorite = false;
    if (type == 'Channels' || type == 'Program') {
        var test = userContext.profile.favorites?.channels?.find((m) => m?._id == _id);
        if (test != undefined) {
            isFavorite = true;
        }
    }
    if (type == 'Cams') {
        var test = userContext.profile.favorites?.cams?.find((m) => m?._id == _id);
        if (test != undefined) {
            isFavorite = true;
        }
    }
    if (type == 'Radio') {
        var test = userContext.profile.favorites?.radios?.find((m) => m?._id == _id);
        if (test != undefined) {
            isFavorite = true;
        }
    }
    if (type == 'Albums' || type == 'Album' || type == 'Music') {
        var test = userContext.profile.favorites?.music?.find((m) => m?._id == _id);
        if (test != undefined) {
            isFavorite = true;
        }
    }
    if (type == 'Podcasts' || type == 'Podcast') {
        var test = userContext.profile.favorites?.podcasts?.find((m) => m?._id == _id);
        if (test != undefined) {
            isFavorite = true;
        }
    }
    if (type == 'Movies' || type == 'Movie') {
        var test = userContext.profile.favorites?.movies?.find((m) => m?._id == _id);
        if (test != undefined) {
            isFavorite = true;
        }
    }
    if (type == 'Shorts' || type == 'Short') {
        var test = userContext.profile.favorites?.shorts?.find((m) => m?._id == _id);
        if (test != undefined) {
            isFavorite = true;
        }
    }
    if (type == 'Series' || type == 'Seasons') {
        var test = userContext.profile.favorites?.series?.find((m) => m?._id == _id);
        if (test != undefined) {
            isFavorite = true;
        }
    }
    if (type == 'Courses' || type == 'Course') {
        var test = userContext.profile.favorites?.courses?.find((m) => m?._id == _id);
        if (test != undefined) {
            isFavorite = true;
        }
    }
    if (type == 'Games' || type == 'Games') {
        var test = userContext.profile.favorites?.games?.find((m) => m?._id == _id);
        if (test != undefined) {
            isFavorite = true;
        }
    }

    return isFavorite;
};

//WATCHINGS
export const manageWatching = (userContext, type, data, application, isWatching, duration, position, program, episode, lesson, song, contentContext) => {
    const _ = require('lodash');
    let newProfile = _.cloneDeep(userContext.profile);
    if (type == 'Channels') {
        newProfile.watchings.channels = getChannelsFromSubscriptionAndRating(contentContext, newProfile.watchings?.channels);
        if (isWatching == false) {
            var index = newProfile.watchings?.channels.findIndex((m) => m._id == data._id);
            if (index >= 0 && newProfile.watchings?.channels.length <= 20) {
                newProfile.watchings?.channels.splice(index, 1);
            } else if (newProfile.watchings?.channels.length == 20) {
                newProfile.watchings?.channels.pop();
            }
            data = {
                ...data,
                program: program,
            };
            newProfile.watchings?.channels.push(data);
            newProfile.watchings.channels = [...newProfile.watchings?.channels].reverse();
        } else {
            var index = newProfile.watchings?.channels.findIndex((m) => m._id == data._id);
            newProfile.watchings?.channels.splice(index, 1);
        }
    }
    if (type == 'Program') {
        newProfile.watchings.channels = getChannelsFromSubscriptionAndRating(contentContext, newProfile.watchings?.channels);
        if (isWatching == false) {
            var index = newProfile.watchings?.channels.findIndex((m) => m._id == data._id);
            if (index >= 0 && newProfile.watchings?.channels.length <= 20) {
                newProfile.watchings?.channels.splice(index, 1);
            } else if (newProfile.watchings?.channels.length == 20) {
                newProfile.watchings?.channels.pop();
            }
            newProfile.watchings?.channels.push(data);
            newProfile.watchings.channels = [...newProfile.watchings?.channels].reverse();
        } else {
            var index = newProfile.watchings?.channels.findIndex((m) => m._id == data._id);
            newProfile.watchings?.channels.splice(index, 1);
        }
    }
    if (type == 'Radio') {
        newProfile.watchings.radios = getRadiosFromSubscriptionAndRating(contentContext, newProfile.watchings?.radios);
        if (isWatching == false) {
            var index = newProfile.watchings?.radios.findIndex((m) => m._id == data._id);
            if (index >= 0 && newProfile.watchings?.radios.length <= 20) {
                newProfile.watchings?.radios.splice(index, 1);
            } else if (newProfile.watchings?.radios.length == 20) {
                newProfile.watchings?.radios.pop();
            }
            data = {
                ...data,
                program: program,
            };
            newProfile.watchings?.radios.push(data);
            newProfile.watchings.radios = [...newProfile.watchings?.radios].reverse();
        } else {
            var index = newProfile.watchings?.radios.findIndex((m) => m._id == data._id);
            newProfile.watchings?.radios.splice(index, 1);
        }
    }
    if (type == 'Cams') {
        newProfile.watchings.cams = getCamsFromSubscriptionAndRating(contentContext, newProfile.watchings?.cams);
        if (isWatching == false) {
            var index = newProfile.watchings?.cams.findIndex((m) => m._id == data._id);
            if (index >= 0 && newProfile.watchings?.cams.length <= 20) {
                newProfile.watchings?.cams.splice(index, 1);
            } else if (newProfile.watchings?.cams.length == 20) {
                newProfile.watchings?.cams.pop();
            }
            data = {
                ...data,
            };
            newProfile.watchings?.cams.push(data);
            newProfile.watchings.cams = [...newProfile.watchings?.cams].reverse();
        } else {
            var index = newProfile.watchings?.cams.findIndex((m) => m._id == data._id);
            newProfile.watchings?.cams.splice(index, 1);
        }
    }
    if (type == 'Movies') {
        newProfile.watchings.movies = getMoviesFromSubscriptionAndRating(contentContext, newProfile.watchings?.movies);
        if (isWatching == false) {
            var index = newProfile.watchings?.movies.findIndex((m) => m._id == data._id);
            if (index >= 0 && newProfile.watchings?.movies.length <= 20) {
                newProfile.watchings?.movies.splice(index, 1);
            } else if (newProfile.watchings?.movies.length == 20) {
                newProfile.watchings?.movies.pop();
            }
            data = {
                ...data,
                continue: {
                    duration: duration,
                    position: position,
                },
            };
            newProfile.watchings?.movies.push(data);
            newProfile.watchings.movies = [...newProfile.watchings?.movies].reverse();
        } else {
            var index = newProfile.watchings?.movies.findIndex((m) => m._id == data._id);
            newProfile.watchings?.movies.splice(index, 1);
        }
    }
    if (type == 'Movie') {
        newProfile.watchings.movies = getMoviesFromSubscriptionAndRating(contentContext, newProfile.watchings?.movies);
        if (isWatching == false) {
            var index = newProfile.watchings?.movies.findIndex((m) => m._id == data._id);
            if (index >= 0 && newProfile.watchings?.movies.length <= 20) {
                newProfile.watchings?.movies.splice(index, 1);
            } else if (newProfile.watchings?.movies.length == 20) {
                newProfile.watchings?.movies.pop();
            }
            data = {
                ...data,
                continue: {
                    duration: duration,
                    position: position,
                },
            };
            newProfile.watchings?.movies.push(data);
            newProfile.watchings.movies = [...newProfile.watchings?.movies].reverse();
        } else {
            var index = newProfile.watchings?.movies.findIndex((m) => m._id == data._id);
            newProfile.watchings?.movies.splice(index, 1);
        }
    }
    if (type == 'Recordings') {
        if (isWatching == false) {
            var index = newProfile.watchings?.recordings.findIndex((m) => m._id == data._id);
            if (index >= 0 && newProfile.watchings?.recordings.length <= 20) {
                newProfile.watchings?.recordings.splice(index, 1);
            } else if (newProfile.watchings?.recordings.length == 20) {
                newProfile.watchings?.recordings.pop();
            }
            data = {
                ...data,
                continue: {
                    duration: duration,
                    position: position,
                },
            };
            newProfile.watchings?.recordings.push(data);
            newProfile.watchings.recordings = [...newProfile.watchings?.recordings].reverse();
        } else {
            var index = newProfile.watchings?.recordings.findIndex((m) => m._id == data._id);
            newProfile.watchings?.recordings.splice(index, 1);
        }
    }
    if (type == 'CatchupTV') {
        if (isWatching == false) {
            var index = newProfile.watchings?.catchuptv.findIndex((m) => m._id == data._id);
            if (index >= 0 && newProfile.watchings?.catchuptv.length <= 20) {
                newProfile.watchings?.catchuptv.splice(index, 1);
            } else if (newProfile.watchings?.catchuptv.length == 20) {
                newProfile.watchings?.catchuptv.pop();
            }
            data = {
                ...data,
                continue: {
                    duration: duration,
                    position: position,
                },
            };
            newProfile.watchings?.catchuptv.push(data);
            newProfile.watchings.catchuptv = [...newProfile.watchings?.catchuptv].reverse();
        } else {
            var index = newProfile.watchings?.catchuptv.findIndex((m) => m._id == data._id);
            newProfile.watchings?.catchuptv.splice(index, 1);
        }
    }
    if (type == 'Albums') {
        newProfile.watchings.music = getAlbumsFromSubscriptionAndRating(contentContext, newProfile.watchings?.music);
        if (isWatching == false) {
            var index = newProfile.watchings?.music.findIndex((m) => m.continue._id == data._id);
            if (index == -1) {
                var album_data = {
                    ...data,
                    continue: {
                        _id: data.album._id,
                        song_index: data.songIndex,
                    },
                    songs: [
                        {
                            _id: song._id,
                            duration: duration,
                            position: position,
                        },
                    ],
                };
                newProfile.watchings?.music.push(album_data);
            } else {
                newProfile.watchings.continue._id = data._id;
                newProfile.watchings.continue.song_index = data.songIndex;
                var index_ = newProfile.watchings?.songs.findIndex((m) => m._id == episode._id);
                if (index_ != -1) {
                    newProfile.watchings?.songs.splice(index_, 1);
                }
                newProfile.watchings?.songs.push({
                    _id: song._id,
                    duration: duration,
                    position: position,
                });
            }
        } else {
            var index = newProfile.watchings?.music.findIndex((m) => m._id == data._id);
            newProfile.watchings?.music.splice(index, 1);
        }
    }
    if (type == 'Podcasts') {
        newProfile.watchings.podcasts = getPodcastsFromSubscriptionAndRating(contentContext, newProfile.watchings?.podcasts);
        if (isWatching == false) {
            var index = newProfile.watchings?.podcasts.findIndex((m) => m.continue._id == data._id);
            if (index == -1) {
                var podcast_data = {
                    ...data,
                    continue: {
                        _id: data._id,
                        episode_index: data.podIndex,
                    },
                    episodes: [
                        {
                            _id: episode._id,
                            duration: duration,
                            position: position,
                        },
                    ],
                };
                newProfile.watchings?.podcasts.push(podcast_data);
            } else {
                newProfile.watchings.continue._id = data._id;
                newProfile.watchings.continue.episode_index = data.podIndex;
                var index_ = newProfile.watchings?.episodes.findIndex((m) => m._id == episode._id);
                if (index_ != -1) {
                    newProfile.watchings?.episodes.splice(index_, 1);
                }
                newProfile.watchings?.episodes.push({
                    _id: episode._id,
                    duration: duration,
                    position: position,
                });
            }
        } else {
            var index = newProfile.watchings?.podcasts.findIndex((m) => m._id == data._id);
            newProfile.watchings?.podcasts.splice(index, 1);
        }
    }
    if (type == 'Series' && data.series == undefined) {
        if (isWatching == false) {
            var index = newProfile.watchings?.series.findIndex((m) => m?._id == data._id);
            if (index == -1) {
                var series_data = {
                    ...data,
                    continue: {
                        season_index: data.seasonIndex ?? 0,
                        episode_index: data.episodeIndex ?? 0,
                        episodes: [
                            {
                                _id: episode._id ?? '',
                                duration: duration ?? 0,
                                position: position ?? 0,
                            },
                        ],
                    },
                };
                newProfile.watchings?.series.push(series_data);
            } else {
                newProfile.watchings.series[index].continue.season_index = data.seasonCategoryIndex;
                newProfile.watchings.series[index].continue.episode_index = data.episodeIndex;
                var index_ = newProfile.watchings?.series[index]?.continue?.episodes?.findIndex((m) => m._id == episode._id);
                if (index_ != -1) {
                    newProfile.watchings?.series[index].continue.episodes.splice(index_, 1);
                }
                newProfile.watchings?.series[index].continue.episodes.push({
                    _id: episode._id,
                    duration: duration,
                    position: position,
                });
            }
        } else {
            var index = newProfile.watchings?.series.findIndex((m) => m._id == data._id);
            newProfile.watchings?.series.splice(index, 1);
        }
    }
    if (type == 'Series' && data.series != undefined) {
        if (isWatching === false) {
            var index = newProfile.watchings?.series.findIndex((m) => m?._id == data.series._id);
            if (index == -1) {
                var series_data = {
                    ...data.series,
                    continue: {
                        season_index: data.seasonCategoryIndex ?? 0,
                        episode_index: data.episodeIndex ?? 0,
                        episodes: [
                            {
                                _id: episode._id ?? '',
                                duration: duration,
                                position: position,
                            },
                        ],
                    },
                };
                newProfile.watchings?.series.push(series_data);
            } else {
                const newEpisode = {
                    _id: episode._id,
                    duration: duration,
                    position: position,
                };
                const episodeIndex = newProfile.watchings?.series[index]?.continue?.episodes?.findIndex((m) => m._id == episode._id);
                if (episodeIndex !== -1) {
                    newProfile.watchings?.series[index].continue.episodes.splice(episodeIndex, 1, newEpisode);
                } else {
                    newProfile.watchings?.series[index].continue.episodes.push(newEpisode);
                }
            }
        } else {
            var index = newProfile.watchings?.series.findIndex((m) => m._id == data._id);
            newProfile.watchings?.series.splice(index, 1);
        }
    }
    if (type == 'Seasons') {
        if (isWatching == false) {
            var index = newProfile.watchings?.series.findIndex((m) => m?._id == data._id);
            if (index == -1) {
                var series_data = {
                    ...data,
                    continue: {
                        season_index: data.seasonIndex ?? 0,
                        episode_index: data.episodeIndex ?? 0,
                        episodes: [
                            {
                                _id: episode._id ?? '',
                                duration: duration ?? 0,
                                position: position ?? 0,
                            },
                        ],
                    },
                };
                newProfile.watchings?.series.push(series_data);
            } else {
                newProfile.watchings.series[index].continue.season_index = data.seasonCategoryIndex;
                newProfile.watchings.series[index].continue.episode_index = data.episodeIndex;
                var index_ = newProfile.watchings?.series[index].continue.episodes.findIndex((m) => m._id == data.seasons[0].episodes[0]._id);
                if (index_ != -1) {
                    newProfile.watchings?.series[index].continue.episodes.splice(index_, 1);
                }
                newProfile.watchings?.series[index].continue.episodes.push({
                    _id: data.seasons[0].episodes[0]._id,
                    duration: 0,
                    position: 0,
                });
            }
        } else {
            var index = newProfile.watchings?.series.findIndex((m) => m._id == data._id);
            newProfile.watchings?.series.splice(index, 1);
        }
    }
    if (type == 'Courses' || type == 'Course') {
        if (isWatching == false) {
            var index = newProfile.watchings?.courses.findIndex((m) => m._id == data.course._id);
            if (index == -1) {
                const courses_data = {
                    ...data.course,
                    continue: {
                        lesson_index: data.lessonIndex,
                        lessons: [
                            {
                                _id: lesson.streams.courses._id,
                                duration: duration,
                                position: position,
                            },
                        ],
                    },
                };
                newProfile.watchings?.courses.push(courses_data);
            } else {
                const newLesson = {
                    _id: lesson.streams.courses._id,
                    duration: duration ?? 0,
                    position: position ?? 0,
                };
                newProfile.watchings.courses[index].continue.lesson_index = data.lessonIndex;
                const lessonIndex = newProfile.watchings?.courses[index]?.continue?.lessons?.findIndex((m) => m._id === lesson.streams.courses._id);
                if (lessonIndex !== -1) {
                    newProfile.watchings?.courses[index]?.continue?.lessons?.splice(lessonIndex, 1, newLesson);
                } else {
                    newProfile.watchings?.courses[index]?.continue?.lessons?.push(newLesson);
                }
            }
        } else {
            var index = newProfile.watchings?.courses.findIndex((m) => m._id == data._id);
            newProfile.watchings?.courses.splice(index, 1);
        }
    }
    if (type == 'Shorts' || type == 'Short') {
        newProfile.watchings.shorts = getShortsFromSubscriptionAndRating(contentContext, newProfile.watchings?.shorts);
        if (isWatching == false) {
            if (newProfile.watchings?.shorts == undefined) {
                newProfile.watchings['shorts'] = [data];
            } else {
                var index = newProfile.watchings?.shorts?.findIndex((m) => m._id == data._id);
                if (index >= 0 && newProfile.watchings?.shorts?.length <= 20) {
                    newProfile.watchings?.shorts?.splice(index, 1);
                } else if (newProfile.watchings?.shorts?.length == 20) {
                    newProfile.watchings?.shorts?.pop();
                }
                data = {
                    ...data,
                    continue: {
                        duration: duration,
                        position: position,
                    },
                };
                newProfile.watchings?.shorts?.push(data);
                newProfile.watchings.shorts = [...newProfile.watchings?.shorts].reverse();
            }
        } else {
            var index = newProfile.watchings?.shorts?.findIndex((m) => m._id == data._id);
            newProfile.watchings?.shorts?.splice(index, 1);
        }
    }
    if (type == 'Games' || type == 'Game') {
        newProfile.watchings.games = getShortsFromSubscriptionAndRating(contentContext, newProfile.watchings?.games);
        if (isWatching == false) {
            if (newProfile.watchings?.games == undefined) {
                newProfile.watchings['games'] = [data];
            } else {
                var index = newProfile.watchings?.games?.findIndex((m) => m._id == data._id);
                if (index >= 0 && newProfile.watchings?.games?.length <= 20) {
                    newProfile.watchings?.games?.splice(index, 1);
                } else if (newProfile.watchings?.games?.length == 20) {
                    newProfile.watchings?.games?.pop();
                }
                data = {
                    ...data,
                    continue: {
                        duration: duration,
                        position: position,
                    },
                };
                newProfile.watchings?.games?.push(data);
                newProfile.watchings.games = [...newProfile.watchings?.games].reverse();
            }
        } else {
            var index = newProfile.watchings?.games?.findIndex((m) => m._id == data._id);
            newProfile.watchings?.games?.splice(index, 1);
        }
    }
    updateProfile('watchings', userContext, application, newProfile);
};
export const watchingStatus = (type, _id, userContext) => {
    if (type == 'Channels' || type == 'Program') {
        var test = userContext.profile.watchings?.channels?.find((m) => m?._id == _id);
        if (test != undefined) {
            return test;
        }
    }
    if (type == 'Movies' || type == 'Movie') {
        var test = userContext.profile.watchings?.movies?.find((m) => m?._id == _id);
        if (test != undefined) {
            return test.continue;
        }
    }

    if (type == 'Shorts' || type == 'Short') {
        var test = userContext.profile.watchings?.shorts?.find((m) => m?._id == _id);
        if (test != undefined) {
            return test.continue;
        }
    }
    if (type == 'Albums') {
        var test = userContext.profile.watchings?.music?.find((m) => m?._id == _id);
        if (test != undefined) {
            return test.continue;
        }
    }
    if (type == 'Podcasts') {
        var test = userContext.profile.watchings?.podcasts?.find((m) => m?._id == _id);
        if (test != undefined) {
            return test.continue;
        }
    }
    if (type == 'Recordings') {
        var test = userContext.profile.watchings?.recordings?.find((m) => m?._id == _id);
        if (test != undefined) {
            return test.continue;
        }
    }
    if (type == 'CatchupTV') {
        var test = userContext.profile.watchings?.catchuptv?.find((m) => m?._id == _id);
        if (test != undefined) {
            return test.continue;
        }
    }
    if (type == 'Series' || type == 'Seasons') {
        var test = userContext.profile.watchings?.series?.find((m) => m?._id == _id);
        if (test != undefined) {
            return test;
        }
    }
    if (type == 'Courses' || type == 'Course') {
        var test = userContext.profile.watchings?.courses?.find((m) => m?._id == _id);
        if (test != undefined) {
            return test;
        }
    }
    if (type == 'Games' || type == 'Game') {
        var test = userContext.profile.watchings?.games?.find((m) => m?._id == _id);
        if (test != undefined) {
            return test;
        }
    }
};

//DEVICE TOKENS
const registerDeviceToken = async (token, user, uuid, model, appContext) => {
    await fetch(
        getGlobals().AppApiUrl +
            '/register/devicetoken?client=' +
            encodeURIComponent(appContext.application.client) +
            '&deployment=' +
            encodeURIComponent(appContext.application.deployment) +
            '&service=' +
            encodeURIComponent(appContext.application.branding.service),
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username: user,
                uuid: uuid,
                token: token,
                model: model,
                timestamp: moment().unix(),
            }),
        }
    );
};

//PROFILES
const updateProfile = (type, userContext, application, items?) => {
    var findIndex = userContext.allProfiles.findIndex((c) => c.id == userContext.profile.id);
    if (items) {
        userContext.setProfile(items);
        userContext.allProfiles[findIndex] = items;
    } else {
        userContext.setProfile(userContext.profile);
        userContext.allProfiles[findIndex] = userContext.profile;
    }
    updateProfilesJson(userContext.allProfiles, application, userContext);
};
export const getProfiles = async (application, userContext, deviceContext, connectedToNetwork, appContext) => {
    let path = utils.getCorrectStoragePrefix(appContext, false) + 'users/' + utils.toAlphaNumeric(userContext.userId).split('').join('/') + '/profiles.json?t=' + moment().unix();

    if ((deviceContext.isPhone || deviceContext.isTablet) && !connectedToNetwork) {
        return await RNFetchBlob.fs.readFile(`${RNFetchBlob.fs.dirs.DocumentDir}/${userContext.userId}-profiles.json`, 'utf8').then((data) => {
            return JSON.parse(data);
        });
    }
    let response = await fetch(path);
    if (response.status == 200) {
        let data = await response.json();
        if (data != undefined) {
            if (deviceContext.isPhone || deviceContext.isTablet) {
                const fileExists = await RNFetchBlob.fs.exists(`${RNFetchBlob.fs.dirs.DocumentDir}/${userContext.userId}-profiles.json`);
                if (!fileExists) {
                    RNFetchBlob.fs.writeFile(`${RNFetchBlob.fs.dirs.DocumentDir}/${userContext.userId}-profiles.json`, JSON.stringify(data), 'utf8');
                }
            }
            return data;
        } else {
            return null;
        }
    } else {
        if (appContext.application.storage != undefined && appContext.application.settings.objectstorage != undefined && appContext.application.settings.objectstorage == true) {
            //when get get to this point is means we did not yet transfered the profiles or its a new user to check this first get the old way
            let path = utils.getOldStoragePrefix(appContext, false) + 'users/' + utils.toAlphaNumeric(userContext.userId).split('').join('/') + '/profiles.json?t=' + moment().unix();
            let response = await fetch(path);
            if (response.status == 200) {
                let data = await response.json();
                if (data != undefined) {
                    if (deviceContext.isPhone || deviceContext.isTablet) {
                        const fileExists = await RNFetchBlob.fs.exists(`${RNFetchBlob.fs.dirs.DocumentDir}/${userContext.userId}-profiles.json`);
                        if (!fileExists) {
                            RNFetchBlob.fs.writeFile(`${RNFetchBlob.fs.dirs.DocumentDir}/${userContext.userId}-profiles.json`, JSON.stringify(data), 'utf8');
                        }
                    }
                    updateProfilesJson(data, application, userContext);
                    return data;
                }
            }
        }
        return null;
    }
};
export const manageProfiles = (userContext, profile, type, value, application) => {
    var findIndex = userContext.allProfiles.findIndex((c) => c.id == profile.id);
    switch (type) {
        case 'name':
            userContext.allProfiles[findIndex].name = value;
            break;
        case 'locked':
            userContext.allProfiles[findIndex].locked = value; //account lock
            break;
        case 'subtitles':
            userContext.allProfiles[findIndex].subtitles = value; //done
            break;
        case 'audio':
            userContext.allProfiles[findIndex].audio = value; //done
            break;
        case 'language':
            userContext.allProfiles[findIndex].language = value;
            break;
        case 'parental':
            userContext.allProfiles[findIndex].parental = value;
            break;
        case 'code':
            userContext.allProfiles[findIndex].code = value;
            break;
        case 'clock':
            userContext.allProfiles[findIndex].clock = value;
            break;
        case 'rating':
            userContext.allProfiles[findIndex].rating = value; //pg
            break;
        case 'aspectRatio':
            userContext.allProfiles[findIndex].aspectRatio = value; //done
            break;
        case 'searches':
            userContext.allProfiles[findIndex].searches = value; //done
            break;
        case 'trackStyle':
            userContext.allProfiles[findIndex].trackStyle = value; //done
            break;
        default:
            break;
    }
    userContext.setProfile(userContext.allProfiles[findIndex]);
    updateProfilesJson(userContext.allProfiles, application, userContext);
};
export const updateProfilesJson = (profiles, application, userContext) => {
    if (application.storage != undefined && application.settings.objectstorage != undefined && application.settings.objectstorage == true) {
        //store on Object Storage
        let path = 'users/' + utils.toAlphaNumeric(userContext.userId).split('').join('/');
        AWS.uploadFile(path, 'profiles.json', application.storage.service, profiles);
    } else {
        let path = application.client + '/' + application.branding.deployment + '/' + application.branding.service + '/users/' + utils.toAlphaNumeric(userContext.userId).split('').join('/');

        fetch('https://jsonstorage.io/upload?path=' + encodeURIComponent(path) + '&name=profiles.json', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(profiles),
        })
            .then(() => {
                if (!Platform.isTV && (Platform.OS === 'android' || Platform.OS === 'ios')) {
                    RNFetchBlob.fs.writeFile(`${RNFetchBlob.fs.dirs.DocumentDir}/${userContext.userId}-profiles.json`, JSON.stringify(profiles), 'utf8');
                }
            })
            .catch((error) => {});
    }
};

//REMINDERS
export const manageReminders = (type, newRem, userContext, application) => {
    if (type == 'add') {
        userContext.profile.reminders.push(newRem);
    }
    if (type == 'delete') {
        userContext.profile.reminders.splice(newRem, 1);
    }
    updateProfile('reminders', userContext, application);
};
export const remindStatus = (_id, userContext) => {
    var test = userContext.profile.reminders.find((m) => m.program.i == _id);
    if (test != undefined) {
        return true;
    } else {
        return false;
    }
};

//RECORDINGS
export const manageRecordings = (type, newRec, userContext, application) => {
    if (type == 'add') {
        userContext.profile.recordings.push(newRec);
    }
    if (type == 'delete') {
        userContext.profile.recordings.splice(newRec, 1);
    }
    updateProfile('recordings', userContext, application);
};
export const recordStatus = (_id, userContext) => {
    var test = userContext.profile.recordings.find((m) => m?.program?.i == _id);
    if (test != undefined) {
        return true;
    } else {
        return false;
    }
};

//RECOMMENDATIONS
export const setRecommendations = async (tags, application, userContext) => {
    if (userContext.profile.recommendations == undefined || tags == undefined || tags == '' || tags == null || tags.length == 0 || !Array.isArray(tags)) {
        return;
    } else {
        tags.forEach((tag) => {
            var test = userContext.profile.recommendations.findIndex((r) => r.tag == tag.name);
            if (test == -1) {
                userContext.profile.recommendations.push({
                    tag: tag.name,
                    count: 1,
                });
            } else {
                userContext.profile.recommendations[test].count = userContext.profile.recommendations[test].count + 1;
            }
        });
        updateProfile('recommendations', userContext, application);
    }
};

//MESSAGES
export const getMessages = async (application, userContext, appContext) => {
    let path =
        utils.getCorrectStoragePrefix(appContext, true) + application.client + '/' + application.branding.deployment + '/' + application.branding.service + '/users/' + utils.toAlphaNumeric(userContext.userId).split('').join('/') + '/messages.json';
    let response = await fetch(path);
    if (response.status == 200) {
        let data = await response.json();
        if (data != undefined) {
            return data;
        } else {
            return [];
        }
    } else {
        return [];
    }
};

//PAY PER VIEW
export const managePayPerView = (transaction_type, ppv_rule, ppv_start, ppv_end, type, content, content_type, access_type, access_length, transaction_amount, application, userContext) => {
    if (type == 'Channel') {
        userContext.profile.payperview?.channels.push({
            content: content,
            content_type: content_type,
            transaction_type: transaction_type,
            transaction_amount: transaction_amount,
            access_type: access_type,
            access_length: access_length,
            ppv_rule: ppv_rule,
            ppv_start: ppv_start,
            ppv_end: ppv_end,
        });
    }
    if (type == 'Program') {
        userContext.profile.payperview?.channels.push({
            content: content,
            content_type: content_type,
            transaction_type: transaction_type,
            transaction_amount: transaction_amount,
            access_type: access_type,
            access_length: access_length,
            ppv_rule: ppv_rule,
            ppv_start: ppv_start,
            ppv_end: ppv_end,
        });
    }
    if (type == 'Cam') {
        userContext.profile.payperview?.cams.push({
            content: content,
            content_type: content_type,
            transaction_type: transaction_type,
            transaction_amount: transaction_amount,
            access_type: access_type,
            access_length: access_length,
            ppv_rule: ppv_rule,
            ppv_start: ppv_start,
            ppv_end: ppv_end,
        });
    }
    if (type == 'Radio') {
        userContext.profile.payperview?.radios.push({
            content: content,
            content_type: content_type,
            transaction_type: transaction_type,
            transaction_amount: transaction_amount,
            access_type: access_type,
            access_length: access_length,
            ppv_rule: ppv_rule,
            ppv_start: ppv_start,
            ppv_end: ppv_end,
        });
    }
    if (type == 'Album') {
        userContext.profile.payperview?.music.push({
            content: content,
            content_type: content_type,
            transaction_type: transaction_type,
            transaction_amount: transaction_amount,
            access_type: access_type,
            access_length: access_length,
            ppv_rule: ppv_rule,
            ppv_start: ppv_start,
            ppv_end: ppv_end,
        });
    }
    if (type == 'Podcast') {
        userContext.profile.payperview?.podcasts.push({
            content: content,
            content_type: content_type,
            transaction_type: transaction_type,
            transaction_amount: transaction_amount,
            ppv_rule: ppv_rule,
            ppv_start: ppv_start,
            ppv_end: ppv_end,
        });
    }
    if (type == 'Movie') {
        userContext.profile.payperview?.movies.push({
            content: content,
            content_type: content_type,
            transaction_type: transaction_type,
            transaction_amount: transaction_amount,
            access_type: access_type,
            access_length: access_length,
            ppv_rule: ppv_rule,
            ppv_start: ppv_start,
            ppv_end: ppv_end,
        });
    }
    if (type == 'Short') {
        userContext.profile.payperview?.shorts.push({
            content: content,
            content_type: content_type,
            transaction_type: transaction_type,
            transaction_amount: transaction_amount,
            access_type: access_type,
            access_length: access_length,
            ppv_rule: ppv_rule,
            ppv_start: ppv_start,
            ppv_end: ppv_end,
        });
    }

    if (type == 'Series' || type == 'Seasons') {
        userContext.profile.payperview?.series.push({
            content: content,
            content_type: content_type,
            transaction_type: transaction_type,
            transaction_amount: transaction_amount,
            access_type: access_type,
            access_length: access_length,
            ppv_rule: ppv_rule,
            ppv_start: ppv_start,
            ppv_end: ppv_end,
        });
    }
    if (type == 'Course') {
        userContext.profile.payperview?.courses.push({
            content: content,
            content_type: content_type,
            transaction_type: transaction_type,
            transaction_amount: transaction_amount,
            access_type: access_type,
            access_length: access_length,
            ppv_rule: ppv_rule,
            ppv_start: ppv_start,
            ppv_end: ppv_end,
        });
    }

    if (type == 'Game') {
        userContext.profile.payperview?.games.push({
            content: content,
            content_type: content_type,
            transaction_type: transaction_type,
            transaction_amount: transaction_amount,
            access_type: access_type,
            access_length: access_length,
            ppv_rule: ppv_rule,
            ppv_start: ppv_start,
            ppv_end: ppv_end,
        });
    }
    updateProfile('payperview', userContext, application);
};
export const payperviewStatus = (type, _id, userContext) => {
    if (userContext.profile.payperview?.length == 0) {
        return {
            isPayPerView: false,
            timePayPerViewStart: 0,
        };
    }
    var isPayPerView = false;
    var timePayPerViewStart = 0;
    if (type == 'Channels' || type == 'Program') {
        var test = userContext.profile.payperview?.channels?.find((m) => m?.content?._id == _id && m.ppv_end > moment().unix());
        if (test != undefined) {
            timePayPerViewStart = test.ppv_start;
            isPayPerView = true;
        }
    }
    if (type == 'Cams') {
        var test = userContext.profile.payperview?.cams?.find((m) => m?.content?._id == _id && m.ppv_end > moment().unix());
        if (test != undefined) {
            timePayPerViewStart = test.ppv_start;
            isPayPerView = true;
        }
    }
    if (type == 'Radio') {
        var test = userContext.profile.payperview?.radios?.find((m) => m?.content?._id == _id && m.ppv_end > moment().unix());
        if (test != undefined) {
            timePayPerViewStart = test.ppv_start;
            isPayPerView = true;
        }
    }
    if (type == 'Albums' || type == 'Album' || type == 'Music') {
        var test = userContext.profile.payperview?.music?.find((m) => m?.content?._id == _id && m.ppv_end > moment().unix());
        if (test != undefined) {
            timePayPerViewStart = test.ppv_start;
            isPayPerView = true;
        }
    }
    if (type == 'Podcasts' || type == 'Podcast') {
        var test = userContext.profile.payperview?.podcasts?.find((m) => m?.content?._id == _id && m.ppv_end > moment().unix());
        if (test != undefined) {
            timePayPerViewStart = test.ppv_start;
            isPayPerView = true;
        }
    }

    if (type == 'Movies' || type == 'Movie') {
        var test = userContext.profile.payperview?.movies?.find((m) => m?.content?._id == _id && m.ppv_end > moment().unix());

        if (test != undefined) {
            timePayPerViewStart = test.ppv_start;
            isPayPerView = true;
        }
    }
    if (type == 'Shorts' || type == 'Short') {
        var test = userContext.profile.payperview?.shorts?.find((m) => m?.content?._id == _id && m.ppv_end > moment().unix());
        if (test != undefined) {
            timePayPerViewStart = test.ppv_start;
            isPayPerView = true;
        }
    }
    if (type == 'Series' || type == 'Seasons') {
        var test = userContext.profile.payperview?.series?.find((m) => m?.content?._id == _id && m.ppv_end > moment().unix());
        if (test != undefined) {
            timePayPerViewStart = test.ppv_start;
            isPayPerView = true;
        }
    }
    if (type == 'Courses' || type == 'Course') {
        var test = userContext.profile.payperview?.courses?.find((m) => m?.content?._id == _id && m.ppv_end > moment().unix());
        if (test != undefined) {
            timePayPerViewStart = test.ppv_start;
            isPayPerView = true;
        }
    }
    if (type == 'Games' || type == 'Games') {
        var test = userContext.profile.payperview?.games?.find((m) => m?.content?._id == _id && m.ppv_end > moment().unix());
        if (test != undefined) {
            timePayPerViewStart = test.ppv_start;
            isPayPerView = true;
        }
    }

    return {
        isPayPerView: isPayPerView,
        timePayPerViewStart: timePayPerViewStart,
    };
};
