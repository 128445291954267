import * as React from 'react';
import { View, Image as Image_ } from '@scriptx-com/xtv-toolkit';
import Pressable from '../../components/pressable/pressable';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { DeviceContext } from '../../../context/deviceContext';
import { AppContext } from '../../../context/appContext';
import Image from '../../components/image/image';
import { getFocusStyling, getPressableStyling } from '../../components/helpers/helper';
import { ContentContext } from '../../../context/contentContext';
import utils from '../../../general/utils';

export interface Program {
    n: string;
    s: any;
    e: any;
}
export interface Data {
    name: string;
    number: string;
    images: {
        widescreen?: string;
        square?: string;
        tile?: string;
        background?: string;
        poster?: string;
        logo?: string;
    };
    _id: string;
    progress?: number;
    catchuptv?: boolean;
    program?: Program;
    color?: string;
    package?: string;
}

export interface Props {
    focusContext?: any;
    focusRepeatContext?: any;
    noMargin?: boolean;
    height: number;
    width: number;
    onPress: any;
    styling: any;
    data: Data;
    type: string;
    index: number;
    rails?: boolean;
    onLongPress: any;
    heroIsPlayer: boolean;
    focusOptions?: any;
    elementType: string;
    onFocus?: () => void;
    onBlur?: () => void;
    isFirst?: boolean;
    isLast?: boolean;
}

const NUMERIC_IMAGES = {
    1: require('../../../../assets/images/numbers/1.png'),
    2: require('../../../../assets/images/numbers/2.png'),
    3: require('../../../../assets/images/numbers/3.png'),
    4: require('../../../../assets/images/numbers/4.png'),
    5: require('../../../../assets/images/numbers/5.png'),
    6: require('../../../../assets/images/numbers/6.png'),
    7: require('../../../../assets/images/numbers/7.png'),
    8: require('../../../../assets/images/numbers/8.png'),
    9: require('../../../../assets/images/numbers/9.png'),
    10: require('../../../../assets/images/numbers/10.png'),
};

export const Top10 = ({ focusContext, focusRepeatContext, focusOptions, height, width, onPress, styling, type, data, index, onLongPress, rails, heroIsPlayer, elementType, ...rest }: Props) => {
    const deviceContext = React.useContext(DeviceContext);
    const appContext = React.useContext(AppContext);
    const contentContext = React.useContext(ContentContext);
    const timeout = React.useRef<NodeJS.Timeout>();

    const onFocus = (data) => {
        timeout.current = setTimeout(() => {
            if (styling.content?.link_to_hero != undefined && styling.content?.link_to_hero && heroIsPlayer) {
                contentContext.setHero({
                    type: type,
                    data: [data],
                });
            }
        }, 1000);
    };

    const onBlur = () => {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
    };

    const getCorrectScaleStyling = () => {
        return getFocusStyling(elementType, appContext).type.indexOf('scale') > -1 && elementType == 'Rails' && deviceContext.formFactor == 'Television';
    };

    const width_ = (width - getCorrectHeight(deviceContext, appContext.application.theme.images.margin * 2)) * 0.7;
    const height_ = (height - getCorrectHeight(deviceContext, appContext.application.theme.images.margin * 2)) * 0.7;

    if (data == undefined && index > 9) {
        return null;
    } else {
        return (
            <View style={getCorrectScaleStyling() ? { height: height * 1.1, justifyContent: 'center', width: width + getCorrectWidth(deviceContext, 100) } : {}}>
                <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Pressable
                        style={{
                            ...getPressableStyling(styling.components?.button._id, appContext),
                            marginBottom: elementType == 'Rails' ? 0 : getCorrectWidth(deviceContext, deviceContext.isPhone || deviceContext.isPwaVertical ? 5 : 4),
                            marginRight: getCorrectWidth(deviceContext, 15),
                            width: width - getCorrectWidth(deviceContext, 20),
                            height: height - getCorrectWidth(deviceContext, 20),
                            backgroundColor: data.color != undefined ? data.color : getPressableStyling(styling.components?.button._id, appContext).backgroundColor,
                        }}
                        focusContext={focusContext}
                        focusRepeatContext={focusRepeatContext}
                        onFocus={() => (rest.onFocus ? rest.onFocus() : onFocus(data))}
                        onBlur={() => (rest.onBlur ? rest.onBlur() : onBlur())}
                        animatorOptions={getFocusStyling(elementType, appContext)}
                        onPress={() => onPress(data?._id)}
                        onLongPress={() => onLongPress(data?._id)}
                        focusOptions={focusOptions}
                        isFirst={rest.isFirst}
                        isLast={rest.isLast}
                    >
                        <View>
                            <View
                                style={{
                                    margin: getCorrectHeight(deviceContext, appContext.application.theme.images.margin),
                                    borderRadius: getCorrectWidth(deviceContext, appContext.application.theme.images.radius) / 2,
                                    backgroundColor: appContext.application.theme.images?.background,
                                    alignItems: 'center',
                                    alignContent: 'center',
                                    justifyContent: 'center',
                                    height: height - getCorrectWidth(deviceContext, 20) - getCorrectHeight(deviceContext, appContext.application.theme.images.margin * 2),
                                }}
                            >
                                <View>
                                    {(type == 'Channels' || type == 'Cams' || type == 'Radio' || type == 'Albums' || type == 'Podcasts') && (
                                        <Image
                                            deviceContext={deviceContext}
                                            source={{ uri: utils.getCorrectStoragePrefix(appContext, true, data?.images?.square) }}
                                            resizeMethod={'scale'}
                                            resizeMode={'cover'}
                                            style={{
                                                borderRadius: getCorrectWidth(deviceContext, appContext.application.theme.images.radius) / 3,
                                                height: height_,
                                                width: width_,
                                            }}
                                        />
                                    )}
                                    {(type == 'Series' || type == 'Courses') && (
                                        <Image
                                            isCache={true}
                                            deviceContext={deviceContext}
                                            source={{ uri: utils.getCorrectStoragePrefix(appContext, true, data?.images?.square) }}
                                            resizeMethod={'scale'}
                                            resizeMode={'cover'}
                                            style={{
                                                borderRadius: getCorrectWidth(deviceContext, appContext.application.theme.images.radius) / 3,
                                                height: width - getCorrectHeight(deviceContext, appContext.application.theme.images.margin * 2) * 1.7,
                                                width: width - getCorrectHeight(deviceContext, appContext.application.theme.images.margin * 2),
                                            }}
                                        />
                                    )}
                                    {type == 'Movies' && (
                                        <Image
                                            deviceContext={deviceContext}
                                            source={{ uri: utils.getCorrectStoragePrefix(appContext, true, data?.images?.square) }}
                                            resizeMethod={'scale'}
                                            resizeMode={'cover'}
                                            style={{
                                                borderRadius: getCorrectWidth(deviceContext, appContext.application.theme.images.radius) / 3,
                                                height: width - getCorrectHeight(deviceContext, appContext.application.theme.images.margin * 2) * 1.5,
                                                width: width - getCorrectHeight(deviceContext, appContext.application.theme.images.margin * 2),
                                            }}
                                        />
                                    )}
                                </View>
                            </View>
                            <View
                                style={{
                                    position: 'absolute',
                                    justifyContent: 'flex-end',

                                    zIndex: 1,
                                    width: width,
                                    left: 0,
                                    bottom: 0,
                                    alignContent: 'center',
                                }}
                            >
                                {(type == 'Channels' || type == 'Cams' || type == 'Radio' || type == 'Albums' || type == 'Podcasts') && (
                                    <Image_
                                        source={require('../../../../assets/images/squa.png')}
                                        resizeMethod={'scale'}
                                        resizeMode={'cover'}
                                        style={{
                                            borderRadius: getPressableStyling(styling.components?.button._id, appContext).borderRadius,
                                            height: '100%',
                                            width: width * 0.8,
                                            opacity: 0.8,
                                        }}
                                    />
                                )}
                                {(type == 'Series' || type == 'Courses') && (
                                    <Image_
                                        source={require('../../../../assets/images/wide.png')}
                                        resizeMethod={'scale'}
                                        resizeMode={'cover'}
                                        style={{
                                            borderRadius: getPressableStyling(styling.components?.button._id, appContext).borderRadius,
                                            height: '100%',
                                            width: width * 1.7,
                                            opacity: 0.8,
                                        }}
                                    />
                                )}
                                {type == 'Movies' && (
                                    <Image_
                                        source={require('../../../../assets/images/rect.png')}
                                        resizeMethod={'scale'}
                                        resizeMode={'cover'}
                                        style={{
                                            borderRadius: getPressableStyling(styling.components?.button._id, appContext).borderRadius,
                                            height: '100%',
                                            width: width * 1.5,
                                            opacity: 0.8,
                                        }}
                                    />
                                )}
                            </View>
                        </View>
                    </Pressable>
                </View>
                <View
                    style={{
                        position: 'absolute',
                        zIndex: 100,
                        justifyContent: 'center',
                        alignItems: 'flex-end',
                        width: getCorrectWidth(deviceContext, 90),
                    }}
                >
                    {index < 9 && (
                        <Image_
                            source={NUMERIC_IMAGES[index + 1]}
                            resizeMethod={'scale'}
                            resizeMode={'contain'}
                            style={{
                                height: getCorrectWidth(deviceContext, 74) * 0.9,
                                width: getCorrectWidth(deviceContext, 52) * 0.9,
                            }}
                        />
                    )}
                    {index == 9 && (
                        <Image_
                            source={NUMERIC_IMAGES[index + 1]}
                            resizeMethod={'scale'}
                            resizeMode={'contain'}
                            style={{
                                height: getCorrectWidth(deviceContext, 76) * 0.9,
                                width: getCorrectWidth(deviceContext, 108) * 0.9,
                            }}
                        />
                    )}
                </View>
            </View>
        );
    }
};
export default Top10;
