import * as React from 'react';
import { View, CreateListRenderItemInfo, FlashList, FocusContext } from '@scriptx-com/xtv-toolkit';
import { Square_Rails as styling } from '../../../models/elements/rails/square/square';
import { getFontSize } from '../../../styling/fontSizes';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { getRealWidth } from '../../../styling/realDeviceSizes';
import { ContentContext } from '../../../context/contentContext';
import {
    getCamsFromSubscriptionAndRating,
    getChannelsFromSubscriptionAndRating,
    getRadiosFromSubscriptionAndRating,
    getCoursesFromSubscriptionAndRating,
    getSeriesFromSubscriptionAndRating,
    getSortingByRecommendation,
    getDetailsScreen,
    EMPTY_DATA_ARRAY,
    EMPTY_DATA_ARRAY_SQUARE,
    checkIfUserGeneratedListAndEmpty,
    getRailsWidth,
    getRailsHeight,
    extraTopBottomMargin,
} from './helpers/helper';
import { DeviceContext } from '../../../context/deviceContext';
import { UserContext } from '../../../context/userContext';
import { AppContext } from '../../../context/appContext';
import Top10 from '../shared/top10';
import { getFocusStyling, getModalStyling } from '../../components/helpers/helper';
import Modal from '../../components/modal/modal';
import { manageWatching, manageFavorite, favoriteStatus, watchingStatus } from '../../../data/account';
import lang from '../../../../application/languages/languages';
import { isPlatformAndroid, isPlatformIos, isPlatformTvos } from '@rnv/renative';
import { getCorrectLeftMargin } from '../../../styling/appLeftMargin';
import { getCurrentScreenName } from '../../../hooks/getScreenName';
import { SettingsContext } from '../../../context/settingsContext';
import { GetScrollElement, GetTopPart, PressAndHold } from './helpers/views';
import useBaseRail from './useBaseRail';
import { filterAgeRatings } from '../grids/helpers/functions';
import utils from '../../../general/utils';


export interface Data {
    items: [
        {
            square_image: string;
            name: string;
            _id: string;
        }
    ];
}

export interface Props {
    styling: styling;
    focusContext?: FocusContext;
    navigation: any;
    heroIsPlayer: boolean;
    railIndex?: number;
}

export const Top10_Rails = React.memo(({ styling, focusContext, navigation, heroIsPlayer, railIndex }: Props) => {
    const screenName = getCurrentScreenName();

    const contentContext = React.useContext(ContentContext);
    const appContext = React.useContext(AppContext);
    const deviceContext = React.useContext(DeviceContext);
    const userContext = React.useContext(UserContext);
    const settingsContext = React.useContext(SettingsContext);
    const [data, setData] = React.useState(screenName === 'Home' && !checkIfUserGeneratedListAndEmpty(styling, userContext, contentContext, appContext) ? EMPTY_DATA_ARRAY_SQUARE : []);

    let height = isPlatformAndroid || isPlatformIos ? React.useRef(getCorrectHeight(deviceContext, styling.placement.height)).current : getCorrectHeight(deviceContext, styling.placement.height);
    if (height == 0 || isNaN(height)) {
        height = getCorrectHeight(deviceContext, 100);
    }
    if (deviceContext.formFactor == 'Phone') {
        height = height * 0.8;
    }
    var width = height;

    //mobile width check
    if (width > getRealWidth(deviceContext) && deviceContext.formFactor == 'Phone') {
        height = getRealWidth(deviceContext) * 0.7;
        width = height;
    }

    const getData = async () => {
        if (styling.content.type == 'Content List') {
            var path = '';
            if (styling.content.list?.type == 'Channels') {
                path = utils.getCorrectStoragePrefix(appContext, true) + 'channels/lists/' + styling.content.list.list + '_list.json';
            }
            if (styling.content.list?.type == 'Cams') {
                path = utils.getCorrectStoragePrefix(appContext, true) + 'cams/lists/' + styling.content.list.list + '_list.json';
            }
            if (styling.content.list?.type == 'Radio') {
                path = utils.getCorrectStoragePrefix(appContext, true) + 'radios/lists/' + styling.content.list.list + '_list.json';
            }
            if (styling.content.list?.type == 'Music') {
                path = utils.getCorrectStoragePrefix(appContext, true) + 'albums/lists/' + styling.content.list.list + '_list.json';
            }
            if (styling.content.list?.type == 'Podcasts') {
                path = utils.getCorrectStoragePrefix(appContext, true) + 'podcasts/lists/' + styling.content.list.list + '_list.json';
            }
            if (styling.content.list?.type == 'Courses') {
                path = utils.getCorrectStoragePrefix(appContext, true) + 'courses/lists/' + styling.content.list.list + '_list.json';
            }
            if (styling.content.list?.type == 'Series') {
                path = utils.getCorrectStoragePrefix(appContext, true) + 'series/lists/' + styling.content.list.list + '_list.json';
            }
            try {
                let response = await fetch(path);
                let data = await response.json();
                if (data != undefined) {
                    if (styling.content.list?.type == 'Channels') {
                        var sortedByRecommended = getSortingByRecommendation(data[0].items, userContext.profile.recommendations);
                        var dataOut = getChannelsFromSubscriptionAndRating(contentContext, sortedByRecommended);
                        if (appContext.application.settings.agerating?.ratings != undefined) {
                            dataOut = filterAgeRatings(dataOut, userContext, appContext);
                        }
                        setData(sortedByRecommended);
                        if (sortedByRecommended?.length) return;
                    }
                    if (styling.content.list?.type == 'Cams') {
                        var sortedByRecommended = getSortingByRecommendation(data[0].items, userContext.profile.recommendations);
                        var dataOut = getCamsFromSubscriptionAndRating(contentContext, sortedByRecommended);
                        if (appContext.application.settings.agerating?.ratings != undefined) {
                            dataOut = filterAgeRatings(dataOut, userContext, appContext);
                        }
                        setData(dataOut);
                        if (dataOut?.length) return;
                    }
                    if (styling.content.list?.type == 'Radio') {
                        var sortedByRecommended = getSortingByRecommendation(data[0].items, userContext.profile.recommendations);
                        var dataOut = getRadiosFromSubscriptionAndRating(contentContext, sortedByRecommended);
                        if (appContext.application.settings.agerating?.ratings != undefined) {
                            dataOut = filterAgeRatings(dataOut, userContext, appContext);
                        }
                        setData(dataOut);
                        if (dataOut?.length) return;
                    }
                    if (styling.content.list?.type == 'Series') {
                        var sortedByRecommended = getSortingByRecommendation(data[0].items, userContext.profile.recommendations);
                        var dataOut = getSeriesFromSubscriptionAndRating(contentContext, sortedByRecommended);
                        if (appContext.application.settings.agerating?.ratings != undefined) {
                            dataOut = filterAgeRatings(dataOut, userContext, appContext);
                        }
                        setData(dataOut);
                        if (dataOut?.length) return;
                    }
                    if (styling.content.list?.type == 'Courses') {
                        var sortedByRecommended = getSortingByRecommendation(data[0].items, userContext.profile.recommendations);
                        var dataOut = getCoursesFromSubscriptionAndRating(contentContext, sortedByRecommended);
                        if (appContext.application.settings.agerating?.ratings != undefined) {
                            dataOut = filterAgeRatings(dataOut, userContext, appContext);
                        }
                        setData(dataOut);
                        if (dataOut?.length) return;
                    }
                    if (styling.content.list?.type == 'Music') {
                        var sortedByRecommended = getSortingByRecommendation(data[0].items, userContext.profile.recommendations);
                        var dataOut = getCoursesFromSubscriptionAndRating(contentContext, sortedByRecommended);
                        if (appContext.application.settings.agerating?.ratings != undefined) {
                            dataOut = filterAgeRatings(dataOut, userContext, appContext);
                        }
                        setData(dataOut);
                        if (dataOut?.length) return;
                    }
                    if (styling.content.list?.type == 'Podcasts') {
                        var sortedByRecommended = getSortingByRecommendation(data[0].items, userContext.profile.recommendations);
                        var dataOut = getCoursesFromSubscriptionAndRating(contentContext, sortedByRecommended);
                        if (appContext.application.settings.agerating?.ratings != undefined) {
                            dataOut = filterAgeRatings(dataOut, userContext, appContext);
                        }
                        setData(dataOut);
                        if (dataOut?.length) return;
                    }
                }
            } catch (error) {
                //
            }
        }
        if (styling.content.type == 'AppsAndroidTV') {
            const apps = await global.InstalledApps.getApps({ includeVersion: true, includeAccentColor: true });
            const dataOut = [] as any;
            for (let app of apps) {
                dataOut.push({
                    name: app.label,
                    images: {
                        square: app.icon,
                    },
                    color: app.accentColor,
                    package: app.packageName,
                });
            }
            setData(dataOut);
            if (dataOut?.length) return;
        }
        // setData(screenName === 'Home' ? EMPTY_DATA_ARRAY : []);
    };

    const { Loader, flashListProps, onViewableItemsChanged, scrollRight, scrollLeft, railsRef, viewableItemsCount } = useBaseRail({
        contentLoaderHeight: height,
        contentLoaderWidth: width,
        data,
        railIndex,
        railType: 'top10',
        getData,
        emptyItemRenderer: (props: any) => {
            return renderItem({
                item: EMPTY_DATA_ARRAY[0],
                index: props.index,
                target: 'Cell',
                emptyRailProps: props,
            });
        },
        animator: getFocusStyling('Rails', appContext),
        isUserGeneratedListAndEmpty: false,
    });

    const detailsItem = (item: any, index: any) => {
        getDetailsScreen(styling, userContext, contentContext, appContext, navigation, item, settingsContext);
    };

    const renderItem = ({ item, focusRepeatContext, index, emptyRailProps }: CreateListRenderItemInfo<any> & { emptyRailProps?: any }) => {
        return (
            <Top10
                elementType={'Rails'}
                heroIsPlayer={heroIsPlayer}
                rails={true}
                index={index}
                type={styling.content.list?.type}
                styling={styling}
                data={item}
                height={height}
                width={width}
                focusRepeatContext={focusRepeatContext}
                focusOptions={{
                    focusKey: `top10-${railIndex}-${index}`,
                }}
                onPress={() => {
                    if(item.package != undefined){
                        global.RNLauncherKitHelper.launchApplication(item.package);
                    } else if (item.type !== 'empty') {
                        detailsItem(item, index);
                    }
                }}
                onLongPress={() => {
                    if (item.type !== 'empty') {
                        showLongpressModal(item, index);
                    }
                }}
                isFirst={index === 0}
                isLast={data.length - 1 === index}
                {...emptyRailProps}
            ></Top10>
        );
    };

    const [showLongpress, setShowLongpress] = React.useState(false);
    const [favorite, setFavorite] = React.useState(false);
    const [watchlist, setWatchlist] = React.useState(false);
    const [longItem, setLongItem] = React.useState(false);

    const showLongpressModal = (item, index) => {
        if (styling.content.press_and_hold && (appContext.application.settings.allow_watchlist || appContext.application.settings.allow_favorites)) {
            setFavorite(favoriteStatus(styling.content.list?.type, item._id, userContext));
            setWatchlist(watchingStatus(styling.content.list?.type, item._id, userContext) == undefined ? false : true);
            setLongItem(item);
            setShowLongpress(true);
        }
    };
    const manageWatchlist_ = () => {
        manageWatching(userContext, styling.content.list?.type, longItem, appContext.application, watchlist, 0, 0, [], [], [], [], contentContext);
        setShowLongpress(false);
        setWatchlist(!watchlist);
    };
    const manageFavorite_ = () => {
        manageFavorite(contentContext, styling.content.list?.type, longItem, appContext.application, favorite, userContext, deviceContext, appContext);
        setShowLongpress(false);
        setFavorite(!favorite);
    };

    React.useEffect(() => {
        if (showLongpress) {
            appContext.setModal(
                <Modal
                    watchlist={watchlist}
                    favorite={favorite}
                    focusContext={focusContext}
                    styling={getModalStyling(appContext.application.theme.modals.parental, appContext)}
                    type={'quickmenu'}
                    submitChoice2={manageWatchlist_}
                    submitChoice={manageFavorite_}
                    setShowModal={() => setShowLongpress(false)}
                    data={longItem}
                    contentType={styling.content.list?.type}
                />
            );
        } else {
            appContext.setModal(null);
        }
    }, [showLongpress, watchlist, favorite, longItem]);

    const openOverviewPage = () => {
        navigation &&
            navigation.navigate(styling.content.list?.type, {
                railsItems: data,
            });
    };

    if (styling.content.type === 'Search' && data.length === 0) {
        return null;
    }

    return (
        <View focusContext={focusContext}>
            <View
                style={{
                    flex: 1,
                    marginLeft: getCorrectLeftMargin(deviceContext, appContext, screenName),
                    marginRight: styling.placement.background_color != '#00000000' && styling.placement.background_color != 'transparent' && deviceContext.formFactor != 'Phone' ? getCorrectWidth(deviceContext, 10) : 0,
                    marginTop: appContext.application.theme.rails_top_margin / (deviceContext.formFactor == 'Phone' ? 2 : 1) + extraTopBottomMargin(deviceContext, styling),
                    marginBottom: appContext.application.theme.rails_bottom_margin / (deviceContext.formFactor == 'Phone' ? 2 : 1) + extraTopBottomMargin(deviceContext, styling),
                }}
            >
                <View style={{ flex: 1 }}>
                    <View
                        style={{
                            backgroundColor: styling.placement.background_color,
                            height: styling.content.type === 'Search' && data.length === 0 ? 0 : getRailsHeight(deviceContext, appContext, styling, height),
                            borderRadius: getCorrectWidth(deviceContext, styling.placement.border_radius),
                            paddingTop: getCorrectHeight(deviceContext, styling.placement.padding),
                            paddingBottom: getCorrectHeight(deviceContext, styling.placement.padding),
                            paddingHorizontal:
                                styling.placement.background_color != '#00000000' && styling.placement.background_color != 'transparent'
                                    ? deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs
                                        ? getCorrectWidth(deviceContext, 5)
                                        : getCorrectWidth(deviceContext, styling.placement.padding)
                                    : 0,
                        }}
                    >
                        <GetTopPart
                            screenName={screenName}
                            focusContext={focusContext}
                            openOverviewPage={openOverviewPage}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            userContext={userContext}
                            styling={styling}
                            data={data.length >= 1 ? data : screenName === 'Home' ? [{ id: '', title: 'ok' }] : []}
                        ></GetTopPart>
                        <View style={{ flex: 1, justifyContent: 'center', width: getRailsWidth(deviceContext, appContext, styling, screenName) }} focusContext={focusContext}>
                            <View>{Loader()}</View>
                            {data.length > 0 && (
                                <FlashList
                                    type="row"
                                    ref={railsRef}
                                    data={data}
                                    onViewableItemsChanged={onViewableItemsChanged}
                                    estimatedItemSize={height}
                                    horizontal={true}
                                    showsHorizontalScrollIndicator={false}
                                    renderItem={renderItem}
                                    initialScrollIndex={0}
                                    lazy
                                    focusOptions={{
                                        autoLayoutScaleAnimation: getFocusStyling('Rails', appContext).type.indexOf('scale') > -1 && deviceContext.formFactor == 'Television' ? true : false,
                                        autoLayoutSize: getFocusStyling('Rails', appContext).type.indexOf('scale') > -1 && deviceContext.formFactor == 'Television' ? 10 : 0,
                                        ...flashListProps.focusOptions,
                                    }}
                                    {...flashListProps}
                                    {...(isPlatformTvos && {
                                        contentInset: { left: 15 },
                                    })}
                                />
                            )}
                            {deviceContext.isWebTV && !deviceContext.isPwa && data.length > 0 && data.length > viewableItemsCount && (
                                <GetScrollElement height={height} scrollLeft={scrollLeft} scrollRight={scrollRight} deviceContext={deviceContext} appContext={appContext}></GetScrollElement>
                            )}
                        </View>
                        {deviceContext.formFactor != 'Phone' && styling.content.press_and_hold && styling.content.press_and_hold_message && data != null && data.length > 0 && (
                            <PressAndHold deviceContext={deviceContext} appContext={appContext} userContext={userContext} lang={lang}></PressAndHold>
                        )}
                    </View>
                </View>
            </View>
        </View>
    );
});
export default Top10_Rails;
