import * as React from 'react';
import { View } from '@scriptx-com/xtv-toolkit';
import { LogoWide as styling } from '../../../models/elements/misc/logowide/logowide';
import { getRealWidth } from '../../../styling/realDeviceSizes';
import { getCorrectWidth } from '../../../styling/correctSizes';
import { DeviceContext } from '../../../context/deviceContext';
import { AppContext } from '../../../context/appContext';
import { RemoteImage } from '../../components/remoteimage/remoteimage';
import { useSafeAreaInsets } from '../../../../libs/react-native-safe-area-context';

export interface Props {
    styling: styling;
    focusContext?: any;
}

export const LogoWide = ({ styling, focusContext }: Props) => {
    const appContext = React.useContext(AppContext);
    const deviceContext = React.useContext(DeviceContext);
    var screenWidth =
        getRealWidth(deviceContext) - getCorrectWidth(deviceContext, +(appContext.application.theme.non_home_left_margin ?? 0) + (appContext.application.theme.non_home_right_margin ?? 0)) - getCorrectWidth(deviceContext, global.menuWidth) - 30;
    const insets = useSafeAreaInsets();

    return (
        <View focusContext={focusContext} style={{ paddingLeft: getCorrectWidth(deviceContext, global.menuWidth), marginTop: insets.top }}>
            <View
                style={{
                    marginLeft: 10,
                    marginRight: 3,
                    marginTop: appContext.application.theme.rails_top_margin,
                    marginBottom: appContext.application.theme.rails_bottom_margin,
                }}
            >
                <View
                    style={{
                        display: 'flex',
                        flex: 1,
                        padding: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 5 : 3),
                        flexDirection: 'row',
                        justifyContent: 'center',
                        backgroundColor: 'transparent',
                    }}
                >
                    <RemoteImage uri={deviceContext.isPhone ? styling.portrait : styling.landscape} desiredWidth={screenWidth} />
                </View>
            </View>
        </View>
    );
};
export default LogoWide;
