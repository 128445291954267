import { Dimensions, PixelRatio } from 'react-native';
import uuid from 'react-native-uuid';
import { retrieveJson as retrieveJsonAS, storeJson as storeJsonAS } from '../../libs/async-storage';

class utils {
    uuidv4() {
        return uuid.v4();
        //  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16));
    }

    RatioOld(pixels) {
        if (!(deviceContext.deviceType == '_AndroidTV' || deviceContext.deviceType == '_FireTV')) return pixels;
        const resolution = Dimensions.get('window').height * PixelRatio.get();
        return Math.round(pixels / (resolution < 2160 ? 1 : 1));
    }

    lightOrDark(color) {
        // Check the format of the color, HEX or RGB?
        if (color.match(/^rgb/)) {
            // If HEX --> store the red, forestgreen, blue values in separate variables
            color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

            r = color[1];
            g = color[2];
            b = color[3];
        } else {
            // If RGB --> Convert it to HEX: http://gist.github.com/983661
            color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));

            r = color >> 16;
            g = (color >> 8) & 255;
            b = color & 255;
        }

        // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
        hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

        // Using the HSP value, determine whether the color is light or dark
        if (hsp > 127.5) {
            return 'light';
        } else {
            return 'dark';
        }
    }

    toAlphaNumeric(input) {
        if (input == '' || input == undefined || input == null) {
            return '';
        }
        input = input.toString().replace(/\s/g, '');
        return input.toString().replace(/[^A-Za-z0-9]/g, '');
    }

    async storeJson(key, value) {
        return storeJsonAS(key, value);
    }
    async retrieveJson(key) {
        return retrieveJsonAS(key);
    }

    getCorrectStoragePrefix = (appContext, fromDeployment, imageUrl) => {
        if (imageUrl?.indexOf('file://') > -1) {
            return imageUrl;
        }
        if (imageUrl?.indexOf('https://tvprofile.') > -1) {
            return imageUrl;
        }
        if (appContext.application.storage != undefined && appContext.application.settings.objectstorage != undefined && appContext.application.settings.objectstorage == true) {
            if (imageUrl?.indexOf('https://') > -1) {
                return imageUrl;
            }
            if (imageUrl != undefined) {
                imageUrl = imageUrl.replace(appContext.application.client + '/', '').replace(appContext.application.deployment + '/', '');
                return 'https://appcloud.akamaized.net/' + appContext.application.storage?.deployment?.region + '/' + appContext.application.client.toLowerCase() + '-' + appContext.application.deployment.toLowerCase() + '/' + imageUrl;
            }
            if (appContext.application.storage?.deployment?.region != undefined && fromDeployment) {
                return 'https://appcloud.akamaized.net/' + appContext.application.storage?.deployment?.region + '/' + appContext.application.client.toLowerCase() + '-' + appContext.application.deployment.toLowerCase() + '/';
            }
            if (appContext.application.storage?.service?.region != undefined && !fromDeployment) {
                return (
                    'https://appcloud.akamaized.net/' +
                    appContext.application.storage?.service?.region +
                    '/' +
                    appContext.application.client.toLowerCase() +
                    '-' +
                    appContext.application.deployment.toLowerCase() +
                    '-' +
                    appContext.application.branding.service.toLowerCase() +
                    '/'
                );
            }
        } else {
            if (fromDeployment) {
                if (imageUrl != undefined) {
                    if (imageUrl.indexOf('https://') > -1) {
                        return imageUrl;
                    } else {
                        return 'https://cloudtv.akamaized.net/' + imageUrl;
                    }
                } else {
                    return 'https://cloudtv.akamaized.net/' + appContext.application.client + '/' + appContext.application.deployment + '/';
                }
            }
            if (!fromDeployment) {
                if (imageUrl != undefined) {
                    if (imageUrl.indexOf('https://') > -1) {
                        return imageUrl;
                    } else {
                        return 'https://cloudtv.akamaized.net/' + imageUrl;
                    }
                } else {
                    return 'https://cloudtv.akamaized.net/' + appContext.application.client + '/' + appContext.application.deployment + '/' + appContext.application.branding.service + '/';
                }
            }
        }
    };
    getOldStoragePrefix = (appContext, fromDeployment) => {
        if (fromDeployment) {
            return 'https://cloudtv.akamaized.net/' + appContext.application.client + '/' + appContext.application.deployment + '/';
        }
        if (!fromDeployment) {
            return 'https://cloudtv.akamaized.net/' + appContext.application.client + '/' + appContext.application.deployment + '/' + appContext.application.branding.service + '/';
        }
    };
}
const utils_ = new utils();
export default utils_;
