import { useFocusEffect } from '@react-navigation/native';
import { View, Image, Screen } from '@scriptx-com/xtv-toolkit';
import moment from 'moment';
import React from 'react';
import { BackHandler } from 'react-native';
import { sendPageReport } from '../../../reporting/apis/page';
import { pageType } from '../../../reporting/models/apps';
import { AppContext } from '../../context/appContext';
import { DeviceContext } from '../../context/deviceContext';
import { UserContext } from '../../context/userContext';
import { getRealHeight, getRealWidth } from '../../styling/realDeviceSizes';
import { getFontSize } from '../../styling/fontSizes';
import Text from '../components/text';
import { getPressableStyling } from '../components/helpers/helper';
import { getCorrectHeight, getCorrectWidth } from '../../styling/correctSizes';
import Rectangle_Grid from '../elements/grids/rectangle';
import Square_Grid from '../elements/grids/square';
import Widescreen_Grid from '../elements/grids/widescreen';
import Player_Grid from '../elements/grids/player';
import Long_Grid from '../elements/grids/long';
import Tile_Grid from '../elements/grids/tile';
import { useSafeAreaInsets } from '../../../libs/react-native-safe-area-context';
import { isPlatformAndroid } from '@rnv/renative';

const Screen_ = ({ navigation, route }: any): React.ReactElement => {
    const { data, styling, focusContext } = route.params;
    const userContext = React.useContext(UserContext);
    const deviceContext = React.useContext(DeviceContext);
    const appContext = React.useContext(AppContext);
    const insets = useSafeAreaInsets();

    //For TV to exit with back button
    React.useEffect(() => {
        const TVBackHandler = (event) => {
            const { key, keyCode } = event;
            if (keyCode == 413 || keyCode == 10009 || keyCode == 27 || keyCode == 461) {
                navigation.goBack();
            }
        };
        if (deviceContext.isTizen || deviceContext.isWebos) window.addEventListener('keydown', TVBackHandler);
        return () => {
            if (deviceContext.isTizen || deviceContext.isWebos) {
                {
                    window.removeEventListener('keydown', TVBackHandler);
                }
            }
        };
    }, []);
    useFocusEffect(
        React.useCallback(() => {
            const start = moment().unix();
            return () => {
                let duration = moment().unix() - start;
                sendPageReport(appContext, deviceContext, userContext, pageType.GAMES_WEBVIEW, duration);
            };
        }, [])
    );
    const disableNativeHardwareBackPress = () => {
        navigation.goBack();
        return true;
    };
    React.useEffect(() => {
        BackHandler.addEventListener('hardwareBackPress', disableNativeHardwareBackPress);
        return () => BackHandler.removeEventListener('hardwareBackPress', disableNativeHardwareBackPress);
    }, []);

    const getTitle = (titles, styling, userContext) => {
        if (titles == undefined || titles.length == 0) {
            return '';
        }
        var test = styling.content.list?.title.find((t) => t.language == userContext.selectedLanguage);
        if (test != undefined) {
            return test.title;
        } else {
            if (styling.content.list?.title != undefined) {
                return styling.content.list?.title[0].title;
            } else {
                return '';
            }
        }
    };

    const getTagline = (taglines, styling, userContext) => {
        if (taglines == undefined || taglines.length == 0) {
            return '';
        }
        var test = styling.content.list?.tagline.find((t) => t.language == userContext.selectedLanguage);
        if (test != undefined) {
            return test.title;
        } else {
            if (styling.content.list?.tagline != undefined) {
                return styling.content.list?.tagline[0].title;
            } else {
                return '';
            }
        }
    };
    const getCorrectGrid = () => {
        //android have paddingBottom of 150 in useGridHeightCalculator.tsx that should not be applied here
        const subtractionAmount = 120 + getCorrectWidth(deviceContext, 15) * 2 + insets.top - (isPlatformAndroid ? 150 : 0);

        if (styling.type == 'Rectangle Rails') {
            return (
                <Rectangle_Grid
                    dataRails={data}
                    navigation={navigation}
                    styling={{ ...styling, placement: { ...styling.placement, background_color: 'transparent', margin_left: 10, margin_right: 10 }, layout: { columns_mobile: 3, columns_tablet: 7, columns_browser: 7, columns_television: 7 } }}
                    focusContext={focusContext}
                    customGridHeight={getRealHeight(deviceContext) - getCorrectHeight(deviceContext, global.menuHeight) - subtractionAmount}
                    screenNameOverride={styling.content.list.type}
                ></Rectangle_Grid>
            );
        }
        if (styling.type == 'Square Rails') {
            return (
                <Square_Grid
                    navigation={navigation}
                    styling={{ ...styling, placement: { ...styling.placement, background_color: 'transparent', margin_left: 10, margin_right: 10 }, layout: { columns_mobile: 4, columns_tablet: 8, columns_browser: 10, columns_television: 10 } }}
                    focusContext={focusContext}
                    customGridHeight={getRealHeight(deviceContext) - getCorrectHeight(deviceContext, global.menuHeight) - subtractionAmount}
                    screenNameOverride={styling.content.list.type}
                ></Square_Grid>
            );
        }
        if (styling.type == 'Wide Rails') {
            return (
                <Widescreen_Grid
                    navigation={navigation}
                    styling={{ ...styling, placement: { ...styling.placement, background_color: 'transparent', margin_left: 10, margin_right: 10 }, layout: { columns_mobile: 1, columns_tablet: 3, columns_browser: 3, columns_television: 3 } }}
                    focusContext={focusContext}
                    customGridHeight={getRealHeight(deviceContext) - getCorrectHeight(deviceContext, global.menuHeight) - subtractionAmount}
                    screenNameOverride={styling.content.list.type}
                ></Widescreen_Grid>
            );
        }
        if (styling.type == 'Player Rails') {
            return (
                <Player_Grid
                    navigation={navigation}
                    styling={{ ...styling, placement: { ...styling.placement, background_color: 'transparent', margin_left: 10, margin_right: 10 }, layout: { columns_mobile: 1, columns_tablet: 3, columns_browser: 3, columns_television: 3 } }}
                    focusContext={focusContext}
                    customGridHeight={getRealHeight(deviceContext) - getCorrectHeight(deviceContext, global.menuHeight) - subtractionAmount}
                    screenNameOverride={styling.content.list.type}
                ></Player_Grid>
            );
        }
        if (styling.type == 'Long Rails') {
            return (
                <Long_Grid
                    navigation={navigation}
                    styling={{ ...styling, placement: { ...styling.placement, background_color: 'transparent', margin_left: 10, margin_right: 10 }, layout: { columns_mobile: 1, columns_tablet: 3, columns_browser: 3, columns_television: 3 } }}
                    focusContext={focusContext}
                    customGridHeight={getRealHeight(deviceContext) - getCorrectHeight(deviceContext, global.menuHeight) - subtractionAmount}
                    screenNameOverride={styling.content.list.type}
                ></Long_Grid>
            );
        }
        if (styling.type == 'Tile Rails') {
            return (
                <Tile_Grid
                    navigation={navigation}
                    styling={{ ...styling, placement: { ...styling.placement, background_color: 'transparent', margin_left: 10, margin_right: 10 }, layout: { columns_mobile: 3, columns_tablet: 5, columns_browser: 6, columns_television: 6 } }}
                    focusContext={focusContext}
                    customGridHeight={getRealHeight(deviceContext) - getCorrectHeight(deviceContext, global.menuHeight) - subtractionAmount}
                ></Tile_Grid>
            );
        }
    };

    return (
        //@ts-ignore
        <Screen style={{ flex: 1 }} focusOptions={{ focusKey: 'content-screen' }}>
            <View
                style={{
                    position: 'absolute',
                    width: getRealWidth(deviceContext),
                    height: '100%',
                    zIndex: 1,
                }}
            >
                <Image source={{ uri: styling.content.list.background_landscape }} resizeMode={'cover'} style={{ height: getRealHeight(deviceContext), width: getRealWidth(deviceContext) }}></Image>
            </View>
            <View
                style={{
                    position: 'absolute',
                    width: getRealWidth(deviceContext),
                    height: '100%',
                    zIndex: 2,
                }}
            >
                <Image source={require('../../../assets/images/gray.png')} resizeMode={'cover'} style={{ height: getRealHeight(deviceContext), width: getRealWidth(deviceContext) }}></Image>
            </View>
            <View style={{ flex: 1, zIndex: 3 }}>
                <View
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        margin: getCorrectWidth(deviceContext, 15),
                        marginLeft: getCorrectWidth(deviceContext, global.menuWidth + 15),
                        backgroundColor: 'rgba(0, 0, 0, 0.3)',
                        borderRadius: getPressableStyling(styling.components?.button._id, appContext).borderRadius,
                    }}
                >
                    <Image source={{ uri: styling.content.list.image }} resizeMode={'cover'} style={{ height: 100, width: 100, borderRadius: getPressableStyling(styling.components?.button._id, appContext).borderRadius }}></Image>
                    <View style={{ flexDirection: 'column' }}>
                        <Text
                            numberOfLines={1}
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontWeight: styling.placement.title_bold ? 'bold' : null,
                                fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                color: styling.placement.title_color == '' ? '#ffffff' : styling.placement.title_color,
                                marginLeft: 10,
                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                textShadowOffset: { width: -1, height: 1 },
                                textShadowRadius: 2,
                            }}
                        >
                            {getTitle(styling.content.list?.title, styling, userContext)}
                        </Text>
                        <Text
                            numberOfLines={1}
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontWeight: styling.placement.title_bold ? 'bold' : null,
                                fontSize: getFontSize(deviceContext, 'Normal'),
                                color: styling.placement.title_color == '' ? '#ffffff' : styling.placement.title_color,
                                marginLeft: 10,
                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                textShadowOffset: { width: -1, height: 1 },
                                textShadowRadius: 2,
                            }}
                        >
                            {getTagline(styling.content.list?.tagline, styling, userContext)}
                        </Text>
                    </View>
                </View>
                <View style={{ flex: 1 }}>{getCorrectGrid()}</View>
            </View>
        </Screen>
    );
};
export default Screen_;
