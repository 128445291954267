import { FocusContext, View, Pressable } from '@scriptx-com/xtv-toolkit';
import * as React from 'react';
import { AppContext } from '../../../context/appContext';
import { DeviceContext } from '../../../context/deviceContext';
import { useEffectOnce } from '../../../hooks/useEffectOnce';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { getAdsStyling, getFocusStyling } from '../../components/helpers/helper';
import { UserContext } from '../../../context/userContext';
import { getCurrentScreenName } from '../../../hooks/getScreenName';
import { RemoteImage } from '../../components/remoteimage/remoteimage';
import { getRealWidth } from '../../../styling/realDeviceSizes';
import Text from '../../components/text';
import { getFontSize } from '../../../styling/fontSizes';
import lang from '../../../../application/languages/languages';
import { Linking } from 'react-native';
import { getCorrectLeftMargin } from '../../../styling/appLeftMargin';

export interface Data {
    image: string;
}

export interface Props {
    styling: any;
    focusContext?: FocusContext;
    fromLoader?: boolean;
}

export const Promotions_CastoolaAds = ({ styling, focusContext, fromLoader }: Props) => {
    const [data, setData] = React.useState([] as any);
    const [show, setShow] = React.useState(false);
    const appContext = React.useContext(AppContext);
    const deviceContext = React.useContext(DeviceContext);
    const userContext = React.useContext(UserContext);
    const screenName = getCurrentScreenName();

    const adWidth =
        getRealWidth(deviceContext) / (deviceContext.isPhone ? 1 : 1.5) -
        getCorrectWidth(deviceContext, 20) -
        getCorrectWidth(deviceContext, global.menuWidth) -
        getCorrectWidth(deviceContext, (appContext.application.theme.non_home_left_margin ?? 0) + (appContext.application.theme.non_home_right_margin ?? 0));

    useEffectOnce(() => {
        async function myAsynFunction() {
            try {
                var random = Math.floor(1000 + Math.random() * 9000);
                var deviceType = '5';
                var deviceOs = '0';
                if (deviceContext.deviceType == 'WebTV') {
                    deviceType = '1';
                    deviceOs = '1';
                } else if (deviceContext.deviceType == 'Android_Mobile') {
                    deviceType = '2';
                    deviceOs = '5';
                } else if (deviceContext.deviceType == 'iOS') {
                    deviceType = '2';
                    deviceOs = '6';
                } else if (deviceContext.deviceType == 'KaiOS') {
                    deviceType = '2';
                    deviceOs = 'Other';
                } else if (deviceContext.deviceType == 'Android_Tablet') {
                    deviceType = '3';
                    deviceOs = '5';
                } else if (deviceContext.deviceType == 'iPad') {
                    deviceType = '3';
                    deviceOs = '6';
                } else if (deviceContext.deviceType == 'SetTopBox') {
                    deviceType = '4';
                    deviceOs = '5';
                } else if (deviceContext.deviceType == 'SmartTV_Tizen') {
                    deviceOs = '8';
                } else if (deviceContext.deviceType == 'SmartTV_LG') {
                    deviceOs = '9';
                } else if (deviceContext.deviceType == 'AndroidTV') {
                    deviceOs = '7';
                } else if (deviceContext.deviceType == 'FireTV') {
                    deviceOs = '13';
                } else if (deviceContext.deviceType == 'SmartTV_Vidaa') {
                    deviceOs = '11';
                }

                let path =
                    'https://mwaretv.client-api.castoola.tv/' +
                    styling.publisher_id +
                    '/v2.5/ads/display?placement_name=billboard&subscriber_id=' +
                    encodeURI(userContext.userId) +
                    '&device_type=' +
                    deviceType +
                    '&device_id=' +
                    encodeURI(deviceContext.uniqueId) +
                    '&consent=' +
                    (userContext.profile.consent != undefined ? userContext.profile.consent : true) +
                    '&profile_id=' +
                    encodeURI(userContext.profile.id) +
                    '&operating_system=' +
                    deviceOs +
                    '&context=' +
                    screenName +
                    '&bust=' +
                    random;

                let response = await fetch(path);
                if (response.status == 200) {
                    let data = await response.json();
                    if (data != undefined) {
                        setData(data);
                        setShow(true);
                        try {
                            sentImpressionTracker(data.impression_trackers);
                        } catch (e) {}
                        try {
                            sentConversionTracker(data.conversion_trackers);
                        } catch (e) {}
                    }
                }
            } catch (e) {}
        }
        myAsynFunction();
    });

    const sentConversionTracker = async (trackers) => {
        trackers.forEach(async (tracker) => {
            try {
                let response = await fetch(tracker);
                if (response.status == 200) {
                }
            } catch (e) {}
        });
    };

    const sentImpressionTracker = async (trackers) => {
        trackers.forEach(async (tracker) => {
            try {
                let response = await fetch(tracker);
                if (response.status == 200) {
                }
            } catch (e) {}
        });
    };
    const handleAdClick = async () => {
        if (deviceContext.isWebTV || deviceContext.isPhone) {
            if (data.click_url != '') {
                data.click_trackers.forEach(async (tracker) => {
                    try {
                        let response = await fetch(tracker);
                        if (response.status == 200) {
                        }
                    } catch (e) {}
                });
                await Linking.openURL(data.click_url);
            }
        }
    };
    return (
        <View
            focusContext={focusContext}
            style={
                {
                    // paddingLeft: getCorrectWidth(deviceContext, global.menuWidth)
                }
            }
        >
            {show && (
                <View
                    focusContext={focusContext}
                    style={{
                        marginLeft: getCorrectLeftMargin(deviceContext, appContext, screenName),
                        marginRight: getCorrectLeftMargin(deviceContext, appContext, screenName),
                        marginTop: appContext.application.theme.rails_top_margin / (deviceContext.formFactor == 'Phone' ? 2 : 1),
                        marginBottom: appContext.application.theme.rails_bottom_margin / (deviceContext.formFactor == 'Phone' ? 2 : 1),
                    }}
                >
                    <View style={{ position: 'absolute', zIndex: 1, top: 5, left: deviceContext.isPhone ? 5 : 10, padding: deviceContext.isPhone ? 3 : 10, backgroundColor: '#000000' }}>
                        {userContext.profile.consent && (
                            <Text style={{ color: '#ffffff', fontFamily: deviceContext.fontType, fontSize: getFontSize(deviceContext, deviceContext.isPhone ? 'XX-Small' : 'Small') }}>
                                {lang.getTranslation(userContext, 'sponsered_personalized_ad')}
                            </Text>
                        )}
                        {!userContext.profile.consent && (
                            <Text style={{ color: '#ffffff', fontFamily: deviceContext.fontType, fontSize: getFontSize(deviceContext, deviceContext.isPhone ? 'XX-Small' : 'Small') }}>{lang.getTranslation(userContext, 'sponsered_ad')}</Text>
                        )}
                    </View>
                    <View style={{ ...getAdsStyling(appContext) }}>
                        <Pressable
                            animatorOptions={getFocusStyling('Buttons', appContext)}
                            hasPreferredFocus={false}
                            style={{
                                //height: adWidth / (deviceContext.isPhone ? 3.6 : 3.65),
                                borderRadius: getAdsStyling(appContext).borderRadius,
                                ...getAdsStyling(appContext),
                            }}
                            onPress={() => handleAdClick()}
                        >
                            <View
                                style={{
                                    ...getAdsStyling(appContext),
                                    display: 'flex',
                                    flex: 1,

                                    padding: getCorrectWidth(deviceContext, deviceContext.isKaiOs ? 5 : 5),
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    borderRadius: getAdsStyling(appContext).borderRadius,
                                    backgroundColor: 'transparent',
                                }}
                            >
                                {data?.creatives?.[0] ? <RemoteImage uri={data.creatives[0].creative_url} desiredWidth={adWidth} /> : null}
                            </View>
                        </Pressable>
                    </View>
                </View>
            )}
        </View>
    );
};

export default Promotions_CastoolaAds;
