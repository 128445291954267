import { View, withParentContextMapper, Image } from '@scriptx-com/xtv-toolkit';
import * as React from 'react';
import { isFactorTv } from '@rnv/renative';
import { AppContext } from '../../../context/appContext';
import { ContentContext } from '../../../context/contentContext';
import { DeviceContext } from '../../../context/deviceContext';
import { UserContext } from '../../../context/userContext';
import { Images } from '../../../models/content/shared/images';
import { Hero_Slider as styling } from '../../../models/elements/heros/slider';
import { getRealHeight, getRealWidth } from '../../../styling/realDeviceSizes';
import { getChannelsFromSubscriptionAndRating, getCoursesFromSubscriptionAndRating, getGamesFromSubscriptionAndRating, getMoviesFromSubscriptionAndRating, getSeriesFromSubscriptionAndRating } from '../rails/helpers/helper';
import moment from 'moment';
import { BackgroundImageBoxed, ButtonRow, DescriptionRow, LogoRowBoxed, MetaRow, StatusTimeRow } from './helpers/helpers_landscape';
import { onPressContinue, onPressFavorite, onPressInformation, onPressPlay, onPressPlayCatchupTV, onPressPlaySeason, onPressPurchase, onPressReplay, onPressTrailer, onPressWatchlist } from '../details/helpers/functions';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { getCorrectLeftMargin } from '../../../styling/appLeftMargin';
import { getFocusStyling, getModalStyling, getPressableStyling } from '../../components/helpers/helper';
import { getCurrentScreenName } from '../../../hooks/getScreenName';
import Swiper from '../../components/swiper/swiper';
import { SettingsContext } from '../../../context/settingsContext';
import { checkIfChannelHasPrograms } from './helpers/helper';
import PayPerView from '../../components/payperview/payperview';
import utils from '../../../general/utils';

export interface Data {
    images: Images;
    name: string;
    tags: string;
    translations: any;
    _id: string;
}

export interface Props {
    styling: styling;
    navigation: any;
    focusContext: any;
}

const FocusAwareSwiper = withParentContextMapper(Swiper);

export const Hero_Single = ({ styling, navigation, focusContext }: Props) => {
    const contentContext = React.useContext(ContentContext);
    const appContext = React.useContext(AppContext);
    const deviceContext = React.useContext(DeviceContext);
    const userContext = React.useContext(UserContext);
    const settingsContext = React.useContext(SettingsContext);
    const initialFocusSet = React.useRef(false);
    const [showPayPerView, setShowPayPerView] = React.useState(false);

    const screenName = getCurrentScreenName();

    const [data, setData] = React.useState([] as any);
    const [pageIndex, setPageIndex] = React.useState(0);

    const [updateScreenFavorite, setUpdateScreenFavorite] = React.useState(0);
    const [updateScreenWatchlist, setUpdateScreenWatchlist] = React.useState(0);
    const [updateScreenPayPerView, setUpdatePayPerView] = React.useState(0);

    const getData = async () => {
        var path = '';
        if (styling.content.type == 'Content List') {
            if (styling.content.list?.type == 'Movies') {
                path = utils.getCorrectStoragePrefix(appContext, true) + 'movies/lists/' + styling.content.list.list + '_list.json';
            }
            if (styling.content.list?.type == 'Series') {
                path = utils.getCorrectStoragePrefix(appContext, true) + 'series/lists/' + styling.content.list.list + '_list.json';
            }
            if (styling.content.list?.type == 'Courses') {
                path = utils.getCorrectStoragePrefix(appContext, true) + 'courses/lists/' + styling.content.list.list + '_list.json';
            }
            if (styling.content.list?.type == 'Channels') {
                path = utils.getCorrectStoragePrefix(appContext, true) + 'channels/lists/' + styling.content.list.list + '_list.json';
            }
            if (styling.content.list?.type == 'Games') {
                path = utils.getCorrectStoragePrefix(appContext, true) + 'games/lists/' + styling.content.list.list + '_list.json';
            }
            try {
                let response = await fetch(path);
                let data = (await response.json()) as any;
                if (data != undefined && data.length > 0) {
                    if (styling.content.list?.type == 'Channels') {
                        var dataOut = getChannelsFromSubscriptionAndRating(contentContext, data[0].items);
                        var dataOutNew = [] as any;
                        if (dataOut.length > 0) {
                            for (var channel of dataOut) {
                                var test = checkIfChannelHasPrograms(channel, contentContext);
                                if (test) {
                                    dataOutNew.push(channel);
                                }
                            }
                            setData(dataOutNew);
                        }
                    } else if (styling.content.list?.type == 'Movies') {
                        var dataOut = getMoviesFromSubscriptionAndRating(contentContext, data[0].items);
                        if (dataOut.length > 0) {
                            let newData = dataOut;
                            if (dataOut.length > 5) {
                                newData = dataOut.slice(0, 5);
                            }
                            setData(newData);
                        }
                    } else if (styling.content.list?.type == 'Courses') {
                        var dataOut = getCoursesFromSubscriptionAndRating(contentContext, data[0].items);
                        if (dataOut.length > 0) {
                            let newData = dataOut;
                            if (dataOut.length > 5) {
                                newData = dataOut.slice(0, 5);
                            }
                            setData(newData);
                        }
                    } else if (styling.content.list?.type == 'Series') {
                        var dataOut = getSeriesFromSubscriptionAndRating(contentContext, data[0].items);
                        if (dataOut.length > 0) {
                            let newData = dataOut;
                            if (dataOut.length > 5) {
                                newData = dataOut.slice(0, 5);
                            }
                            setData(newData);
                        }
                    } else if (styling.content.list?.type == 'Games') {
                        var dataOut = getGamesFromSubscriptionAndRating(contentContext, data[0].items);
                        if (dataOut.length > 0) {
                            let newData = dataOut;
                            if (dataOut.length > 5) {
                                newData = dataOut.slice(0, 5);
                            }
                            setData(newData);
                        }
                    }
                }
            } catch (error) {
                setData(null);
            }
        }
    };

    React.useEffect(() => {
        navigation.addListener('focus', () => {
            getData();
        });
        if (!deviceContext.isWebTV) {
            getData();
        }
    }, [navigation]);

    const HeroObject = React.useMemo(
        () =>
            ({ item, index }) => {
                var program = [] as any;
                if (styling.content.list?.type == 'Channels') {
                    if (contentContext.epgTv != undefined) {
                        var epgChannel = contentContext.epgTv?.find((e) => e.i == item._id);
                        if (epgChannel != undefined) {
                            var currentTime = moment().unix();
                            program = epgChannel.epg.find((e) => e.s <= currentTime && e.e >= currentTime);
                        }
                    }
                }

                if (index > 0) {
                    initialFocusSet.current = true;
                }

                return (
                    <View key={index} focusContext={focusContext}>
                        <BackgroundImageBoxed styling={styling} program={program} item={data[index]} type={styling.content.list?.type} deviceContext={deviceContext} appContext={appContext} contentContext={contentContext}></BackgroundImageBoxed>
                        <View
                            style={{
                                zIndex: 3,
                                width: getRealWidth(deviceContext),
                                height: getRealHeight(deviceContext) * 0.55,
                                justifyContent: 'center',
                                // paddingLeft: getCorrectLeftMargin(deviceContext, appContext, screenName),
                            }}
                        >
                            <View style={{ paddingLeft: getCorrectWidth(deviceContext, 40) }}>
                                <LogoRowBoxed program={program} item={data[index]} type={styling.content.list?.type} deviceContext={deviceContext} appContext={appContext} contentContext={contentContext} styling={styling}></LogoRowBoxed>
                                <View style={{ flexDirection: 'row' }}>
                                    {styling.content.list?.type == 'Channels' && (
                                        <StatusTimeRow
                                            program={program}
                                            item={data[index]}
                                            type={styling.content.list?.type}
                                            deviceContext={deviceContext}
                                            appContext={appContext}
                                            userContext={userContext}
                                            contentContext={contentContext}
                                            styling={styling}
                                        ></StatusTimeRow>
                                    )}
                                    {styling.content.list?.type != 'Channels' && (
                                        <MetaRow
                                            program={program}
                                            item={data[index]}
                                            type={styling.content.list?.type}
                                            deviceContext={deviceContext}
                                            appContext={appContext}
                                            userContext={userContext}
                                            contentContext={contentContext}
                                            styling={styling}
                                        ></MetaRow>
                                    )}
                                </View>
                                <DescriptionRow program={program} item={data[index]} type={styling.content.list?.type} deviceContext={deviceContext} userContext={userContext} contentContext={contentContext} styling={styling}></DescriptionRow>
                            </View>

                            <View style={{ paddingLeft: getCorrectWidth(deviceContext, 40) }}>
                                <ButtonRow
                                    focusable={index === pageIndex}
                                    hasPreferredFocus={index === 0 && !initialFocusSet.current}
                                    focusContext={focusContext}
                                    item={data[index]}
                                    program={program}
                                    type={styling.content.list?.type}
                                    deviceContext={deviceContext}
                                    appContext={appContext}
                                    userContext={userContext}
                                    styling={styling}
                                    onPressBack={() => navigation.goBack()}
                                    onPressPlay={(item) => onPressPlay(styling.content.list?.type, navigation, item, contentContext, appContext, userContext, 0, settingsContext)}
                                    onPressInformation={(item) => onPressInformation(styling.content.list?.type, contentContext, appContext, userContext, item, navigation, settingsContext)}
                                    onPressPlaySeason={(seasonIndex, episodeIndex) => onPressPlaySeason(contentContext, data, navigation, seasonIndex, episodeIndex, settingsContext)}
                                    onPressPlayCatchupTV={(startType, item, programIndex) => onPressPlayCatchupTV(navigation, startType, item, contentContext, settingsContext, programIndex)}
                                    onPressTrailer={(item) => onPressTrailer(styling.content.list?.type, navigation, item, settingsContext)}
                                    onPressPurchase={() => onPressPurchase()}
                                    onPressFavorite={(isFavorite, item) => onPressFavorite(isFavorite, contentContext, userContext, styling.content.list?.type, appContext, deviceContext, setUpdateScreenFavorite, item)}
                                    onPressWatchlist={(isWatchlist, item, seasonIndex, episodeIndex) =>
                                        onPressWatchlist(isWatchlist, userContext, styling.content.list?.type, appContext, contentContext, setUpdateScreenWatchlist, item, seasonIndex, episodeIndex)
                                    }
                                    onPressContinue={(item) => onPressContinue(styling.content.list?.type, navigation, item, settingsContext)}
                                />
                            </View>
                        </View>
                    </View>
                );
            },
        [pageIndex, data?.length]
    );

    var left = getCorrectLeftMargin(deviceContext, appContext, screenName) + 10 + getCorrectWidth(deviceContext, global.menuWidth);
    return (
        <View style={{ flex: 1 }} focusContext={focusContext}>
            {showPayPerView && (
                <PayPerView
                    focusContext={focusContext}
                    styling={getModalStyling(appContext.application.theme.modals.payperview, appContext)}
                    stylingContent={styling}
                    setShowModal={() => setShowPayPerView(false)}
                    type={styling.content.list?.type == 'Channels' ? 'Program' : styling.content.list?.type}
                    data={data}
                    startContent={() => {
                        setUpdatePayPerView(moment().unix());
                        setShowPayPerView(false);
                    }}
                ></PayPerView>
            )}
            {data != null && data.length != 0 && (
                <View
                    style={{
                        position: 'absolute',
                        width: getRealWidth(deviceContext) + getCorrectWidth(deviceContext, global.menuWidth),
                        height: getRealHeight(deviceContext),
                        zIndex: -1,
                        opacity: 0.5,
                    }}
                >
                    <Image
                        source={require('../../../../assets/images/blackis.png')}
                        resizeMode={'cover'}
                        style={{ height: getRealHeight(deviceContext) + 10, width: getRealWidth(deviceContext) + getCorrectWidth(deviceContext, global.menuWidth) }}
                    ></Image>
                </View>
            )}

            {data != null && data.length != 0 && (
                <View
                    style={{
                        marginTop: getCorrectHeight(deviceContext, 50),
                        left: left - 10,
                        width: getRealWidth(deviceContext),
                        flexDirection: 'row',
                        flexGrow: 1,
                        overflow: 'hidden',
                    }}
                >
                    <View
                        style={[
                            {
                                ...getPressableStyling(styling.components?.button._id, appContext),
                                height: getRealHeight(deviceContext) * 0.6 + 14,
                                padding: 4,
                                width: getRealWidth(deviceContext) - left,
                                flexDirection: 'row',
                                backgroundColor: 'transparent',
                                zIndex: -1,
                            },
                        ]}
                        focus={false}
                        focusOptions={{
                            stealFocus: true,
                            focusKey: 'content-screen',
                            nextFocusLeft: ['menu-drawer'],
                            autoFocusEnabled: true,
                        }}
                        animatorOptions={{
                            type: 'border',
                            focus: {
                                duration: 150,
                                borderColor: getFocusStyling('Buttons', appContext).focus.borderColor,
                                borderWidth: 2,
                                backgroundColor: 'transparent',
                            },
                            blur: {
                                borderWidth: 0,
                                backgroundColor: 'transparent',
                            },
                        }}
                        hasPreferredFocus={true}
                        onPress={() => null}
                    >
                        <View style={{ width: getRealWidth(deviceContext) - left + 10 }}>
                            <FocusAwareSwiper
                                deviceContext={deviceContext}
                                focusContext={focusContext}
                                paginationStyle={{
                                    position: 'absolute',
                                    bottom: 20,
                                    left: 0,
                                    right: getCorrectWidth(deviceContext, deviceContext.isWebTV ? 100 : 70),
                                    flexDirection: 'row',
                                    flex: 1,
                                    justifyContent: 'flex-end',
                                }}
                                dotStyle={{ marginRight: getCorrectWidth(deviceContext, 5) }}
                                activeDotStyle={{ marginRight: getCorrectWidth(deviceContext, 5) }}
                                dotColor={appContext.application.theme.progresses.base}
                                activeDotColor={appContext.application.theme.progresses.progress}
                                onIndexChanged={(index) => {
                                    if (isFactorTv) {
                                        setPageIndex(index);
                                    }
                                }}
                                isPlaceholderSlide={undefined}
                                v
                                hideSwiper={false}
                            >
                                {data.map((item, index) => (
                                    <HeroObject key={index} item={item} index={index} />
                                ))}
                            </FocusAwareSwiper>
                        </View>
                    </View>
                </View>
            )}
        </View>
    );
};
export default Hero_Single;
