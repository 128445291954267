import * as React from 'react';
import { View, Screen, FocusContext } from '@scriptx-com/xtv-toolkit';
import { getFontSize } from '../../../styling/fontSizes';
import { Promotions_Ota as styling } from '../../../models/elements/promotions/ota/ota';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { useEffectOnce } from '../../../hooks/useEffectOnce';
import Pressable from '../../../../application/builder/components/pressable/pressable';
import lang from '../../../../application/languages/languages';
import { ContentContext } from '../../../context/contentContext';
import { DeviceContext } from '../../../context/deviceContext';
import { UserContext } from '../../../context/userContext';
import { AppContext } from '../../../context/appContext';
import Text from '../../components/text';
import { getFocusStyling, getPressableStyling, getPressableTextStyling } from '../../components/helpers/helper';
import { getCorrectLeftMargin } from '../../../styling/appLeftMargin';
import { getCurrentScreenName } from '../../../hooks/getScreenName';
import utils from '../../../general/utils';

export interface Data {
    title: string;
    sub: string;
}

export interface Props {
    styling: styling;
    focusContext?: any;
}

export const Promotions_Ota = ({ styling, focusContext }: Props) => {
    const [show, setShow] = React.useState(false);
    const [data, setData] = React.useState<Data>([] as any);
    const contentContext = React.useContext(ContentContext);
    const appContext = React.useContext(AppContext);
    const deviceContext = React.useContext(DeviceContext);
    const contextUser = React.useContext(UserContext);
    const screenName = getCurrentScreenName();

    const getMessage = async () => {
        var path = utils.getCorrectStoragePrefix(appContext, true) + appContext.application.client + '/' + appContext.application.deployment + '/messages/' + 0 + '_ota.json';
        try {
            let response = await fetch(path);
            let data = await response.json();
            if (data != undefined) {
                setData({
                    title: data.title,
                    sub: data.sub,
                });
                setShow(true);
            }
        } catch (error) {}
    };

    useEffectOnce(() => {
        getMessage();
    });

    const installUpdate = () => {};

    return (
        <View focusContext={focusContext} style={{ 
            paddingLeft: getCorrectWidth(deviceContext, global.menuWidth) }}>
            {show && (
                <View
                    style={{
                        marginLeft: getCorrectLeftMargin(deviceContext, appContext, screenName),
                        marginRight: 0,
                        marginTop: appContext.application.theme.rails_top_margin / (deviceContext.formFactor == 'Phone' ? 2 : 1),
                        marginBottom: appContext.application.theme.rails_bottom_margin / (deviceContext.formFactor == 'Phone' ? 2 : 1),
                        backgroundColor: styling.general.background_color,
                        borderColor: styling.general.border_color,
                        borderRadius: styling.general.border_radius,
                        borderWidth: styling.general.border_width,
                        flexDirection: 'row',
                    }}
                >
                    <View
                        style={{
                            flex: 1,
                            margin: 10,
                        }}
                    >
                        {styling.texts.show_title && (
                            <Text
                                style={{
                                    color: styling.texts.title_color == '' ? '#ffffff' : styling.texts.title_color,
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, styling.texts.title_size),
             
                                }}
                            >
                                App Update available, your TV Application just got better
                            </Text>
                        )}
                        {styling.texts.show_subtext && (
                            <Text
                                style={{
                                    color: styling.texts.title_color == '' ? '#ffffff' : styling.texts.title_color,
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, styling.texts.title_size),
                         
                                }}
                            >
                                Ready to install
                            </Text>
                        )}
                    </View>
                    <View
                        style={{
                            margin: 10,
                        }}
                    >
                        <Pressable animatorOptions={getFocusStyling('Buttons', appContext)} onPress={() => installUpdate()}>
                            <View
                                style={{
                                    marginTop: 10,
                                    width: getCorrectWidth(deviceContext, 100),
                                    height: getCorrectHeight(deviceContext, 30),
                                    ...getPressableStyling(styling.components?.button?._id, appContext),
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Text
                                    style={{
                                        padding: 10,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                    }}
                                >
                                    {lang.getTranslation(contextUser, 'install')}
                                </Text>
                            </View>
                        </Pressable>
                    </View>
                </View>
            )}
        </View>
    );
};

export default Promotions_Ota;
