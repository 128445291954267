import * as React from 'react';
import Page from '../../../application/builder/pages/page';
import ScreenWrapper from '../../wrapper';
import { getFocusOptions } from '../../wrapper/helpers/getFocusOptions';
import { DeviceContext } from '../../../application/context/deviceContext';
import { AppContext } from '../../../application/context/appContext';
import { CoreManager } from '@scriptx-com/xtv-toolkit';

const Screen_ = ({ navigation, route, focusContext }): React.ReactElement => {
    const appContext = React.useContext(AppContext);
    const deviceContext = React.useContext(DeviceContext);
    const screen = appContext.application.screens.find((s) => s.type == 'Watchlist');
    const data = [];
    const focusOptions = getFocusOptions(deviceContext, appContext, 'content-screen');

    React.useEffect(() => {
        CoreManager.setFocus('content-screen');
    }, []);
    return (
        <ScreenWrapper style={{ flex: 1 }} focusOptions={{ ...focusOptions, focusKey: 'content-screen' }}>
            <Page
                navigation={navigation}
                route={route}
                screen={screen}
                data={data}
                type={'Watchlist'}
                contentFocusOptions={{ group: 'content', nextFocusLeft: focusOptions?.nextFocusLeft, nextFocusDown: focusOptions?.nextFocusDown, nextFocusUp: focusOptions?.nextFocusUp }}
            ></Page>
        </ScreenWrapper>
    );
};
export default Screen_;
