import { Pressable, ScrollView, View, Image, CoreManager, CreateListRenderItemInfo, FlashList } from '@scriptx-com/xtv-toolkit';
import moment from 'moment';
import * as React from 'react';
import { AppContext } from '../../../application/context/appContext';
import { DeviceContext } from '../../../application/context/deviceContext';
import { UserContext } from '../../../application/context/userContext';
import lang from '../../../application/languages/languages';
import { getCorrectHeight, getCorrectWidth } from '../../../application/styling/correctSizes';
import { getFontSize } from '../../../application/styling/fontSizes';
import { getRealHeight, getRealWidth } from '../../../application/styling/realDeviceSizes';
import { actionType } from '../../../reporting/models/apps';
import ScreenWrapper from '../../wrapper';
import Text from '../../../application/builder/components/text';
import { getProfiles, updateDevicesJson, updateProfilesJson } from '../../../application/data/account';
import { getFocusStyling, getModalStyling, getPressableStyling, getPressableTextStyling, getSelectedStyling } from '../../../application/builder/components/helpers/helper';
import { ContentContext } from '../../../application/context/contentContext';
import { SettingsContext } from '../../../application/context/settingsContext';
import { CommonActions } from '@react-navigation/native';
import utils from '../../../application/general/utils';
import { sendActionReport } from '../../../reporting/apis/action';
import { colorShade } from '../../../application/styling/colorShade';
import ManualScreen from './manual';
import SubscriptionScreen from './subscription';
import MessagesScreen from './messages';
import SupportScreen from './support';
import AboutScreen from './about';
import DevicesScreen from './devices';
import UpdatesScreen from './updates';
import { Animated, BackHandler } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { createNewProfiles } from '../profiles/helpers/helper';
import { PlayerContext } from '../../../application/context/playerContext';
import Parental from '../../../application/builder/components/parental/parental_lock';
import DisclaimerScreen from '../disclaimer/disclaimer';
import { useSafeAreaInsets } from '../../../libs/react-native-safe-area-context';
import { useEffectOnce } from '../../../application/hooks/useEffectOnce';
import { Ratio } from '@scriptx-com/xtv-toolkit/src/helpers';

const AnimatedView = Animated.createAnimatedComponent(View);

const Settings = ({ navigation, route, focusContext }): React.ReactElement => {
    const appContext = React.useContext(AppContext);
    const deviceContext = React.useContext(DeviceContext);
    const userContext = React.useContext(UserContext);
    const contentContext = React.useContext(ContentContext);
    const settingsContext = React.useContext(SettingsContext);
    const playerContext = React.useContext(PlayerContext);
    const [selectedSetting, setSelectedSetting] = React.useState(deviceContext.isPhone ? '' : 'About');
    const isSettingOpen = React.useRef(false);
    const [profiles, setProfiles] = React.useState(userContext.allProfiles);
    const [showParental, setShowParental] = React.useState(false);
    const [parentalApproved, setParentalApproved] = React.useState(false);
    const [profile, setProfile] = React.useState([] as any);
    const [profileIndex, setProfileIndex] = React.useState(0);
    const [menuItems, setMenuItems] = React.useState([] as any);
    const insets = useSafeAreaInsets();

    var styling = [] as any;
    if (deviceContext.isWebTV && !deviceContext.isPwaVertical) {
        styling = appContext.application.navigators?.browser;
    } else if (deviceContext.isTablet) {
        styling = appContext.application.navigators?.tablet;
    } else if (deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical) {
        styling = appContext.application.navigators?.mobile;
    } else if (deviceContext.isTelevision) {
        styling = appContext.application.navigators?.television;
    }

    var navigatorType = 'Bottom Menu';
    if (deviceContext.isWebTV && !deviceContext.isPwaVertical) {
        navigatorType = appContext.application.navigators?.browser.type;
    } else if (deviceContext.isTablet) {
        navigatorType = appContext.application.navigators?.tablet.type;
    } else if (deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical) {
        navigatorType = appContext.application.navigators?.mobile.type;
    } else if (deviceContext.isTelevision) {
        navigatorType = appContext.application.navigators?.television.type;
    }

    const menuAnim = React.useRef(new Animated.Value(0)).current;
    const settingAnim = React.useRef(new Animated.Value(-getRealWidth(deviceContext))).current;

    const goingToSetting = () => {
        Animated.parallel([
            Animated.timing(settingAnim, {
                toValue: 0,
                duration: 300,
                useNativeDriver: true,
            }),
            Animated.timing(menuAnim, {
                toValue: -getRealWidth(deviceContext),
                duration: 300,
                useNativeDriver: true,
            }),
        ]).start();
    };

    const goingToMenu = () => {
        Animated.parallel([
            Animated.timing(menuAnim, {
                toValue: 0,
                duration: 300,
                useNativeDriver: true,
            }),
            Animated.timing(settingAnim, {
                toValue: -getRealWidth(deviceContext),
                duration: 300,
                useNativeDriver: true,
            }),
        ]).start();
    };

    React.useEffect(() => {
        loadCustomerData();
        BackHandler.addEventListener('hardwareBackPress', onPressBack);
        return () => {
            BackHandler.removeEventListener('hardwareBackPress', onPressBack);
        };
    }, []);

    const onPressBack = () => {
        if (deviceContext.isPhone && isSettingOpen.current === true) {
            isSettingOpen.current = false;
            goingToMenu();
            return true;
        }
        return false;
    };

    const loadCustomerData = async () => {
        if (profiles.length == 0) {
            var allProfiles = (await getProfiles(appContext.application, userContext, deviceContext, true, appContext)) as any;
            if (allProfiles != null && route?.params?.fromEditOrAdd == undefined) {
                userContext.setAllProfiles(allProfiles);
                setProfiles(allProfiles);
            } else if (route?.params?.fromEditOrAdd != undefined) {
                setProfiles(userContext.allProfiles);
            } else {
                var id = utils.uuidv4();
                var newProfiles = createNewProfiles(id, userContext, appContext);
                await updateProfilesJson(newProfiles, appContext.application, userContext);
                userContext.setAllProfiles(newProfiles);
                setProfiles(newProfiles);
            }
        }
    };

    const onPress = (menu: any) => {
        if (menu === 'Switch Profile') {
            settingsContext.setShowMainMenu(false);
            navigation.dispatch(
                CommonActions.reset({
                    index: 1,
                    routes: [{ name: 'Profiles' }],
                })
            );
        } else if (menu === 'Logout') {
            settingsContext.setShowMainMenu(false);

            userContext.setAutoLogin(false);
            utils.storeJson('AutoLogin', false);

            userContext.setAllProfiles([]);

            //delete current device
            var devices = userContext.devices;
            sendActionReport(appContext, deviceContext, userContext, actionType.DELETE_DEVICE);
            var index = devices.findIndex((d) => d.uuid == deviceContext.uniqueId);
            if (index != -1) {
                devices.splice(index, 1);
                updateDevicesJson(appContext, userContext, devices);
            }

            setTimeout(() => {
                if (appContext.application.settings.enable_delete_userdata == true || deviceContext.isSmartTV == true) {
                    userContext.setAppId('');
                    utils.storeJson('ServiceID', '');

                    userContext.setUserId('');
                    utils.storeJson('UserID', '');
                    userContext.setPass('');
                    utils.storeJson('Pass', '');
                }

                if (appContext.application.settings?.enable_nested == true) {
                    contentContext.setActiveMenu('Services');
                    navigation.dispatch(
                        CommonActions.reset({
                            index: 1,
                            routes: [{ name: 'Services' }],
                        })
                    );
                } else if (appContext.application?.settings.enable_anonymous == true) {
                    contentContext.setActiveMenu('Anonymous');
                    navigation.dispatch(
                        CommonActions.reset({
                            index: 1,
                            routes: [{ name: 'Anonymous' }],
                        })
                    );
                } else {
                    contentContext.setActiveMenu('Login');
                    navigation.dispatch(
                        CommonActions.reset({
                            index: 1,
                            routes: [{ name: 'Login' }],
                        })
                    );
                }
            }, 1000);
        } else if (menu === 'Refresh') {
            settingsContext.setShowMainMenu(false);
            contentContext.setActiveMenu('Loader');
            navigation.dispatch(
                CommonActions.reset({
                    index: 1,
                    routes: [{ name: 'Loader' }],
                })
            );
        } else {
            setSelectedSetting(menu);
            if (deviceContext.isPhone) {
                isSettingOpen.current = true;
                goingToSetting();
            }
        }
    };

    const selectProfile = async (profile, index) => {
        sendActionReport(appContext, deviceContext, userContext, actionType.SELECT_PROFILE);
        if (!parentalApproved && profile.locked) {
            setProfile(profile);
            setProfileIndex(index);
            setShowParental(true);
        } else {
            var profile = userContext.allProfiles.find((p) => p.id == profile.id);

            if (profile.favorites == undefined) {
                profile.favorites = {
                    channels: [],
                    radios: [],
                    cams: [],
                    movies: [],
                    series: [],
                    courses: [],
                    podcasts: [],
                    music: [],
                    shorts: [],
                    games: [],
                };
            }
            if (profile.payperview == undefined) {
                profile.payperview = {
                    channels: [],
                    radios: [],
                    cams: [],
                    movies: [],
                    series: [],
                    courses: [],
                    podcasts: [],
                    music: [],
                    shorts: [],
                    games: [],
                };
            }
            if (profile.watchings == undefined) {
                profile.watchings = {
                    channels: [],
                    radios: [],
                    cams: [],
                    movies: [],
                    series: [],
                    seasons: [],
                    episodes: [],
                    courses: [],
                    lessons: [],
                    podcasts: [],
                    pods: [],
                    music: [],
                    songs: [],
                    shorts: [],
                    games: [],
                };
            }
            if (profile.recordings == undefined) {
                profile.recordings = [];
            }
            if (profile.reminders == undefined) {
                profile.reminders = [];
            }
            if (profile.recommendations == undefined) {
                profile.recommendations = [];
            }

            playerContext.setAspectRatio(profile.aspectRatio != undefined ? profile.aspectRatio : 'Cover');
            if (profile.trackStyle != undefined) {
                playerContext.setTrackStyle(profile.trackStyle);
            }
            userContext.setProfile(profile);
            userContext.setProfiled(true);
            deviceContext.setClockSetting(profile.clock == 'AM/PM' ? 'hh:mm A' : 'HH:mm');

            userContext.setSelectedLanguage(profile.language);

            var code = appContext.application.settings.languages.find((l) => l.name == profile.language);
            if (code != undefined) {
                userContext.setSelectedLanguageCode(code.code);
                userContext.setIso2(code.code);
                try {
                    var locales = moment.locales();
                    if (locales != undefined) {
                        var test = locales.find((l) => l == code.code);
                        if (test != undefined) {
                            moment.locale(code.code);
                        }
                    }
                } catch (e) {
                    //do nothing
                }
            }

            navigation.dispatch(
                CommonActions.reset({
                    index: 1,
                    routes: [{ name: 'Home' }],
                })
            );
            contentContext.setActiveMenu('Home');

            // Avoid race condition when page is not yet added
            setTimeout(() => {
                settingsContext.setShowMainMenu(true);
            }, 500);
        }
    };

    var menuArray = [] as any;
    const renderSettings = () => {
        if (appContext.application.settings.enable_profiles != false && !deviceContext.isPhone) {
            menuArray.push({ name: 'switch_profile', menu: 'Switch Profile' });
        }
        if (appContext.application.settings.show_info_manual) {
            menuArray.push({ name: 'manual', menu: 'Manual' });
        }
        if (appContext.application.settings.enable_register) {
            menuArray.push({ name: 'account', menu: 'Registration' });
        }

        if (appContext.application.settings.show_info_subscription && userContext.userId.indexOf('_review') < 0) {
            menuArray.push({ name: 'subscription', menu: 'Subscription' });
        }

        if (appContext.application.settings.show_info_messages && userContext.userId.indexOf('_review') < 0) {
            menuArray.push({ name: 'messages', menu: 'Messages' });
        }
        // if (appContext.application.settings.show_info_renewal && userContext.userId.indexOf('_review') < 0) {
        //     menuArray.push({ name: 'renewal', menu: 'Renewal' });
        // }
        if (appContext.application.settings.show_info_support && userContext.userId.indexOf('_review') < 0) {
            menuArray.push({ name: 'support', menu: 'Support' });
        }
        if (appContext.application.settings.show_info_apps && global.Cart != undefined && userContext.userId.indexOf('_review') < 0) {
            menuArray.push({ name: 'apps', menu: 'Apps' });
        }
        if (appContext.application.settings.show_info_about) {
            menuArray.push({ name: 'about', menu: 'About' });
        }
        if (appContext.application.settings.show_info_devices) {
            menuArray.push({ name: 'devices', menu: 'Devices' });
        }
        if (appContext.application.settings.show_info_logout) {
            menuArray.push({ name: 'logout', menu: 'Logout' });
        }
        if (appContext.application.settings.show_info_updates && userContext.userId.indexOf('_review') < 0 && (deviceContext.isAndroid || deviceContext.isAndroidTV || deviceContext.isFireTV)) {
            menuArray.push({ name: 'updates', menu: 'Updates' });
        }
        if (appContext.application.settings.show_info_refresh) {
            menuArray.push({ name: 'refresh', menu: 'Refresh' });
        }
        if (appContext.application.settings.show_info_disclaimer && userContext.userId.indexOf('_review') < 0) {
            menuArray.push({ name: 'disclaimer', menu: 'Disclaimer' });
        }

        var menu = [];
        menuArray.map((item, index) => {
            if (item.name != undefined) {
                menu.push(item);
            }
        });
        setMenuItems(menu);
    };

    useEffectOnce(() => {
        renderSettings();
    });

    const renderMenu = ({ item, focusRepeatContext, index }: CreateListRenderItemInfo<any>) => {
        return (
            <View
                focusRepeatContext={focusRepeatContext}
                style={{
                    backgroundColor: deviceContext.isPhone
                        ? 'transparent'
                        : item.menu === contentContext.activeMenu
                        ? colorShade(getPressableStyling(styling.components?.button?._id, appContext).backgroundColor, 95)
                        : getPressableStyling(styling.components?.button?._id, appContext).backgroundColor,
                    marginBottom: 5,

                    borderRadius: getPressableStyling(styling.components?.button?._id, appContext).borderRadius,
                    alignSelf: 'center',

                    width: deviceContext.isPhone ? getRealWidth(deviceContext) - getCorrectWidth(deviceContext, 20) : getCorrectWidth(deviceContext, 190 - global.menuWidth),
                }}
            >
                <Pressable
                    focusContext={focusContext}
                    style={{
                        flexDirection: 'row',
                        height: getCorrectHeight(deviceContext, 35),
                        borderRadius: getPressableStyling(styling.components?.button?._id, appContext).borderRadius,
                        backgroundColor: deviceContext.isPhone
                            ? 'transparent'
                            : item.menu === contentContext.activeMenu
                            ? colorShade(getPressableStyling(styling.components?.button?._id, appContext).backgroundColor, 95)
                            : getPressableStyling(styling.components?.button?._id, appContext).backgroundColor,
                        ...(deviceContext.isPhone && { borderBottomWidth: 1, borderColor: getPressableTextStyling(styling.components?.button?._id, appContext).color }),
                    }}
                    animatorOptions={getFocusStyling('Buttons', appContext)}
                    key={index}
                    onPress={() => onPress(item.menu)}
                    focusOptions={{
                        focusKey: `menuitem-${item.menu}`,
                        hasPreferredFocus: item.menu === 'Manual' ? true : false,
                        nextFocusRight: ['settings-screen'],
                    }}
                >
                    <View
                        style={{
                            flexGrow: 1,
                            alignSelf: 'center',
                            alignContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'row',
                        }}
                    >
                        <Text
                            numberOfLines={1}
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Normal'),
                                marginLeft: getCorrectWidth(deviceContext, 10),
                                ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                borderBottomColor: 'transparent',
                                paddingVertical: getCorrectHeight(deviceContext, 4),
                                zIndex: 1,
                            }}
                        >
                            {lang.getTranslation(userContext, item.name)} {userContext?.product?.settings?.wallet_enabled && item.menu == 'Wallet' ? '(' + userContext.walletBalance + ')' : ''}
                        </Text>
                    </View>
                </Pressable>
            </View>
        );
    };
    const renderItem = ({ item, focusRepeatContext, index }: CreateListRenderItemInfo<any>) => {
        return (
            <View
                focusRepeatContext={focusRepeatContext}
                style={{
                    display: 'flex',
                    width: getRealWidth(deviceContext) / (profiles.length < 3 ? profiles.length : 3),
                    alignItems: 'center',
                    marginBottom: getCorrectHeight(deviceContext, 20),
                }}
            >
                <Pressable
                    style={{
                        margin: getCorrectHeight(deviceContext, 10),
                        width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.25 : 0.11),
                    }}
                    onPress={() => selectProfile(item, index)}
                >
                    <View
                        style={{
                            alignItems: 'center',
                            width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.25 : 0.11),
                        }}
                    >
                        <Image
                            resizeMethod={'scale'}
                            resizeMode={'cover'}
                            source={{ uri: item.avatar }}
                            style={{
                                width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.25 : 0.11),
                                height: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.25 : 0.11),
                                borderRadius: (getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.3 : 0.11)) / 2,
                                borderColor: '#fff',
                                borderWidth: deviceContext.isKaiOs ? getCorrectWidth(deviceContext, 4) : getCorrectWidth(deviceContext, 2),
                            }}
                        ></Image>
                    </View>
                </Pressable>

                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Text
                        numberOfLines={1}
                        style={{
                            textAlign: 'center',
                            fontFamily: deviceContext.fontType,
                            fontSize: getFontSize(deviceContext, 'Normal'),
                            ...getPressableTextStyling(appContext.application.authentication.profiles.components?.button?._id, appContext),
                            marginRight: getCorrectWidth(deviceContext, 4),
                            width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.3 : 0.12),
                        }}
                    >
                        {item.name}sddd
                    </Text>
                </View>
            </View>
        );
    };

    const MenuComponent = React.useMemo(() => {
        switch (selectedSetting) {
            case 'Manual':
                return <ManualScreen navigation={navigation} route={route} focusContext={focusContext} />;
            case 'Subscription':
                return <SubscriptionScreen navigation={navigation} route={route} focusContext={focusContext} />;
            case 'Messages':
                return <MessagesScreen navigation={navigation} route={route} focusContext={focusContext} />;
            case 'Support':
                return <SupportScreen navigation={navigation} route={route} focusContext={focusContext} />;
            case 'About':
                return <AboutScreen navigation={navigation} route={route} focusContext={focusContext} />;
            case 'Devices':
                return <DevicesScreen navigation={navigation} route={route} focusContext={focusContext} />;
            case 'Updates':
                return <UpdatesScreen navigation={navigation} route={route} focusContext={focusContext} />;
            case 'Disclaimer':
                return <DisclaimerScreen navigation={navigation} route={route} focusContext={focusContext} showButton={false} />;
            default:
                break;
        }
    }, [selectedSetting]);

    const ButtonBack = React.useMemo(() => {
        return (
            <Pressable
                style={{
                    height: getCorrectHeight(deviceContext, 35),
                    width: getCorrectHeight(deviceContext, 35),
                    ...getPressableStyling(styling.components?.button?._id, appContext),
                }}
                animatorOptions={getFocusStyling('Buttons', appContext)}
                onPress={() => {
                    isSettingOpen.current = false;
                    goingToMenu();
                }}
            >
                <View
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: getCorrectHeight(deviceContext, 35),
                        width: getCorrectHeight(deviceContext, 35),
                    }}
                >
                    <FontAwesomeIcon icon={faArrowLeft} color={getPressableTextStyling(styling.components?.button?._id, appContext).color} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>
                </View>
            </Pressable>
        );
    }, []);

    return (
        <ScreenWrapper style={{ flex: 1, paddingTop: navigatorType == 'Split Menu' ? (deviceContext.isPhone ? 10 : 75) : 0 }}>
            {showParental && (
                <Parental
                    focusContext={focusContext}
                    styling={getModalStyling(appContext.application.theme.modals.parental, appContext)}
                    setParentalApproved={() => {
                        setParentalApproved(true);
                        selectProfile(profile, profileIndex);
                    }}
                    setShowParental={(e) => {
                        setShowParental(e);
                    }}
                    profile={profile}
                    type={'access_profile'}
                ></Parental>
            )}
            {deviceContext.isPhone && (
                <View style={{ flex: 1, paddingTop: insets.top, justifyContent: 'center', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                    <AnimatedView
                        style={[
                            { transform: [{ translateX: menuAnim }] },
                            {
                                flex: 1,
                            },
                        ]}
                        focusOptions={{ group: 'settings-buttons', focusKey: 'settings-buttons', nextFocusRight: ['settings-screen'], nextFocusLeft: ['menu-drawer'] }}
                    >
                        <View
                            style={{
                                height: getCorrectWidth(deviceContext, 120),
                                marginTop: 10,
                            }}
                        >
                            <FlashList
                                data={profiles}
                                estimatedItemSize={getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.3 : 0.2)}
                                horizontal={true}
                                showsVerticalScrollIndicator={false}
                                renderItem={renderItem}
                                initialScrollIndex={0}
                                type={'row'}
                                style={{ flex: 1 }}
                            />
                        </View>
                        <View style={{ flex: 1 }}>
                            <FlashList data={menuItems} estimatedItemSize={200} horizontal={false} showsHorizontalScrollIndicator={false} renderItem={renderMenu} initialScrollIndex={0} type={'grid'} numColumns={1} style={{ flex: 1 }} />
                        </View>
                    </AnimatedView>
                    <AnimatedView style={{ transform: [{ translateX: settingAnim }] }}>
                        {deviceContext.isPhone && ButtonBack}
                        {MenuComponent}
                    </AnimatedView>
                </View>
            )}
            {!deviceContext.isPhone && (
                <View focusContext={focusContext} style={{ flex: 1, flexDirection: 'row', paddingTop: insets.top }}>
                    <View
                        focusOptions={{ group: 'settings-buttons', focusKey: 'settings-buttons', nextFocusRight: ['settings-screen'], nextFocusLeft: ['menu-drawer'] }}
                        style={{ flex: 1, marginTop: navigatorType == 'Split Menu' || navigatorType == 'Top Menu' ? 20 : 12, marginLeft: getCorrectWidth(deviceContext, global.menuWidth) }}
                    >
                        <FlashList
                            style={{ width: Ratio(350), ...((deviceContext.isAndroidTV || deviceContext.isAppleTV) && { height: Ratio(890) }) }}
                            data={menuItems}
                            estimatedItemSize={200}
                            horizontal={false}
                            showsVerticalScrollIndicator={false}
                            renderItem={renderMenu}
                            initialScrollIndex={0}
                            type={'row'}
                        />
                    </View>

                    <View style={{ height: Ratio(1000) }}>{MenuComponent}</View>
                </View>
            )}
        </ScreenWrapper>
    );
};
export default Settings;
