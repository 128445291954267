import { MutableRefObject } from 'react';
import { View } from 'react-native';
import _ from 'lodash';

export const getUsedHeight = (appContext: any, screenName: any, gridMargin: any, extra: any, insets = 0, tabBarHeight = 0) => {
    var usedHeight = 0;
    var elements = appContext.application.screens.find((s) => s.type == screenName);

    if (elements != undefined) {
        elements.content.forEach((element) => {
            if (element.filterbars != undefined) {
                usedHeight = usedHeight + (extra + gridMargin + element.filterbars.general.margin_top + element.filterbars.general.border_width * 2);
            }
            if (element.menus != undefined) {
                usedHeight = usedHeight + (extra + gridMargin + element.menus.general.margin_top + element.menus.general.border_width * 2);
            }
        });
        elements.header.forEach((element) => {
            if (element.messages != undefined) {
                usedHeight = usedHeight + (extra + gridMargin + element.messages.general.margin_top + element.messages.general.border_width * 2) + 30;
            }
        });
    }
    return usedHeight + insets + tabBarHeight;
};
const ageRatings = [
    { name: 'Kids (all)', index: 0 },
    { name: 'Older Kids 7+', index: 1 },
    { name: 'Teens 13+', index: 2 },
    { name: 'Young Adults 16+', index: 3 },
    { name: 'Adults 18+', index: 4 },
];
//for a list
export const filterAgeRatings = (items, userContext, appContext) => {
    var itemsOut = [] as any;
    var appRatings = appContext.application.settings.agerating?.ratings;
    var userRating = userContext.profile.parental.index; ///0 = Kids, 1 = Young Kids , etc
    for (var item of items) {
        if (item.parental == undefined || item.parental.rating == undefined || item.parental.rating == '') {
            itemsOut.push(item);
        } else {
            if (item.parental.rating.indexOf(',') == -1) {
                //single rating
                var appRating = appRatings?.find((ar) => ar.name == item.parental.rating);
                if (appRating != undefined) {
                    var contentRating = ageRatings.find((a) => a.name == appRating.type);
                    if (contentRating != undefined && userRating >= contentRating.index) {
                        itemsOut.push(item);
                    }
                }
            } else {
                //multiple ratings
                var alreadyAdded = false;
                var splittedRating = item.parental.rating.split(', ');
                for (var rating of splittedRating) {
                    var appRating = appRatings?.find((ar) => ar.name == rating);
                    if (appRating != undefined) {
                        var contentRating = ageRatings.find((a) => a.name == appRating.type);
                        if (contentRating != undefined && userRating >= contentRating.index && alreadyAdded == false) {
                            itemsOut.push(item);
                            alreadyAdded = true;
                        }
                    }
                }
            }
        }
    }

    return itemsOut;
};

export const getGridData = async (userContext, screenName, contentContext, appContext) => {
    if (screenName == 'Recordings' && userContext.profile.recordings != undefined) {
        return userContext.profile.recordings;
    }
    if (screenName == 'Channels' && contentContext.channels.categories != undefined) {
        var channels = contentContext.channels.categories[contentContext.channelData.localCategoryIndex != 0 ? contentContext.channelData.localCategoryIndex : contentContext.channelData.channelCategoryIndex].channels;
        if (channels != undefined) {
            return channels.sort((a, b) => a.number - b.number);
        }
    }
    if (screenName == 'Cams' && contentContext.cams.categories != undefined) {
        var cams = contentContext.cams.categories[contentContext.camData.localCategoryIndex != 0 ? contentContext.camData.localCategoryIndex : contentContext.camData.camCategoryIndex].cams;
        if (cams != undefined) {
            return cams.sort((a, b) => a.number - b.number);
        }
    }
    if (screenName == 'Series' && contentContext.series.categories != undefined) {
        return contentContext.series.categories[contentContext.seriesData.localCategoryIndex != 0 ? contentContext.seriesData.localCategoryIndex : contentContext.seriesData.seriesCategoryIndex].series;
    }
    if (screenName == 'Seasons' && contentContext.seriesData.seasons != undefined) {
        return contentContext.seriesData.season.episodes;
    }
    if (screenName == 'Courses' && contentContext.courses.categories != undefined) {
        return contentContext.courses.categories[contentContext.courseData.localCategoryIndex != 0 ? contentContext.courseData.localCategoryIndex : contentContext.courseData.courseCategoryIndex].courses;
    }
    if (screenName == 'Course' && contentContext.courseData.course.lessons != undefined) {
        return contentContext.courseData.course.lessons;
    }
    if (screenName == 'Radio' && contentContext.radios.categories != undefined) {
        var radio = contentContext.radios.categories[contentContext.radioData.localCategoryIndex != 0 ? contentContext.radioData.localCategoryIndex : contentContext.radioData.radioCategoryIndex].radios;
        if (radio != undefined) {
            return radio.sort((a, b) => a.number - b.number);
        }
    }
    if (screenName == 'Podcasts' && contentContext.podcasts.categories != undefined) {
        return contentContext.podcasts.categories[contentContext.podcastData.localCategoryIndex != 0 ? contentContext.podcastData.localCategoryIndex : contentContext.podcastData.podcastCategoryIndex].podcasts;
    }
    if (screenName == 'Music' && contentContext.albums.categories != undefined) {
        return contentContext.albums.categories[contentContext.albumData.localCategoryIndex != 0 ? contentContext.albumData.localCategoryIndex : contentContext.albumData.albumCategoryIndex].albums;
    }
    if (screenName == 'Movies' && contentContext.movies?.categories != undefined) {
        return contentContext.movies.categories[contentContext.movieData.localCategoryIndex != 0 ? contentContext.movieData.localCategoryIndex : contentContext.movieData.movieCategoryIndex].movies;
    }
    if (screenName == 'Games' && contentContext.games.categories != undefined) {
        return contentContext.games.categories[contentContext.gameData.localCategoryIndex != 0 ? contentContext.gameData.localCategoryIndex : contentContext.gameData.gameCategoryIndex].games;
    }
    if (screenName == 'Shorts' && contentContext.shorts.categories != undefined) {
        return contentContext.shorts.categories[contentContext.shortData.localCategoryIndex != 0 ? contentContext.shortData.localCategoryIndex : contentContext.shortData.shortCategoryIndex].shorts;
    }
    return [];
};

export const calculateGridHeight = async (wrapperRef: MutableRefObject<View>, insets = 0, tabBarHeight = 0): Promise<number> => {
    return new Promise((resolve) => {
        if (wrapperRef.current) {
            wrapperRef.current.measure((_x, _y, _width, _height, _pageX, pageY) => {
                resolve((pageY ? pageY : 0) + insets + tabBarHeight);
            });
        } else {
            resolve(insets + tabBarHeight);
        }
    });
};
