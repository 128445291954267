import { Text, View, CreateListRenderItemInfo, FlashList, Screen, setFocus } from '@scriptx-com/xtv-toolkit';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import * as React from 'react';
import { getFontSize } from '../../../styling/fontSizes';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import Pressable from '../pressable/pressable';
import { DeviceContext } from '../../../context/deviceContext';
import { getRealHeight, getRealWidth } from '../../../styling/realDeviceSizes';
import { faArrowTurnDownLeft, faDeleteLeft, faTrash } from '@fortawesome/pro-light-svg-icons';
import { Keyboard as Styling } from '../../../models/components/keyboard/keyboard';
import { KeyboardContext } from '../../../context/keyboardContext';
import { AppContext } from '../../../context/appContext';
import { getPressableStyling, getFocusStyling, getPressableTextStyling } from '../helpers/helper';
import { ContentContext } from '../../../context/contentContext';
import { getCurrentScreenName } from '../../../hooks/getScreenName';

export interface InputProps {
    props?: any;
    width: number;
    isSecure?: boolean;
    placeholder?: string;
    styling: Styling;
    onChangText?: any;
    style?: any;
    value?: any;
    focusContext?: any;
    focusOptions?: any;
    onCloseKeyboard?: any;
}

const KeyboardWrapper = (props: InputProps) => {
    const { keyboardVisible, onChangeText, placeholder, focusOptions, isSecure, value } = React.useContext(KeyboardContext);

    if (keyboardVisible) {
        return <Keyboard_ {...props} onChangText={onChangeText} placeholder={placeholder} focusOptions={focusOptions} value={value} isSecure={isSecure} />;
    }

    return null;
};

const Keyboard_ = ({ width, value, isSecure = false, placeholder = '', styling, onChangText, style, focusContext, onCloseKeyboard, focusOptions, ...props }: InputProps) => {
    const deviceContext = React.useContext(DeviceContext);
    const appContext = React.useContext(AppContext);
    const { search } = React.useContext(ContentContext);
    const [keyboard, setKeyboard] = React.useState('alfabet');
    const [caps, setCaps] = React.useState(false);
    const [alphabet] = React.useState(['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '@', '.', '-', '_']);
    const localValue = React.useRef(value ?? '');
    const [special] = React.useState(['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '_', '+', '-', '{', '}', '[', ']', ':', ';', '"', '|', '\\', '<', '>', ',', '.', '/', '?', 'del', 'abc']);
    const [space] = React.useState(['$%^', 'ABC', '.com', '________', 'clear', 'del', 'enter']);
    const screenName = getCurrentScreenName();

    const onChangTextLocal = (letter) => {
        if (letter == 'ABC') {
            setCaps(!caps);
        } else if (letter == 'abc') {
            setKeyboard('alfabet');
        } else if (letter == '$%^') {
            setKeyboard('specials');
        } else {
            if (letter === '________') {
                localValue.current = `${localValue.current} `;
            } else if (letter != 'del' && letter != 'clear' && letter != 'enter') {
                localValue.current = `${localValue.current}${caps ? letter.toUpperCase() : letter}`;
            }
            if (letter == 'del') {
                localValue.current = localValue.current.substring(0, localValue.current.length - 1);
            }
            if (letter == 'clear') localValue.current = '';
            if (letter == 'enter') {
                onCloseKeyboard?.(true);
            } else onChangText(localValue.current);
        }
    };

    //needed to keep localValue in sync with shorthand presses from searchBar
    React.useEffect(() => {
        if (screenName === 'Search') {
            localValue.current = search;
        }
    }, [search]);

    const renderSquareItem = ({ item, focusRepeatContext, index }: CreateListRenderItemInfo<any>) => {
        return (
            <Pressable
                style={{
                    ...getPressableStyling(styling.components?.button?._id, appContext),
                    height: getCorrectHeight(deviceContext, 25),
                    width: (getRealWidth(deviceContext) * 0.7) / 10 - 5,
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 5,
                }}
                focusRepeatContext={focusRepeatContext}
                animatorOptions={getFocusStyling('Buttons', appContext)}
                onPress={() => onChangTextLocal(item)}
            >
                <View>
                    {item == 'enter' && <FontAwesomeIcon icon={faArrowTurnDownLeft} size={getFontSize(deviceContext, 'Normal')} color={'#fff'}></FontAwesomeIcon>}
                    {item == 'del' && <FontAwesomeIcon icon={faDeleteLeft} size={getFontSize(deviceContext, 'Normal')} color={'#fff'}></FontAwesomeIcon>}
                    {item == 'clear' && <FontAwesomeIcon icon={faTrash} size={getFontSize(deviceContext, 'Normal')} color={'#fff'}></FontAwesomeIcon>}

                    {item != 'del' && item != 'clear' && item != 'enter' && (
                        <Text
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Normal'),
                                ...getPressableTextStyling(styling.components?.button?._id, appContext),
                            }}
                        >
                            {caps && item != 'ABC' && item.toUpperCase()}
                            {!caps && item != 'ABC' && item}

                            {caps && item == 'ABC' && item.toLowerCase()}
                            {!caps && item == 'ABC' && item}
                        </Text>
                    )}
                </View>
            </Pressable>
        );
    };
    const renderItem = ({ item, focusRepeatContext, index }: CreateListRenderItemInfo<any>) => {
        return (
            <Pressable
                style={{
                    ...getPressableStyling(styling.components?.button?._id, appContext),
                    height: getCorrectHeight(deviceContext, 25),
                    width: (getRealWidth(deviceContext) * 0.7) / 7 - 5,
                    padding: getCorrectWidth(deviceContext, 5),
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: 5,
                    marginBottom: 5,
                }}
                focusRepeatContext={focusRepeatContext}
                animatorOptions={getFocusStyling('Buttons', appContext)}
                onPress={() => onChangTextLocal(item)}
            >
                <View>
                    {item == 'enter' && <FontAwesomeIcon icon={faArrowTurnDownLeft} size={getFontSize(deviceContext, 'Normal')} color={'#fff'}></FontAwesomeIcon>}
                    {item == 'del' && <FontAwesomeIcon icon={faDeleteLeft} size={getFontSize(deviceContext, 'Normal')} color={'#fff'}></FontAwesomeIcon>}
                    {item == 'clear' && <FontAwesomeIcon icon={faTrash} size={getFontSize(deviceContext, 'Normal')} color={'#fff'}></FontAwesomeIcon>}

                    {item != 'del' && item != 'clear' && item != 'enter' && (
                        <Text
                            style={{
                                ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Normal'),
                            }}
                        >
                            {item != 'ABC' && item}
                            {caps && item == 'ABC' && item.toLowerCase()}
                            {!caps && item == 'ABC' && item}
                        </Text>
                    )}
                </View>
            </Pressable>
        );
    };

    React.useEffect(() => {
        setFocus('keyboard');
    }, []);

    return (
        <Screen
            focusOptions={{ focusKey: 'keyboard', screenOrder: 999, ...focusOptions }}
            style={{
                flex: 1,
                position: 'absolute',
                zIndex: 9999,
                bottom: 0,
                height: getRealHeight(deviceContext) / 2.5,
            }}
        >
            <View style={{ flex: 1, justifyContent: 'center' }}>
                <View
                    style={{
                        flex: 5,
                        padding: getCorrectHeight(deviceContext, 10),

                        width: getRealWidth(deviceContext),
                        backgroundColor: styling.background_color,
                    }}
                >
                    <View style={{ height: (getRealHeight(deviceContext) / 2.5) * 0.72, flexDirection: 'row', justifyContent: 'center' }}>
                        <View
                            style={{
                                flex: 1,
                                flexDirection: 'row',
                                marginRight: getCorrectWidth(deviceContext, 5),
                            }}
                        ></View>
                        <View
                            style={{
                                width: getRealWidth(deviceContext) * 0.7,
                            }}
                        >
                            <View
                                style={{
                                    height: getCorrectHeight(deviceContext, 30) * 4,
                                    width: getRealWidth(deviceContext) * 0.7,
                                    marginRight: getCorrectWidth(deviceContext, 5),
                                }}
                                focusOptions={{ group: 'main-letters', allowFocusOutsideGroup: true, focusKey: 'main-letters' }}
                            >
                                {keyboard == 'alfabet' && caps && (
                                    <FlashList
                                        data={alphabet}
                                        estimatedItemSize={30}
                                        numColumns={10}
                                        horizontal={false}
                                        renderItem={renderSquareItem}
                                        initialScrollIndex={0}
                                        type={'grid'}
                                        style={{ flex: 1 }}
                                        showsHorizontalScrollIndicator={false}
                                        focusOptions={{ nextFocusDown: space.length ? 'space-letters' : undefined }}
                                    />
                                )}
                                {keyboard == 'alfabet' && !caps && (
                                    <FlashList
                                        data={alphabet}
                                        estimatedItemSize={30}
                                        numColumns={10}
                                        horizontal={false}
                                        renderItem={renderSquareItem}
                                        initialScrollIndex={0}
                                        type={'grid'}
                                        style={{ flex: 1 }}
                                        showsHorizontalScrollIndicator={false}
                                        focusOptions={{ nextFocusDown: space.length ? 'space-letters' : undefined }}
                                    />
                                )}
                                {keyboard == 'specials' && (
                                    <FlashList
                                        data={special}
                                        estimatedItemSize={30}
                                        numColumns={10}
                                        horizontal={false}
                                        renderItem={renderSquareItem}
                                        initialScrollIndex={0}
                                        type={'grid'}
                                        style={{ flex: 1 }}
                                        showsHorizontalScrollIndicator={false}
                                        focusOptions={{ nextFocusDown: space.length ? 'space-letters' : undefined }}
                                    />
                                )}
                            </View>
                            <View
                                style={{
                                    height: 65,
                                    width: getRealWidth(deviceContext) * 0.7,
                                    marginRight: getCorrectWidth(deviceContext, 5),
                                }}
                                focusOptions={{ group: 'space-letters', allowFocusOutsideGroup: true, focusKey: 'space-letters' }}
                            >
                                {keyboard == 'alfabet' && caps && (
                                    <FlashList
                                        data={space}
                                        estimatedItemSize={60}
                                        numColumns={7}
                                        horizontal={false}
                                        renderItem={renderItem}
                                        initialScrollIndex={0}
                                        type={'grid'}
                                        style={{ flex: 1 }}
                                        showsHorizontalScrollIndicator={false}
                                        focusOptions={{ nextFocusUp: 'main-letters' }}
                                    />
                                )}
                                {keyboard == 'alfabet' && !caps && (
                                    <FlashList
                                        data={space}
                                        estimatedItemSize={60}
                                        numColumns={7}
                                        horizontal={false}
                                        renderItem={renderItem}
                                        initialScrollIndex={0}
                                        type={'grid'}
                                        style={{ flex: 1 }}
                                        showsHorizontalScrollIndicator={false}
                                        focusOptions={{ nextFocusUp: 'main-letters' }}
                                    />
                                )}
                            </View>
                        </View>
                        <View
                            style={{
                                flex: 1,
                                flexDirection: 'row',
                                marginRight: getCorrectWidth(deviceContext, 5),
                            }}
                        ></View>
                    </View>
                </View>
            </View>
        </Screen>
    );
};

export { KeyboardWrapper };
export default Keyboard_;
