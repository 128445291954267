import React from 'react';
import { ActivityIndicator, View } from '@scriptx-com/xtv-toolkit';
import { CommonActions } from '@react-navigation/native';
import moment from 'moment';
import { useContext, useState } from 'react';
import { PlayerContext } from '@theoplayer/react-native-ui';
import { AppContext } from '../context/appContext';
import { ContentContext } from '../context/contentContext';
import { DeviceContext } from '../context/deviceContext';
import { SettingsContext } from '../context/settingsContext';
import { UserContext } from '../context/userContext';
import utils from '../general/utils';
import { useEffectOnce } from '../hooks/useEffectOnce';
import lang from '../languages/languages';
import { getCorrectHeight } from '../styling/correctSizes';
import { getFontSize } from '../styling/fontSizes';
import { getRealHeight, getRealWidth } from '../styling/realDeviceSizes';
import { getUserIp, updateDevicesJson } from './account';
import { loadContent, reloadEpg } from './content';
import { getCurrentScreenName } from '../hooks/getScreenName';
import Text from '../builder/components/text';
import { getGlobals } from '../../globals';

export interface Props {
    navigation: any;
}

export const Reload = ({ navigation }: Props) => {
    const contentContext = useContext(ContentContext);
    const appContext = useContext(AppContext);
    const userContext = useContext(UserContext);
    const deviceContext = useContext(DeviceContext);
    const playerContext = useContext(PlayerContext);
    const settingsContext = useContext(SettingsContext);
    const [showLoader, setShowLoader] = useState(false);
    const screenName = getCurrentScreenName();

    const reloadCustomer = async () => {
        if (userContext.accountLoadedTimestamp + 3600 < moment().unix()) {
            getUserIp(userContext.userId, userContext.pass, appContext, userContext, deviceContext, userContext, playerContext, true).then((result) => {
                if (result != 'success') {
                    settingsContext.setShowMainMenu(false);

                    userContext.setAutoLogin(false);
                    utils.storeJson('AutoLogin', false);

                    if (appContext.application.settings.enable_delete_userdata == true || deviceContext.isSmartTV == true) {
                        userContext.setAppId('');
                        utils.storeJson('ServiceID', '');

                        userContext.setUserId('');
                        utils.storeJson('UserID', '');
                        userContext.setPass('');
                        utils.storeJson('Pass', '');
                    }
                    if (appContext.application.settings?.enable_nested == true) {
                        navigation.dispatch(
                            CommonActions.reset({
                                index: 1,
                                routes: [{ name: 'Services' }],
                            })
                        );
                    } else {
                        if (appContext.application?.settings.enable_anonymous != true) {
                            navigation.dispatch(
                                CommonActions.reset({
                                    index: 1,
                                    routes: [{ name: 'Login' }],
                                })
                            );
                        } else {
                            navigation.dispatch(
                                CommonActions.reset({
                                    index: 1,
                                    routes: [{ name: 'Anonymous' }],
                                })
                            );
                        }
                    }
                } else {
                    //success ik denk we moeten alles opnieuw neerzetten??
                    // if (data.wallet != undefined && data.wallet.enabled && data.wallet.balance != undefined) {
                    //     userContext.setWalletBalance(data.wallet.balance);
                    // }
                }
            });
        }
    };
    const reloadDevices = async () => {
        let path =
            utils.getCorrectStoragePrefix(appContext, false) +
            'users/' +
            utils.toAlphaNumeric(userContext.userId).split('').join('/') +
            '/devices.json?t=' +
            moment().unix();
        let response = await fetch(path);
        if (response.status == 200) {
            let data = await response.json();
            if (data != undefined) {
                var test = data.find((d) => d.uuid == deviceContext.uniqueId);
                if (test?.logout != undefined && test.logout == true) {
                    var devices = data.find((d) => d.uuid != deviceContext.uniqueId);
                    userContext.setDevices(devices);
                    updateDevicesJson(appContext.application, userContext, devices);
                    settingsContext.setShowMainMenu(false);
                    userContext.setAutoLogin(false);
                    utils.storeJson('AutoLogin', false);
                    if (appContext.application.settings?.enable_nested == true) {
                        navigation.dispatch(
                            CommonActions.reset({
                                index: 1,
                                routes: [{ name: 'Services' }],
                            })
                        );
                    } else {
                        if (appContext.application?.settings.enable_anonymous != true) {
                            navigation.dispatch(
                                CommonActions.reset({
                                    index: 1,
                                    routes: [{ name: 'Login' }],
                                })
                            );
                        } else {
                            navigation.dispatch(
                                CommonActions.reset({
                                    index: 1,
                                    routes: [{ name: 'Anonymous' }],
                                })
                            );
                        }
                    }
                } else {
                    userContext.setDevices(data);
                }
            }
        }
    };

    const reloadContent = async () => {
        await loadContent(appContext, contentContext, userContext).then((result) => {});
    };
    const reloadEPG = async () => {
        await reloadEpg(appContext, contentContext, userContext).then((result) => {});
    };

    useEffectOnce(() => {
        async function fetchData() {
            if (!deviceContext.isKaiOs) {
                if (screenName == 'Home' || screenName == 'TV Guide' || screenName == 'Channels') {
                    const date = moment().add(0, 'day').format('DD') + '-' + Number(moment().add(0, 'day').month() + 1) + '-' + moment().add(0, 'day').year();
                    if (contentContext.epgLoadedDate != date) {
                        console.log('reload epg');
                        setShowLoader(true);
                        await reloadEPG();
                        contentContext.setEpgLoadedDate(date);
                        setShowLoader(false);
                    }

                    if (contentContext.dataLoadedTimestamp + 86400 < moment().unix()) {
                        if (screenName == 'Home') {
                            console.log('reload customer & devices from server');
                            await reloadDevices();
                            await reloadCustomer();
                        }
                        console.log('reload content');
                        setShowLoader(true);
                        await reloadContent();
                        contentContext.setDataLoadedTimestamp(moment().unix());
                        setShowLoader(false);
                    }
                }
            }
        }
        fetchData();
    });

    if (showLoader) {
        return (
            <View
                style={{
                    position: 'absolute',
                    zIndex: 9999,
                    width: getRealWidth(deviceContext),
                    height: getRealHeight(deviceContext),
                    backgroundColor: 'rgba(0, 0, 0, 0.50)',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <View
                    style={{
                        backgroundColor: '#000',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: getCorrectHeight(deviceContext, 20),
                        paddingVertical: getCorrectHeight(deviceContext, 20),
                        borderColor: '#333',
                        borderWidth: 2,
                        borderRadius: 5,
                    }}
                >
                    <ActivityIndicator color={'#999'} size={'large'} style={{ marginBottom: getCorrectHeight(deviceContext, 10) }}></ActivityIndicator>
                    <Text
                        style={{
                            color: '#999',
                            fontFamily: deviceContext.fontType,
                            fontSize: getFontSize(deviceContext, 'Large'),
                            fontWeight: 'bold',
                        }}
                    >
                        {lang.getTranslation(userContext, 'refresh_content')}
                    </Text>
                </View>
            </View>
        );
    } else {
        return null;
    }
};
export default Reload;
