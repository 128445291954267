import * as React from 'react';
import { View } from '@scriptx-com/xtv-toolkit';
import { getFontSize } from '../../../application/styling/fontSizes';
import lang from '../../../application/languages/languages';
import { DeviceContext } from '../../../application/context/deviceContext';
import { UserContext } from '../../../application/context/userContext';
import ScreenWrapper from '../../wrapper';
import { AppContext } from '../../../application/context/appContext';
import { getCorrectHeight } from '../../../application/styling/correctSizes';
import { CommonActions, useFocusEffect } from '@react-navigation/native';
import moment from 'moment';
import { sendPageReport } from '../../../reporting/apis/page';
import { actionType, pageType } from '../../../reporting/models/apps';
import { getGlobals } from '../../../globals';
import Text from '../../../application/builder/components/text';
import { getFocusStyling, getPressableStyling, getPressableTextStyling } from '../../../application/builder/components/helpers/helper';
import Pressable from '../../../application/builder/components/pressable/pressable';
import { getRealWidth } from '../../../application/styling/realDeviceSizes';
import { getUserIp } from '../../../application/data/account';
import { PlayerContext } from '../../../application/context/playerContext';
import { sendActionReport } from '../../../reporting/apis/action';
import utils from '../../../application/general/utils';
import { ActivityIndicator, Platform } from 'react-native';
import { getQueryString } from '../../../application/app/helpers/getQueryString';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faMobile } from '@fortawesome/fontawesome-pro-light';
import UdpSocket from 'react-native-udp/lib/types/UdpSocket.js';
import { useEffectOnce } from '../../../application/hooks/useEffectOnce';

const Screen_ = ({ navigation, route, focusContext }): React.ReactElement => {
    const appContext = React.useContext(AppContext);
    const deviceContext = React.useContext(DeviceContext);
    const userContext = React.useContext(UserContext);
    const playerContext = React.useContext(PlayerContext);
    const [code, setCode] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const [socket, setSocket] = React.useState<UdpSocket>();

    let QRCode = global.QRCode;
    let server;

    React.useEffect(() => {
        getCode();
        return () => {
            socket && socket.close();
            if (deviceContext.isIos) {
                server.setBroadcast(true);
            }
        };
    }, [navigation, route]);

    useEffectOnce(() => {
        async function myAsynFunction() {
            server = global.dgram.createSocket({ type: 'udp4', reusePort: true });
            server.bind(8887);
            setSocket(server);
            const serverIp = await global.NetworkInfo.getIPV4Address();

            global.NetworkInfo.getGatewayIPAddress().then((ssid) => {
                setTimeout(() => {
                    var splitGateway = ssid.split('.');
                    var baseGateway = splitGateway[0] + '.' + splitGateway[1] + '.' + splitGateway[2] + '.';
                    server.send(JSON.stringify({ type: 'authentication', ipaddress: serverIp }), undefined, undefined, 8887, '255.255.255.255', (error) => {});
                }, 1000);
            });
            server.on('message', (data, rinfo) => {
                var incoming = JSON.parse(data);
                if (incoming.type == 'login') {
                    setIsLoading(true);
                    userContext.setUserId(incoming.userid);
                    userContext.setPass(incoming.pass);
                    if (incoming.appid != undefined && incoming.appid != '') {
                        userContext.setAppId(incoming.appid);
                        checkAppId(incoming.appid, incoming.userid, incoming.pass);
                    } else {
                        loginUser(incoming.userid, incoming.pass);
                    }
                }
            });
            server.on('listening', function (msg, rinfo) {
                if (Platform.OS === 'ios') {
                    setTimeout(() => {
                        server.setBroadcast(true);
                    }, 500);
                }
            });
        }
        if (!deviceContext.isWebTV && !deviceContext.isTizen && !deviceContext.isWebos) {
            myAsynFunction();
        }
    });

    const getCode = async () => {
        await fetch(
            getGlobals().AppApiUrl +
                '/auth/code?client=' +
                encodeURIComponent(appContext.application.client) +
                '&deployment=' +
                encodeURIComponent(appContext.application.deployment) +
                '&service=' +
                encodeURIComponent(appContext.application.branding.service),
            {
                method: 'GET',
            }
        )
            .then((response) => response.json())
            .then((response) => {
                setCode(response.code);
                pingCode(response.code);
            })
            .catch((error) => {
            });
    };

    const pingCode = async (code) => {
        await fetch(
            getGlobals().AppApiUrl +
                '/auth/ping?code=' +
                code +
                '&client=' +
                encodeURIComponent(appContext.application.client) +
                '&deployment=' +
                encodeURIComponent(appContext.application.deployment) +
                '&service=' +
                encodeURIComponent(appContext.application.branding.service),
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            }
        )
            .then((response) => response.json())
            .then((response) => {
                if (response.userid != undefined) {
                    setIsLoading(true);
                    userContext.setUserId(response.userid);
                    userContext.setPass(response.pass);
                    if (response.appid != undefined && response.appid != '') {
                        userContext.setAppId(response.appid);
                        checkAppId(response.appid, response.userid, response.pass);
                    } else {
                        loginUser(response.userid, response.pass);
                    }
                } else {
                    setTimeout(() => {
                        pingCode(code);
                    }, 3000);
                }
            });
    };

    const checkAppId = async (appId, userid, pass) => {
        sendActionReport(appContext, deviceContext, userContext, actionType.SELECT_SERVICE);
        var test = appContext.application.settings.nested_apps.find((s) => s.appid == appId);
        if (test != undefined || appId == appContext.application.branding.appid) {
            userContext.setOrginalPackageId(getGlobals().AppPackageID);
            fetchData(test.packageid, appId, userid, pass);
        }
    };
    const fetchData = async (packageId, appId, userid, pass) => {
        const path = 'https://cloudtv.akamaized.net/donotremove/tvms/domains/' + packageId + '/app.json?' + moment().unix();
        await fetch(path)
            .then((response) => response.json())
            .then(async (responseJson) => {
                global.Application = responseJson;
                var pathCart = utils.getCorrectStoragePrefix(appContext, true) + responseJson.client + '/' + responseJson.deployment + '/' + responseJson.branding.service + '/carts/' + responseJson.settings.webshop_id + '_cart.json';
                await fetch(pathCart)
                    .then((responseCart) => responseCart.json())
                    .then((responseCartJson) => {
                        global.Cart = responseCartJson;
                        utils.storeJson('ServiceID', appId);
                        userContext.setAppId(appId);
                        appContext.setApplication(global.Application);
                        loginUser(userid, pass);
                    })
                    .catch(() => {
                        utils.storeJson('ServiceID', appId);
                        userContext.setAppId(appId);
                        appContext.setApplication(global.Application);
                        loginUser(userid, pass);
                    });
            });
    };

    const loginUser = async (userid, pass) => {
        sendActionReport(appContext, deviceContext, userContext, actionType.LOGIN);
        var result = await getUserIp(userid, pass, appContext, userContext, deviceContext, userContext, playerContext, false);
        if (result != 'success') {
        } else {
            if (deviceContext.isWebTV) {
                var webTvUrl = window.location.href;
                var queryStrings = getQueryString(webTvUrl) as any;
                if (webTvUrl.indexOf('connect=true') > -1 || queryStrings?.connect != undefined) {
                    navigation && navigation.navigate('LoginCode');
                } else {
                    startContentLoader();
                }
            } else {
                startContentLoader();
            }
        }
    };

    const startContentLoader = () => {
        navigation.dispatch(
            CommonActions.reset({
                index: 1,
                routes: [{ name: 'Loader' }],
            })
        );
    };

    useFocusEffect(
        React.useCallback(() => {
            const start = moment().unix();
            return () => {
                let duration = moment().unix() - start;
                sendPageReport(appContext, deviceContext, userContext, pageType.CONNECT, duration);
            };
        }, [])
    );

    const goBack = () => {
        navigation.dispatch(
            CommonActions.reset({
                index: 1,
                routes: [{ name: 'Login' }],
            })
        );
    };

    return (
        <ScreenWrapper style={{ flex: 1 }} focusOptions={{ focusKey: 'screen' }}>
            <View style={{ flex: 1 }}>
                {!isLoading && (
                    <View style={{ flex: 4, margin: 10 }}>
                        {deviceContext.isSmartTV && (
                            <Text
                                style={{
                                    alignSelf: 'center',
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                    fontWeight: 'bold',
                                    color: appContext.application.authentication?.connect?.header.title_color,
                                    margin: getCorrectHeight(deviceContext, 10),
                                    marginBottom: getCorrectHeight(deviceContext, 20),
                                }}
                            >
                                {lang.getTranslation(userContext, 'two_ways')}
                            </Text>
                        )}
                        {!deviceContext.isSmartTV && (
                            <Text
                                style={{
                                    alignSelf: 'center',
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                    fontWeight: 'bold',
                                    color: appContext.application.authentication?.connect?.header.title_color,
                                    margin: getCorrectHeight(deviceContext, 10),
                                    marginBottom: getCorrectHeight(deviceContext, 20),
                                }}
                            >
                                {lang.getTranslation(userContext, 'three_ways')}
                            </Text>
                        )}
                        <View style={{ flex: 1, flexDirection: 'row' }}>
                            <View style={{ flex: 1, margin: 30 }}>
                                <Text
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Large'),
                                        ...getPressableTextStyling(appContext.application.authentication.connect.components?.button?._id, appContext),
                                        marginBottom: getCorrectHeight(deviceContext, 20),
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'on_web')}
                                </Text>
                                <Text
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        ...getPressableTextStyling(appContext.application.authentication.connect.components?.button?._id, appContext),
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'on_web_1_part1')}
                                    {appContext.application.settings.webtv}?connect=true
                                    {lang.getTranslation(userContext, 'on_web_1_part2')}
                                </Text>
                                <Text
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        ...getPressableTextStyling(appContext.application.authentication.connect.components?.button?._id, appContext),
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'on_web_2')}
                                </Text>
                                <Text
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        ...getPressableTextStyling(appContext.application.authentication.connect.components?.button?._id, appContext),
                                        marginBottom: getCorrectHeight(deviceContext, 10),
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'on_web_3')}
                                </Text>
                                <Text
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                        ...getPressableTextStyling(appContext.application.authentication.connect.components?.button?._id, appContext),
                                        marginBottom: getCorrectHeight(deviceContext, 10),
                                    }}
                                >
                                    {code}
                                </Text>
                                <Text
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        ...getPressableTextStyling(appContext.application.authentication.connect.components?.button?._id, appContext),
                                        marginBottom: getCorrectHeight(deviceContext, 10),
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'when_connected')}
                                </Text>
                            </View>

                            <View>
                                <View style={{ flex: 1, alignItems: 'center' }}>
                                    <View
                                        style={{
                                            flex: 1,
                                            width: 1,
                                            borderLeftColor: appContext.application.authentication?.connect?.header.title_color,
                                            borderLeftWidth: 1,
                                        }}
                                    ></View>
                                </View>
                                <Text
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                        fontWeight: 'bold',
                                        color: appContext.application.authentication?.connect?.header.title_color,
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'or')}
                                </Text>
                                <View style={{ flex: 1, alignItems: 'center' }}>
                                    <View
                                        style={{
                                            flex: 1,
                                            width: 1,
                                            borderLeftColor: appContext.application.authentication?.connect?.header.title_color,
                                            borderLeftWidth: 1,
                                        }}
                                    ></View>
                                </View>
                            </View>

                            <View style={{ flex: 1, margin: 30 }}>
                                <Text
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Large'),
                                        ...getPressableTextStyling(appContext.application.authentication.connect.components?.button?._id, appContext),

                                        marginBottom: getCorrectHeight(deviceContext, 20),
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'scan_qr')}
                                </Text>
                                <Text
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        ...getPressableTextStyling(appContext.application.authentication.connect.components?.button?._id, appContext),
                                        marginBottom: getCorrectHeight(deviceContext, 10),
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'scan_qr_1')}
                                </Text>
                                <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                                    <View
                                        style={{
                                            marginVertical: getCorrectHeight(deviceContext, 20),
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            backgroundColor: 'white',
                                            padding: getCorrectHeight(deviceContext, 10),
                                        }}
                                    >
                                        {deviceContext.isWebTV == false && <QRCode value={appContext.application.settings.webtv + '?connect=true'} />}
                                    </View>
                                </View>
                                <Text
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        ...getPressableTextStyling(appContext.application.authentication.connect.components?.button?._id, appContext),
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'scan_qr_2')}
                                </Text>
                            </View>
                            {!deviceContext.isSmartTV && (
                                <View>
                                    <View style={{ flex: 1, alignItems: 'center' }}>
                                        <View
                                            style={{
                                                flex: 1,
                                                width: 1,
                                                borderLeftColor: appContext.application.authentication?.connect?.header.title_color,
                                                borderLeftWidth: 1,
                                            }}
                                        ></View>
                                    </View>
                                    <Text
                                        style={{
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                            fontWeight: 'bold',
                                            color: appContext.application.authentication?.connect?.header.title_color,
                                        }}
                                    >
                                        {lang.getTranslation(userContext, 'or')}
                                    </Text>
                                    <View style={{ flex: 1, alignItems: 'center' }}>
                                        <View
                                            style={{
                                                flex: 1,
                                                width: 1,
                                                borderLeftColor: appContext.application.authentication?.connect?.header.title_color,
                                                borderLeftWidth: 1,
                                            }}
                                        ></View>
                                    </View>
                                </View>
                            )}
                            {!deviceContext.isSmartTV && (
                                <View style={{ flex: 1, margin: 30 }}>
                                    <Text
                                        style={{
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Large'),
                                            ...getPressableTextStyling(appContext.application.authentication.connect.components?.button?._id, appContext),
                                            marginBottom: getCorrectHeight(deviceContext, 20),
                                        }}
                                    >
                                        {lang.getTranslation(userContext, 'on_your_phone')}
                                    </Text>
                                    <Text
                                        style={{
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                            ...getPressableTextStyling(appContext.application.authentication.connect.components?.button?._id, appContext),
                                        }}
                                    >
                                        {lang.getTranslation(userContext, 'on_your_phone_info')}
                                    </Text>
                                    <View style={{ marginVertical: getCorrectHeight(deviceContext, 20), justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                                        <FontAwesomeIcon icon={faMobile as never} color={'white'} size={getFontSize(deviceContext, 'Mega') * 1.5}></FontAwesomeIcon>
                                    </View>

                                    <Text
                                        style={{
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                            ...getPressableTextStyling(appContext.application.authentication.connect.components?.button?._id, appContext),
                                            marginBottom: getCorrectHeight(deviceContext, 10),
                                            marginTop: getCorrectHeight(deviceContext, 10),
                                        }}
                                    >
                                        {lang.getTranslation(userContext, 'when_connected')}
                                    </Text>
                                </View>
                            )}
                        </View>

                        <View style={{ flexDirection: 'row', justifyContent: 'center', marginBottom: 30, marginTop: getCorrectHeight(deviceContext, 20) }}>
                            <Pressable
                                style={{
                                    marginTop: 10,
                                    width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.8 : 0.3),
                                    height: getCorrectHeight(deviceContext, 30),
                                    ...getPressableStyling(appContext.application.authentication.connect.components?.button?._id, appContext),
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                                animatorOptions={getFocusStyling('Buttons', appContext)}
                                onPress={() => goBack()}
                            >
                                <View>
                                    <Text
                                        style={{
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                            ...getPressableTextStyling(appContext.application.authentication.connect.components?.button?._id, appContext),
                                        }}
                                    >
                                        {lang.getTranslation(userContext, 'back')}
                                    </Text>
                                </View>
                            </Pressable>
                        </View>
                    </View>
                )}
                {isLoading && (
                    <View
                        style={{
                            flex: 4,
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignContent: 'center',
                            alignItems: 'center',
                            alignSelf: 'center',
                            paddingBottom: deviceContext.isPhone ? 100 : 10,
                        }}
                    >
                        <ActivityIndicator size={'large'} color={'#fff'}></ActivityIndicator>
                    </View>
                )}
            </View>
        </ScreenWrapper>
    );
};
export default Screen_;
