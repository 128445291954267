import * as React from 'react';
import { View, withParentContextMapper, Image } from '@scriptx-com/xtv-toolkit';
import { getFontSize } from '../../../styling/fontSizes';
import Gradient from 'react-native-linear-gradient';
import Pressable from '../../components/pressable/pressable';
import {  getCorrectWidth } from '../../../styling/correctSizes';
import { favoriteStatus } from '../../../data/account';
import { UserContext } from '../../../context/userContext';
import { DeviceContext } from '../../../context/deviceContext';
import { AppContext } from '../../../context/appContext';
import Text from '../../components/text';
import { getFocusStyling, getPressableStyling } from '../../components/helpers/helper';
import { getCorrectName } from '../players/helpers/functions';
import utils from '../../../general/utils';

export interface Program {
    n: string;
    s: any;
    e: any;
}
export interface Data {
    name: string;
    number: string;
    images: {
        widescreen?: string;
        square?: string;
        tile?: string;
        background?: string;
        poster?: string;
        logo?: string;
    };
    _id: string;
    styling: any;
    progress?: number;
    catchuptv?: boolean;

    program?: Program;
}

export interface Props {
    focusContext?: any;
    focusRepeatContext?: any;
    focusOptions?: any;
    noMargin?: boolean;
    height: number;
    width: number;
    onPress: any;
    styling: any;
    data: Data;
    type: string;
    rails?: boolean;
    elementType: string;
    onFocus?: () => void;
    onBlur?: () => void;
    isFirst?: boolean;
    isLast?: boolean;
}

const LinearGradient = withParentContextMapper(Gradient);

export const Genre = ({ focusContext, focusRepeatContext, noMargin, height, width, onPress, styling, type, data, elementType, ...rest }: Props) => {
    const userContext = React.useContext(UserContext);
    const deviceContext = React.useContext(DeviceContext);
    const appContext = React.useContext(AppContext);

    var favorite = favoriteStatus(type, data?._id, userContext);

    const getRibbonText = () => {
        var test = styling.ribbon.title.find((t) => t.language == userContext.selectedLanguage);
        if (test != undefined) {
            return test.title;
        } else {
            if (styling.content.title != undefined) {
                return styling.content.title[0].title;
            } else {
                return '';
            }
        }
    };
    const getCorrectScaleStyling = () => {
        return getFocusStyling(elementType, appContext).type.indexOf('scale') > -1 && elementType == 'Rails' && deviceContext.formFactor == 'Television';
    };

    if (data == undefined) {
        return null;
    } else {
        return (
            <View style={getCorrectScaleStyling() ? { height: height * 1.1, justifyContent: 'center' } : {}}>
                <Pressable
                    focusContext={focusContext}
                    focusRepeatContext={focusRepeatContext}
                    animatorOptions={getFocusStyling(elementType, appContext)}
                    onPress={() => onPress(data?._id)}
                    style={[
                        {
                            ...getPressableStyling(styling.components?.button._id, appContext),
                            marginBottom: elementType == 'Rails' ? 0 : getCorrectWidth(deviceContext, deviceContext.isPhone || deviceContext.isPwaVertical ? 5 : 4),
                            marginRight: getCorrectWidth(deviceContext, 10),
                            width: width,
                            height: height,
                        },
                    ]}
                    onFocus={() => rest.onFocus?.()}
                    onBlur={() => rest.onBlur?.()}
                    focusOptions={rest.focusOptions}
                    isFirst={rest.isFirst}
                    isLast={rest.isLast}
                >
                    <View>
                        <View
                            style={{
                                width: width,
                                height: height,
                                borderRadius: getCorrectWidth(deviceContext, appContext.application.theme.images.radius),
                                justifyContent: 'center',
                                alignContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <View
                                style={{
                                    backgroundColor: 'transparent',
                                    width: width,
                                    height: height,
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <LinearGradient
                                    style={{
                                        margin: getCorrectWidth(deviceContext, appContext.application.theme.images.margin),
                                        backgroundColor: 'transparent',
                                        borderRadius: getCorrectWidth(deviceContext, appContext.application.theme.images.radius),
                                        width: width - getCorrectWidth(deviceContext, appContext.application.theme.images.margin * 2),
                                        height: height - getCorrectWidth(deviceContext, appContext.application.theme.images.margin * 2),
                                        justifyContent: 'center',
                                        alignContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    locations={[0, 1]}
                                    colors={[
                                        data?.styling?.background1 != null ? data?.styling?.background1 : getPressableStyling(styling.components?.button._id, appContext).backgroundColor,
                                        data?.styling?.background2 != null ? data?.styling?.background2 : getPressableStyling(styling.components?.button._id, appContext).backgroundColor,
                                    ]}
                                    start={{ x: 1, y: 0 }}
                                    end={{ x: 1, y: 1 }}
                                >
                                    {data?.styling?.image != '' && data?.styling?.image != undefined && (
                                        <Image
                                            source={{ uri: utils.getCorrectStoragePrefix(appContext, true, data?.styling?.image) }}
                                            resizeMethod={'scale'}
                                            resizeMode={'cover'}
                                            style={{
                                                borderRadius: getCorrectWidth(deviceContext, appContext.application.theme.images.radius),
                                                height: height,
                                                width: width,
                                            }}
                                        />
                                    )}

                                    <View
                                        style={{
                                            position: 'absolute',
                                            width: width,
                                            height: height,
                                            justifyContent: 'center',
                                            alignContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                color: styling.texts.title_color == '' ? '#ffffff' : styling.texts.title_color,
                                                fontWeight: styling.texts.title_bold == true ? 'bold' : 'normal',
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                                textShadowOffset: { width: -1, height: 1 },
                                                textShadowRadius: 2,
                                            }}
                                        >
                                            {getCorrectName(data, userContext)}
                                        </Text>
                                    </View>
                                </LinearGradient>
                            </View>
                        </View>
                    </View>
                </Pressable>
            </View>
        );
    }
};
export default Genre;
