import * as React from 'react';
import { BackHandler } from 'react-native';
import Modal from '../../../application/builder/components/modal/modal';
import Page from '../../../application/builder/pages/page';
import { AppContext } from '../../../application/context/appContext';
import { DeviceContext } from '../../../application/context/deviceContext';
import { useTVRemoteHandler } from '../../../application/hooks/useTVRemoteHandler';
import ScreenWrapper from '../../wrapper';
import { getFocusOptions, Ratio } from '../../wrapper/helpers/getFocusOptions';
import { getModalStyling } from '../../../application/builder/components/helpers/helper';
import { getNativeBackPress } from '../../wrapper/helpers/getNativeBackPress';
import { useFocusEffect } from '../../wrapper/useFocusEffect';
import closeApp from './helpers/index';
const Screen_ = ({ navigation, route, focusContext }): React.ReactElement => {
    const appContext = React.useContext(AppContext);
    const deviceContext = React.useContext(DeviceContext);
    const screen = appContext.application.screens.find((s) => s.type == 'Home');
    const data = [];
    const focusOptions = getFocusOptions(deviceContext, appContext, 'content-screen', 'Home');

    const [showExit, setShowExit] = React.useState(false);
    const showExitSync = React.useRef(false);
    const backCount = React.useRef(0);
    const disableNativeHardwareBackPress = () => {
        return getNativeBackPress();
    };

    React.useEffect(() => {
        const handler = BackHandler.addEventListener('hardwareBackPress', disableNativeHardwareBackPress);
        return () => {
            handler.remove();
            showExitSync.current = false;
            backCount.current = 0;
        };
    }, []);

    const popupHandler = (eventType) => {
        if (['back', 'menu'].includes(eventType) || deviceContext.isKaiOs) {
            backCount.current++;
            if (backCount.current == 1) {
                setShowExit(true);
                showExitSync.current = true;
            }
            if (backCount.current == 2) {
                if (deviceContext.isTizen) {
                    // @ts-expect-error
                    if (typeof tizen !== 'undefined') {
                        // @ts-expect-error
                        window.tizen.application.getCurrentApplication().exit();
                    }
                } else if (deviceContext.isWebos) {
                    // @ts-expect-error
                    if (typeof webOS !== 'undefined') {
                        // @ts-expect-error
                        webOS.platformBack();
                    }
                } else if (deviceContext.isKaiOs) {
                    window.close();
                } else {
                    closeApp();
                }
            }
        } else {
            if (showExitSync.current == true) {
                cancelExit();
            }
        }
    };

    useTVRemoteHandler(({ eventType, eventKeyAction }: any) => {
        if (!deviceContext.isWebTV && eventKeyAction === 'down') {
            popupHandler(eventType);
        }
    });
    useFocusEffect(
        React.useCallback(() => {
            const smartTVRemoteHandler = (event) => {
                const { key, keyCode } = event;
                if (keyCode == 413 || keyCode == 10009 || keyCode == 27 || keyCode == 461) {
                    if (screen.type == 'Home') {
                        backCount.current++;
                        if (backCount.current == 1) {
                            setShowExit(true);
                            showExitSync.current = true;
                        }
                        if (backCount.current == 2) {
                            if (deviceContext.isTizen) {
                                // @ts-expect-error
                                if (typeof tizen !== 'undefined') {
                                    // @ts-expect-error
                                    window.tizen.application.getCurrentApplication().exit();
                                }
                            } else if (deviceContext.isWebos) {
                                // @ts-expect-error
                                if (typeof webOS !== 'undefined') {
                                    // @ts-expect-error
                                    webOS.platformBack();
                                }
                            } else {
                                BackHandler.exitApp();
                            }
                        }
                    }
                } else {
                    if (showExitSync.current == true) {
                        cancelExit();
                    }
                }
            };

            if (deviceContext.isTizen || deviceContext.isWebos) {
                window.addEventListener('keydown', smartTVRemoteHandler);
            }
            return () => {
                if (deviceContext.isTizen || deviceContext.isWebos) {
                    window.removeEventListener('keydown', smartTVRemoteHandler);
                }
            };
        }, [])
    );

    const cancelExit = () => {
        setShowExit(false);
        showExitSync.current = false;
        backCount.current = 0;
    };
    //change the viewport from 160 to 300 cause the rails where hidden in the top
    return (
        <ScreenWrapper style={{ flex: 1 }} focusOptions={{ ...focusOptions, focusKey: 'content-screen', stealFocus: true, ignoreInitialFocus: false, ignoreAutoFocusOnViewRemove: true, verticalViewportOffset: Ratio(230) }}>
            {showExit && <Modal focusContext={focusContext} styling={getModalStyling(appContext.application.theme.modals.exit, appContext)} type={'exit'} submitChoice={cancelExit} timerTime={3} />}
            <Page
                navigation={navigation}
                route={route}
                screen={screen}
                data={data}
                type={'Home'}
                onExit={popupHandler} //needed for kaios
            />
        </ScreenWrapper>
    );
};
export default Screen_;
