import { CreateListRenderItemInfo, FlashList, Image, Screen, ScrollView, View } from '@scriptx-com/xtv-toolkit';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { CommonActions } from '@react-navigation/native';
import * as React from 'react';
import { useState } from 'react';
import Pressable from '../builder/components/pressable/pressable';
import { AppContext } from '../context/appContext';
import { DeviceContext } from '../context/deviceContext';
import { SettingsContext } from '../context/settingsContext';
import { UserContext } from '../context/userContext';
import utils from '../general/utils';
import LANG from '../languages/languages';
import { getNativeBackPress } from '../../screens/wrapper/helpers/getNativeBackPress';
import Stacks from '../stacks/StackSelector';
import { getCorrectHeight, getCorrectWidth } from '../styling/correctSizes';
import { getFontSize } from '../styling/fontSizes';
import { getMenuIcon } from '../styling/menuIcon';
import { FlashList as FlashList_ } from '@scriptx-com/flash-list';
import { getRealWidth } from '../styling/realDeviceSizes';
import Text from '../builder/components/text';
import { ContentContext } from '../context/contentContext';
import { getFocusStyling, getPressableStyling, getPressableTextStyling, getSelectedStyling } from '../builder/components/helpers/helper';
import { AddCachingTaskEvent, CacheEventType, MediaCache } from 'react-native-theoplayer';
import { CachingTaskView } from '../builder/components/downloadbutton/downloadbutton';
import { checkIfKnownMenuItem, checkIfMenuHasWebview } from './helpers/helpers';
import { actionType } from '../../reporting/models/apps/action';
import { sendActionReport } from '../../reporting/apis/action';
import { updateDevicesJson } from '../data/account';
import { faCoins } from '@fortawesome/pro-light-svg-icons';
import { colorShade } from '../styling/colorShade';
import { NetworkContext } from '../context/networkContext';
import { BackHandler } from 'react-native';

const Tab = createMaterialTopTabNavigator();

function MyTabBar({ state, descriptors, navigation }) {
    var menu = '';
    var styling = [] as any;
    var addTaskEvent = undefined;

    const settingsContext = React.useContext(SettingsContext);
    const appContext = React.useContext(AppContext);
    const userContext = React.useContext(UserContext);
    const deviceContext = React.useContext(DeviceContext);
    const contentContext = React.useContext(ContentContext);
    const networkContext = React.useContext(NetworkContext);
    const [activeSettings, setActiveSettings] = useState('');
    const rails = React.useRef<FlashList_<number> | null>(null);
    const [viewIndex, setViewIndex] = React.useState(0);
    const [viewableItemsCount, setViewableItemsCount] = React.useState(0);
    const [task, setTask] = useState([] as any);
    const [downloadStatus, setDownloadStatus] = useState('false');

    if (deviceContext.isWebTV && !deviceContext.isPwaVertical) {
        styling = appContext.application.navigators?.browser;
    } else if (deviceContext.isTablet) {
        styling = appContext.application.navigators?.tablet;
    } else if (deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical) {
        styling = appContext.application.navigators?.mobile;
    } else if (deviceContext.isTelevision) {
        styling = appContext.application.navigators?.television;
    }

    React.useEffect(() => {
        addTaskEvent = MediaCache.addEventListener(CacheEventType.addtask, (event: AddCachingTaskEvent) => {
            if (event.task.status == 'idle') {
                setTask(event.task);
                setDownloadStatus('loading');
            }
        });
        return () => {
            if (addTaskEvent) {
                MediaCache.removeEventListener(CacheEventType.addtask, addTaskEvent);
            }
        };
    }, []);

    React.useEffect(() => {
        const backAction = () => {
            if (!getNativeBackPress()) {
                if (tabsToForward.includes(contentContext.activeMenu)) {
                    contentContext.setActiveMenu((deviceContext.isPhone && networkContext.connected) || !deviceContext.isPhone ? 'Home' : 'Downloads');
                    setActiveSettings('');
                    navigation.dispatch(
                        CommonActions.reset({
                            index: 1,
                            routes: [{ name: (deviceContext.isPhone && networkContext.connected) || !deviceContext.isPhone ? 'Home' : 'Downloads' }],
                        })
                    );
                    return true;
                } else return false;
                return true;
            } else {
                return false;
            }
        };
        const backHandler = BackHandler.addEventListener('hardwareBackPress', backAction);

        settingsContext.setTabBarHeight(getCorrectHeight(deviceContext, 40));
        return backHandler.remove;
    }, [navigation, contentContext.activeMenu]);

    global.menuWidth = 0;
    global.settingsMenu = 200;
    global.menuHeight = 50;
    const tabsToForward = ['Manual', 'Subscription', 'Messages', 'Support', 'About', 'Devices', 'Updates', 'Disclaimer', 'Channels', 'TV Guide', 'Radio', 'Recordings', 'Cams', 'Movies', 'Shorts', 'Series', 'Music', 'Podcasts', 'Courses'];

    const onPress = (menu: any) => {
        if (menu != 'Account' && activeSettings == '') {
            if (checkIfKnownMenuItem(menu)) {
                contentContext.setActiveMenu(menu);
                navigation.dispatch(
                    CommonActions.reset({
                        index: 1,
                        routes: [{ name: menu }],
                    })
                );
            } else {
                if (menu !== 'Profiles') setActiveSettings(menu);
                contentContext.setActiveMenu(menu);
                navigation.dispatch(
                    CommonActions.reset({
                        index: 1,
                        routes: [{ name: menu }],
                    })
                );
            }
        } else {
            contentContext.setActiveMenu(menu);
            if (menu == 'Back') {
                contentContext.setActiveMenu((deviceContext.isPhone && networkContext.connected) || !deviceContext.isPhone ? 'Home' : 'Downloads');
                setActiveSettings('');
                navigation.dispatch(
                    CommonActions.reset({
                        index: 1,
                        routes: [{ name: (deviceContext.isPhone && networkContext.connected) || !deviceContext.isPhone ? 'Home' : 'Downloads' }],
                    })
                );
            } else if (menu == 'Account') {
                setActiveSettings(menu);
                contentContext.setActiveMenu('Manual');
                navigation.dispatch(
                    CommonActions.reset({
                        index: 1,
                        routes: [{ name: 'Settings' }],
                    })
                );
            } else if (menu == 'Switch Profile' || menu === 'Profiles') {
                setActiveSettings('');
                settingsContext.setShowMainMenu(false);
                navigation.dispatch(
                    CommonActions.reset({
                        index: 1,
                        routes: [{ name: 'Profiles' }],
                    })
                );
            } else if (menu == 'Logout') {
                settingsContext.setShowMainMenu(false);
                setActiveSettings('');

                userContext.setAutoLogin(false);
                utils.storeJson('AutoLogin', false);

                userContext.setAllProfiles([]);

                //delete current device
                var devices = userContext.devices;
                sendActionReport(appContext, deviceContext, userContext, actionType.DELETE_DEVICE);
                var index = devices.findIndex((d) => d.uuid == deviceContext.uniqueId);
                if (index != -1) {
                    devices.splice(index, 1);
                    updateDevicesJson(appContext, userContext, devices);
                }

                setTimeout(() => {
                    if (appContext.application.settings.enable_delete_userdata == true || deviceContext.isSmartTV == true) {
                        userContext.setAppId('');
                        utils.storeJson('ServiceID', '');
                        userContext.setSelectedLanguage('');
                        utils.storeJson('Selected_Language', '');
                        userContext.setUserId('');
                        utils.storeJson('UserID', '');
                        userContext.setPass('');
                        utils.storeJson('Pass', '');
                    }

                    if (appContext.application.settings?.enable_nested == true) {
                        contentContext.setActiveMenu('Services');
                        navigation.dispatch(
                            CommonActions.reset({
                                index: 1,
                                routes: [{ name: 'Services' }],
                            })
                        );
                    } else if (appContext.application?.settings.enable_anonymous == true) {
                        contentContext.setActiveMenu('Anonymous');
                        navigation.dispatch(
                            CommonActions.reset({
                                index: 1,
                                routes: [{ name: 'Anonymous' }],
                            })
                        );
                    } else {
                        contentContext.setActiveMenu('Login');
                        navigation.dispatch(
                            CommonActions.reset({
                                index: 1,
                                routes: [{ name: 'Login' }],
                            })
                        );
                    }
                }, 1000);
            } else if (menu == 'Refresh') {
                setActiveSettings('');
                settingsContext.setShowMainMenu(false);
                contentContext.setActiveMenu('Loader');
                setActiveSettings('');
                navigation.dispatch(
                    CommonActions.reset({
                        index: 1,
                        routes: [{ name: 'Loader' }],
                    })
                );
            } else {
                contentContext.setActiveMenu(menu);
                navigation.dispatch(
                    CommonActions.reset({
                        index: 1,
                        routes: [{ name: menu }],
                    })
                );
            }
        }
    };

    var menuArray = [] as any;
    const menuItems = (deviceContext.isPhone && networkContext.connected) || !deviceContext.isPhone ? appContext.application.menus : [{ name: 'Loader' }, { name: 'Downloads' }, { name: 'Player' }];
    menuItems.forEach((element) => {
        if (
            element.name != 'Logout' &&
            element.name != 'Services' &&
            element.name != 'Disclaimer' &&
            element.name != 'Signin' &&
            element.name != 'Forgot' &&
            element.name != 'Check' &&
            element.name != 'Player' &&
            element.name != 'Profiles' &&
            element.name != 'Languages' &&
            element.name != 'Settings' &&
            element.name != 'Messages' &&
            element.name != 'Loader' &&
            element.name != 'Support'
        ) {
            if (
                element.name == 'Search' ||
                (userContext.userId.indexOf('_review') > -1 && element.name == 'Downloads') ||
                (!deviceContext.isPhone && !deviceContext.isTablet && !deviceContext.isWebTV && element.name == 'Downloads') ||
                (userContext.userId.indexOf('_review') > -1 && element.name == 'Recordings') ||
                (element.name == 'Games' && !deviceContext.isWebTV && !deviceContext.isPhone && !deviceContext.isTablet) ||
                (checkIfMenuHasWebview(appContext, element.name) && !deviceContext.isWebTV && !deviceContext.isPhone && !deviceContext.isTablet)
            ) {
                //do nothing
            } else {
                var menu_ = { name: element.name, menu: element.name };
                menuArray.push(menu_);
            }
        }
    });
    var first = 'Search';
    menuArray.sort(function (x, y) {
        return x.menu == first ? -1 : y.menu == first ? 1 : 0;
    });
    var first = 'Switch Profile';
    menuArray.sort(function (x, y) {
        return x.menu == first ? -1 : y.menu == first ? 1 : 0;
    });

    if (menuArray.length > 1 && menu == '') {
        menu = menuArray[1].menu;
    }
    const scrollRight = () => {
        rails.current?.scrollToIndex({ animated: true, index: viewIndex + 1 });
    };

    const scrollLeft = () => {
        const next = Math.max(0, viewIndex - viewableItemsCount);
        rails.current?.scrollToIndex({ animated: true, index: next });
    };

    const onViewableItemsChanged = ({ viewableItems, changed }) => {
        if (viewableItems.length !== viewableItemsCount) {
            setViewableItemsCount(viewableItems.length);
        }
        if (Array.isArray(changed)) {
            let visibleItemIndex;
            changed.forEach((item) => {
                if (item.isViewable) {
                    visibleItemIndex = item.index;
                }
            });
            if (visibleItemIndex !== undefined) {
                setViewIndex(visibleItemIndex);
            }
        }
    };
    const renderItem = ({ item, focusRepeatContext, index }: CreateListRenderItemInfo<any>) => {
        return (
            <Pressable style={{ marginRight: 5 }} animatorOptions={getFocusStyling('Buttons', appContext)} key={index} onPress={() => onPress(item.menu)}>
                <>
                    {downloadStatus == 'loading' && item.menu == 'Downloads' && (
                        <View style={{ position: 'absolute', zIndex: 999, left: getCorrectWidth(deviceContext, 15), top: getCorrectWidth(deviceContext, 7), marginRight: getCorrectWidth(deviceContext, 5) }}>
                            <CachingTaskView size={'Large'} setDownloadStatus={setDownloadStatus} task={task} debug={false} deviceContext={deviceContext} appContext={appContext}></CachingTaskView>
                        </View>
                    )}
                    {contentContext.activeMenu != item.menu && (
                        <View
                            style={{
                                margin: 5,
                                height: getCorrectHeight(deviceContext, 30) - 10,
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                paddingHorizontal: getCorrectWidth(deviceContext, 15),
                                flexDirection: deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical ? 'column' : 'row',
                                ...getPressableStyling(styling.components?.button?._id, appContext),
                            }}
                        >
                            {<FontAwesomeIcon size={getFontSize(deviceContext, 'Normal') * 1.4} style={{ color: appContext.application.theme.icons.background }} icon={getMenuIcon(item.menu, userContext)} />}

                            <Text
                                numberOfLines={1}
                                style={{
                                    paddingTop: deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical ? 5 : 0,
                                    paddingLeft: 10,
                                    paddingRight: deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical ? 10 : 0,
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                    ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                }}
                            >
                                {LANG.getTranslation(userContext, item.name)}
                            </Text>
                        </View>
                    )}
                    {contentContext.activeMenu == item.menu && (
                        <View
                            style={{
                                margin: 5,
                                height: getCorrectHeight(deviceContext, 30) - 10,
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                paddingHorizontal: getCorrectWidth(deviceContext, 15),
                                flexDirection: deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical ? 'column' : 'row',
                                backgroundColor: getSelectedStyling('Navigators', appContext).background_color,
                                borderRadius: getPressableStyling(styling.components?.button?._id, appContext).borderRadius,
                            }}
                        >
                            {<FontAwesomeIcon size={getFontSize(deviceContext, 'Normal') * 1.4} style={{ color: getSelectedStyling('Navigators', appContext).text_color }} icon={getMenuIcon(item.menu, userContext)} />}

                            <Text
                                numberOfLines={1}
                                style={{
                                    paddingTop: deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical ? 5 : 0,
                                    paddingLeft: 10,
                                    paddingRight: deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical ? 10 : 0,
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                    ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                    color: getSelectedStyling('Navigators', appContext).text_color,
                                }}
                            >
                                {LANG.getTranslation(userContext, item.name)}
                            </Text>
                        </View>
                    )}
                </>
            </Pressable>
        );
    };
    if (settingsContext.showMainMenu) {
        return (
            <Screen focusOptions={{ stealFocus: false }}>
                <View style={{ flex: 1, flexDirection: 'row', backgroundColor: styling.general.background_color1 }}>
                    <View
                        style={{
                            marginRight: getCorrectWidth(deviceContext, 5),
                            justifyContent: 'center',
                            alignItems: deviceContext.isPhone ? 'center' : 'flex-start',
                            marginLeft: deviceContext.isPhone ? 0 : getCorrectWidth(deviceContext, 15),
                        }}
                    >
                        <Image
                            style={{ width: getCorrectWidth(deviceContext, 100) * 0.7, height: getCorrectHeight(deviceContext, 25) * 0.7 }}
                            resizeMethod={'scale'}
                            resizeMode={'contain'}
                            source={{ uri: appContext.application.branding.logos.logo }}
                        ></Image>
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
                        {menuArray.length > viewableItemsCount && (
                            <Pressable animatorOptions={getFocusStyling('Buttons', appContext)} onPress={() => scrollLeft()} style={{ justifyContent: 'center' }}>
                                <FontAwesomeIcon size={getFontSize(deviceContext, 'Normal') * 1.8} style={{ color: '#fff' }} icon={faChevronLeft} />
                            </Pressable>
                        )}
                        <View
                            style={{
                                width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, deviceContext.isPhone ? 150 : 200) * 0.9 - getCorrectWidth(deviceContext, deviceContext.isPhone ? 150 : 250) * 0.9,
                            }}
                        >
                            <FlashList
                                ref={rails}
                                type="row"
                                onViewableItemsChanged={onViewableItemsChanged}
                                extraData={menuArray}
                                data={menuArray}
                                estimatedItemSize={getCorrectWidth(deviceContext, 200)}
                                numColumns={1}
                                horizontal={true}
                                showsHorizontalScrollIndicator={false}
                                renderItem={renderItem}
                                initialScrollIndex={0}
                                style={{ flex: 1 }}
                            />
                        </View>
                        {menuArray.length > viewableItemsCount && (
                            <Pressable animatorOptions={getFocusStyling('Buttons', appContext)} onPress={() => scrollRight()} style={{ justifyContent: 'center' }}>
                                <FontAwesomeIcon size={getFontSize(deviceContext, 'Normal') * 1.8} style={{ color: '#fff' }} icon={faChevronRight} />
                            </Pressable>
                        )}
                    </View>
                    <View style={{ justifyContent: 'flex-end', flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
                        {!deviceContext.isPhone && (
                            <Pressable style={{ marginRight: 15 }} animatorOptions={getFocusStyling('Buttons', appContext)} onPress={() => onPress('Account')}>
                                <View
                                    style={{
                                        width: getCorrectHeight(deviceContext, 30) - 10,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: getCorrectHeight(deviceContext, 30) - 10,

                                        flexDirection: deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical ? 'column' : 'row',
                                        ...getPressableStyling(styling.components?.button?._id, appContext),
                                        backgroundColor: colorShade(getPressableStyling(styling.components?.button?._id, appContext).backgroundColor, 95),
                                        borderRadius: 100,
                                    }}
                                >
                                    <FontAwesomeIcon size={getFontSize(deviceContext, 'Normal') * 1.4} style={{ color: appContext.application.theme.icons.background }} icon={getMenuIcon('Account', userContext)} />
                                </View>
                            </Pressable>
                        )}
                        {!deviceContext.isPhone && userContext.userId.indexOf('_review') == -1 && (
                            <Pressable style={{ marginRight: 5 }} animatorOptions={getFocusStyling('Buttons', appContext)} onPress={() => onPress('Search')}>
                                <View
                                    style={{
                                        height: getCorrectHeight(deviceContext, 30) - 10,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: getCorrectHeight(deviceContext, 30) - 10,

                                        flexDirection: deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical ? 'column' : 'row',
                                        ...getPressableStyling(styling.components?.button?._id, appContext),
                                        backgroundColor: colorShade(getPressableStyling(styling.components?.button?._id, appContext).backgroundColor, 95),
                                        borderRadius: 100,
                                    }}
                                >
                                    <FontAwesomeIcon size={getFontSize(deviceContext, 'Normal') * 1.4} style={{ color: appContext.application.theme.icons.background }} icon={getMenuIcon('Search', userContext)} />
                                </View>
                            </Pressable>
                        )}

                        {!deviceContext.isPhone && appContext.application.settings.enable_profiles != false && (
                            <View style={{ marginRight: 5 }}>
                                <Pressable style={{ marginRight: 5 }} animatorOptions={getFocusStyling('Buttons', appContext)} onPress={() => onPress('Profiles')}>
                                    <View
                                        style={{
                                            height: getCorrectHeight(deviceContext, 30) - 10,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '100%',

                                            paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                            flexDirection: deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical ? 'column' : 'row',
                                            ...getPressableStyling(styling.components?.button?._id, appContext),
                                        }}
                                    >
                                        <Image
                                            resizeMethod={'scale'}
                                            resizeMode={'cover'}
                                            source={{
                                                uri: userContext.profile.avatar,
                                            }}
                                            style={{
                                                width: getCorrectWidth(deviceContext, 30) - 10,
                                                height: getCorrectWidth(deviceContext, 30) - 10,
                                                borderRadius: 100,
                                                borderColor: colorShade(getPressableStyling(styling.components?.button?._id, appContext).backgroundColor, 95),
                                                borderWidth: deviceContext.isKaiOs ? getCorrectWidth(deviceContext, 2) : getCorrectWidth(deviceContext, 1),
                                            }}
                                        ></Image>
                                    </View>
                                </Pressable>
                            </View>
                        )}
                        {!deviceContext.isPhone && userContext?.product?.settings?.wallet_enabled && (
                            <View style={{ marginRight: 25 }}>
                                <Pressable animatorOptions={getFocusStyling('Buttons', appContext)} onPress={() => onPress('Wallet')}>
                                    <View
                                        style={{
                                            flexDirection: 'row',
                                            backgroundColor: colorShade(getPressableStyling(styling.components?.button?._id, appContext).backgroundColor, 95),
                                            borderRadius: getPressableStyling(styling.components?.button?._id, appContext).borderRadius,
                                            padding: getCorrectWidth(deviceContext, 6),
                                        }}
                                    >
                                        <FontAwesomeIcon size={getFontSize(deviceContext, 'Normal') * 1.4} style={{ color: appContext.application.theme.icons.background }} icon={faCoins} />
                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                fontFamily: deviceContext.fontType,
                                                paddingLeft: getCorrectWidth(deviceContext, 5),
                                                paddingRight: getCorrectWidth(deviceContext, 2),
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                            }}
                                        >
                                            {userContext.walletBalance}
                                        </Text>
                                    </View>
                                </Pressable>
                            </View>
                        )}
                    </View>
                </View>
            </Screen>
        );
    }

    return null;
}
export default () => {
    return (
        <Tab.Navigator tabBar={(props) => <MyTabBar {...props} />}>
            <Tab.Screen name="AppStack" component={Stacks} />
        </Tab.Navigator>
    );
};
