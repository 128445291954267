import * as React from 'react';
import { useState, Dispatch } from 'react';
import { Albums } from '../models/content/albums/albums';
import { Album_Bundles } from '../models/content/albums/bundles';
import { Cam_Bundles } from '../models/content/cams/bundles';
import { Cams } from '../models/content/cams/cams';
import { Channel_Bundles } from '../models/content/channels/bundles';
import { Channels } from '../models/content/channels/channels';
import { Course_Bundles } from '../models/content/courses/bundles';
import { Courses } from '../models/content/courses/courses';
import { Lessons } from '../models/content/courses/lessons';
import { Movie_Bundles } from '../models/content/movies/bundles';
import { Movies } from '../models/content/movies/movies';
import { Shorts_Bundles } from '../models/content/shorts/bundles';
import { Shorts } from '../models/content/shorts/shorts';
import { Podcast_Bundles } from '../models/content/podcasts/bundles';
import { Podcasts } from '../models/content/podcasts/podcasts';
import { Radio_Bundles } from '../models/content/radio/bundles';
import { Radio } from '../models/content/radio/radio';
import { Series_Bundles } from '../models/content/series/bundles';
import { Series_Episodes } from '../models/content/series/episodes';
import { Seasons } from '../models/content/series/seasons';
import { Series } from '../models/content/series/series';
import { Epg_Data, Program } from '../models/content/epg/epg_data';
import { getTimeline } from './helpers/timeline';
import { Album_Songs } from '../models/content/albums/songs';
import { Podcast_Episodes } from '../models/content/podcasts/episodes';
import { Games } from '../models/content/games/games';

interface ContentContext {
    catchuptvData: {
        program: Program;
        programIndex: number;
    };
    setCatchupTVData: Dispatch<any>;

    // catchuptv: CatchupTV
    // setCatchupTV: Dispatch<any>;

    channelData: {
        channel: Channels;
        channelCategoryIndex: number;
        channelListCategoryIndex: number;
        channelIndex: number;
        channelList: Channels[];

        program: Program;
        programIndex: number;

        programList: Program[];
        programListIndex: number;

        channelListList?: Channels[];
        localCategoryIndex: number;
    };
    setChannelData: Dispatch<any>;

    camData: {
        cam: Cams;
        program: Program;
        camCategoryIndex: number;
        camIndex: number;
        camList: Cams[];
        programIndex: number;
        programList: Program[];

        camListList?: Cams[];
        localCategoryIndex: number;
    };
    setCamData: Dispatch<any>;

    radioData: {
        radio: Radio;
        program: Program;
        radioCategoryIndex: number;
        radioIndex: number;
        radioList: Radio[];
        programIndex: number;
        programList: Program[];

        radioListList?: Radio[];
        localCategoryIndex: number;
    };
    setRadioData: Dispatch<any>;

    movieData: {
        movie: Movies;
        movieCategoryIndex: number;
        movieIndex: number;
        localCategoryIndex: number;
    };
    setMovieData: Dispatch<any>;

    shortData: {
        short: Shorts;
        shortCategoryIndex: number;
        shortIndex: number;
        shortList: Shorts[];
        localCategoryIndex: number;
    };
    setShortData: Dispatch<any>;

    gameData: {
        game: Games;
        gameCategoryIndex: number;
        gameIndex: number;
        gameList: Games[];
        localCategoryIndex: number;
    };
    setGameData: Dispatch<any>;

    courseData: {
        course: Courses;
        courseCategoryIndex: number;
        courseIndex: number;
        lesson: Lessons;
        lessonIndex: number;
        //lessons: Lessons[]
        lessonList: Lessons[];
        localCategoryIndex: number;
    };
    setCourseData: Dispatch<any>;

    seriesData: {
        series: Series;
        seriesCategoryIndex: number;
        seriesIndex: number;

        seasons: Seasons[];
        season: Seasons;
        seasonCategoryIndex: number;

        //episodes: Series_Episodes[]
        episodeList: Series_Episodes[];
        episode: Series_Episodes;
        episodeIndex: number;
        localCategoryIndex: number;
    };
    setSeriesData: Dispatch<any>;

    podcastData: {
        podcast: Podcasts;
        podcastCategoryIndex: number;
        podcastIndex: number;
        podcastList: Podcasts[];
        episodeIndex: number;
        episode: Podcast_Episodes;
        episodes: Podcast_Episodes[];
        localCategoryIndex: number;
    };
    setPodcastData: Dispatch<any>;

    albumData: {
        album: Albums;
        albumCategoryIndex: number;
        albumIndex: number;

        albumList?: Albums[];
        songIndex?: number;
        songs?: Album_Songs[];
        song?: Album_Songs;
        localCategoryIndex: number;
    };
    setAlbumData: Dispatch<any>;

    recordingData: {
        program: any;
        channel: Channels;
        programIndex: number;
    };
    setRecordingData: Dispatch<any>;

    epgLoadedDate: string;
    setEpgLoadedDate: Dispatch<any>;

    dataLoadedTimestamp: number;
    setDataLoadedTimestamp: Dispatch<any>;

    searchRef: React.MutableRefObject<string>;
    search: string;
    setSearch: Dispatch<any>;
    searching: boolean;
    setSearching: Dispatch<any>;
    sort: string;
    setSort: Dispatch<any>;

    moviesTags: any;
    setMoviesTags: Dispatch<any>;
    seriesTags: any;
    setSeriesTags: Dispatch<any>;

    actors: any;
    setActors: Dispatch<any>;

    drmIrdetoAccountID: string;
    setDrmIrdetoAccountID: Dispatch<any>;
    drmCertificateUrl: string;
    setDrmCertificateUrl: Dispatch<any>;
    drmLicenseServerUrl: string;
    setDrmLicenseServerUrl: Dispatch<any>;
    drmKeyServerUrl: string;
    setDrmKeyServerUrl: Dispatch<any>;

    // profile: any;
    // setProfile: Dispatch<any>;
    // profiles: any;
    // setProfiles: Dispatch<any>;

    favorites: any;
    setFavorites: Dispatch<any>;
    watching: any;
    setWatching: Dispatch<any>;
    recordings: any;
    setRecordings: Dispatch<any>;

    epgTv: Epg_Data[];
    setEpgTv: Dispatch<any>;
    epgTvExtra: Epg_Data[];
    setEpgTvExtra: Dispatch<any>;
    epgOffSetDay: number;
    setEpgOffSetDay: Dispatch<any>;
    epgRadio: Epg_Data[];
    setEpgRadio: Dispatch<any>;
    epgCam: Program[];
    setEpgCam: Dispatch<any>;

    channels: Channel_Bundles;
    setChannels: Dispatch<any>;
    cams: Cam_Bundles;
    setCams: Dispatch<any>;
    radios: Radio_Bundles;
    setRadios: Dispatch<any>;
    movies: Movie_Bundles;
    setMovies: Dispatch<any>;
    shorts: Shorts_Bundles;
    setShorts: Dispatch<any>;
    podcasts: Podcast_Bundles;
    setPodcasts: Dispatch<any>;
    albums: Album_Bundles;
    setAlbums: Dispatch<any>;
    series: Series_Bundles;
    setSeries: Dispatch<any>;
    courses: Course_Bundles;
    setCourses: Dispatch<any>;
    games: Course_Bundles;
    setGames: Dispatch<any>;

    hero: any;
    setHero: Dispatch<any>;

    activeMenu: any;
    setActiveMenu: Dispatch<any>;

    disableScroll: boolean;
    setDisableScroll: Dispatch<boolean>;

    nestedCategorySubIndex: number;
    setNestedCategorySubIndex: Dispatch<number>;
}
export const ContentContext = React.createContext<ContentContext>(null);

export function ContentProvider({ children }: { children: any }) {
    const [epgLoadedDate, setEpgLoadedDate] = useState('');
    const [dataLoadedTimestamp, setDataLoadedTimestamp] = useState(0);
    const [nestedCategorySubIndex, setNestedCategorySubIndex] = useState(0);

    const searchRef = React.useRef('');
    const [search, setSearch] = useState('');
    const [searching, setSearching] = useState(false);
    const [sort, setSort] = useState('asc');

    const [moviesTags, setMoviesTags] = useState([] as any);
    const [seriesTags, setSeriesTags] = useState([] as any);

    const [actors, setActors] = useState([] as any);

    const [epgTv, setEpgTv] = useState([] as any);
    const [epgTvExtra, setEpgTvExtra] = useState([] as any);
    const [epgOffSetDay, setEpgOffSetDay] = useState(0);
    const [epgRadio, setEpgRadio] = useState([] as any);
    const [epgCam, setEpgCam] = useState<Program[]>(getTimeline);

    const [drmIrdetoAccountID, setDrmIrdetoAccountID] = useState('');
    const [drmCertificateUrl, setDrmCertificateUrl] = useState('');
    const [drmLicenseServerUrl, setDrmLicenseServerUrl] = useState('');
    const [drmKeyServerUrl, setDrmKeyServerUrl] = useState('');

    // const [profile, setProfile] = useState([] as any);
    // const [profiles, setProfiles] = useState([] as any);

    const [favorites, setFavorites] = useState([] as any);
    const [watching, setWatching] = useState([] as any);
    const [recordings, setRecordings] = useState([] as any);

    const [channels, setChannels] = useState([] as any);
    const [cams, setCams] = useState([] as any);
    const [radios, setRadios] = useState([] as any);
    const [podcasts, setPodcasts] = useState([] as any);
    const [albums, setAlbums] = useState([] as any);
    const [movies, setMovies] = useState([] as any);
    const [shorts, setShorts] = useState([] as any);
    const [series, setSeries] = useState([] as any);
    const [courses, setCourses] = useState([] as any);
    const [games, setGames] = useState([] as any);
    const [disableScroll, setDisableScroll] = useState(false);

    const [activeMenu, setActiveMenu] = useState('Home');

    const [catchuptvData, setCatchupTVData] = useState([] as any);
    const [channelData, setChannelData] = useState({
        channel: [] as any,
        channelCategoryIndex: 0,
        channelListCategoryIndex: 0,
        channelIndex: 0,
        channelList: [] as any,
        program: [] as any,
        programIndex: 0,
        programList: [] as any,
        programListIndex: 0,
        channelListList: [] as any,
        localCategoryIndex: 0,
    });
    const [camData, setCamData] = useState({
        cam: [] as any,
        program: [] as any,
        camCategoryIndex: 0,
        camIndex: 0,
        camList: [] as any,
        programIndex: 0,
        programList: [] as any,
        camListList: [] as any,
        localCategoryIndex: 0,
    });
    const [radioData, setRadioData] = useState({
        radio: [] as any,
        program: [] as any,
        radioCategoryIndex: 0,
        radioIndex: 0,
        radioList: [] as any,
        programIndex: 0,
        programList: [] as any,
        radioListList: [] as any,
        localCategoryIndex: 0,
    });
    const [movieData, setMovieData] = useState({
        movie: [] as any,
        movieCategoryIndex: 0,
        movieIndex: 0,
        localCategoryIndex: 0,
    });
    const [shortData, setShortData] = useState({
        short: [] as any,
        shortCategoryIndex: 0,
        shortIndex: 0,
        shortList: [] as any,
        localCategoryIndex: 0,
    });
    const [gameData, setGameData] = useState({
        game: [] as any,
        gameCategoryIndex: 0,
        gameIndex: 0,
        gameList: [] as any,
        localCategoryIndex: 0,
    });
    const [seriesData, setSeriesData] = useState({
        series: [] as any,
        seriesCategoryIndex: 0,
        seriesIndex: 0,
        seasons: [] as any,
        season: [] as any,
        seasonCategoryIndex: 0,
        episodeList: [] as any,
        episode: [] as any,
        episodeIndex: 0,
        localCategoryIndex: 0,
    });
    const [albumData, setAlbumData] = useState({
        album: [] as any,
        albumCategoryIndex: 0,
        albumIndex: 0,
        albumList: [] as any,
        songIndex: 0,
        songs: [] as any,
        song: [] as any,
        localCategoryIndex: 0,
    });
    const [podcastData, setPodcastData] = useState({
        podcast: [] as any,
        podcastCategoryIndex: 0,
        podcastIndex: 0,
        podcastList: [] as any,
        episodeIndex: 0,
        episode: [] as any,
        episodes: [] as any,
        localCategoryIndex: 0,
    });
    const [courseData, setCourseData] = useState({
        course: [] as any,
        courseCategoryIndex: 0,
        courseIndex: 0,
        lesson: [] as any,
        lessonIndex: 0,
        lessonList: [] as any,
        localCategoryIndex: 0,
    });
    const [recordingData, setRecordingData] = useState([] as any);
    const [hero, setHero] = useState([] as any);

    return (
        <ContentContext.Provider
            value={{
                catchuptvData,
                setCatchupTVData,
                channelData,
                setChannelData,
                camData,
                setCamData,
                radioData,
                setRadioData,
                movieData,
                setMovieData,
                shortData,
                setShortData,
                seriesData,
                setSeriesData,
                albumData,
                setAlbumData,
                podcastData,
                setPodcastData,
                courseData,
                setCourseData,
                recordingData,
                setRecordingData,
                gameData,
                setGameData,

                epgLoadedDate,
                setEpgLoadedDate,
                dataLoadedTimestamp,
                setDataLoadedTimestamp,

                searchRef,
                search,
                setSearch: (v) => {
                    searchRef.current = v;
                    setSearch(v);
                },
                searching,
                setSearching,

                seriesTags,
                setSeriesTags,
                moviesTags,
                setMoviesTags,

                actors,
                setActors,

                epgTv,
                setEpgTv,
                epgTvExtra,
                setEpgTvExtra,
                epgOffSetDay,
                setEpgOffSetDay,
                epgRadio,
                setEpgRadio,
                epgCam,
                setEpgCam,

                sort,
                setSort,

                // profile,
                // setProfile,
                // profiles,
                // setProfiles,

                favorites,
                setFavorites,
                watching,
                setWatching,
                recordings,
                setRecordings,

                drmIrdetoAccountID,
                setDrmIrdetoAccountID,
                drmCertificateUrl,
                setDrmCertificateUrl,
                drmLicenseServerUrl,
                setDrmLicenseServerUrl,
                drmKeyServerUrl,
                setDrmKeyServerUrl,

                channels,
                setChannels,
                cams,
                setCams,
                radios,
                setRadios,
                movies,
                setMovies,
                shorts,
                setShorts,
                podcasts,
                setPodcasts,
                albums,
                setAlbums,
                series,
                setSeries,
                courses,
                setCourses,
                games,
                setGames,

                hero,
                setHero,

                activeMenu,
                setActiveMenu,

                disableScroll,
                setDisableScroll,

                nestedCategorySubIndex,
                setNestedCategorySubIndex,
            }}
        >
            {children}
        </ContentContext.Provider>
    );
}

// export function useUser() {
//     const context = React.useContext(ContentContext);
//     return context;
// }
