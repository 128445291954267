import { CreateListRenderItemInfo, FlashList, FocusContext, Image, Pressable, View } from '@scriptx-com/xtv-toolkit';
import moment from 'moment';
import * as React from 'react';
import { ActivityIndicator } from 'react-native';
import { ContentContext } from '../../../../application/context/contentContext';
import { getCorrectHeight, getCorrectWidth } from '../../../../application/styling/correctSizes';
import { getFontSize } from '../../../../application/styling/fontSizes';
import { AppContext } from '../../../context/appContext';
import { DeviceContext } from '../../../context/deviceContext';
import { SettingsContext } from '../../../context/settingsContext';
import { UserContext } from '../../../context/userContext';
import { getEpgDataOtherDate } from '../../../data/content';
import { useEffectOnce } from '../../../hooks/useEffectOnce';
import { useGridHeightCalculator } from '../../../hooks/useGridHeightCalculator';
import lang from '../../../languages/languages';
import { TV_Guide_TV_Guide as styling } from '../../../models/elements/tvguide/tvguide/tvguide';
import { getRealHeight, getRealWidth } from '../../../styling/realDeviceSizes';
import Dropdown_ from '../../components/dropdown/dropdown';
import { getDropdownStyling, getFocusStyling, getPressableStyling, getPressableTextStyling } from '../../components/helpers/helper';
import Pressable_ from '../../components/pressable/pressable';
import Text from '../../components/text';
import { getCorrectCategories, getCorrectName } from '../players/helpers/functions';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faEllipsisVertical } from '@fortawesome/pro-light-svg-icons';
import { filterAgeRatings, getGridData } from '../grids/helpers/functions';
import utils from '../../../general/utils';

export interface Data {
    image: string;
}

export interface Props {
    styling: styling;
    navigation: any;
    focusContext?: FocusContext;
}

const DEFAULT_TAB_BAR_HEIGHT = 50;

export const TVGuide_Vertical = ({ styling, navigation, focusContext }: Props) => {
    const contentContext = React.useContext(ContentContext);
    const deviceContext = React.useContext(DeviceContext);
    const appContext = React.useContext(AppContext);
    const userContext = React.useContext(UserContext);
    const settingsContext = React.useContext(SettingsContext);
    const [data, setData] = React.useState([]);
    const [dates, setDates] = React.useState([] as any);
    const [selectedDate, setSelectedDate] = React.useState(+new Date());
    const [offsetIndex, setOffsetIndex] = React.useState(0);
    const [offsetDay, setOffsetDay] = React.useState(0);
    const dropdown1Opened = React.useRef(false);
    const dropdown2Opened = React.useRef(false);

    const [loaded, setLoaded] = React.useState(false);
    const { onLayout, targetRef, gridHeight } = useGridHeightCalculator();

    var screenWidth = getRealWidth(deviceContext);

    const channelsCategoriesEpg = (epgtv, channels) => {
        var epg = [] as any;
        channels.forEach((channel) => {
            var epgFind = epgtv.find((e) => e.i == channel._id);
            if (epgFind != undefined) {
                epg.push(epgFind);
            }
        });
        return epg;
    };

    useEffectOnce(() => {
        async function myAsynFunction() {
            var items = await getGridData(userContext, 'Channels', contentContext, appContext);
            items = filterAgeRatings(items, userContext, appContext);
            if (items != undefined && items.length > 0) {
                setData(channelsCategoriesEpg(contentContext.epgTv, items));
            } else {
                setData(null);
            }
            setLoaded(true);
        }
        myAsynFunction();
    });

    const addDays = (date, days) => {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    };

    React.useEffect(() => {
        var max = 3;
        var min = (appContext.application.settings.epg_rentention - 1) * -1;
        var dates = [];
        const date = new Date();
        for (var i = min; i < max; i++) {
            dates.push({
                date: addDays(date, i),
            });
        }
        setDates(dates);
    }, []);

    const showChannelPrograms = (channel_id, channel_index, epg) => {
        navigation &&
            navigation.navigate('Programs', {
                channel_id: channel_id,
                styling: styling,
                offsetDay: offsetDay,
                channel_index: channel_index,
                epg: epg,
            });
    };

    const onSelectCategory = (e, i) => {
        setLoaded(false);
        contentContext.setChannelData({
            ...contentContext.channelData,
            channelList: contentContext.channels.categories[i].channels,
            channelCategoryIndex: i,
            channelListCategoryIndex: i,
            localCategoryIndex: i,
        });
        const filteredChannels = filterAgeRatings(contentContext.channels.categories[i].channels, userContext, appContext);
        if (offsetDay == 0) {
            setData(channelsCategoriesEpg(contentContext.epgTv, filteredChannels));
        } else {
            setData(channelsCategoriesEpg(contentContext.epgTvExtra, filteredChannels));
        }
        setLoaded(true);
    };
    const onSelectDate = async (e, i) => {
        if (e == selectedDate) {
            return;
        }
        setLoaded(false);
        setSelectedDate(e);
        var day = moment(e).diff(moment(), 'days');
        setOffsetDay(day);
        const filteredChannels = filterAgeRatings(contentContext.channels.categories[contentContext.channelData.channelCategoryIndex].channels, userContext, appContext);
        if (day != 0) {
            await getEpgDataOtherDate(appContext, contentContext, userContext, day).then((result) => {
                if (result) {
                    setTimeout(() => {
                        contentContext.setEpgTvExtra(result);
                        contentContext.setEpgOffSetDay(day);
                        setData(channelsCategoriesEpg(result, filteredChannels));
                        setLoaded(true);
                    }, 1000);
                }
            });
        } else {
            contentContext.setEpgOffSetDay(0);
            setData(channelsCategoriesEpg(contentContext.epgTv, contentContext.channels.categories[contentContext.channelData.channelCategoryIndex].channels));
            setLoaded(true);
        }
    };

    const renderItem = ({ item, focusRepeatContext, index }: CreateListRenderItemInfo<any>) => {
        var epg = item.epg;
        var current = moment().add(offsetDay, 'days').unix();
        var epgCurrentIndex = epg.findIndex((e) => e.s <= current && e.e >= current);
        const currentProgram = epg[epgCurrentIndex];
        const programLength = epg[epgCurrentIndex] != undefined ? epg[epgCurrentIndex].e - current : 0;

        return (
            <View
                style={{
                    borderRadius: getPressableStyling(styling.components?.button?._id, appContext).borderRadius,
                    marginRight: getCorrectWidth(deviceContext, 10),
                    flex: 1,
                    marginBottom: getCorrectHeight(deviceContext, 3),
                    flexDirection: 'row',
                    backgroundColor: 'rgba(0, 0, 0, 0.80)',
                }}
            >
                <Pressable_ animatorOptions={getFocusStyling('Buttons', appContext)} onPress={() => showChannelPrograms(item.i, index, item.epg)}>
                    <View style={{ flexDirection: 'row' }}>
                        <View style={{ margin: getCorrectWidth(deviceContext, 10), justifyContent: 'center' }}>
                            <Image
                                deviceContext={deviceContext}
                                source={{ uri: utils.getCorrectStoragePrefix(appContext, true, item.m) }}
                                resizeMethod={'scale'}
                                resizeMode={'cover'}
                                style={{
                                    height: getCorrectWidth(deviceContext, 60),
                                    width: getCorrectWidth(deviceContext, 60),
                                }}
                            />
                        </View>
                        <View>
                            <View style={{ flex: 1, height: getCorrectWidth(deviceContext, 100), justifyContent: 'center', alignContent: 'center', alignSelf: 'center' }}>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, 120),
                                        fontWeight: 'bold',
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        color: '#ffffff',
                                    }}
                                >
                                    {item.d}. {item.n}
                                </Text>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, 120),
                                        fontWeight: 'bold',
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        color: '#ffffff',
                                    }}
                                >
                                    {currentProgram != undefined ? currentProgram.n : ''}
                                </Text>

                                <Text
                                    numberOfLines={1}
                                    style={{
                                        fontWeight: 'normal',
                                        fontSize: getFontSize(deviceContext, 'Small'),
                                        color: '#ffffff',
                                    }}
                                >
                                    {currentProgram != undefined ? moment.unix(currentProgram?.s).format(deviceContext.clockSetting) + ' - ' + moment.unix(currentProgram?.e).format(deviceContext.clockSetting) : ''}
                                </Text>
                                {offsetDay == 0 && (
                                    <Text
                                        numberOfLines={1}
                                        style={{
                                            fontWeight: 'normal',
                                            fontSize: getFontSize(deviceContext, 'Small'),
                                            color: '#999999',
                                        }}
                                    >
                                        {currentProgram != undefined ? Math.round(programLength / 60) + 'm ' + lang.getTranslation(userContext, 'left') : ''}
                                    </Text>
                                )}
                            </View>
                        </View>

                        <View style={{ flexDirection: 'row', width: getCorrectWidth(deviceContext, 20), justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                            <FontAwesomeIcon icon={faEllipsisVertical} color={'#ffffff'}></FontAwesomeIcon>
                        </View>
                    </View>
                </Pressable_>
            </View>
        );
    };

    return (
        <View style={[{ height: getRealHeight(deviceContext) - settingsContext.tabBarHeight }]} focusContext={focusContext} ref={targetRef} onLayout={onLayout}>
            {!loaded && (
                <View
                    style={{
                        height: getRealHeight(deviceContext) - getCorrectHeight(deviceContext, settingsContext.tabBarHeight),
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        alignSelf: 'center',
                    }}
                >
                    <ActivityIndicator size={'large'} color={'#fff'}></ActivityIndicator>
                </View>
            )}
            {loaded && (
                <View style={{ flex: 1 }}>
                    <View
                        style={{
                            flexDirection: 'row',
                            margin: getCorrectWidth(deviceContext, 5),
                            height: getCorrectHeight(deviceContext, 70),
                            zIndex: 999,
                        }}
                    >
                        <View
                            style={{
                                flex: deviceContext.isPhone || deviceContext.isPwaVertical ? 1 : undefined,
                            }}
                        >
                            <View style={{ zIndex: 9, flex: 1 }}>
                                <Dropdown_
                                    width={getRealWidth(deviceContext) - getCorrectWidth(deviceContext, 10)}
                                    setSelection={(item, index) => {
                                        contentContext.setDisableScroll(false);
                                        onSelectDate(item, index);
                                    }}
                                    height={getRealHeight(deviceContext) * 0.35}
                                    styling={getDropdownStyling(styling.components?.dropdown, appContext)}
                                    animation={getFocusStyling('Dropdowns', appContext)}
                                    value={moment(selectedDate).format('ll')}
                                    dataIn={dates}
                                    type={'Dates'}
                                    onToggleDropdown={(val) => {
                                        if (val) {
                                            dropdown1Opened.current = true;
                                        } else {
                                            dropdown1Opened.current = false;
                                            if (dropdown2Opened.current) contentContext.setDisableScroll(true);
                                        }
                                    }}
                                />
                            </View>
                            <View style={{ marginVertical: getCorrectHeight(deviceContext, 3) }}></View>
                            <Dropdown_
                                width={getRealWidth(deviceContext) - getCorrectWidth(deviceContext, 10)}
                                setSelection={(item, index) => {
                                    if (!dropdown1Opened.current) contentContext.setDisableScroll(false);
                                    onSelectCategory(item, index);
                                }}
                                height={getRealHeight(deviceContext) * 0.35}
                                styling={getDropdownStyling(styling.components?.dropdown, appContext)}
                                animation={getFocusStyling('Dropdowns', appContext)}
                                value={getCorrectName(contentContext.channels.categories[contentContext.channelData.channelCategoryIndex], userContext)}
                                dataIn={getCorrectCategories(contentContext.channels, userContext)}
                                onToggleDropdown={(val) => {
                                    if (val) {
                                        dropdown2Opened.current = true;
                                    } else {
                                        dropdown2Opened.current = false;
                                        if (dropdown1Opened.current) contentContext.setDisableScroll(true);
                                    }
                                }}
                                type={'Categories'}
                            />
                        </View>
                    </View>

                    {data.length > 0 && (
                        <View
                            style={{
                                flex: 1,
                                width: screenWidth,

                                backgroundColor: styling.tvguide.backgroundColor,
                                marginLeft: getCorrectWidth(deviceContext, 5),
                                marginRight: getCorrectWidth(deviceContext, 5),
                                // marginTop: getCorrectHeight(deviceContext, 2),
                                paddingBottom: deviceContext.isIos ? getCorrectHeight(deviceContext, global.menuHeight + 5) : 0,
                            }}
                            focusContext={focusContext}
                        >
                            <FlashList
                                scrollEnabled={!contentContext.disableScroll}
                                extraData={offsetIndex}
                                data={data}
                                estimatedItemSize={getCorrectHeight(deviceContext, 70)}
                                horizontal={false}
                                numColumns={1}
                                showsHorizontalScrollIndicator={false}
                                renderItem={renderItem}
                                initialScrollIndex={0}
                                type={'grid'}
                                style={{ flex: 1 }}
                            />
                        </View>
                    )}
                </View>
            )}
        </View>
    );
};

export default TVGuide_Vertical;
