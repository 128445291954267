import { View, Image as NormalImage } from '@scriptx-com/xtv-toolkit';
import { getCorrectHeight, getCorrectWidth } from '../../../../styling/correctSizes';
import { getFocusStyling, getPressableStyling, getPressableTextStyling, getSelectedStyling } from '../../../components/helpers/helper';
import Pressable from '../../../components/pressable/pressable';
import Text from '../../../components/text/index';
import { getFontSize } from '../../../../styling/fontSizes';
import lang from '../../../../languages/languages';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { payperviewStatus, watchingStatus } from '../../../../data/account';
import moment from 'moment';
import { getRealWidth } from '../../../../styling/realDeviceSizes';
import { RemoteImageHeightWidth } from '../../../components/remoteimage/remoteimage';
import Image from '../../../components/image/image';
import React from 'react';
import { colorShade } from '../../../../styling/colorShade';
import { useSafeAreaInsets } from '../../../../../libs/react-native-safe-area-context';
import utils from '../../../../general/utils';

export const BackgroundImage = ({ item, program, type, styling, deviceContext, appContext, contentContext, fullWidth }) => {
    return (
        <>
            <View style={{ position: 'absolute', top: -100, zIndex: 0 }}>
                {type == 'Channels' && getProgramImage(program, item, styling, fullWidth, deviceContext, appContext, true)}
                {type == 'Movies' && getMovieImage(item, styling, fullWidth, deviceContext, appContext, true)}
                {type == 'Series' && getSeriesImage(item, styling, fullWidth, deviceContext, appContext, contentContext, true)}
                {type == 'Courses' && getCourseImage(item, styling, fullWidth, deviceContext, appContext, contentContext, true)}
                {type == 'Shorts' && getShortImage(item, deviceContext, styling, fullWidth, appContext, contentContext, true)}

                <View
                    style={{
                        position: 'absolute',
                        width: getRealWidth(deviceContext),
                        height: getRealWidth(deviceContext) * 1.5,
                        zIndex: 99,
                        alignItems: 'center',
                        top: getCorrectWidth(deviceContext, 15),
                    }}
                >
                    <Image
                        isLocal={true}
                        deviceContext={deviceContext}
                        source={require('../../../../../assets/images/blackish_mobile.png')}
                        resizeMode={'cover'}
                        style={{
                            width: getRealWidth(deviceContext),
                            height: getRealWidth(deviceContext) * 1.5,
                        }}
                    ></Image>
                </View>
            </View>
            <View
                style={{
                    position: 'absolute',
                    zIndex: 1,
                    marginTop: getCorrectHeight(deviceContext, fullWidth ? 0 : 10),
                }}
            >
                <View
                    style={{
                        width: getRealWidth(deviceContext),
                        display: 'flex',
                        justifyContent: 'center',
                        borderRadius: getPressableStyling(styling.components?.button?._id, appContext).borderRadius,
                        marginTop: getCorrectWidth(deviceContext, fullWidth ? 0 : 10),
                        marginLeft: fullWidth ? 0 : getCorrectWidth(deviceContext, 20),
                    }}
                >
                    {type == 'Channels' && getProgramImage(program, item, styling, fullWidth, deviceContext, appContext, false)}
                    {type == 'Movies' && getMovieImage(item, styling, fullWidth, deviceContext, appContext, false)}
                    {type == 'Series' && getSeriesImage(item, styling, fullWidth, deviceContext, appContext, contentContext, false)}
                    {type == 'Courses' && getCourseImage(item, styling, fullWidth, deviceContext, appContext, contentContext, false)}
                    {type == 'Shorts' && getShortImage(item, deviceContext, styling, fullWidth, appContext, contentContext, false)}
                </View>
            </View>
        </>
    );
};
export const PayPerViewStatus = ({ deviceContext, userContext, appContext, styling, item, type }) => {
    var isPayPerViewEnabled = item.payperview?.enable;
    var isPayPerView = payperviewStatus(type, item._id, userContext).isPayPerView;

    var remainingPayPerViewDays = 0;
    if (isPayPerView) {
        var now = moment().unix();
        var diff = now - payperviewStatus(type, item._id, userContext).timePayPerViewStart;
        remainingPayPerViewDays = item.payperview?.rule?.access_length - Math.round(moment.duration(diff, 'seconds').asDays());
        if (remainingPayPerViewDays <= 0) {
            isPayPerView = false;
        }
    }
    return (
        <View style={{ position: 'absolute', zIndex: 999, top: 0, margin: getCorrectWidth(deviceContext, 10) }}>
            {isPayPerViewEnabled && isPayPerView && (
                <View
                    style={{
                        height: getCorrectHeight(deviceContext, 40),
                        width: getCorrectHeight(deviceContext, 40),
                        ...getPressableStyling(styling.components?.button?._id, appContext),
                        backgroundColor: colorShade(getPressableStyling(styling.components?.button?._id, appContext).backgroundColor, 95),
                        borderRadius: 100,
                        justifyContent: 'center',
                    }}
                >
                    <Text
                        style={{
                            textAlign: 'center',
                            fontFamily: deviceContext.fontType,
                            fontSize: getFontSize(deviceContext, 'X-Small'),
                            ...getPressableTextStyling(styling.components?.button?._id, appContext),
                            fontWeight: 'bold',
                        }}
                    >
                        {remainingPayPerViewDays} {lang.getTranslation(deviceContext, 'days')}
                    </Text>
                    <Text
                        style={{
                            textAlign: 'center',
                            fontFamily: deviceContext.fontType,
                            fontSize: getFontSize(deviceContext, 'X-Small'),
                            ...getPressableTextStyling(styling.components?.button?._id, appContext),
                            fontWeight: 'bold',
                        }}
                    >
                        {lang.getTranslation(deviceContext, 'remaining')}
                    </Text>
                </View>
            )}
        </View>
    );
};
export const LogoRow = ({ item, program, type, fullWidth, deviceContext, appContext, styling }) => {
    if (type == 'Channels') {
        return (
            <View style={{ flexDirection: 'column', width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, fullWidth ? 40 : 80) }}>
                <View
                    style={{
                        backgroundColor: getPressableStyling(styling.components?.button?._id, appContext).backgroundColor,
                        borderRadius: 100,
                        flexDirection: 'row',
                        width: getCorrectWidth(deviceContext, 50),
                        height: getCorrectWidth(deviceContext, 50),
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Image
                        deviceContext={deviceContext}
                        resizeMethod={'scale'}
                        resizeMode={'cover'}
                        source={{ uri: utils.getCorrectStoragePrefix(appContext, true, item?.images?.square) }}
                        style={{
                            width: getCorrectWidth(deviceContext, 35),
                            height: getCorrectWidth(deviceContext, 35),
                        }}
                    ></Image>
                </View>
                <View>
                    {program?.l != undefined && program?.l != '' && (
                        <View style={{ justifyContent: 'center', marginVertical: getCorrectHeight(deviceContext, 5) }}>
                            <RemoteImageHeightWidth uri={program?.l} desiredWidth={getRealWidth(deviceContext) * 0.6} desiredHeight={0} deviceContext={deviceContext} />
                        </View>
                    )}
                    {(program?.l == undefined || program?.l == '') && (
                        <View style={{ justifyContent: 'center', marginTop: 5 }}>
                            <Text
                                numberOfLines={2}
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                    fontWeight: styling.texts.title_bold ? 'bold' : null,
                                    color: styling.texts.title_color == '' ? '#ffffff' : styling.texts.title_color,
                                    includeFontPadding: false,
                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 2,
                                }}
                            >
                                {program.n}
                            </Text>
                        </View>
                    )}
                </View>
            </View>
        );
    } else if (type == 'Movies' || type == 'Courses' || type == 'Shorts' || type == 'Series') {
        return (
            <View style={{ flexDirection: 'column', width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, 80) }}>
                <View>
                    {item.images.logo != undefined && item.images.logo != '' && (
                        <View style={{ justifyContent: 'center' }}>
                            <RemoteImageHeightWidth uri={utils.getCorrectStoragePrefix(appContext, true, item?.images.logo)} desiredWidth={getRealWidth(deviceContext) * 0.25} desiredHeight={50} deviceContext={deviceContext} />
                        </View>
                    )}
                    {(item.images.logo == undefined || item.images.logo == '') && (
                        <View style={{ justifyContent: 'center', marginTop: 5 }}>
                            <Text
                                numberOfLines={2}
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'ExtraExtraExtraLarge'),
                                    fontWeight: styling.texts.title_bold ? 'bold' : null,
                                    color: styling.texts.title_color == '' ? '#ffffff' : styling.texts.title_color,
                                    includeFontPadding: false,
                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 2,
                                }}
                            >
                                {item.name}
                            </Text>
                        </View>
                    )}
                </View>
            </View>
        );
    }
};
export const StatusTimeRow = ({ item, program, type, fullWidth, deviceContext, appContext, userContext, styling }) => {
    if (type == 'Channels') {
        var currentTime = moment().unix();
        var isLive = program.s < currentTime && program.e > currentTime;
        var isPast = program.s < currentTime && program.e < currentTime;
        return (
            <View style={{ flexDirection: 'column', width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, fullWidth ? 40 : 80) }}>
                <View style={{ flexDirection: 'row', marginBottom: getCorrectHeight(deviceContext, 5), marginTop: getCorrectHeight(deviceContext, 5) }}>
                    {isLive && (
                        <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                            <Text
                                style={{
                                    backgroundColor: 'crimson',
                                    color: appContext.application.theme.tags.color,
                                    borderRadius: appContext.application.theme.tags.radius / 2,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    padding: getCorrectWidth(deviceContext, 2),
                                    paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                    includeFontPadding: false,
                                    fontWeight: 'bold',
                                }}
                            >
                                {lang.getTranslation(userContext, 'live').toUpperCase()}
                            </Text>
                        </View>
                    )}

                    {isLive && item.interactivetv.pausetv_enabled && (
                        <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                            <Text
                                style={{
                                    backgroundColor: '#4169e1',
                                    color: appContext.application.theme.tags.color,
                                    borderRadius: appContext.application.theme.tags.radius / 2,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    padding: getCorrectWidth(deviceContext, 2),
                                    paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                    includeFontPadding: false,
                                    fontWeight: 'bold',
                                }}
                            >
                                {lang.getTranslation(userContext, 'replay').toUpperCase()}
                            </Text>
                        </View>
                    )}

                    {(isLive || (isPast && item.interactivetv.catchuptv_enabled)) && (
                        <View style={{ justifyContent: 'center', marginLeft: getCorrectWidth(deviceContext, 2) }}>
                            <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>
                        </View>
                    )}
                    <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 5), marginLeft: isLive || (isPast && item.interactivetv.catchuptv_enabled) ? 5 : 0 }}>
                        <Text
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                color: styling.texts.title_color == '' ? '#ffffff' : styling.texts.title_color,
                                includeFontPadding: false,
                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                textShadowOffset: { width: -1, height: 1 },
                                textShadowRadius: 2,
                            }}
                        >
                            {moment.unix(program?.s).format('ddd ' + deviceContext.clockSetting) + ' - ' + moment.unix(program?.e).format(deviceContext.clockSetting)}
                        </Text>
                    </View>
                </View>
            </View>
        );
    } else {
        return <></>;
    }
};
export const MetaRow = ({ item, program, type, deviceContext, appContext, userContext, styling }) => {
    if (type == 'Channels') {
        return (
            <View style={{ flexDirection: 'column', width: getRealWidth(deviceContext) * 0.5 }}>
                <View style={{ flexDirection: 'row', marginBottom: getCorrectHeight(deviceContext, 15) }}>
                    {program.mt?.ye != undefined && program.mt?.ye != '' && (
                        <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 5) }}>
                            <Text
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    color: styling.texts.title_color == '' ? '#ffffff' : styling.texts.title_color,
                                    includeFontPadding: false,
                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 2,
                                }}
                            >
                                {program?.mt?.ye}
                            </Text>
                        </View>
                    )}
                    {program.mt?.ye != undefined && program.mt?.ye != '' && program?.mt?.ge != undefined && program?.mt?.ge.length > 0 && <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>}
                    {program?.mt?.ge != undefined && program?.mt?.ge.length > 0 && <View>{getTagsChannels(program?.mt?.ge, appContext, deviceContext, userContext)}</View>}

                    {program?.p != undefined && program?.p != '' && <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>}
                    {program?.p != undefined && program?.p != '' && (
                        <View style={{ justifyContent: 'center' }}>
                            <Text
                                style={{
                                    backgroundColor: appContext.application.theme.tags.background,
                                    color: appContext.application.theme.tags.color,
                                    borderRadius: appContext.application.theme.tags.radius / 2,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    margin: getCorrectWidth(deviceContext, 2),
                                    padding: getCorrectWidth(deviceContext, 2),
                                    paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                    includeFontPadding: false,
                                }}
                            >
                                {program?.p}
                            </Text>
                        </View>
                    )}
                </View>
            </View>
        );
    } else if (type == 'Movies' || type == 'Courses' || type == 'Shorts' || type == 'Series') {
        return (
            <View style={{ flexDirection: 'column', width: getRealWidth(deviceContext) * 0.5 }}>
                <View style={{ flexDirection: 'row', marginBottom: getCorrectHeight(deviceContext, 15) }}>
                    {item.released != undefined && item.released != '' && (
                        <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 5) }}>
                            <Text
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    color: styling.texts.title_color == '' ? '#ffffff' : styling.texts.title_color,
                                    includeFontPadding: false,
                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 2,
                                }}
                            >
                                {item.released}
                            </Text>
                        </View>
                    )}
                    {item.released != undefined && item.released != '' && item.tags != undefined && <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>}
                    {item.tags != undefined && item.tags.length > 0 && <View>{getTags(item.tags, appContext, deviceContext, userContext)}</View>}
                    {item.tags != undefined && item.tags.length > 0 && item.runtime != undefined && item.runtime != '' && <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>}
                    {item.runtime != undefined && item.runtime != '' && (
                        <View style={{ justifyContent: 'center', marginHorizontal: getCorrectWidth(deviceContext, 5) }}>
                            <Text
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    color: styling.texts.title_color == '' ? '#ffffff' : styling.texts.title_color,
                                    includeFontPadding: false,
                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 2,
                                }}
                            >
                                {moment.utc().startOf('day').add({ minutes: item.runtime }).format('H')}
                                {'h:'}
                                {moment.utc().startOf('day').add({ minutes: item.runtime }).format('mm')}
                                {'m'}
                            </Text>
                        </View>
                    )}
                    {item.runtime != undefined && item.runtime != '' && item.rating != undefined && item.rating != '' && <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>}
                    {item.rating != undefined && item.rating != '' && (
                        <Text
                            style={{
                                backgroundColor: appContext.application.theme.tags.background,
                                color: appContext.application.theme.tags.color,
                                borderRadius: appContext.application.theme.tags.radius / 2,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                margin: getCorrectWidth(deviceContext, 2),
                                padding: getCorrectWidth(deviceContext, 2),
                                paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                includeFontPadding: false,
                            }}
                        >
                            {item.rating}
                        </Text>
                    )}
                    {item.parental != undefined && item.parental.rating != undefined && item.parental.rating != '' && <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>}
                    {item.parental != undefined && item.parental.rating != undefined && item.parental.rating != '' && <View>{getRatings(item.parental.rating, appContext, deviceContext, userContext)}</View>}
                </View>
            </View>
        );
    }
};
export const DescriptionRow = ({ item, program, type, fullWidth, deviceContext, userContext, styling }) => {
    if (type == 'Channels') {
        return (
            <View style={{ flexDirection: 'column', width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, fullWidth ? 40 : 80) }}>
                <View>
                    <Text
                        numberOfLines={5}
                        style={{
                            fontFamily: deviceContext.fontType,
                            fontSize: getFontSize(deviceContext, 'Normal'),
                            color: styling.texts.subtext_color,
                            textShadowColor: 'rgba(0, 0, 0, 0.2)',
                            textShadowOffset: { width: -1, height: 1 },
                            textShadowRadius: 2,
                        }}
                    >
                        {program?.d}
                    </Text>
                </View>
            </View>
        );
    } else if (type == 'Movies' || type == 'Courses' || type == 'Shorts') {
        return (
            <View style={{ flexDirection: 'column', width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, fullWidth ? 40 : 80) }}>
                <View>
                    {item.translations?.find((trans) => trans.name == userContext.selectedLanguage) != undefined && (
                        <Text
                            numberOfLines={5}
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Normal'),

                                color: styling.texts.subtext_color,
                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                textShadowOffset: { width: -1, height: 1 },
                                textShadowRadius: 2,
                            }}
                        >
                            {item.translations?.find((trans) => trans.name == userContext.selectedLanguage)?.description}
                        </Text>
                    )}
                    {item.translations?.find((trans) => trans.name == userContext.selectedLanguage) == undefined && item.translations?.length > 0 && (
                        <Text
                            numberOfLines={5}
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Normal'),

                                color: styling.texts.subtext_color,
                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                textShadowOffset: { width: -1, height: 1 },
                                textShadowRadius: 2,
                            }}
                        >
                            {item.translations[0]?.description}
                        </Text>
                    )}
                </View>
            </View>
        );
    } else if (type == 'Series') {
        var watchlist = watchingStatus(type, item._id, userContext);
        var episodeIndex = 0;
        var seasonIndex = 0;
        if (watchlist != undefined) {
            episodeIndex = watchlist.continue.episode_index ?? 0;
            seasonIndex = watchlist.continue.season_index ?? 0;
        }
        return (
            <View style={{ flexDirection: 'column', width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, fullWidth ? 40 : 80) }}>
                <View>
                    {item.seasons[seasonIndex].translations?.find((trans) => trans.name == userContext.selectedLanguage) != undefined && (
                        <Text
                            numberOfLines={5}
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Normal'),

                                color: styling.texts.subtext_color,
                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                textShadowOffset: { width: -1, height: 1 },
                                textShadowRadius: 2,
                            }}
                        >
                            {item.seasons[seasonIndex].translations?.find((trans) => trans.name == userContext.selectedLanguage)?.description}
                        </Text>
                    )}
                    {item.seasons[seasonIndex].translations?.find((trans) => trans.name == userContext.selectedLanguage) == undefined && item.translations?.length > 0 && (
                        <Text
                            numberOfLines={5}
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Normal'),

                                color: styling.texts.subtext_color,
                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                textShadowOffset: { width: -1, height: 1 },
                                textShadowRadius: 2,
                            }}
                        >
                            {item.seasons[seasonIndex].translations[0]?.description}
                        </Text>
                    )}
                </View>
            </View>
        );
    } else {
        return <></>;
    }
};
export const ProgressRow = ({ item, program, type, deviceContext, appContext, userContext, styling }) => {
    var progress = 0;
    var remaining = 0;
    if (type == 'Channels') {
        var currentTime = moment().unix();
        var totalProgram = program?.e - program?.s;
        progress = ((currentTime - program?.s) / totalProgram) * 100;
        var isLive = program.s < currentTime && program.e > currentTime;
        remaining = totalProgram - (currentTime - program?.s);
        if (!isLive) {
            progress = 0;
        }
    }
    if (type == 'Movies' || type == 'Courses' || type == 'Shorts') {
        var watching = watchingStatus(type, item._id, userContext);
        if (watching != undefined && watching.position != undefined) {
            progress = Math.round((watching.position / watching.duration) * 100);
            remaining = watching.duration - watching.position;
        }
    }
    if (type == 'Series') {
        //
    }
    if (progress > 0) {
        return (
            <View style={{ flexDirection: 'column', width: getRealWidth(deviceContext) * 0.22, marginTop: getCorrectHeight(deviceContext, 15) }}>
                <View
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <View
                        style={{
                            backgroundColor: appContext.application.theme.progresses.base,
                            height: getCorrectHeight(deviceContext, 2),
                            width: getRealWidth(deviceContext) * 0.22,
                        }}
                    >
                        <View
                            style={{
                                backgroundColor: appContext.application.theme.progresses.progress,
                                height: getCorrectHeight(deviceContext, 2),
                                width: progress + '%',
                            }}
                        ></View>
                    </View>
                    <View style={{ marginLeft: getCorrectWidth(deviceContext, 2), justifyContent: 'center' }}>
                        <Text
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                fontWeight: styling.texts.title_bold ? 'bold' : null,
                                color: styling.texts.title_color == '' ? '#ffffff' : styling.texts.title_color,
                                includeFontPadding: false,
                            }}
                        >
                            {moment.utc().startOf('day').add({ seconds: remaining }).format('H')}
                            {'h:'}
                            {moment.utc().startOf('day').add({ seconds: remaining }).format('mm')}
                            {'m'}
                            {moment.utc().startOf('day').add({ seconds: remaining }).format('ss')}
                            {'s'} {lang.getTranslation(userContext, 'left')}
                        </Text>
                    </View>
                </View>
            </View>
        );
    } else {
        return <></>;
    }
};

export const checkIfHeaderHasLogoWide = (appContext) => {
    var test = appContext.application?.screens[0]?.header[0];
    if (test.logowide) {
        return true;
    } else {
        return false;
    }
};
export const ButtonRowTop = ({ deviceContext, appContext, userContext, playerContext, styling, onPressChromecast, onPressAirplay, fullWidth, isSlider }) => {
    const insets = useSafeAreaInsets();
    return (
        <View
            style={{
                flex: 1,
                position: 'absolute',
                zIndex: 9999,
                left: getCorrectWidth(deviceContext, fullWidth ? 5 : 15),
                right: getCorrectWidth(deviceContext, fullWidth ? 5 : 20),
                flexDirection: 'row',
                width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, fullWidth ? 20 : 40),
            }}
        >
            <View style={{ flex: 1, alignItems: 'flex-start' }}>
                {!checkIfHeaderHasLogoWide(appContext) && (
                    <NormalImage
                        resizeMethod={'scale'}
                        resizeMode={'cover'}
                        style={{
                            width: getCorrectWidth(deviceContext, 125),
                            height: getCorrectWidth(deviceContext, 31.25),
                        }}
                        source={{ uri: appContext.application.branding.logos.logo }}
                    />
                )}
            </View>
        </View>
    );
};
export const ButtonRow = ({ item, program, type, fullWidth, deviceContext, appContext, userContext, styling, onPressInformation, onPressPurchase, onPressContinue, onPressPlayCatchupTV, onPressPlaySeason, onPressPlay }) => {
    var isPayPerViewEnabled = item.payperview?.enable;
    var isPayPerView = payperviewStatus(type, item._id, userContext).isPayPerView;
    var watchlist = watchingStatus(type, item._id, userContext);
    var hasProgress = watchlist != undefined && watchlist?.position > 0 ? true : false;
    var progress = watchlist?.position;
    switch (type) {
        case 'Movies':
            return (
                <>
                    <View style={{ flexDirection: 'row', marginTop: getCorrectHeight(deviceContext, 15) }}>
                        {!hasProgress && (
                            <ButtonTextPlusIcon
                                hasProgress={hasProgress}
                                fullWidth={fullWidth}
                                first={true}
                                deviceContext={deviceContext}
                                appContext={appContext}
                                userContext={userContext}
                                styling={styling}
                                onPress={() => onPressInformation(item)}
                                text={'information'}
                            ></ButtonTextPlusIcon>
                        )}
                        {((!isPayPerViewEnabled && hasProgress) || (isPayPerViewEnabled && isPayPerView && hasProgress)) && (
                            <ButtonContinuePlusIcon
                                progress={0}
                                deviceContext={deviceContext}
                                appContext={appContext}
                                userContext={userContext}
                                styling={styling}
                                onPress={() => onPressContinue(item)}
                                text={'continue'}
                                season={undefined}
                                episode={undefined}
                                lesson={undefined}
                            ></ButtonContinuePlusIcon>
                        )}
                        {isPayPerViewEnabled && !isPayPerView && (
                            <ButtonTextPayPerView
                                deviceContext={deviceContext}
                                appContext={appContext}
                                userContext={userContext}
                                styling={styling}
                                onPress={onPressPurchase}
                                text={'purchase_option'}
                                rule={item.payperview?.rule}
                            ></ButtonTextPayPerView>
                        )}
                    </View>
                </>
            );
            break;
        case 'Courses':
            var lessonIndex = 0;
            if (watchlist != undefined) {
                episodeIndex = watchlist.continue.lesson_index ?? 0;
            }
            return (
                <>
                    <View style={{ flexDirection: 'row', marginTop: getCorrectHeight(deviceContext, 15) }}>
                        {!hasProgress && (
                            <ButtonTextPlusIcon
                                hasProgress={hasProgress}
                                fullWidth={fullWidth}
                                first={true}
                                deviceContext={deviceContext}
                                appContext={appContext}
                                userContext={userContext}
                                styling={styling}
                                onPress={() => onPressInformation(item)}
                                text={'information'}
                            ></ButtonTextPlusIcon>
                        )}
                        {((!isPayPerViewEnabled && hasProgress) || (isPayPerViewEnabled && isPayPerView && hasProgress)) && (
                            <ButtonContinuePlusIcon
                                progress={0}
                                deviceContext={deviceContext}
                                appContext={appContext}
                                userContext={userContext}
                                styling={styling}
                                onPress={() => onPressContinue(item)}
                                text={'continue'}
                                lesson={lessonIndex}
                                season={undefined}
                                episode={undefined}
                            ></ButtonContinuePlusIcon>
                        )}

                        {isPayPerViewEnabled && !isPayPerView && (
                            <ButtonTextPayPerView
                                rule={item.payperview?.rule}
                                deviceContext={deviceContext}
                                appContext={appContext}
                                userContext={userContext}
                                styling={styling}
                                onPress={onPressPurchase}
                                text={'purchase_option'}
                            ></ButtonTextPayPerView>
                        )}
                    </View>
                </>
            );
            break;
        case 'Shorts':
            return (
                <>
                    <View style={{ flexDirection: 'row', marginTop: getCorrectHeight(deviceContext, 15) }}>
                        {!hasProgress && (
                            <ButtonTextPlusIcon
                                hasProgress={hasProgress}
                                fullWidth={fullWidth}
                                first={true}
                                deviceContext={deviceContext}
                                appContext={appContext}
                                userContext={userContext}
                                styling={styling}
                                onPress={() => onPressInformation(item)}
                                text={'information'}
                            ></ButtonTextPlusIcon>
                        )}
                        {((!isPayPerViewEnabled && hasProgress) || (isPayPerViewEnabled && isPayPerView && hasProgress)) && (
                            <ButtonContinuePlusIcon
                                progress={0}
                                deviceContext={deviceContext}
                                appContext={appContext}
                                userContext={userContext}
                                styling={styling}
                                onPress={() => onPressContinue(item)}
                                text={'continue'}
                                season={undefined}
                                episode={undefined}
                                lesson={undefined}
                            ></ButtonContinuePlusIcon>
                        )}
                        {isPayPerViewEnabled && !isPayPerView && (
                            <ButtonTextPayPerView
                                rule={item.payperview?.rule}
                                deviceContext={deviceContext}
                                appContext={appContext}
                                userContext={userContext}
                                styling={styling}
                                onPress={onPressPurchase}
                                text={'purchase_option'}
                            ></ButtonTextPayPerView>
                        )}
                    </View>
                </>
            );
            break;
        case 'Series':
            var episodeIndex = 0;
            var seasonIndex = 0;
            hasProgress = watchlist?.continue != undefined && watchlist?.continue?.position > 0 ? true : false;
            if (watchlist != undefined && hasProgress) {
                episodeIndex = watchlist.continue.episode_index;
                seasonIndex = watchlist.continue.season_index;
            }
            return (
                <>
                    <View style={{ flexDirection: 'row', marginTop: getCorrectHeight(deviceContext, 15) }}>
                        {!hasProgress && (
                            <ButtonTextPlusIcon
                                hasProgress={hasProgress}
                                first={true}
                                fullWidth={fullWidth}
                                deviceContext={deviceContext}
                                appContext={appContext}
                                userContext={userContext}
                                styling={styling}
                                onPress={onPressInformation}
                                text={'information'}
                            ></ButtonTextPlusIcon>
                        )}
                        {((!isPayPerViewEnabled && hasProgress) || (isPayPerViewEnabled && isPayPerView && hasProgress)) && (
                            <ButtonContinuePlusIcon
                                progress={progress}
                                deviceContext={deviceContext}
                                appContext={appContext}
                                userContext={userContext}
                                styling={styling}
                                onPress={() => onPressPlaySeason(seasonIndex, episodeIndex)}
                                text={'continue'}
                                season={seasonIndex}
                                episode={episodeIndex}
                                lesson={undefined}
                            ></ButtonContinuePlusIcon>
                        )}
                        {isPayPerViewEnabled && !isPayPerView && (
                            <ButtonTextPayPerView
                                rule={item.payperview?.rule}
                                deviceContext={deviceContext}
                                appContext={appContext}
                                userContext={userContext}
                                styling={styling}
                                onPress={onPressPurchase}
                                text={'purchase_option'}
                            ></ButtonTextPayPerView>
                        )}
                    </View>
                </>
            );
            break;
        case 'Channels':
            var currentTime = moment().unix();
            var isLive = program.s < currentTime && program.e > currentTime;
            var watchlist = watchingStatus(type, item._id, userContext);
            return (
                <>
                    <View style={{ flexDirection: 'row', marginTop: getCorrectHeight(deviceContext, 15) }}>
                        {isLive && (
                            <>
                                {(!isPayPerViewEnabled || (isPayPerViewEnabled && isPayPerView)) && (
                                    <ButtonTextPlusIcon
                                        hasProgress={item.interactivetv.pausetv_enabled}
                                        fullWidth={fullWidth}
                                        first={true}
                                        deviceContext={deviceContext}
                                        appContext={appContext}
                                        userContext={userContext}
                                        styling={styling}
                                        onPress={() => onPressPlay(item)}
                                        text={'watch_now'}
                                    ></ButtonTextPlusIcon>
                                )}
                            </>
                        )}
                        {isLive && item.interactivetv.pausetv_enabled && (
                            <>
                                {(!isPayPerViewEnabled || (isPayPerViewEnabled && isPayPerView)) && (
                                    <ButtonTextPlusIcon
                                        hasProgress={item.interactivetv.pausetv_enabled}
                                        fullWidth={fullWidth}
                                        first={false}
                                        deviceContext={deviceContext}
                                        appContext={appContext}
                                        userContext={userContext}
                                        styling={styling}
                                        onPress={() => onPressPlayCatchupTV('live', item, -1)}
                                        text={'restart'}
                                    ></ButtonTextPlusIcon>
                                )}
                            </>
                        )}
                    </View>
                    {isPayPerViewEnabled && !isPayPerView && (
                        <ButtonTextPayPerView rule={item.payperview?.rule} deviceContext={deviceContext} appContext={appContext} userContext={userContext} styling={styling} onPress={onPressPurchase} text={'purchase_option'}></ButtonTextPayPerView>
                    )}
                </>
            );
            break;
        default:
            return <View></View>;
            break;
    }
};
export const Pager = ({ selected, total, deviceContext, appContext, styling }) => {
    var paging = [] as any;
    for (let index_ = 0; index_ < total; index_++) {
        paging.push(
            <View
                key={index_}
                style={{
                    borderRadius: 100,
                    backgroundColor: selected == index_ ? getSelectedStyling('Navigators', appContext).background_color : getPressableTextStyling(styling.components?.button?._id, appContext).color,
                    height: getCorrectWidth(deviceContext, 4),
                    width: getCorrectWidth(deviceContext, 4),
                    margin: getCorrectWidth(deviceContext, 5),
                    shadowColor: 'rgba(0, 0, 0, 0.3)',
                    shadowOffset: { width: -1, height: 1 },
                    shadowRadius: 2,
                }}
            ></View>
        );
    }
    return paging;
};

///extra functions
export const ButtonIconTop = ({ deviceContext, appContext, styling, onPress, icon, userContext, text, color }) => {
    return (
        <View>
            <Pressable
                style={{
                    height: getCorrectHeight(deviceContext, 25),
                    width: getCorrectHeight(deviceContext, 25),
                    ...getPressableStyling(styling.components?.button?._id, appContext),
                    backgroundColor: 'rgba(0, 0, 0, 0.45)',
                    borderRadius: 100,
                }}
                animatorOptions={getFocusStyling('Buttons', appContext)}
                onPress={() => onPress()}
            >
                <View
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: getCorrectHeight(deviceContext, 25),
                        width: getCorrectHeight(deviceContext, 25),
                    }}
                >
                    <FontAwesomeIcon icon={icon} color={color} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>
                </View>
            </Pressable>
            {text != '' && (
                <Text
                    style={{
                        marginTop: getCorrectHeight(deviceContext, 2),
                        textAlign: 'center',
                        fontFamily: deviceContext.fontType,
                        fontSize: getFontSize(deviceContext, 'Small'),
                        ...getPressableTextStyling(styling.components?.button?._id, appContext),
                    }}
                >
                    {lang.getTranslation(userContext, text)}
                </Text>
            )}
        </View>
    );
};
const ButtonTextPayPerView = ({ deviceContext, appContext, userContext, styling, onPress, text, rule }) => {
    return (
        <Pressable
            style={{
                marginTop: getCorrectWidth(deviceContext, 5),
                height: getCorrectHeight(deviceContext, 30),
                ...getPressableStyling(styling.components?.button?._id, appContext),
                justifyContent: 'center',
                alignItems: 'center',
                width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, 40),
            }}
            animatorOptions={getFocusStyling('Buttons', appContext)}
            onPress={() => onPress()}
        >
            <View
                style={{
                    justifyContent: 'center',
                    alignItems: 'center',

                    height: getCorrectHeight(deviceContext, 30),
                    flexDirection: 'row',
                }}
            >
                <Text
                    style={{
                        fontFamily: deviceContext.fontType,
                        fontSize: getFontSize(deviceContext, 'Normal'),
                        ...getPressableTextStyling(styling.components?.button?._id, appContext),
                    }}
                >
                    {lang.getTranslation(userContext, text)}
                </Text>
            </View>
        </Pressable>
    );
};
const ButtonTextPlusIcon = ({ deviceContext, appContext, userContext, styling, onPress, text, first, fullWidth, hasProgress }) => {
    return (
        <Pressable
            style={{
                marginRight: first ? 5 : 0,
                height: getCorrectHeight(deviceContext, 30),
                ...getPressableStyling(styling.components?.button?._id, appContext),
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: getPressableStyling(styling.components?.button?._id, appContext).backgroundColor,
                width: (getRealWidth(deviceContext) - getCorrectWidth(deviceContext, fullWidth ? 40 : 80)) / (hasProgress ? 2 : 1),
            }}
            animatorOptions={getFocusStyling('Buttons', appContext)}
            onPress={() => onPress()}
        >
            <View
                style={{
                    justifyContent: 'center',
                    alignItems: 'center',

                    height: getCorrectHeight(deviceContext, 30),
                    flexDirection: 'row',
                }}
            >
                <Text
                    style={{
                        fontFamily: deviceContext.fontType,
                        fontSize: getFontSize(deviceContext, 'Normal'),
                        ...getPressableTextStyling(styling.components?.button?._id, appContext),
                    }}
                >
                    {lang.getTranslation(userContext, text)}
                </Text>
            </View>
        </Pressable>
    );
};
const ButtonContinuePlusIcon = ({ deviceContext, appContext, userContext, styling, onPress, text, season, episode, progress, lesson }) => {
    return (
        <Pressable
            style={{
                height: getCorrectHeight(deviceContext, 30),
                ...getPressableStyling(styling.components?.button?._id, appContext),
                justifyContent: 'center',
                alignItems: 'center',
                width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, 80),
            }}
            animatorOptions={getFocusStyling('Buttons', appContext)}
            onPress={() => onPress()}
        >
            <View
                style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingHorizontal: getCorrectWidth(deviceContext, 20),
                    height: getCorrectHeight(deviceContext, 30),
                }}
            >
                {!isNaN && (
                    <Text
                        style={{
                            fontFamily: deviceContext.fontType,
                            fontSize: getFontSize(deviceContext, 'Normal'),
                            ...getPressableTextStyling(styling.components?.button?._id, appContext),
                        }}
                    >
                        {lang.getTranslation(userContext, text)} {lang.getTranslation(userContext, 'season')} {season + 1} {lang.getTranslation(userContext, 'episode')} {episode + 1}
                    </Text>
                )}
                {isNaN && (
                    <Text
                        style={{
                            fontFamily: deviceContext.fontType,
                            fontSize: getFontSize(deviceContext, 'Normal'),
                            ...getPressableTextStyling(styling.components?.button?._id, appContext),
                        }}
                    >
                        {lang.getTranslation(userContext, text)}
                    </Text>
                )}
                {progress > 0 && (
                    <View style={{ flexDirection: 'row' }}>
                        <View
                            style={{
                                marginTop: getCorrectHeight(deviceContext, 5),
                                backgroundColor: appContext.application.theme.progresses.base,
                                height: getCorrectHeight(deviceContext, 2),
                                width: '50%',
                            }}
                        >
                            <View
                                style={{
                                    backgroundColor: appContext.application.theme.progresses.progress,
                                    height: getCorrectHeight(deviceContext, 2),

                                    width: progress + '%',
                                }}
                            ></View>
                        </View>
                    </View>
                )}
            </View>
        </Pressable>
    );
};
const getRatings = (ratings, appContext, deviceContext, userContext) => {
    var ratingOut = [] as any;
    var ratingsSplit = ratings.split(',');
    if (ratings.indexOf(',') == -1) {
        ratingOut.push(
            <Text
                style={{
                    backgroundColor: appContext.application.theme.tags.background,
                    color: appContext.application.theme.tags.color,
                    borderRadius: appContext.application.theme.tags.radius / 2,
                    fontSize: getFontSize(deviceContext, 'Small'),
                    margin: getCorrectWidth(deviceContext, 2),
                    padding: getCorrectWidth(deviceContext, 2),
                    paddingHorizontal: getCorrectWidth(deviceContext, 4),
                    includeFontPadding: false,
                }}
            >
                {ratings}
            </Text>
        );
    } else if (ratingsSplit != undefined && ratingsSplit[0] != undefined) {
        for (var rating of ratingsSplit) {
            ratingOut.push(
                <Text
                    style={{
                        backgroundColor: appContext.application.theme.tags.background,
                        color: appContext.application.theme.tags.color,
                        borderRadius: appContext.application.theme.tags.radius / 2,
                        fontSize: getFontSize(deviceContext, 'Small'),
                        margin: getCorrectWidth(deviceContext, 2),
                        padding: getCorrectWidth(deviceContext, 2),
                        paddingHorizontal: getCorrectWidth(deviceContext, 4),
                        includeFontPadding: false,
                    }}
                >
                    {rating}
                </Text>
            );
        }
    }
    if (ratingOut.length > 0) {
        return <View style={{ flexGrow: 1, flexDirection: 'row' }}>{ratingOut}</View>;
    } else {
        return null;
    }
};
const getTags = (tags, appContext, deviceContext, userContext) => {
    var tagsOut = [] as any;
    if (tags != undefined && tags[0] != undefined && tags[0].tag != undefined) {
        tags.map((tag, index) => {
            if (tag.tag != undefined) {
                var test = tag.tag.find((t) => t.name == userContext.selectedLanguage);
                if (test == undefined) {
                    tag.name = tag[0]?.description;
                } else {
                    tag.name = test?.description;
                }
            }
            if (index < 1 && tag.name != '') {
                tagsOut.push(
                    <Text
                        style={{
                            backgroundColor: appContext.application.theme.tags.background,
                            color: appContext.application.theme.tags.color,
                            borderRadius: appContext.application.theme.tags.radius / 2,
                            fontSize: getFontSize(deviceContext, 'Small'),
                            margin: getCorrectWidth(deviceContext, 2),
                            padding: getCorrectWidth(deviceContext, 2),
                            paddingHorizontal: getCorrectWidth(deviceContext, 4),
                            includeFontPadding: false,
                        }}
                    >
                        {tag.name}
                    </Text>
                );
            }
        });
    }
    if (tagsOut.length > 0) {
        return <View style={{ flexGrow: 1, flexDirection: 'row' }}>{tagsOut}</View>;
    } else {
        return null;
    }
};
const getTagsChannels = (tags, appContext, deviceContext, userContext) => {
    var tagsOut = [] as any;
    if (tags != undefined && tags.length > 0) {
        tags.map((tag, index) => {
            if (index < 3) {
                tagsOut.push(
                    <Text
                        style={{
                            backgroundColor: appContext.application.theme.tags.background,
                            borderRadius: appContext.application.theme.tags.radius / 2,
                            color: appContext.application.theme.tags.color,
                            fontSize: getFontSize(deviceContext, 'Small'),
                            margin: getCorrectWidth(deviceContext, 2),
                            padding: getCorrectWidth(deviceContext, 2),
                            paddingHorizontal: getCorrectWidth(deviceContext, 4),
                            includeFontPadding: false,
                        }}
                    >
                        {tag}
                    </Text>
                );
            }
        });
    }
    if (tagsOut.length > 0) {
        return <View style={{ flexGrow: 1, flexDirection: 'row' }}>{tagsOut}</View>;
    } else {
        return null;
    }
};
const DotInBetween = ({ deviceContext, styling }) => {
    return (
        <View style={{ justifyContent: 'center', marginHorizontal: getCorrectWidth(deviceContext, 3) }}>
            <View style={{ width: getCorrectWidth(deviceContext, 3), height: getCorrectWidth(deviceContext, 3), backgroundColor: styling.texts.title_color == '' ? '#ffffff' : styling.texts.title_color, borderRadius: 100 }}></View>
        </View>
    );
};
const getProgramImage = (program, data, styling, fullWidth, deviceContext, appContext, isBlur) => {
    if (program != undefined && program?.r != '' && program?.r != null && program?.r.indexOf('https://') > -1) {
        var image = program.r
            .replace('.jpg', '_' + Math.round(getRealWidth(deviceContext)) + 'x' + Math.round(getRealWidth(deviceContext) * 1.5) + '.jpg')
            .replace('.png', '_' + Math.round(getRealWidth(deviceContext)) + 'x' + Math.round(getRealWidth(deviceContext) * 1.5) + '.png');

        return (
            <View
                style={{
                    borderRadius: fullWidth ? 0 : getPressableStyling(styling.components?.button?._id, appContext).borderRadius,
                }}
            >
                <NormalImage
                    blurRadius={isBlur ? 95 : 0}
                    deviceContext={deviceContext}
                    resizeMethod={'resize'}
                    resizeMode={'cover'}
                    FadeZoom
                    style={{
                        borderRadius: fullWidth ? 0 : getPressableStyling(styling.components?.button?._id, appContext).borderRadius,
                        width: getRealWidth(deviceContext) - (isBlur ? 0 : 20),
                        height: getRealWidth(deviceContext) * 1.5 - 10,
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        alignSelf: 'center',
                        borderColor: '#d7d7d7',
                        borderWidth: isBlur ? 0 : 1,
                        overflow: 'hidden',
                    }}
                    source={{ uri: image }}
                />
            </View>
        );
    } else {
        return (
            <NormalImage
                blurRadius={95}
                deviceContext={deviceContext}
                resizeMethod={'scale'}
                resizeMode={'cover'}
                FadeZoom
                style={{
                    backgroundColor: '#000000',
                    borderRadius: fullWidth ? 0 : getPressableStyling(styling.components?.button?._id, appContext).borderRadius,
                    width: getRealWidth(deviceContext) - (isBlur ? 0 : 20),
                    height: getRealWidth(deviceContext) * 1.5 - 10,
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                    alignSelf: 'center',
                    borderColor: '#d7d7d7',
                    borderWidth: isBlur ? 0 : 1,
                    overflow: 'hidden',
                }}
                source={{ uri: utils.getCorrectStoragePrefix(appContext, true, data?.images?.square) }}
            />
        );
    }
};
const getMovieImage = (item, styling, fullWidth, deviceContext, appContext, isBlur) => {
    if (item != undefined && item?.images?.poster != '') {
        return (
            <NormalImage
                blurRadius={isBlur ? 95 : 0}
                deviceContext={deviceContext}
                resizeMethod={'scale'}
                resizeMode={'cover'}
                FadeZoom
                style={{
                    borderRadius: fullWidth ? 0 : getPressableStyling(styling.components?.button?._id, appContext).borderRadius,
                    width: getRealWidth(deviceContext) - (isBlur ? 0 : 20),
                    height: getRealWidth(deviceContext) * 1.5 - 10,
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                    alignSelf: 'center',
                    borderColor: '#d7d7d7',
                    borderWidth: isBlur ? 0 : 1,
                    overflow: 'hidden',
                }}
                source={{ uri: utils.getCorrectStoragePrefix(appContext, true, item?.images?.poster) }}
            />
        );
    } else {
        return <></>;
    }
};
const getSeriesImage = (item, deviceContext, styling, fullWidth, appContext, contentContext, isBlur) => {
    if (item != undefined && item.seasons[contentContext.seriesData.seasonCategoryIndex].images?.poster != '') {
        return (
            <NormalImage
                blurRadius={isBlur ? 95 : 0}
                deviceContext={deviceContext}
                resizeMethod={'scale'}
                resizeMode={'cover'}
                FadeZoom
                style={{
                    borderRadius: fullWidth ? 0 : getPressableStyling(styling.components?.button?._id, appContext).borderRadius,
                    width: getRealWidth(deviceContext) - (isBlur ? 0 : 20),
                    height: getRealWidth(deviceContext) * 1.5 - 10,
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                    alignSelf: 'center',
                    borderColor: '#d7d7d7',
                    borderWidth: isBlur ? 0 : 1,
                    overflow: 'hidden',
                }}
                source={{ uri: utils.getCorrectStoragePrefix(appContext, true, item.seasons[contentContext.seriesData.seasonCategoryIndex].images?.poster) }}
            />
        );
    } else {
        return <></>;
    }
};
const getCourseImage = (item, deviceContext, styling, fullWidth, appContext, contentContext, isBlur) => {
    if (item != undefined && item.lessons[contentContext.courseData.lessonIndex].images?.poster != '') {
        return (
            <NormalImage
                blurRadius={isBlur ? 95 : 0}
                deviceContext={deviceContext}
                resizeMethod={'scale'}
                resizeMode={'cover'}
                FadeZoom
                style={{
                    borderRadius: fullWidth ? 0 : getPressableStyling(styling.components?.button?._id, appContext).borderRadius,
                    width: getRealWidth(deviceContext) - (isBlur ? 0 : 20),
                    height: getRealWidth(deviceContext) * 1.5 - 10,
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                    alignSelf: 'center',
                    borderColor: '#d7d7d7',
                    borderWidth: isBlur ? 0 : 1,
                    overflow: 'hidden',
                }}
                source={{ uri: utils.getCorrectStoragePrefix(appContext, true, item.images?.poster) }}
            />
        );
    } else {
        return <></>;
    }
};
const getShortImage = (item, deviceContext, styling, fullWidth, appContext, contentContext, isBlur) => {
    if (item != undefined && item.images?.poster != '') {
        return (
            <NormalImage
                blurRadius={isBlur ? 95 : 0}
                deviceContext={deviceContext}
                resizeMethod={'scale'}
                resizeMode={'cover'}
                FadeZoom
                style={{
                    borderRadius: fullWidth ? 0 : getPressableStyling(styling.components?.button?._id, appContext).borderRadius,
                    width: getRealWidth(deviceContext) - (isBlur ? 0 : 20),
                    height: getRealWidth(deviceContext) * 1.5 - 10,
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                    alignSelf: 'center',
                    borderColor: '#d7d7d7',
                    borderWidth: isBlur ? 0 : 1,
                    overflow: 'hidden',
                }}
                source={{ uri: utils.getCorrectStoragePrefix(appContext, true, item.images?.poster) }}
            />
        );
    } else {
        return <></>;
    }
};
