import * as React from 'react';
import { View, CreateListRenderItemInfo, FlashList, FocusContext, setFocus, CoreManager } from '@scriptx-com/xtv-toolkit';
import Actor from '../shared/actor';
import { getFontSize } from '../../../styling/fontSizes';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { getRealWidth } from '../../../styling/realDeviceSizes';
import { ContentContext } from '../../../context/contentContext';
import { EMPTY_DATA_ARRAY, EMPTY_DATA_ARRAY_SQUARE, checkIfUserGeneratedListAndEmpty, getRailsWidth, getRailsHeight, extraTopBottomMargin } from './helpers/helper';
import { DeviceContext } from '../../../context/deviceContext';
import { UserContext } from '../../../context/userContext';
import { AppContext } from '../../../context/appContext';
import { getFocusStyling } from '../../components/helpers/helper';
import { getCurrentScreenName } from '../../../hooks/getScreenName';
import { isPlatformAndroid, isPlatformIos, isPlatformTvos } from '@rnv/renative';
import { getCorrectLeftMargin } from '../../../styling/appLeftMargin';
import { GetScrollElement, GetTopPart } from './helpers/views';
import useBaseRail from './useBaseRail';

export interface Data {
    items: [
        {
            square_image: string;
            name: string;
            _id: string;
        }
    ];
}

export interface Props {
    styling: any;
    focusContext?: FocusContext;
    navigation: any;
    reloadElements: any;
    heroIsPlayer: boolean;
    railIndex?: number;
}
export const Actor_Rails = React.memo(({ styling, focusContext, navigation, reloadElements, heroIsPlayer, railIndex }: Props) => {
    const screenName = getCurrentScreenName();

    const contentContext = React.useContext(ContentContext);
    const appContext = React.useContext(AppContext);
    const deviceContext = React.useContext(DeviceContext);
    const userContext = React.useContext(UserContext);
    const [data, setData] = React.useState(screenName === 'Home' && !checkIfUserGeneratedListAndEmpty(styling, userContext, contentContext, appContext) ? EMPTY_DATA_ARRAY_SQUARE : []);

    let height = isPlatformAndroid || isPlatformIos ? React.useRef(getCorrectHeight(deviceContext, styling.placement.height)).current : getCorrectHeight(deviceContext, styling.placement.height);
    if (height == 0 || isNaN(height)) {
        height = getCorrectHeight(deviceContext, 100);
    }
    if (deviceContext.formFactor == 'Phone') {
        height = height * 0.8;
    }
    var width = height;

    //mobile width check
    if (width > getRealWidth(deviceContext) && deviceContext.formFactor == 'Phone') {
        height = getRealWidth(deviceContext) * 0.7;
        if (styling.texts.placement_text == 'Below') {
            height = height + getFontSize(deviceContext, 'Normal') + getFontSize(deviceContext, 'Normal') + getFontSize(deviceContext, 'Large');
        }
        width = height;
    }

    const [dataRefreshed, setDataRefreshed] = React.useState<any>();
    const currentFocusRailIndex = React.useRef<number | undefined>();

    const getData = () => {
        setData(styling.actors);
        return;
    };

    const { Loader, flashListProps, onViewableItemsChanged, scrollRight, scrollLeft, railsRef, viewableItemsCount } = useBaseRail({
        contentLoaderHeight: height,
        contentLoaderWidth: width,
        data,
        railIndex,
        railType: 'actor',
        contentType: styling.content.type,
        getData,
        emptyItemRenderer: (props: any) => {
            return renderItem({
                item: EMPTY_DATA_ARRAY[0],
                index: props.index,
                target: 'Cell',
                emptyRailProps: props,
            });
        },
        animator: getFocusStyling('Rails', appContext),
        isUserGeneratedListAndEmpty: false,
    });

    React.useEffect(() => {
        if (railIndex === currentFocusRailIndex.current) {
            if (data.length === 0) {
                setFocus(`empty-actor-placeholder-${railIndex}`);
            } else {
                const focusIndex = data.length > 2 ? 2 : 0;
                setFocus(`actor-${railIndex}-${focusIndex}`);
            }
        }
    }, [dataRefreshed]);

    const detailsItem = (item: any, index: any) => {
        navigation &&
            navigation.push('Actors', {
                actor: item,
                styling: styling,
            });
    };

    const renderItem = ({ item, focusRepeatContext, index, emptyRailProps }: CreateListRenderItemInfo<any> & { emptyRailProps?: any }) => {
        return (
            <Actor
                elementType={'Rails'}
                heroIsPlayer={heroIsPlayer}
                rails={true}
                type={styling.content.list?.type}
                styling={styling}
                data={item}
                height={height}
                width={width}
                focusOptions={{
                    focusKey: `actor-${railIndex}-${index}`,
                }}
                focusRepeatContext={focusRepeatContext}
                onPress={() => {
                    detailsItem(item, index);
                }}
                isFirst={index === 0}
                isLast={data.length - 1 === index}
                {...emptyRailProps}
            ></Actor>
        );
    };

    const openOverviewPage = () => {
        navigation &&
            navigation.navigate(styling.content.list?.type, {
                railsItems: data,
            });
    };

    if (styling.content.type === 'Search' && data.length === 0) {
        return null;
    }

    return (
        <View focusContext={focusContext}>
            {data != null && data.length > 0 && (
                <View
                    style={{
                        flex: 1,
                        marginLeft: getCorrectLeftMargin(deviceContext, appContext, screenName),
                        marginRight: styling.placement.background_color != '#00000000' && styling.placement.background_color != 'transparent' && deviceContext.formFactor != 'Phone' ? getCorrectWidth(deviceContext, 10) : 0,
                        marginTop: appContext.application.theme.rails_top_margin / (deviceContext.formFactor == 'Phone' ? 2 : 1) + extraTopBottomMargin(deviceContext, styling),
                        marginBottom: appContext.application.theme.rails_bottom_margin / (deviceContext.formFactor == 'Phone' ? 2 : 1) + extraTopBottomMargin(deviceContext, styling),
                    }}
                >
                    <View style={{ flex: 1 }}>
                        <View
                            style={{
                                backgroundColor: styling.placement.background_color,

                                height: styling.content.type === 'Search' && data.length === 0 ? 0 : getRailsHeight(deviceContext, appContext, styling, height),
                                borderRadius: getCorrectWidth(deviceContext, styling.placement.border_radius),
                                paddingTop: getCorrectHeight(deviceContext, styling.placement.padding),
                                paddingBottom: getCorrectHeight(deviceContext, styling.placement.padding),
                                paddingHorizontal:
                                    styling.placement.background_color != '#00000000' && styling.placement.background_color != 'transparent'
                                        ? deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs
                                            ? getCorrectWidth(deviceContext, 5)
                                            : getCorrectWidth(deviceContext, styling.placement.padding)
                                        : 0,
                            }}
                        >
                            <GetTopPart
                                screenName={screenName}
                                focusContext={focusContext}
                                openOverviewPage={openOverviewPage}
                                deviceContext={deviceContext}
                                appContext={appContext}
                                userContext={userContext}
                                styling={styling}
                                data={data.length >= 1 ? data : screenName === 'Home' ? [{ id: '', title: 'ok' }] : []}
                            ></GetTopPart>

                            <View style={{ flexDirection: 'row' }}>
                                <View
                                    style={{ flex: 1, width: getRailsWidth(deviceContext, appContext, styling, screenName) }}
                                    focusContext={focusContext}
                                    focusOptions={{
                                        group: `actor-${railIndex}`,
                                        focusKey: `actor-${railIndex}`,
                                        allowFocusOutsideGroup: true,
                                    }}
                                >
                                    <View>{Loader()}</View>
                                    {data != null && data.length > 0 && (
                                        <FlashList
                                            type="row"
                                            ref={railsRef}
                                            data={data}
                                            onViewableItemsChanged={onViewableItemsChanged}
                                            estimatedItemSize={height}
                                            horizontal={true}
                                            showsHorizontalScrollIndicator={false}
                                            renderItem={renderItem}
                                            {...(isPlatformTvos && {
                                                contentInset: { left: 11 },
                                            })}
                                            focusOptions={{
                                                autoLayoutScaleAnimation: getFocusStyling('Rails', appContext).type.indexOf('scale') > -1 && deviceContext.formFactor == 'Television' ? true : false,
                                                autoLayoutSize: getFocusStyling('Rails', appContext).type.indexOf('scale') > -1 && deviceContext.formFactor == 'Television' ? 10 : 0,
                                                ...flashListProps.focusOptions,
                                            }}
                                            {...flashListProps}
                                        />
                                    )}
                                    {deviceContext.isWebTV && !deviceContext.isPwa && data.length > 0 && data.length > viewableItemsCount && (
                                        <GetScrollElement height={height} scrollLeft={scrollLeft} scrollRight={scrollRight} deviceContext={deviceContext} appContext={appContext}></GetScrollElement>
                                    )}
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            )}
        </View>
    );
});
export default Actor_Rails;
