import { View, ActivityIndicator, Image as Image_, FlashList, CreateListRenderItemInfo } from '@scriptx-com/xtv-toolkit';
import moment from 'moment';
import * as React from 'react';
import Pressable from '../../../../application/builder/components/pressable/pressable';
import lang from '../../../../application/languages/languages';
import { AppContext } from '../../../context/appContext';
import { ContentContext } from '../../../context/contentContext';
import { DeviceContext } from '../../../context/deviceContext';
import { UserContext } from '../../../context/userContext';
import { watchingStatus } from '../../../data/account';
import { Hero_Single as styling } from '../../../models/elements/heros/single';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { getFontSize } from '../../../styling/fontSizes';
import { getRealHeight, getRealWidth } from '../../../styling/realDeviceSizes';
import { ImageFadeZoomFadeOut } from '../../animations/imageFadeZoomFadeOut';
import { checkParentalLockShouldApply, getLiveImage } from '../../elements/players/helpers/functions';
import { getChannelsFromSubscriptionAndRating, getDetailsChannel, getDetailsCourse, getDetailsMovie, getDetailsSeries, getMoviesFromSubscriptionAndRating, playChannel, playMovie } from '../rails/helpers/helper';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faPlay, faSquareInfo } from '@fortawesome/pro-light-svg-icons';
import Image from '../../components/image/image';
import Text from '../../components/text';
import { PlayerContext } from '../../../context/playerContext';
import { CommonActions } from '@react-navigation/native';
import { getFocusStyling, getModalStyling, getPressableStyling, getPressableTextStyling } from '../../components/helpers/helper';
import { getLiveTag, getProgramTime } from '../players/helpers/helpers';
import { RemoteImageHeightWidth } from '../../components/remoteimage/remoteimage';
import { SettingsContext } from '../../../context/settingsContext';
import PayPerView from '../../components/payperview/payperview';
import utils from '../../../general/utils';

export interface Props {
    styling: styling;
    navigation: any;
    focusContext?: any;
}

export const Hero_Player = ({ styling, navigation, focusContext }: Props) => {
    const contentContext = React.useContext(ContentContext);
    const appContext = React.useContext(AppContext);
    const userContext = React.useContext(UserContext);
    const deviceContext = React.useContext(DeviceContext);
    const playerContext = React.useContext(PlayerContext);
    const settingsContext = React.useContext(SettingsContext);

    const [hide, setHide] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const [data, setData] = React.useState([] as any);
    const [index, setIndex] = React.useState(0);
    const [progress, setProgress] = React.useState(0);
    const [program, setProgram] = React.useState([] as any);
    const [showPayPerView, setShowPayPerView] = React.useState(false);
    const [updateScreenPayPerView, setUpdatePayPerView] = React.useState(0);

    var isParentalEnabled = false;

    const getData = async () => {
        var path = '';
        if (styling.content.type == 'Content List') {
            if (styling.content.list?.type == 'Movies') {
                path = utils.getCorrectStoragePrefix(appContext, true) +'movies/lists/' + styling.content.list.list + '_list.json';
            }
            if (styling.content.list?.type == 'Series') {
                path = utils.getCorrectStoragePrefix(appContext, true) +'series/lists/' + styling.content.list.list + '_list.json';
            }
            if (styling.content.list?.type == 'Courses') {
                path = utils.getCorrectStoragePrefix(appContext, true) +'courses/lists/' + styling.content.list.list + '_list.json';
            }
            if (styling.content.list?.type == 'Channels') {
                path = utils.getCorrectStoragePrefix(appContext, true) +'channels/lists/' + styling.content.list.list + '_list.json';
            }
            try {
                let response = await fetch(path);
                let data = await response.json();

                if (data != undefined && data.length > 0) {
                    if (styling.content.list?.type == 'Channels') {
                        var dataOut = getChannelsFromSubscriptionAndRating(contentContext, data[0].items);
                        var random = Math.floor(Math.random() * dataOut.length);
                        var channel = dataOut[random];
                        if (contentContext.epgTv[0] != undefined) {
                            var epgChannel = contentContext.epgTv?.find((e) => e.i == channel._id);
                            if (epgChannel != undefined) {
                                var currentTime = moment().unix();
                                var program = epgChannel.epg.find((e) => e.s <= currentTime && e.e >= currentTime);
                                if (program != undefined) {
                                    setProgram(program);
                                    var totalProgram = program?.e - program?.s;
                                    var progress = ((currentTime - program?.s) / totalProgram) * 100;
                                    isParentalEnabled = checkParentalLockShouldApply(channel,program,appContext,userContext);
                                    setProgress(progress);
                                    setIndex(random);
                                    setData(dataOut);
                                    setShow(true);
                                }
                            }
                        }
                    } else {
                        var random = Math.floor(Math.random() * data[0].items.length);
                        if (styling.content.list?.type == 'Movies') {
                            try {
                                var dataOut = getMoviesFromSubscriptionAndRating(contentContext, data[0].items);
                                var watching = watchingStatus('Movies', dataOut[random]._id, userContext);
                                if (watching != undefined && watching.position != undefined) {
                                    setProgress(Math.round((watching.position / watching.duration) * 100));
                                }
                                setIndex(random);
                                setData(dataOut);
                                setShow(true);
                            } catch (e) {
                                setProgress(0);
                            }
                        }
                    }
                }
            } catch (error) {
                setHide(true);
            }
        }
    };

    React.useEffect(() => {
        getData();
    }, [navigation]);

    const playItem = (item: any) => {
        if (styling.content.list?.type == 'Movies') {
            playMovie(contentContext, item, navigation, settingsContext);
        }
        if (styling.content.list?.type == 'Channels') {
            playChannel(contentContext, userContext, appContext, item, navigation, settingsContext);
        }
    };

    const detailItem = (item: any) => {
        if (styling.content.list?.type == 'Channels') {
            getDetailsChannel(contentContext, item, navigation);
        }
        if (styling.content.list?.type == 'Movies') {
            getDetailsMovie(contentContext, item, navigation);
        }
        if (styling.content.list?.type == 'Series') {
            getDetailsSeries(navigation, item, navigation);
        }
        if (styling.content.list?.type == 'Courses') {
            getDetailsCourse(navigation, item, navigation);
        }
    };

    const generateUniqueIdx = (prevIdx) => {
        let rand = Math.floor(Math.random() * data.length);
        while (rand === prevIdx) {
            rand = Math.floor(Math.random() * data.length);
        }
        return rand;
    };

    const nextSlide = () => {
        const rand = generateUniqueIdx(index);

        var progress = 0;
        if (styling.content.list?.type == 'Movies') {
            try {
                var watching = watchingStatus('Movies', data[rand]._id, userContext);
                if (watching != undefined && watching.position != undefined) {
                    setProgress(Math.round((watching.position / watching.duration) * 100));
                } else {
                    setProgress(0);
                }
            } catch (e) {
                setProgress(0);
            }
        }
        if (styling.content.list?.type == 'Channels') {
            var channel = data[rand];
            if (contentContext.epgTv[0] != undefined) {
                var epgChannel = contentContext.epgTv?.find((e) => e.i == channel._id);
                if (epgChannel != undefined) {
                    var currentTime = moment().unix();
                    var program = epgChannel.epg.find((e) => e.s <= currentTime && e.e >= currentTime);
                    if (program != undefined) {
                        var totalProgram = program?.e - program?.s;
                        var progress = ((currentTime - program?.s) / totalProgram) * 100;
                        isParentalEnabled = checkParentalLockShouldApply(channel,program,appContext,userContext);
                        setProgress(progress);
                        setProgram(program);
                    }
                }
            }
        }
        setIndex(rand);
    };

    const getCorrectImage = () => {
        if (program?.m != '') {
            return program.m;
        } else {
            return getLiveImage(data[index]?.streams.channels.urls.standard_url);
        }
    };

    const checkIfLogoExists = () => {
        if (styling.content.list?.type == 'Movies' || styling.content.list?.type == 'Series') {
            if (data[index]?.images?.logo != undefined && data[index]?.images?.logo != '') {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    const openAccount = () => {
        navigation.dispatch(
            CommonActions.reset({
                index: 1,
                routes: [{ name: 'Profiles' }],
            })
        );
    };

    return (
        <View style={{ flex: 1 }} focusContext={focusContext}>
            {showPayPerView && (
                <PayPerView
                    focusContext={focusContext}
                    styling={getModalStyling(appContext.application.theme.modals.payperview, appContext)}
                    stylingContent={styling}
                    setShowModal={() => setShowPayPerView(false)}
                    type={styling.content.list?.type == 'Channels' ? 'Program' : styling.content.list?.type}
                    data={data}
                    startContent={() => {
                        setUpdatePayPerView(moment().unix());
                        setShowPayPerView(false);
                    }}
                ></PayPerView>
            )}
            <View style={{ flex: 1 }}>
                {!show && (
                    <View>
                        <View
                            style={{
                                flexDirection: 'row',
                                backgroundColor: '#000',
                                width: getRealWidth(deviceContext),
                                height: getRealHeight(deviceContext) * 0.6,
                                justifyContent: 'center',
                            }}
                        >
                            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                <ActivityIndicator size={'large'} color={'white'}></ActivityIndicator>
                            </View>
                        </View>
                    </View>
                )}
                {show && !styling.capped && (!deviceContext.isPhone || !deviceContext.isKaiOs || deviceContext.isPwaVertical) && (
                    <View
                        style={{
                            position: 'absolute',
                            width: getRealWidth(deviceContext),
                            height: getRealWidth(deviceContext),
                            zIndex: 1,
                        }}
                    >
                        <Image
                            isLocal={true}
                            deviceContext={deviceContext}
                            source={require('../../../../assets/images/gradient-home1.png')}
                            resizeMethod={'scale'}
                            resizeMode={'stretch'}
                            style={{ height: getRealHeight(deviceContext), width: getRealWidth(deviceContext) }}
                        ></Image>
                    </View>
                )}
                {show && (styling.capped || deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical) && (
                    <View
                        style={{
                            position: 'absolute',
                            width: getRealWidth(deviceContext),

                            zIndex: 1,
                        }}
                    >
                        <Image
                            isLocal={true}
                            deviceContext={deviceContext}
                            resizeMethod={'scale'}
                            resizeMode={'contain'}
                            source={require('../../../../assets/images/gradient-home.png')}
                            style={{
                                height: getCorrectHeight(deviceContext, styling.content.list?.type == 'Channels' ? 450 : deviceContext.isAndroid ? 490 : 450),
                                width: getRealWidth(deviceContext),
                            }}
                        ></Image>
                    </View>
                )}

                {show && (deviceContext.isPhone || deviceContext.isPwaVertical) && (
                    <View
                        style={{
                            flex: 1,
                        }}
                    >
                        <View
                            style={{
                                width: getRealWidth(deviceContext),
                                display: 'flex',
                                justifyContent: 'center',
                                overflow: 'hidden',
                                height: styling.capped ? getCorrectHeight(deviceContext, 320) : null,
                                backgroundColor: '#000',
                            }}
                        >
                            {styling.content.list?.type == 'Channels' && (
                                <ImageFadeZoomFadeOut
                                    deviceContext={deviceContext}
                                    resizeMethod={'scale'}
                                    resizeMode={'contain'}
                                    key={index}
                                    style={{
                                        width: getRealWidth(deviceContext),
                                        height: (getRealWidth(deviceContext) / 16) * 18,
                                    }}
                                    next={() => nextSlide()}
                                    source={{ uri: getCorrectImage() }}
                                />
                            )}
                            {styling.content.list?.type != 'Channels' && (
                                <ImageFadeZoomFadeOut
                                    blurRadius={80}
                                    deviceContext={deviceContext}
                                    resizeMethod={'scale'}
                                    resizeMode={'contain'}
                                    key={index}
                                    style={{
                                        width: getRealWidth(deviceContext),
                                        height: getRealWidth(deviceContext) * 1.5,
                                    }}
                                    next={() => nextSlide()}
                                    source={{ uri: utils.getCorrectStoragePrefix(appContext, data[index]?.images?.poster)  }}
                                />
                            )}
                        </View>
                    </View>
                )}

                <View
                    style={{
                        zIndex: 2,
                        position: 'absolute',
                        width: getRealWidth(deviceContext),
                        height: getRealWidth(deviceContext) * 1.2,
                        flex: 1,
                        top: getCorrectHeight(deviceContext, deviceContext.isAndroid ? 20 : deviceContext.isIos ? 30 : 0),
                    }}
                >
                    <View style={{ flex: 1 }} skey={index} change={index}>
                        <View style={{ flex: 1, flexDirection: 'row', padding: getCorrectHeight(deviceContext, 10) }}>
                            <View style={{ flex: 1, paddingLeft: getCorrectWidth(deviceContext, 12) }}>
                                <Image_
                                    resizeMethod={'scale'}
                                    resizeMode={'contain'}
                                    style={{
                                        width: getCorrectWidth(deviceContext, 100) * 0.9,
                                        height: getCorrectWidth(deviceContext, 25) * 0.9,
                                    }}
                                    source={{ uri: appContext.application.branding.logos.logo }}
                                />
                            </View>
                            <View style={{ flex: 1, alignItems: 'flex-end' }}>
                                <Pressable animatorOptions={getFocusStyling('Buttons', appContext)} key={index} onPress={() => openAccount()}>
                                    <Image_
                                        resizeMethod={'scale'}
                                        resizeMode={'cover'}
                                        source={{
                                            uri: userContext.profile.avatar,
                                        }}
                                        style={{
                                            width: getCorrectWidth(deviceContext, 35),
                                            height: getCorrectWidth(deviceContext, 35),

                                            borderRadius: 100,
                                        }}
                                    ></Image_>
                                </Pressable>
                            </View>
                        </View>
                        {show && (
                            <>
                                <View
                                    style={{
                                        flex: 4,
                                        justifyContent: 'flex-end',
                                        alignItems: 'center',
                                        marginBottom: getCorrectHeight(deviceContext, styling.content.list?.type != 'Channels' ? 20 : 40),
                                    }}
                                >
                                    {styling.content.list?.type != 'Channels' && (
                                        <Image
                                            deviceContext={deviceContext}
                                            resizeMethod={'scale'}
                                            resizeMode={'contain'}
                                            key={index}
                                            style={{
                                                width: getRealWidth(deviceContext) * 0.45,
                                                height: getRealWidth(deviceContext) * 0.45 * 1.5,

                                                borderWidth: getCorrectWidth(deviceContext, 2),
                                                borderColor: '#000',
                                            }}
                                            source={{ uri: utils.getCorrectStoragePrefix(appContext,  data[index]?.images?.poster )}}
                                        />
                                    )}
                                    {styling.content.list?.type == 'Channels' && (
                                        <Image
                                            deviceContext={deviceContext}
                                            resizeMethod={'scale'}
                                            resizeMode={'contain'}
                                            key={index}
                                            style={{
                                                width: getRealWidth(deviceContext) * 0.4,
                                                height: getRealWidth(deviceContext) * 0.4,
                                            }}
                                            source={{ uri: utils.getCorrectStoragePrefix(appContext,  data[index]?.images?.square) }}
                                        />
                                    )}
                                    {styling.content.list?.type != 'Channels' && !checkIfLogoExists() && (
                                        <Text
                                            numberOfLines={deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical ? 1 : 1}
                                            style={{
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                                fontWeight: styling.texts.title_bold ? 'bold' : null,
                                                color: styling.texts.title_color == '' ? '#ffffff' : styling.texts.title_color,
                                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                                textShadowOffset: { width: -1, height: 1 },
                                                textShadowRadius: 2,
                                                textAlign: 'center',
                                                width: getRealWidth(deviceContext) * 0.8,
                                            }}
                                        >
                                            {data[index]?.name}
                                        </Text>
                                    )}
                                    {checkIfLogoExists() && <RemoteImageHeightWidth uri={utils.getCorrectStoragePrefix(appContext, true) + data[index]?.images.logo} desiredWidth={getRealWidth(deviceContext) * 0.25} desiredHeight={100} deviceContext={deviceContext} />}
                                    {styling.content.list?.type == 'Channels' && (
                                        <View style={{ justifyContent: 'center', marginTop: getCorrectHeight(deviceContext, 10) }}>
                                            <Text
                                                numberOfLines={deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical ? 1 : 1}
                                                style={{
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Large'),
                                                    fontWeight: styling.texts.title_bold ? 'bold' : null,
                                                    color: styling.texts.title_color == '' ? '#ffffff' : styling.texts.title_color,
                                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                                    textShadowOffset: { width: -1, height: 1 },
                                                    textShadowRadius: 2,
                                                    textAlign: 'center',
                                                    width: getRealWidth(deviceContext) * 0.8,
                                                }}
                                            >
                                                {data[index]?.number}. {data[index]?.name}
                                            </Text>
                                            <Text
                                                numberOfLines={deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical ? 1 : 1}
                                                style={{
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Large'),
                                                    fontWeight: styling.texts.title_bold ? 'bold' : null,
                                                    color: styling.texts.title_color == '' ? '#ffffff' : styling.texts.title_color,
                                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                                    textShadowOffset: { width: -1, height: 1 },
                                                    textShadowRadius: 2,
                                                    textAlign: 'center',
                                                    width: getRealWidth(deviceContext) * 0.8,
                                                }}
                                            >
                                                {program.n}
                                            </Text>

                                            {progress > 0 && (
                                                <View
                                                    style={{
                                                        bottom: getCorrectHeight(deviceContext, 5),
                                                        marginTop: getCorrectHeight(deviceContext, 10),
                                                    }}
                                                >
                                                    <View
                                                        style={{
                                                            marginVertical: getCorrectHeight(deviceContext, 2),
                                                            height: getCorrectHeight(deviceContext, 2),

                                                            width:
                                                                getRealWidth(deviceContext) -
                                                                getCorrectWidth(deviceContext, deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical ? 40 : 60) -
                                                                getCorrectWidth(deviceContext, global.menuWidth),
                                                            backgroundColor: appContext.application.theme.progresses.base,
                                                        }}
                                                    >
                                                        <View
                                                            style={{
                                                                backgroundColor: appContext.application.theme.progresses.progress,
                                                                height: getCorrectHeight(deviceContext, 2),

                                                                width: progress + '%',
                                                            }}
                                                        ></View>
                                                    </View>
                                                </View>
                                            )}
                                            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                                {getLiveTag(lang.getTranslation(userContext, 'live').toUpperCase(), appContext, deviceContext)}
                                                {getProgramTime(moment.unix(program?.s).format('dddd ' + deviceContext.clockSetting) + '-' + moment.unix(program?.e).format(deviceContext.clockSetting), appContext, deviceContext)}
                                            </View>
                                        </View>
                                    )}

                                    {/* {getTags(data[index]?.tags, styling, appContext, deviceContext, userContext)} */}
                                </View>
                                <View
                                    style={{
                                        backgroundColor: 'rgba(0, 0, 0, 0.90)',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    {styling.content.list?.type == 'Movies' && (
                                        <Pressable
                                            style={{
                                                marginVertical: getCorrectHeight(deviceContext, 5),
                                                marginRight: getCorrectHeight(deviceContext, 5),
                                                width: getCorrectWidth(deviceContext, 100),
                                                ...getPressableStyling(styling.components?.button?._id, appContext),
                                            }}
                                            animatorOptions={getFocusStyling('Buttons', appContext)}
                                            onPress={() => playItem(data[index])}
                                        >
                                            <View
                                                style={{
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    flexDirection: 'row',
                                                    height: getCorrectHeight(deviceContext, 30),
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faPlay} color={'#fff'} size={getFontSize(deviceContext, 'Normal') * 1.5} style={{ marginRight: 3 }}></FontAwesomeIcon>
                                                <Text
                                                    style={{
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                    }}
                                                >
                                                    {lang.getTranslation(userContext, 'play_movie')}
                                                </Text>
                                            </View>
                                        </Pressable>
                                    )}
                                    {styling.content.list?.type != 'Channels' && (
                                        <Pressable
                                            style={{
                                                marginVertical: getCorrectHeight(deviceContext, 5),
                                                marginRight: getCorrectHeight(deviceContext, 5),
                                                width: getCorrectWidth(deviceContext, 100),
                                                ...getPressableStyling(styling.components?.button?._id, appContext),
                                            }}
                                            animatorOptions={getFocusStyling('Buttons', appContext)}
                                            onPress={() => detailItem(data[index])}
                                        >
                                            <View
                                                style={{
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    flexDirection: 'row',
                                                    height: getCorrectHeight(deviceContext, 30),
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faSquareInfo} color={'#fff'} size={getFontSize(deviceContext, 'Normal') * 1.5} style={{ marginRight: 3 }}></FontAwesomeIcon>

                                                <Text
                                                    style={{
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                    }}
                                                >
                                                    {lang.getTranslation(userContext, 'information')}
                                                </Text>
                                            </View>
                                        </Pressable>
                                    )}

                                    {styling.content.list?.type == 'Channels' && (
                                        <View style={{ flexDirection: 'row', marginBottom: getCorrectHeight(deviceContext, 15) }}>
                                            <Pressable
                                                style={{
                                                    marginVertical: getCorrectHeight(deviceContext, 5),
                                                    marginRight: getCorrectHeight(deviceContext, 5),
                                                    width: getCorrectWidth(deviceContext, 100),
                                                    ...getPressableStyling(styling.components?.button?._id, appContext),
                                                }}
                                                animatorOptions={getFocusStyling('Buttons', appContext)}
                                                onPress={() => playItem(data[index])}
                                            >
                                                <View
                                                    style={{
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        flexDirection: 'row',
                                                        height: getCorrectHeight(deviceContext, 30),
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faPlay} color={'#fff'} size={getFontSize(deviceContext, 'Normal') * 1.5} style={{ marginRight: 10 }}></FontAwesomeIcon>

                                                    <Text
                                                        style={{
                                                            fontFamily: deviceContext.fontType,
                                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                                            ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                        }}
                                                    >
                                                        {lang.getTranslation(userContext, 'watch_now')}
                                                    </Text>
                                                </View>
                                            </Pressable>
                                            <Pressable
                                                style={{
                                                    marginVertical: getCorrectHeight(deviceContext, 5),
                                                    marginRight: getCorrectHeight(deviceContext, 5),
                                                    width: getCorrectWidth(deviceContext, 100),

                                                    ...getPressableStyling(styling.components?.button?._id, appContext),
                                                }}
                                                animatorOptions={getFocusStyling('Buttons', appContext)}
                                                onPress={() => detailItem(data[index])}
                                            >
                                                <View
                                                    style={{
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        flexDirection: 'row',
                                                        height: getCorrectHeight(deviceContext, 30),
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faSquareInfo} color={'#fff'} size={getFontSize(deviceContext, 'Normal') * 1.5} style={{ marginRight: 10 }}></FontAwesomeIcon>

                                                    <Text
                                                        style={{
                                                            fontFamily: deviceContext.fontType,
                                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                                            ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                        }}
                                                    >
                                                        {lang.getTranslation(userContext, 'information')}
                                                    </Text>
                                                </View>
                                            </Pressable>
                                        </View>
                                    )}
                                </View>
                            </>
                        )}
                    </View>
                </View>
            </View>
        </View>
    );
};
export default Hero_Player;
