import * as React from 'react';
import { DeviceContext } from '../../../application/context/deviceContext';
import Top_Portrait from '../../../application/builder/elements/details/top.portrait';
import Top_Landscape from '../../../application/builder/elements/details/top.landscape';
import AudioStandard_Portrait from '../../../application/builder/elements/details/audiostandard.portrait';
import AudioStandard_Landscape from '../../../application/builder/elements/details/audiostandard.landscape';
import { getQueryString } from '../../../application/app/helpers/getQueryString';
import { ContentContext } from '../../../application/context/contentContext';
import { ActivityIndicator, View } from 'react-native';
import { getRealHeight, getRealWidth } from '../../../application/styling/realDeviceSizes';
import { getCorrectHeight, getCorrectWidth } from '../../../application/styling/correctSizes';
import { useEffectOnce } from '../../../application/hooks/useEffectOnce';
import { AppContext } from '../../../application/context/appContext';
import { UserContext } from '../../../application/context/userContext';
import utils from '../../../application/general/utils';
import { navigate } from '../../../application/hooks/useNotifications';

export const Sharing = ({ navigation, focusContext, contentScreensAvailable }) => {
    const deviceContext = React.useContext(DeviceContext);
    const contentContext = React.useContext(ContentContext);
    const appContext = React.useContext(AppContext);
    const userContext = React.useContext(UserContext);
    const [isLoading, setIsLoading] = React.useState(true);
    const [sharingType, setSharingType] = React.useState('video');
    const [screenName, setScreenName] = React.useState('');
    const [styling, setStyling] = React.useState([] as any);

    useEffectOnce(() => {
        async function myAsynFunction() {
            if (deviceContext.isWebTV) {
                var webTvUrl = window.location.href;
                var queryStrings = getQueryString(webTvUrl) as any;
            }
            if (queryStrings?.type != undefined) {
                setSharingType(queryStrings?.type);
            }

            var contentId = queryStrings?.id;
            var contentType = queryStrings?.content;

            //check if user already has an account
            var user = await utils.retrieveJson('UserID');
            var pass = await utils.retrieveJson('Pass');

            if (user != '' && pass != '') {
                navigation.navigate('Redirect', {
                    redirect: contentType,
                    _id: contentId,
                });
            } else {
                switch (contentType) {
                    case 'Movie':
                        contentContext.setMovieData({
                            movie: {
                                _id: contentId,
                            },
                        });
                        var screen = appContext.application.screens.find((s) => s.type == 'Movie Details');
                        var details = screen.hero[0].details_top;
                        if (details != undefined) {
                            setStyling(details);
                        }
                        setScreenName('Movie');
                        setIsLoading(false);
                        break;
                    case 'Seasons':
                        contentContext.setSeriesData({
                            serie: {
                                _id: contentId,
                            },
                        });
                        var screen = appContext.application.screens.find((s) => s.type == 'Seasons');
                        var details = screen.hero[0].details_top;
                        if (details != undefined) {
                            setStyling(details);
                        }
                        setScreenName('Seasons');
                        setIsLoading(false);
                        break;
                    case 'Course':
                        contentContext.setCourseData({
                            course: {
                                _id: contentId,
                            },
                        });
                        var screen = appContext.application.screens.find((s) => s.type == 'Course Details');
                        var details = screen.hero[0].details_top;
                        if (details != undefined) {
                            setStyling(details);
                        }
                        setScreenName('Course');
                        setIsLoading(false);
                        break;
                    case 'Game':
                        contentContext.setGameData({
                            game: {
                                _id: contentId,
                            },
                        });
                        var screen = appContext.application.screens.find((s) => s.type == 'Game Details');
                        var details = screen.hero[0].details_top;
                        if (details != undefined) {
                            setStyling(details);
                        }
                        setScreenName('Game');
                        setIsLoading(false);
                        break;
                    case 'Short':
                        contentContext.setShortData({
                            short: {
                                _id: contentId,
                            },
                        });
                        var screen = appContext.application.screens.find((s) => s.type == 'Short Details');
                        var details = screen.hero[0].details_top;
                        if (details != undefined) {
                            setStyling(details);
                        }
                        setScreenName('Short');
                        setIsLoading(false);
                        break;
                    default:
                        break;
                }
            }
        }
        myAsynFunction();
    });

    return (
        <>
            {isLoading && (
                <View
                    style={{
                        flexDirection: 'row',
                        width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, global.menuWidth),
                        height: getRealHeight(deviceContext) - getCorrectHeight(deviceContext, deviceContext.isPhone || deviceContext.isPwaVertical ? 0 : 100),
                    }}
                >
                    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                        <ActivityIndicator size={'large'} color={'red'}></ActivityIndicator>
                    </View>
                </View>
            )}
            {!isLoading && sharingType == 'video' && (
                <>
                    {(deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical) && (
                        <Top_Portrait styling={styling} navigation={navigation} focusContext={focusContext} contentScreensAvailable={contentScreensAvailable} isFromSocialSharing={true} socialScreenName={screenName}></Top_Portrait>
                    )}
                    {!deviceContext.isPhone && !deviceContext.isKaiOs && !deviceContext.isPwaVertical && (
                        <Top_Landscape styling={styling} navigation={navigation} focusContext={focusContext} contentScreensAvailable={contentScreensAvailable} isFromSocialSharing={true} socialScreenName={screenName}></Top_Landscape>
                    )}
                </>
            )}
            {!isLoading && sharingType == 'audio' && (
                <>
                    {(deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical) && (
                        <AudioStandard_Portrait styling={styling} navigation={navigation} focusContext={focusContext} isFromSocialSharing={true} socialScreenName={screenName}></AudioStandard_Portrait>
                    )}
                    {!deviceContext.isPhone && !deviceContext.isKaiOs && !deviceContext.isPwaVertical && (
                        <AudioStandard_Landscape styling={styling} navigation={navigation} focusContext={focusContext} isFromSocialSharing={true} socialScreenName={screenName}></AudioStandard_Landscape>
                    )}
                </>
            )}
        </>
    );
};

export default Sharing;
