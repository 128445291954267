import { Pressable, ScrollView, View, Screen } from '@scriptx-com/xtv-toolkit';
import { useFocusEffect } from '@react-navigation/native';
import { isPlatformAndroid, isPlatformIos } from '@rnv/renative';
import moment from 'moment';
import * as React from 'react';
import { AppContext } from '../../../application/context/appContext';
import { DeviceContext } from '../../../application/context/deviceContext';
import { UserContext } from '../../../application/context/userContext';
import lang from '../../../application/languages/languages';
import { getCorrectHeight, getCorrectWidth } from '../../../application/styling/correctSizes';
import { getFontSize } from '../../../application/styling/fontSizes';
import { getRealWidth } from '../../../application/styling/realDeviceSizes';
import { useSafeAreaInsets } from '../../../libs/react-native-safe-area-context';
import { sendPageReport } from '../../../reporting/apis/page';
import { pageType } from '../../../reporting/models/apps';
import { getFocusOptions } from '../../wrapper/helpers/getFocusOptions';
import Text from '../../../application/builder/components/text';
import { ContentContext } from '../../../application/context/contentContext';

const ManualScreen = ({ navigation, route, focusContext }): React.ReactElement => {
    const appContext = React.useContext(AppContext);
    const deviceContext = React.useContext(DeviceContext);
    const userContext = React.useContext(UserContext);
    const contentContext = React.useContext(ContentContext);
    const insets = useSafeAreaInsets();
    const [show, setShow] = React.useState(false);

    React.useEffect(() => {
        setShow(true);
    }, [navigation, route]);

    useFocusEffect(
        React.useCallback(() => {
            contentContext.setActiveMenu(route.name);
            const start = moment().unix();
            return () => {
                let duration = moment().unix() - start;
                sendPageReport(appContext, deviceContext, userContext, pageType.APPINFO, duration);
            };
        }, [])
    );

    const focusOptions = getFocusOptions(deviceContext, appContext, 'settings-screen');

    return (
        <Screen focusContext={focusContext} focusOptions={{ ...focusOptions }} style={{ flex: 1, width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext,  global.settingsMenu) }}>
            <View
                style={{
                    height:getCorrectHeight(deviceContext,100),
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: appContext.application.theme.settings.background_color1,
                    borderRadius: appContext.application.theme.settings.border_radius,
                    margin: getCorrectWidth(deviceContext, 10),
                }}
            >
                <Text
                    style={{
                        fontFamily: deviceContext.fontType,
                        fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                        fontWeight: appContext.application.authentication?.services?.header.title_bold ? 'bold' : 'normal',
                        color: '#ffffff',
                    }}
                >
                    {lang.getTranslation(userContext, 'app_manual')}
                </Text>
                <Text
                    style={{
                        fontFamily: deviceContext.fontType,
                        fontSize: getFontSize(deviceContext, appContext.application.authentication?.services?.header.sub_size),
                        fontWeight: appContext.application.authentication?.services?.header.sub_bold ? 'bold' : 'normal',
                        color: '#ffffff',
                        textAlign: 'center',
                    }}
                >
                    {lang.getTranslation(userContext, 'app_manual_info').toUpperCase()}
                </Text>
            </View>
            <View style={{ flex: 4, alignItems: 'center', paddingBottom: getCorrectHeight(deviceContext, deviceContext.isTelevision ? 0 : 30) }}>
                <ScrollView
                    contentContainerStyle={{
                        paddingBottom: insets.bottom + (isPlatformAndroid ? 40 : isPlatformIos ? 120 : 40),
                        ...(deviceContext.isWebTV && {
                            height: getCorrectHeight(deviceContext, 180),
                        }),
                    }}
                >
                    <Pressable
                        focusOptions={{
                            hasPreferredFocus: true,
                            nextFocusLeft: ['settings-buttons'],
                            animator: {
                                type: 'scale',
                                focus: {
                                    scale: 1.01,
                                },
                            },
                        }}
                        style={{
                            padding: getCorrectWidth(deviceContext, 20),
                            flexDirection: 'row',
                            width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext,  20 + global.settingsMenu),
                            backgroundColor: appContext.application.theme.settings.background_color2,
                            borderRadius: appContext.application.theme.settings.border_radius,

                            margin: getCorrectWidth(deviceContext, 10),
                        }}
                    >
                        <View style={{ flex: 1, flexDirection: 'column' }}>
                            <View style={{ flexDirection: 'row' }}>
                                <Text
                                    style={{
                                        color: appContext.application.theme.settings.font_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'app_manual_profiles')}
                                </Text>
                            </View>
                        </View>
                    </Pressable>
                    <Pressable
                        focusOptions={{
                            nextFocusLeft: ['settings-buttons'],
                            animator: {
                                type: 'scale',
                                focus: {
                                    scale: 1.01,
                                },
                            },
                        }}
                        style={{
                            padding: getCorrectWidth(deviceContext, 20),
                            flexDirection: 'row',
                            width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext,  20 + global.settingsMenu),
                            backgroundColor: appContext.application.theme.settings.background_color2,
                            borderRadius: appContext.application.theme.settings.border_radius,

                            margin: getCorrectWidth(deviceContext, 10),
                        }}
                    >
                        <View style={{ flex: 1, flexDirection: 'column' }}>
                            <View style={{ flexDirection: 'row' }}>
                                <Text
                                    style={{
                                        color: appContext.application.theme.settings.font_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'app_manual_longpress')}
                                </Text>
                            </View>
                        </View>
                    </Pressable>
                    <Pressable
                        focusOptions={{
                            nextFocusLeft: ['settings-buttons'],
                            animator: {
                                type: 'scale',
                                focus: {
                                    scale: 1.01,
                                },
                            },
                        }}
                        style={{
                            padding: getCorrectWidth(deviceContext, 20),
                            flexDirection: 'row',
                            width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext,  20 + global.settingsMenu),
                            backgroundColor: appContext.application.theme.settings.background_color2,
                            borderRadius: appContext.application.theme.settings.border_radius,

                            margin: getCorrectWidth(deviceContext, 10),
                        }}
                    >
                        <View style={{ flex: 1, flexDirection: 'column' }}>
                            <View style={{ flexDirection: 'row' }}>
                                <Text
                                    style={{
                                        color: appContext.application.theme.settings.font_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'app_manual_osd')}
                                </Text>
                            </View>
                        </View>
                    </Pressable>
                </ScrollView>
            </View>
        </Screen>
    );
};
export default ManualScreen;
