import * as React from 'react';
import { View, Image as Image_, PressableFocusOptions } from '@scriptx-com/xtv-toolkit';
import { getFontSize } from '../../../styling/fontSizes';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faPlay } from '@fortawesome/pro-light-svg-icons';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import Pressable from '../../components/pressable/pressable';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { watchingStatus } from '../../../data/account';
import { getCurrentScreenName } from '../../../hooks/getScreenName';
import { UserContext } from '../../../context/userContext';
import { ContentContext } from '../../../context/contentContext';
import moment from 'moment';
import { InteractiveTV } from '../../../models/content/shared/interactivetv';
import { getLiveImage, getTimedImage } from '../../elements/players/helpers/functions';
import { DeviceContext } from '../../../context/deviceContext';
import lang from '../../../languages/languages';
import { AppContext } from '../../../context/appContext';
import { Translations } from '../../../models/content/shared/translations';
import Text from '../../components/text';
import { getFocusStyling, getPressableStyling } from '../../components/helpers/helper';
import { getLiveTag, getReplayTag } from '../players/helpers/helpers';
import { RemoteImageHeightWidth } from '../../components/remoteimage/remoteimage';
import utils from '../../../general/utils';

export interface Program {
    n: string;
    s: any;
    e: any;
}

export interface Data {
    name: string;
    _id: string;
    number?: string;
    duration?: number;
    images: {
        widescreen?: string;
        square?: string;
        tile?: string;
        background?: string;
        poster?: string;
        logo?: string;
    };
    catchuptv?: boolean;
    interactivetv?: InteractiveTV;
    progress?: number;
    program?: Program;
    channel?: any;
    translations?: Translations;
    color?: string;
    package?: string;
}

export interface Props {
    focusContext?: any;
    focusOptions?: PressableFocusOptions;
    focusRepeatContext?: any;
    noMargin?: boolean;
    height: number;
    width: number;
    onPress: any;
    styling: any;
    data: Data;
    type: string;
    rails?: boolean;
    onLongPress: any;
    heroIsPlayer: boolean;
    elementType: string;
    onFocus?: () => void;
    onBlur?: () => void;
    isFirst?: boolean;
    isLast?: boolean;
    isFavorite?: boolean;
}

export const Banner = ({ focusContext, focusRepeatContext, noMargin, height, width, onPress, styling, type, data, onLongPress, focusOptions, heroIsPlayer, elementType, isFavorite = false, ...rest }: Props) => {
    const appContext = React.useContext(AppContext);
    const userContext = React.useContext(UserContext);
    const contentContext = React.useContext(ContentContext);
    const deviceContext = React.useContext(DeviceContext);
    const screenName = getCurrentScreenName();
    const timeout = React.useRef<NodeJS.Timeout>();

    const onFocus = (data) => {
        timeout.current = setTimeout(() => {
            if (styling.content?.link_to_hero != undefined && styling.content?.link_to_hero && heroIsPlayer) {
                contentContext.setHero({
                    type: type,
                    data: [data],
                });
            }
        }, 1000);
    };

    const onBlur = () => {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
    };

    var progress = -1;

    if (type == 'Seasons') {
        var watching = watchingStatus(type, contentContext.seriesData.series._id, userContext);
        if (watching != undefined && watching.continue != undefined) {
            var episode = watching.continue.episodes?.find((e) => e?._id == data?._id);
            if (episode != undefined) {
                progress = (episode.position / episode.duration) * 100;
            } else {
                progress = 0;
            }
        } else {
            progress = 0;
        }
    }
    if (type == 'Lessons') {
        var watching = watchingStatus('Courses', contentContext.courseData.course._id, userContext);
        if (watching != undefined && watching.position != undefined) {
            var lesson = watching.lessons.find((e) => e._id == data?._id);
            if (lesson != undefined) {
                progress = (lesson.position / lesson.duration) * 100;
            } else {
                progress = 0;
            }
        } else {
            progress = 0;
        }
    }
    if (type == 'Channels') {
        if (contentContext.epgTv[0] != undefined) {
            var epgAll = contentContext.epgTv?.find((e) => e.i == data?._id);

            if (epgAll != undefined) {
                var currentTime = moment().unix();
                var currentItem = epgAll.epg?.find((e) => e.s <= currentTime && e.e >= currentTime);

                if (currentItem != undefined) {
                    var totalProgram = currentItem.e - currentItem.s;
                    var percentageProgram = ((currentTime - currentItem.s) / totalProgram) * 100;
                    data.program = currentItem;
                    progress = percentageProgram;
                    data.catchuptv = data?.interactivetv.pausetv_enabled;
                } else {
                    progress = 0;
                }
            } else {
                progress = 0;
            }
        }
    }
    if (screenName == 'Cams') {
        var epgAll1 = contentContext.epgCam.find((e) => e.i == data?._id);
        if (epgAll1 != undefined) {
            var currentTime = moment().unix();
            var currentItem = epgAll.epg.find((e) => e.s <= currentTime && e.e > currentTime);
            if (currentItem != undefined) {
                var totalProgram = currentItem.e - currentItem.s;
                var percentageProgram = ((currentTime - currentItem.s) / totalProgram) * 100;
                data.program = currentItem;
                progress = percentageProgram;
            } else {
                progress = 0;
            }
        } else {
            progress = 0;
        }
    }

    if (screenName == 'Channel_Player') {
        if (contentContext.epgTv[0] != undefined) {
            var epgAll = contentContext.epgTv?.find((e) => e.i == data?._id);
            if (epgAll != undefined) {
                var currentTime = moment().unix();
                var currentItem = epgAll.epg.find((e) => e.s <= currentTime && e.e > currentTime);
                if (currentItem != undefined) {
                    var totalProgram = currentItem.e - currentItem.s;
                    var percentageProgram = ((currentTime - currentItem.s) / totalProgram) * 100;
                    data.program = currentItem;
                    progress = percentageProgram;
                    data.catchuptv = data?.interactivetv.pausetv_enabled;
                }
            } else {
                progress = 0;
            }
        }
    }
    if (screenName == 'Channels') {
        if (contentContext.epgTv[0] != undefined) {
            var epgAll = contentContext.epgTv?.find((e) => e.i == data?._id);
            if (epgAll != undefined) {
                var currentTime = moment().unix();
                var currentItem = epgAll.epg.find((e) => e.s <= currentTime && e.e > currentTime);
                if (currentItem != undefined) {
                    var totalProgram = currentItem.e - currentItem.s;
                    var percentageProgram = ((currentTime - currentItem.s) / totalProgram) * 100;
                    data.program = currentItem;
                    progress = percentageProgram;
                    data.catchuptv = data?.interactivetv.pausetv_enabled;
                }
            } else {
                progress = 0;
            }
        }
    }
    if (screenName == 'Radio') {
        if (contentContext.epgRadio[0] != undefined) {
            var epgAll = contentContext.epgRadio.find((e) => e.i == data?._id);
            if (epgAll != undefined) {
                var currentTime = moment().unix();
                var currentItem = epgAll.epg.find((e) => e.s <= currentTime && e.e > currentTime);
                if (currentItem != undefined) {
                    var totalProgram = currentItem.e - currentItem.s;
                    var percentageProgram = ((currentTime - currentItem.s) / totalProgram) * 100;
                    data.program = currentItem;
                    progress = percentageProgram;
                    data.catchuptv = data?.interactivetv.pausetv_enabled;
                }
            } else {
                progress = 0;
            }
        }
    }
    var recordingDone = false;
    var recordingPlanned = false;
    var recordingProgress = false;
    if (screenName == 'Recordings') {
        var currentTime = moment().unix();
        if (currentTime > data?.program?.e) {
            recordingDone = true;
        } else if (currentTime > data?.program?.s && currentTime < data?.program?.e) {
            recordingProgress = true;
        } else {
            recordingPlanned = true;
        }
    }

    const focusWidth = () => {
        return getPressableStyling(styling.components?.button._id, appContext).borderWidth * 2;
    };

    const getCorrectScaleStyling = () => {
        return getFocusStyling(elementType, appContext).type.indexOf('scale') > -1 && elementType == 'Rails' && deviceContext.formFactor == 'Television';
    };

    if (data == undefined) {
        return null;
    } else {
        return (
            <View
                style={{
                    flex: 1,
                    paddingLeft: 3,
                    ...(!deviceContext.isPhone && { marginLeft: rest.isFirst ? getCorrectWidth(deviceContext, 100) : 0, marginRight: rest.isLast ? getCorrectWidth(deviceContext, 100) : 0 }),
                }}
            >
                <View style={getCorrectScaleStyling() ? { height: height * 1.1, justifyContent: 'center' } : {}}>
                    <Pressable
                        style={[
                            {
                                ...getPressableStyling(styling.components?.button._id, appContext),
                                marginBottom: elementType == 'Rails' ? 0 : getCorrectWidth(deviceContext, deviceContext.isPhone || deviceContext.isPwaVertical ? 5 : 4),
                                width: width - getCorrectWidth(deviceContext, deviceContext.isPhone ? 10 : 5),
                                backgroundColor: data.color != undefined ? data.color : getPressableStyling(styling.components?.button._id, appContext).backgroundColor,
                            },
                        ]}
                        focusContext={focusContext}
                        focusRepeatContext={focusRepeatContext}
                        focusOptions={focusOptions}
                        onFocus={() => {
                            rest.onFocus ? rest.onFocus() : onFocus(data);
                        }}
                        onBlur={() => (rest.onBlur ? rest.onBlur() : onBlur())}
                        animatorOptions={getFocusStyling(elementType, appContext)}
                        onPress={() => (screenName == 'Recordings' ? onPress(data) : onPress(data?._id))}
                        onLongPress={() => onLongPress(data?._id)}
                        isFirst={rest.isFirst}
                        isLast={rest.isLast}
                    >
                        <View>
                            <View
                                style={{
                                    margin: getCorrectHeight(deviceContext, appContext.application.theme.images.margin),
                                    borderRadius: getCorrectWidth(deviceContext, appContext.application.theme.images.radius) / 2,
                                    backgroundColor: appContext.application.theme.images?.background,
                                    alignItems: 'center',
                                }}
                            >
                                <Image_
                                    source={{ uri: utils.getCorrectStoragePrefix(appContext, true, data?.images?.background) }}
                                    resizeMethod={'scale'}
                                    resizeMode={'cover'}
                                    style={{
                                        borderRadius: getCorrectWidth(deviceContext, appContext.application.theme.images.radius) / 3,
                                        height: height,
                                        width: width - getCorrectHeight(deviceContext, appContext.application.theme.images.margin * 2) - getCorrectWidth(deviceContext, deviceContext.isPhone ? 10 : 6),
                                    }}
                                />

                                {(type == 'Channels' || screenName == 'Channels' || type == 'Cams' || screenName == 'Cams') && styling.texts.placement_text == 'Below' && (
                                    <View
                                        style={{
                                            position: 'absolute',
                                            zIndex: 1,
                                            top: 5,
                                            left: 5,
                                            width: '100%',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        <Image_
                                            deviceContext={deviceContext}
                                            source={{ uri: utils.getCorrectStoragePrefix(appContext, true, data?.images?.square) }}
                                            resizeMethod={'resize'}
                                            resizeMode={'cover'}
                                            style={{
                                                borderRadius: getCorrectWidth(deviceContext, appContext.application.theme.images.radius) / 2,
                                                height: getCorrectWidth(deviceContext, 30),
                                                width: getCorrectWidth(deviceContext, 30),
                                            }}
                                        />
                                    </View>
                                )}
                                {(type == 'Channels' || screenName == 'Channels' || type == 'Cams' || screenName == 'Cams') && appContext.application.theme.icons.show_live && styling.texts.placement_text == 'Above' && (
                                    <View
                                        style={{
                                            position: 'absolute',
                                            zIndex: 999,
                                            top: getCorrectWidth(deviceContext, appContext.application.theme.images.margin),
                                            right: getCorrectWidth(deviceContext, appContext.application.theme.images.margin),
                                            flexDirection: 'row',
                                        }}
                                    >
                                        <>{getLiveTag(lang.getTranslation(userContext, 'live').toUpperCase(), appContext, deviceContext)}</>
                                        {data?.catchuptv && <>{getReplayTag(lang.getTranslation(userContext, 'replay').toUpperCase(), appContext, deviceContext)}</>}
                                    </View>
                                )}
                                {(type == 'Channels' || screenName == 'Channels' || type == 'Cams' || screenName == 'Cams') && appContext.application.theme.icons.show_live && styling.texts.placement_text != 'Above' && (
                                    <View
                                        style={{
                                            position: 'absolute',
                                            zIndex: 999,
                                            bottom: getCorrectWidth(deviceContext, appContext.application.theme.images.margin) + 5,
                                            right: getCorrectWidth(deviceContext, appContext.application.theme.images.margin),
                                            flexDirection: 'row',
                                        }}
                                    >
                                        <>{getLiveTag(lang.getTranslation(userContext, 'live').toUpperCase(), appContext, deviceContext)}</>
                                        {data?.catchuptv && <>{getReplayTag(lang.getTranslation(userContext, 'replay').toUpperCase(), appContext, deviceContext)}</>}
                                    </View>
                                )}
                                {(type == 'Channels' || screenName == 'Channels' || type == 'Cams' || screenName == 'Cams') && appContext.application.theme.icons.show_favorite && isFavorite && (
                                    <View
                                        style={{
                                            position: 'absolute',
                                            top: 5,
                                            right: 5,
                                            width: '100%',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-end',
                                        }}
                                    >
                                        <View
                                            style={{
                                                backgroundColor: '#333',
                                                borderRadius: 100,
                                                padding: getCorrectHeight(deviceContext, 3),
                                                marginLeft: getCorrectWidth(deviceContext, 3),
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faHeart} color={'#fff'} size={getFontSize(deviceContext, 'Normal')} />
                                        </View>
                                    </View>
                                )}

                                {data?.number == undefined && data?.images?.logo != '' && (
                                    <View style={{ position: 'absolute', left: 10, top: 10 }}>
                                        <RemoteImageHeightWidth uri={utils.getCorrectStoragePrefix(appContext, true, data?.images?.logo)} desiredWidth={width * 0.3} desiredHeight={height * 0.2} deviceContext={deviceContext} />
                                    </View>
                                )}
                            </View>
                        </View>
                    </Pressable>
                </View>
            </View>
        );
    }
};
export default Banner;
