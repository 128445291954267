import React, { PureComponent } from 'react';
import { Image, StyleProp, View, ViewStyle } from 'react-native';
import type { TextTrackCue } from 'react-native-theoplayer';
import { isThumbnailTrack, TextTrack } from 'react-native-theoplayer';
import { StaticTimeLabel } from '@theoplayer/react-native-ui';
import type { Thumbnail } from './Thumbnail';
import { isTileMapThumbnail } from './Thumbnail';
import { URL as URLPolyfill } from './Urlpolyfill';
import utils from '../../../general/utils';
import _ from 'lodash';
import { getFontSize } from '../../../styling/fontSizes';

const SPRITE_REGEX = /^([^#]*)#xywh=(\d+),(\d+),(\d+),(\d+)\s*$/;
const TAG = 'ThumbnailView';

interface ThumbnailViewState {
    imageWidth: number;
    imageHeight: number;
    renderWidth: number;
    renderHeight: number;
}

export interface ThumbnailViewProps {
    /**
     * Thumbnail track. A valid thumbnail track should have properties:
     * <br/> - `'kind'` equals `'metadata'`.
     * <br/> - `'label'` equals `'thumbnails'`.
     */
    thumbnailTrack: TextTrack;

    /**
     * Current time.
     */
    time: number;

    /**
     * Stream duration
     */
    duration: number;

    /**
     * Whether to show a time label.
     */
    showTimeLabel: boolean;

    /**
     * Used to set the width of the rendered thumbnail. The height will be calculated according to the image's aspect ratio.
     */
    size: number;

    /**
     * Optional style applied to the time label.
     */
    timeLabelStyle?: StyleProp<ViewStyle>;

    appContext: any;
    deviceContext: any;
    seekBarWidth: number;
}

export const DEFAULT_THUMBNAIL_VIEW_STYLE: ThumbnailStyle = {
    containerThumbnail: {
        alignItems: 'center',
        flexDirection: 'row',
    },
    thumbnail: {
        overflow: 'hidden',
        backgroundColor: 'transparent',
    },
};

export interface ThumbnailStyle {
    containerThumbnail: ViewStyle;
    thumbnail: ViewStyle;
}

export class MultipleThumbnail extends PureComponent<ThumbnailViewProps, ThumbnailViewState> {
    static defaultProps = {
        showTimeLabel: true,
    };
    private _ismounted = false;

    constructor(props: ThumbnailViewProps) {
        super(props);
        const { size } = props;
        this.state = { imageWidth: size, imageHeight: size, renderWidth: size, renderHeight: 1 };
    }

    componentDidMount() {
        this._ismounted = true;
    }

    componentWillUnmount() {
        this._ismounted = false;
    }

    private getCueIndexAtTime(time: number): number | undefined {
        const { thumbnailTrack } = this.props;

        // Ignore if it's an invalid track or not a thumbnail track.
        if (!isThumbnailTrack(thumbnailTrack)) {
            console.warn(TAG, 'Invalid thumbnail track');
            return undefined;
        }

        // Ignore if the track does not have cues
        if (thumbnailTrack.cues == null || thumbnailTrack.cues.length == 0) {
            return undefined;
        }

        const cues = thumbnailTrack.cues;
        let cueIndex = 0;
        for (const [index, cue] of cues.entries()) {
            if (cue.startTime <= time) {
                cueIndex = index;
            } else if (time >= cue.endTime) {
                return cueIndex;
            }
        }
        return cueIndex;
    }

    private resolveThumbnailUrl(thumbnail: string, appContext): string {
        thumbnail = utils.getCorrectStoragePrefix(appContext, true, thumbnail);
        const { thumbnailTrack } = this.props;
        // NOTE: TextTrack.src is supported in Android SDK as of 3.5+
        if (thumbnailTrack && thumbnailTrack.src) {
            return new URLPolyfill(thumbnail, thumbnailTrack.src).href;
        } else {
            return thumbnail;
        }
    }

    private getThumbnailImageForCue(cue: TextTrackCue, appContext): Thumbnail | null {
        const thumbnailContent = cue && cue.content;
        if (!thumbnailContent) {
            // Cue does not contain any thumbnail info.
            return null;
        }
        const spriteMatch = thumbnailContent.match(SPRITE_REGEX);
        if (spriteMatch) {
            // The thumbnail is part of a tile.
            const [, url, x, y, w, h] = spriteMatch;
            return {
                tileX: +x,
                tileY: +y,
                tileWidth: +w,
                tileHeight: +h,
                url: this.resolveThumbnailUrl(url, appContext),
            };
        } else {
            // The thumbnail is a separate image.
            return {
                url: this.resolveThumbnailUrl(thumbnailContent, appContext),
            };
        }
    }

    private onTileImageLoad = (thumbnail: Thumbnail) => () => {
        if (!this._ismounted) {
            return;
        }
        const { size } = this.props;
        const { tileWidth, tileHeight } = thumbnail;
        if (tileWidth && tileHeight) {
            Image.getSize(thumbnail.url, (width: number, height: number) => {
                this.setState({
                    imageWidth: width,
                    imageHeight: height,
                    renderWidth: size,
                    renderHeight: (tileHeight * size) / tileWidth,
                });
            });
        }
    };

    private onImageLoadError = (event: any) => {
        console.error(TAG, 'Failed to load thumbnail url:', event.nativeEvent.error);
    };

    private onImageLoad = (thumbnail: Thumbnail) => () => {
        if (!this._ismounted) {
            return;
        }
        const { size } = this.props;
        Image.getSize(thumbnail.url, (width: number, height: number) => {
            this.setState({
                imageWidth: width,
                imageHeight: height,
                renderWidth: size,
                renderHeight: (height * size) / width,
            });
        });
    };

    private renderThumbnail = (thumbnail: Thumbnail, index: number, current: boolean) => {
        const { imageWidth, imageHeight, renderWidth, renderHeight} = this.state;
        const { size } = this.props;
        const scale = current ? 1.1 : 0.9;

        if (isTileMapThumbnail(thumbnail)) {
            const ratio = thumbnail.tileWidth == 0 ? 0 : (scale * size) / thumbnail.tileWidth;
            return (
                <View
                    key={index}
                    style={[
                        DEFAULT_THUMBNAIL_VIEW_STYLE.thumbnail,
                        {
                            width: scale * renderWidth,
                            height: scale * renderHeight + 5,
                            borderColor: current ? '#fff' : '#999',
                            borderWidth: 3,
                            borderRadius: 5,
                        },
                    ]}
                >
                    <Image
                        resizeMode={'cover'}
                        style={{
                            position: 'absolute',
                            top: -ratio * thumbnail.tileY,
                            left: -ratio * thumbnail.tileX,
                            width: ratio * imageWidth,
                            height: ratio * imageHeight,
                        }}
                        source={{ uri: thumbnail.url }}
                        onError={this.onImageLoadError}
                        onLoad={this.onTileImageLoad(thumbnail)}
                    />
                </View>
            );
        } else {
            return (
                <View key={index} style={[DEFAULT_THUMBNAIL_VIEW_STYLE.thumbnail, { width: scale * renderWidth, height: scale * renderHeight }]}>
                    <Image resizeMode={'contain'} style={{ width: scale * size, height: scale * renderHeight }} source={{ uri: thumbnail.url }} onError={this.onImageLoadError} onLoad={this.onImageLoad(thumbnail)} />
                </View>
            );
        }
    };

    render() {
        const { time, duration, thumbnailTrack, showTimeLabel, timeLabelStyle, appContext, deviceContext, seekBarWidth } = this.props;
        if (!thumbnailTrack || !thumbnailTrack.cues || thumbnailTrack.cues.length === 0) {
            // No thumbnails to render.
            return <></>;
        }

        const nowCueIndex = this.getCueIndexAtTime(time);
        if (nowCueIndex === undefined) {
            // No thumbnail for current time
            return <></>;
        }

        const first = this.getThumbnailImageForCue(thumbnailTrack.cues[nowCueIndex - 2], appContext);
        const second = this.getThumbnailImageForCue(thumbnailTrack.cues[nowCueIndex - 1], appContext);
        const current = this.getThumbnailImageForCue(thumbnailTrack.cues[nowCueIndex], appContext);
        const fourth = this.getThumbnailImageForCue(thumbnailTrack.cues[nowCueIndex + 1], appContext);
        const fifth = this.getThumbnailImageForCue(thumbnailTrack.cues[nowCueIndex + 2], appContext);

        if (current === null) {
            // No thumbnail for current time
            return <></>;
        }
        const { renderHeight } = this.state;
        return (
            <View style={{ flexDirection: 'column' }}>
                <StaticTimeLabel
                    style={[
                        {
                            position: 'absolute',
                            zIndex: 999,
                            bottom: 20,
                            marginLeft: 20,
                            height: 20,
                            alignSelf: 'center',
                            fontSize: getFontSize(deviceContext, 'Normal'),
                        },
                        timeLabelStyle,
                    ]}
                    time={time}
                    duration={duration * 1000}
                    showDuration={true}
                />

                <View style={[DEFAULT_THUMBNAIL_VIEW_STYLE.containerThumbnail, { height: renderHeight, width: seekBarWidth }]}>
                    <View style={{ flex: 2, flexDirection: 'row', justifyContent: 'flex-end' }}>
                        <View style={{ margin: 5 }}>{first && this.renderThumbnail(first, 0, false)}</View>
                        <View style={{ margin: 5 }}>{second && this.renderThumbnail(second, 0, false)}</View>
                    </View>
                    <View style={{ margin: 5 }}>{this.renderThumbnail(current, 0, true)}</View>
                    <View style={{ flex: 2, flexDirection: 'row' }}>
                        <View style={{ margin: 5 }}>{fourth && this.renderThumbnail(fourth, 0, false)}</View>
                        <View style={{ margin: 5 }}>{fifth && this.renderThumbnail(fifth, 0, false)}</View>
                    </View>
                </View>
            </View>
        );
    }
}
