import React, { useState } from 'react';
import Pressable from '../pressable/pressable';
import { getCorrectHeight } from '../../../styling/correctSizes';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { getFocusStyling, getPressableStyling, getPressableTextStyling } from '../helpers/helper';
import { getFontSize } from '../../../styling/fontSizes';
import Text from '../text';
import { AppContext } from '../../../context/appContext';
import { UserContext } from '../../../context/userContext';
import { DeviceContext } from '../../../context/deviceContext';
import { AddCachingTaskEvent, CacheEventType, CachingTask, CachingTaskEventType, CachingTaskParameters, MediaCache, SourceDescription, useCachingTaskList, useCachingTaskProgress, useCachingTaskStatus } from 'react-native-theoplayer';
import { faDownload, faTrash } from '@fortawesome/pro-light-svg-icons';
import lang from '../../../../application/languages/languages';
import { View } from '@scriptx-com/xtv-toolkit';
import { useEffectOnce } from '../../../hooks/useEffectOnce';
import CircularProgress from '../circularprogress/circularprogress';
import { getStreamTypeCasting } from '../../elements/players/helpers/stream';
import { colorShade } from '../../../styling/colorShade';
import RNFetchBlob from 'rn-fetch-blob';

export const DownloadButton = (props: { styling: any; screenName: string; item: any; text?: any; type?: string }): React.ReactElement => {
    var addTaskEvent = undefined;
    var stateTaskEvent = undefined;
    var testTask = undefined;
    const { styling, screenName, item, text, type } = props;
    const appContext = React.useContext(AppContext);
    const userContext = React.useContext(UserContext);
    const deviceContext = React.useContext(DeviceContext);
    const [task, setTask] = useState([] as any);
    const [downloadStatus, setDownloadStatus] = useState('false');

    const downloadItem = () => {
        downloadContent();
    };

    useEffectOnce(() => {
        const tasks = MediaCache.tasks as any;
        var task = tasks.find((f) => f.source.metadata._id == item._id);
        if (task == undefined) {
            setDownloadStatus('false');
        } else {
            setDownloadStatus('done');
            const fileExists = RNFetchBlob.fs.exists(`${RNFetchBlob.fs.dirs.DocumentDir}/downloads/${item._id}.json`).then((exist) => {
                if (!exist) {
                    RNFetchBlob.fs.writeFile(`${RNFetchBlob.fs.dirs.DocumentDir}/downloads/${item._id}.json`, JSON.stringify(item), 'utf8');
                }
            });
        }
    });

    const getCorrectStream = () => {
        if (screenName == 'Movie') {
            return {
                src: item.streams.movies.url,
                type: getStreamTypeCasting(item.streams.movies.url),
            };
        }
        if (screenName == 'Series') {
            return {
                src: item.streams.series.url,
                type: getStreamTypeCasting(item.streams.series.url),
            };
        }
        if (screenName == 'Course') {
            return {
                src: item.streams.courses.url,
                type: getStreamTypeCasting(item.streams.courses.url),
            };
        }
        if (screenName == 'Short') {
            return {
                src: item.streams.shorts.url,
                type: getStreamTypeCasting(item.streams.shorts.url),
            };
        }
        return {
            src: '',
            type: '',
        };
    };

    const downloadContent = async () => {
        const stream = getCorrectStream();
        const source = {
            sources: [stream],
            metadata: {
                contentType: screenName,
                poster: item.images?.poster,
                widescreen: item.images?.widescreen,
                name: item.name,
                _id: item._id,
                translations: item.translations,
            },
        } as SourceDescription;

        // Caching parameters
        const parameters = {
            allowsCellularAccess: true,
            // Cache the whole stream
            amount: '100%',
            // Cache for 24 hours
            expirationDate: new Date(Date.now() + 24 * 60 * 60 * 1000 * 365),
        } as CachingTaskParameters;

        // Create a caching task
        await MediaCache.createTask(source, parameters);
        RNFetchBlob.fs.writeFile(`${RNFetchBlob.fs.dirs.DocumentDir}/downloads/${item._id}.json`, JSON.stringify(item), 'utf8');
    };

    React.useEffect(() => {
        addTaskEvent = MediaCache.addEventListener(CacheEventType.addtask, (event: AddCachingTaskEvent) => {
            if (event.task.status == 'idle' && downloadStatus == 'false') {
                setTask(event.task);
                setDownloadStatus('loading');
                event.task.start();
            }
        });
        return () => {
            MediaCache.removeEventListener(CacheEventType.addtask, addTaskEvent);
        };
    }, []);

    const downloadCancel = async () => {
        const fileExists = await RNFetchBlob.fs.exists(`${RNFetchBlob.fs.dirs.DocumentDir}/downloads/${item._id}.json`);
        if (fileExists) {
            await RNFetchBlob.fs.unlink(`${RNFetchBlob.fs.dirs.DocumentDir}/downloads/${item._id}.json`);
        }
        const tasks = MediaCache.tasks as any;
        var task = tasks.find((f) => f.source.metadata._id == item._id);
        if (task != undefined) {
            task.remove();
            setDownloadStatus('false');
        }
    };
    const downloadRemove = async () => {
        const fileExists = await RNFetchBlob.fs.exists(`${RNFetchBlob.fs.dirs.DocumentDir}/downloads/${item._id}.json`);
        if (fileExists) {
            await RNFetchBlob.fs.unlink(`${RNFetchBlob.fs.dirs.DocumentDir}/downloads/${item._id}.json`);
        }
        const tasks = MediaCache.tasks as any;
        var task = tasks.find((f) => f.source.metadata._id == item._id);
        if (task != undefined) {
            task.remove();
            setDownloadStatus('false');
        }
    };

    return (
        <View>
            <Pressable
                style={{
                    height: getCorrectHeight(deviceContext, text != undefined ? 35 : type != undefined ? 20 : 30),
                    width: getCorrectHeight(deviceContext, text != undefined ? 35 : type != undefined ? 20 : 30),
                    ...getPressableStyling(styling.components?.button?._id, appContext),
                    backgroundColor: colorShade(getPressableStyling(styling.components?.button?._id, appContext).backgroundColor, 95),
                    borderRadius: 100,
                }}
                animatorOptions={getFocusStyling('Buttons', appContext)}
                onPress={() => (downloadStatus == 'loading' ? downloadCancel() : downloadStatus == 'done' ? downloadRemove() : downloadItem())}
            >
                <>
                    {downloadStatus != 'loading' && downloadStatus != 'done' && (
                        <View
                            style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: getCorrectHeight(deviceContext, text != undefined ? 35 : type != undefined ? 20 : 30),
                                width: getCorrectHeight(deviceContext, text != undefined ? 35 : type != undefined ? 20 : 30),
                            }}
                        >
                            <FontAwesomeIcon icon={faDownload} color={getPressableTextStyling(styling.components?.button?._id, appContext).color} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>
                        </View>
                    )}
                    {downloadStatus == 'loading' && (
                        <View
                            style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: getCorrectHeight(deviceContext, text != undefined ? 35 : type != undefined ? 20 : 30),
                                width: getCorrectHeight(deviceContext, text != undefined ? 35 : type != undefined ? 20 : 30),
                            }}
                        >
                            <View>
                                <CachingTaskView size={'Large'} setDownloadStatus={setDownloadStatus} task={task} debug={false} deviceContext={deviceContext} appContext={appContext}></CachingTaskView>
                            </View>
                        </View>
                    )}
                    {downloadStatus == 'done' && (
                        <View
                            style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: getCorrectHeight(deviceContext, text != undefined ? 35 : type != undefined ? 20 : 30),
                                width: getCorrectHeight(deviceContext, text != undefined ? 35 : type != undefined ? 20 : 30),
                            }}
                        >
                            <FontAwesomeIcon icon={faTrash} color={getPressableTextStyling(styling.components?.button?._id, appContext).color} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>
                        </View>
                    )}
                </>
            </Pressable>

            {text != '' && (
                <Text
                    style={{
                        marginTop: getCorrectHeight(deviceContext, 2),
                        textAlign: 'center',
                        fontFamily: deviceContext.fontType,
                        fontSize: getFontSize(deviceContext, 'Small'),
                        ...getPressableTextStyling(styling.components?.button?._id, appContext),
                    }}
                >
                    {lang.getTranslation(userContext, text)}
                </Text>
            )}
        </View>
    );
};

export function CachingTaskView(props: { task: CachingTask; debug: boolean; deviceContext; appContext; setDownloadStatus; size }) {
    const { task, debug, deviceContext, appContext, setDownloadStatus, size } = props;
    const progress = useCachingTaskProgress(task, debug);
    if (progress == 1) {
        setDownloadStatus('done');
    }
    return (
        <View>
            <CircularProgress size={getFontSize(deviceContext, size)} progress={progress} circleColor={appContext.application.theme.progresses.base} strokeColor={appContext.application.theme.progresses.progress}></CircularProgress>
        </View>
    );
}

export default DownloadButton;
