import * as React from 'react';
import { View, FocusContext } from '@scriptx-com/xtv-toolkit';
import { DvrProgress as styling } from '../../../models/elements/misc/dvrprogress/dvrprogress';
import { getRealWidth } from '../../../styling/realDeviceSizes';
import lang from '../../../languages/languages';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { getFontSize } from '../../../styling/fontSizes';
import { UserContext } from '../../../context/userContext';
import { DeviceContext } from '../../../context/deviceContext';
import Text from '../../components/text';
import { AppContext } from '../../../context/appContext';

export interface Props {
    styling: styling;
    focusContext?: FocusContext;
}

export const DvrProgress = ({ styling, focusContext }: Props) => {
    const userContext = React.useContext(UserContext);
    const deviceContext = React.useContext(DeviceContext);
    const appContext = React.useContext(AppContext);
    var hoursAvailable = userContext.product.services.recordings.hours * 3600;
    var hoursUsed = 0;
    userContext.profile.recordings.forEach((recording) => {
        var totaltime = recording.program?.e - recording.program?.s;
        hoursUsed += totaltime;
    });
    var progress = Math.round((hoursUsed / hoursAvailable) * 100);

    var screenWidth =
        getRealWidth(deviceContext) - getCorrectWidth(deviceContext, +(appContext.application.theme.non_home_left_margin ?? 0) + (appContext.application.theme.non_home_right_margin ?? 0)) - getCorrectWidth(deviceContext, global.menuWidth);
    return (
        <View focusContext={focusContext} style={{ paddingLeft: getCorrectWidth(deviceContext, global.menuWidth) }}>
            <View
                style={{
                    height: getCorrectHeight(deviceContext, 40),
                    backgroundColor: styling.general.background_color,
                    marginLeft: appContext.application.theme.non_home_left_margin ?? 0,
                    marginRight: appContext.application.theme.non_home_right_margin ?? 0,
                    marginTop: getCorrectHeight(deviceContext, styling.general.margin_top ?? 0),
                    marginBottom: getCorrectHeight(deviceContext, styling.general.margin_bottom ?? 0),
                    width: screenWidth,
                }}
            >
                <View style={{ flexDirection: 'row' }} focusContext={focusContext}>
                    <View
                        style={{
                            height: getCorrectHeight(deviceContext, 40),
                            paddingHorizontal: getCorrectWidth(deviceContext, 15),
                            paddingLeft: getCorrectWidth(deviceContext, 5),
                            justifyContent: 'center',
                        }}
                    >
                        <Text
                            style={{
                                color: styling.texts.title_color == '' ? '#ffffff' : styling.texts.title_color,
                                fontWeight: styling.texts.title_bold ? 'bold' : 'normal',
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Normal'),
                            }}
                        >
                            {lang.getTranslation(userContext, 'hours_available')}: {userContext.product.services.recordings.hours}
                        </Text>
                        <Text
                            style={{
                                color: styling.texts.title_color == '' ? '#ffffff' : styling.texts.title_color,
                                fontWeight: styling.texts.title_bold ? 'bold' : 'normal',
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Normal'),
                            }}
                        >
                            {lang.getTranslation(userContext, 'hours_used')}: {hoursUsed / 3600}
                        </Text>
                    </View>
                    <View style={{ flex: 1 }}>
                        <View
                            style={{
                                flex: 1,
                                flexDirection: 'row',
                                backgroundColor: appContext.application.theme.progresses.base,
                                margin: getCorrectWidth(deviceContext, 5),
                                marginRight: getCorrectWidth(deviceContext, 5),
                            }}
                        >
                            <View style={{ backgroundColor: appContext.application.theme.progresses.progress, width: progress + '%' }}></View>
                        </View>
                    </View>
                </View>
            </View>
        </View>
    );
};
export default DvrProgress;
