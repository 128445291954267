import * as React from 'react';
import { useState, Dispatch } from 'react';
import NetInfo, { useNetInfo } from '@react-native-community/netinfo';
import { DeviceContext } from './deviceContext';
import { Screen } from '@scriptx-com/xtv-toolkit';
import Modal from '../builder/components/modal/modal';
import { getModalStyling } from '../builder/components/helpers/helper';
import { AppContext } from './appContext';
import { navigationRef } from '../navigation/navigation.base';
import { CommonActions, useNavigation } from '@react-navigation/native';

interface NetworkContext {
    connected: boolean;
    setConnected: Dispatch<any>;
    showFullMenu: boolean;
    setShowFullMenu: React.Dispatch<React.SetStateAction<boolean>>;
}
export const NetworkContext = React.createContext<NetworkContext>(null);

export function NetworkProvider({ children }: { children: any }) {
    const netInfo = useNetInfo();
    const [connected, setConnected] = useState(true);
    const [modalCanBeShowed, setModalCanBeShowed] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showFullMenu, setShowFullMenu] = useState(false);
    const deviceContext = React.useContext(DeviceContext);
    const appContext = React.useContext(AppContext);

    React.useEffect(() => {
        NetInfo.fetch().then((status) => {
            setConnected(status.isInternetReachable);
        });
    }, [netInfo.isInternetReachable]);

    // React.useEffect(() => {
    //     setTimeout(() => {
    //        setModalCanBeShowed(true);
    //     }, 3000);
    // }, []);

    React.useEffect(() => {
        if (modalCanBeShowed) {
            setShowModal(true);
        }
    }, [connected]);

    // if (deviceContext.isTizen) {
    //     // @ts-expect-error
    //     webapis.network.addNetworkStateChangeListener(function (value) {
    //         // @ts-expect-error
    //         if (value == webapis.network.NetworkState.GATEWAY_DISCONNECTED) {
    //             setConnected(false);
    //             // @ts-expect-error
    //         } else if (value == webapis.network.NetworkState.GATEWAY_CONNECTED) {
    //             setConnected(true);
    //         }
    //     });
    // }

    // maybe lg works like webtv??
    // if (deviceContext.isWebos) {
    //     window.addEventListener('online', () => {
    //         setConnected(true);
    //     });
    //     window.addEventListener('offline', () => {
    //         setConnected(false);
    //     });
    // }

    return (
        <NetworkContext.Provider
            value={{
                connected,
                setConnected,
                showFullMenu,
                setShowFullMenu,
            }}
        >
            {children}
            {showModal && (deviceContext.isPhone || deviceContext.isTablet) && (
                <Screen style={{ position: 'absolute', zIndex: 9999, top: 0, left: 0, bottom: 0, right: 0 }}>
                    <Modal
                        focusContext={undefined}
                        styling={getModalStyling(appContext.application.theme.modals.exit, appContext)}
                        type={'network_status_changed'}
                        submitChoice={() => {
                            navigationRef.current.dispatch(
                                CommonActions.navigate({
                                    name: 'Login',
                                })
                            );
                            setShowModal(false);
                        }}
                        setShowModal={() => setShowModal}
                        rotated={deviceContext.isPhone && navigationRef.current.getCurrentRoute().name.includes('Player')}
                    />
                </Screen>
            )}
        </NetworkContext.Provider>
    );
}

// export function useUser() {
//     const context = React.useContext(NetworkContext);
//     return context;
// }
