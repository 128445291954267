import React from 'react';
import { CreateListRenderItemInfo, FlashList, View, Image as Image_, FocusContext } from '@scriptx-com/xtv-toolkit';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { DeviceContext } from '../../../../context/deviceContext';
import { UserContext } from '../../../../context/userContext';
import { ContentContext } from '../../../../context/contentContext';
import { AppContext } from '../../../../context/appContext';
import Pressable from '../../../components/pressable/pressable';
import Image from '../../../components/image/image';
import Text from '../../../components/text';
import { getRealHeight, getRealWidth } from '../../../../styling/realDeviceSizes';
import { getCorrectHeight, getCorrectWidth } from '../../../../styling/correctSizes';
import { getFocusStyling, getPressableStyling, getPressableTextStyling } from '../../../components/helpers/helper';
import { getFontSize } from '../../../../styling/fontSizes';
import { BackHandler } from 'react-native';
import { watchingStatus } from '../../../../data/account';
import utils from '../../../../general/utils';

type MiniEPGTypes = {
    focusContext?: FocusContext;
    navigation: any;
    playLessonIndex: any;
    styling: any;
    onHide: () => void;
};

const MiniCourses = ({ focusContext, navigation, onHide, playLessonIndex, styling }: MiniEPGTypes) => {
    const deviceContext = React.useContext(DeviceContext);
    const userContext = React.useContext(UserContext);
    const contentContext = React.useContext(ContentContext);
    const appContext = React.useContext(AppContext);

    const disableNativeHardwareBackPress = () => {
        onHide();
        return true;
    };

    React.useEffect(() => {
        BackHandler.addEventListener('hardwareBackPress', disableNativeHardwareBackPress);
        return () => {
            BackHandler.removeEventListener('hardwareBackPress', disableNativeHardwareBackPress);
        };
    }, []);

    const getDescription = (item) => {
        var test = item.translations.find((trans) => trans.name == userContext.selectedLanguage);
        if (test != undefined) {
            return test?.description;
        } else {
            return item.translations[0]?.description;
        }
    };

    return (
        <View
            focusContext={focusContext}
            style={{
                zIndex: 1000,
                width: deviceContext.isPhone ? getRealHeight(deviceContext, 'screen') : getRealWidth(deviceContext, 'screen'),
                backgroundColor: '#00000099',
                position: 'absolute',
                height: deviceContext.isPhone ? getRealWidth(deviceContext) : getRealHeight(deviceContext),
                flexDirection: 'column',
            }}
        >
            <View style={{ flex: 5 }}>
                <View style={{ zIndex: 10 }}>
                    <Pressable
                        style={{
                            margin: 40,
                            width: getCorrectWidth(deviceContext, 40),
                            height: getCorrectWidth(deviceContext, 40),
                            padding: getCorrectHeight(deviceContext, 3),
                            borderRadius: 100,
                        }}
                        animatorOptions={getFocusStyling('Buttons', appContext)}
                        focusOptions={{ focusKey: 'close-btn' }}
                        onPress={() => onHide()}
                    >
                        <View
                            style={{
                                flex: 1,
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                borderRadius: 100,
                            }}
                        >
                            <FontAwesomeIcon icon={faArrowLeft} color={getPressableTextStyling(styling.components?.button?._id, appContext).color} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>
                        </View>
                    </Pressable>
                </View>
                <View style={{ position: 'absolute' }}>
                    <Image
                        isCache={true}
                        deviceContext={deviceContext}
                        source={{ uri: utils.getCorrectStoragePrefix(appContext, true, contentContext.courseData.course.images.background)  }}
                        resizeMethod={'scale'}
                        resizeMode={'cover'}
                        style={{
                            height: deviceContext.isPhone ? getRealWidth(deviceContext) : getRealHeight(deviceContext),
                            width: deviceContext.isPhone ? getRealHeight(deviceContext) : getRealWidth(deviceContext),
                        }}
                    />
                </View>
                <View style={{ position: 'absolute' }}>
                    <Image_
                        source={require('../../../../../assets/images/cover-bg.png')}
                        resizeMethod={'scale'}
                        resizeMode={'stretch'}
                        style={{
                            height: deviceContext.isPhone ? getRealWidth(deviceContext) : getRealHeight(deviceContext),
                            width: deviceContext.isPhone ? getRealHeight(deviceContext, 'screen') : getRealWidth(deviceContext),
                        }}
                    ></Image_>
                </View>
            </View>
            <View style={{ zIndex: 10, paddingLeft: 50, flex: 5, width: deviceContext.isPhone ? getRealHeight(deviceContext) : getRealWidth(deviceContext) }}>
                <FlashList
                    horizontal={true}
                    data={contentContext.courseData.course.lessons}
                    showsVerticalScrollIndicator={false}
                    estimatedItemSize={55}
                    drawDistance={55 * 2}
                    style={{ flex: 1 }}
                    renderItem={({ item, focusRepeatContext, index }: CreateListRenderItemInfo<any>) => {
                        var progress = -1;
                        var watching = watchingStatus('Shorts', contentContext.shortData.short._id, userContext);
                        if (watching != undefined && watching.position != undefined) {
                            var short = watching.short.find((e) => e._id == item?._id);
                            if (short != undefined) {
                                progress = (short.position / short.duration) * 100;
                            }
                        }
                        return (
                            <Pressable
                                focusContext={focusContext}
                                focusRepeatContext={focusRepeatContext}
                                style={{
                                    width: getCorrectWidth(deviceContext, 170),
                                    height: getCorrectWidth(deviceContext, 160),
                                    backgroundColor: '#00000050',
                                    borderRadius: getPressableStyling(styling?.components?.button?._id, appContext).borderRadius,
                                    marginRight: getCorrectHeight(deviceContext, 10),
                                }}
                                animatorOptions={getFocusStyling('Buttons', appContext)}
                                onPress={() => {
                                    contentContext.setCourseData({
                                        ...contentContext.courseData,
                                        lessonIndex: index,
                                        lesson: item,
                                    });
                                    playLessonIndex();
                                }}
                            >
                                <View style={{ flex: 1, justifyContent: 'center', margin: getCorrectWidth(deviceContext, 5) }}>
                                    <View>
                                        <View>
                                            <Image
                                                isCache={true}
                                                deviceContext={deviceContext}
                                                source={{ uri: utils.getCorrectStoragePrefix(appContext, true,  item?.images?.widescreen) }}
                                                resizeMethod={'scale'}
                                                resizeMode={'cover'}
                                                style={{
                                                    height: getCorrectWidth(deviceContext, 90),
                                                    width: getCorrectWidth(deviceContext, 160),
                                                }}
                                            />
                                            <View style={{ position: 'absolute', zIndex: 1 }}>
                                                <Image_
                                                    source={require('../../../../../assets/images/episodes-overlay.png')}
                                                    resizeMethod={'scale'}
                                                    resizeMode={'stretch'}
                                                    style={{
                                                        height: getCorrectWidth(deviceContext, 90),
                                                        width: getCorrectWidth(deviceContext, 160),
                                                    }}
                                                ></Image_>
                                            </View>
                                        </View>
                                        <View style={{ position: 'absolute', bottom: 10, alignItems: 'center', width: getCorrectWidth(deviceContext, 160) }}>
                                            {progress > -1 && (
                                                <View
                                                    style={{
                                                        width: getCorrectWidth(deviceContext, 150),
                                                        backgroundColor: progress == 0 ? 'transparent' : appContext.application.theme.progresses.base,
                                                        height: getCorrectHeight(deviceContext, 2),
                                                       
                                                    }}
                                                >
                                                    <View
                                                        style={{
                                                            backgroundColor: appContext.application.theme.progresses.progress,
                                                            height: getCorrectHeight(deviceContext, 2),
                                                   

                                                            width: progress + '%',
                                                        }}
                                                    ></View>
                                                </View>
                                            )}
                                        </View>
                                    </View>
                                    <View style={{ flex: 1, width: getCorrectWidth(deviceContext, 160) }}>
                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                marginVertical: 10,
                                                color: '#ffffff',
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                lineHeight: getFontSize(deviceContext, 'Normal'),
                                                fontWeight: 'normal',
                                            }}
                                        >
                                            {item.number}. {item.name}
                                        </Text>
                                        <Text
                                            numberOfLines={5}
                                            style={{
                                                color: '#ffffff',
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Small'),
                                                lineHeight: getFontSize(deviceContext, 'Small'),
                                                fontWeight: 'normal',
                                            }}
                                        >
                                            {getDescription(item)}
                                        </Text>
                                    </View>
                                </View>
                            </Pressable>
                        );
                    }}
                    initialScrollIndex={contentContext.courseData.lessonIndex}
                    initialFocusIndex={contentContext.courseData.lessonIndex}
                    type={'row'}
                    focusOptions={{ nextFocusUp: 'close-btn' }}
                />
            </View>
        </View>
    );
};

export default MiniCourses;
