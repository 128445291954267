export const getGlobals = () => {
    return {
        AppName: 'TV App', //App Name comes from the branding menu in the App Builder
        AppPackageID: '', //renativeJSON.common.id, //AppPackageID ID from the settings menu in the App Builder
        //AppPackageID: '', //renativeJSON.common.id, //AppPackageID ID from the settings menu in the App Builder
        App_Version: '10.3.68', //we only change the last 2 digits if needed ONLY UP NEVER DOWN
        DefaultLanguage: 'English', //make sure to put a default language here that is part of the offered languages in the app

        AppApiUrl: 'https://app.tvms.io/api/v1', //production
        AppReportingUrl: 'https://analytics.tvms.io/api/v1', //production
        AppData: 'https://cloudtv.akamaized.net/',
        // AppCloudDeployment: 'https://appcloud.akamaized.net/region/deployment',
        // AppCloudService: 'https://appcloud.akamaized.net/region/service'
    };
};
