import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
var Buffer = require('buffer/').Buffer 

const AWS = {
    uploadFile: async function (path, name, bucket, data) {
        try {
            const dataIn = JSON.stringify(data);
            const buffer = Buffer.from(dataIn);

            var s3 = new S3Client({
                credentials: {
                    accessKeyId: 'J493W5XNRYM3UGEESLUH',
                    secretAccessKey: '9Ee0dMdfnySpiSyB9rIzZe7vWRr2mhgV342HelUQ',
                },
                endpoint: 'https://' + bucket.hostname.replace(bucket.label + '.', ''),
                region: bucket.region,
            });
   
            try {
                const command = new PutObjectCommand({
                    Bucket: bucket.label,
                    Key: path + '/' + name,
                    Body: buffer,
                    ACL: 'public-read',
                });
                await s3.send(command);
            } catch (e) {
            }
        } catch (error) {
        }
    },
};

export default AWS;
