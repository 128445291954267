import utils from '../../general/utils';

export const loadStorageVariables = async () => {
    const responses = await Promise.all([
        utils.retrieveJson('Selected_Language'),
        utils.retrieveJson('ServiceID'),
        utils.retrieveJson('UserID'),
        utils.retrieveJson('Pass'),
        utils.retrieveJson('AutoLogin'),
        utils.retrieveJson('OnboardingFinished'),
        utils.retrieveJson('Selected_Language_Code'),
    ]);
    return responses;
};
