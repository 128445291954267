import { View } from 'react-native';
import { filterThumbnailTracks } from 'react-native-theoplayer';
import { MultipleThumbnail } from './MultipleThumbnail';
import React, { useEffect } from 'react';
import { PlayerContext } from '../../../context/playerContext';
import { AppContext } from '../../../context/appContext';
import { DeviceContext } from '../../../context/deviceContext';

export interface ThumbnailViewProps {
    duration: number;
    displayPercent: number;
    seekBarWidth: number;
}

export function MultiThumbnailView(props: ThumbnailViewProps) {
    const playerContext = React.useContext(PlayerContext);
    const appContext = React.useContext(AppContext);
    const deviceContext = React.useContext(DeviceContext);
    const thumbnailTrack = filterThumbnailTracks(playerContext.player.textTracks);
    if (!thumbnailTrack) {
        return <></>;
    }

    const { duration, seekBarWidth, displayPercent } = props;
    const thumbnailSize = seekBarWidth / 5;
    const currentTime = duration * displayPercent;

    return (
        <View
            style={{
                position: 'absolute',
                top: -(thumbnailSize * 0.7),
            }}
        >
            <MultipleThumbnail deviceContext={deviceContext} seekBarWidth={seekBarWidth} appContext={appContext} thumbnailTrack={thumbnailTrack} duration={duration} time={currentTime * 1000} size={thumbnailSize} showTimeLabel={false} />
        </View>
    );
}
