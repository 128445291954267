import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { faPlay } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import * as React from 'react';
import { View, Image as Image_ } from '@scriptx-com/xtv-toolkit';
import { AppContext } from '../../../context/appContext';
import { ContentContext } from '../../../context/contentContext';
import { DeviceContext } from '../../../context/deviceContext';
import { UserContext } from '../../../context/userContext';
import { favoriteStatus, watchingStatus } from '../../../data/account';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { getFontSize } from '../../../styling/fontSizes';
import { getFocusStyling, getPressableStyling } from '../../components/helpers/helper';
import Image from '../../components/image/image';
import Pressable from '../../components/pressable/pressable';
import Text from '../../components/text';
import utils from '../../../general/utils';

export interface Props {
    focusContext: any;
    focusOptions?: any;
    focusRepeatContext: any;
    noMargin?: boolean;
    height: number;
    width: number;
    onPress: any;
    styling: any;
    data: any;
    type: string;
    rails?: boolean;
    onLongPress: any;
    heroIsPlayer: boolean;
    elementType: string;
    onFocus?: () => void;
    onBlur?: () => void;
    isFirst?: boolean;
    isLast?: boolean;
}

export const Rectangle = ({ focusContext, focusRepeatContext, noMargin, height, width, onPress, styling, type, data, onLongPress, focusOptions, heroIsPlayer, elementType, ...rest }: Props) => {
    const userContext = React.useContext(UserContext);
    const deviceContext = React.useContext(DeviceContext);
    const appContext = React.useContext(AppContext);
    const contentContext = React.useContext(ContentContext);
    const timeout = React.useRef<NodeJS.Timeout>();

    const onFocus = (data) => {
        timeout.current = setTimeout(() => {
            if (styling.content?.link_to_hero != undefined && styling.content?.link_to_hero && heroIsPlayer) {
                contentContext.setHero({
                    type: type,
                    data: [data],
                });
            }
        }, 1000);
    };

    const onBlur = () => {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
    };

    var favorite = favoriteStatus(type, data?._id, userContext);
    var progress = 0;

    if (type == 'Movies') {
        try {
            var watching = watchingStatus('Movies', data?._id, userContext);
            progress = Math.round((watching.position / watching.duration) * 100);
        } catch (e) {}
    }
    const getRibbonText = () => {
        var test = styling.ribbon.title.find((t) => t.language == userContext.selectedLanguage);
        if (test != undefined) {
            return test.title;
        } else {
            if (styling.content.title != undefined) {
                return styling.content.title[0].title;
            } else {
                return '';
            }
        }
    };
    const getExtraStyle = () => {
        if (styling.ribbon.align == 'Right') {
            return { right: -2 };
        } else {
            return { left: -2 };
        }
    };

    const focusWidth = () => {
        return getPressableStyling(styling.components?.button._id, appContext).borderWidth * 2;
    };

    const getTags = (tags, appContext, deviceContext, userContext) => {
        var tagsOut = [] as any;
        if (tags != undefined && tags[0] != undefined && tags[0].tag != undefined) {
            tags.map((tag, index) => {
                if (tag.tag != undefined) {
                    var test = tag.tag.find((t) => t.name == userContext.selectedLanguage);
                    if (test == undefined) {
                        tag.name = tag[0]?.description;
                    } else {
                        tag.name = test?.description;
                    }
                }
                if (index < 2 && tag.name != '') {
                    tagsOut.push(
                        <Text
                            key={index}
                            style={{
                                color: styling.texts.subtext_color,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                paddingTop: getCorrectWidth(deviceContext, 2),
                                paddingRight: getCorrectWidth(deviceContext, 4),
                                includeFontPadding: false,
                            }}
                        >
                            {tag.name}
                        </Text>
                    );
                }
            });
        }
        if (tagsOut.length > 0) {
            return <View style={{ flexGrow: 1, flexDirection: 'row' }}>{tagsOut}</View>;
        } else {
            return null;
        }
    };

    const getCorrectScaleStyling = () => {
        return getFocusStyling(elementType, appContext).type.indexOf('scale') > -1 && elementType == 'Rails' && deviceContext.formFactor == 'Television';
    };

    if (data == undefined) {
        return null;
    } else {
        return (
            <View style={{ flex: 1, paddingHorizontal: 3 }}>
                <View style={getCorrectScaleStyling() ? { height: height * 1.1, justifyContent: 'center' } : {}}>
                    <Pressable
                        style={[
                            {
                                ...getPressableStyling(styling.components?.button._id, appContext),
                                marginBottom: elementType == 'Rails' ? 0 : getCorrectWidth(deviceContext, deviceContext.isPhone || deviceContext.isPwaVertical ? 5 : 4),
                                marginRight: getCorrectWidth(deviceContext, 10),
                                width: width,
                            },
                        ]}
                        focusContext={focusContext}
                        focusRepeatContext={focusRepeatContext}
                        onFocus={() => (rest.onFocus ? rest.onFocus() : onFocus(data))}
                        onBlur={() => (rest.onBlur ? rest.onBlur() : onBlur())}
                        animatorOptions={getFocusStyling(elementType, appContext)}
                        onPress={() => onPress(data?._id)}
                        onLongPress={() => onLongPress(data?._id)}
                        focusOptions={focusOptions}
                        isFirst={rest.isFirst}
                        isLast={rest.isLast}
                    >
                        <View>
                            {styling.ribbon != undefined && styling.ribbon.show_ribbon && (
                                <View style={[getExtraStyle(), { position: 'absolute', zIndex: 9999 }]}>
                                    <View
                                        style={{
                                            padding: getCorrectWidth(deviceContext, 3),
                                            paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            backgroundColor: styling.ribbon.background_color,

                                            borderRadius: getCorrectWidth(deviceContext, styling.ribbon.border_radius),
                                        }}
                                    >
                                        <Text
                                            style={{
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Small'),
                                                color: styling.ribbon.title_color,
                                                fontWeight: styling.ribbon.title_bold ? 'bold' : 'normal',
                                            }}
                                        >
                                            {getRibbonText()}
                                        </Text>
                                    </View>
                                </View>
                            )}
                            <View
                                style={{
                                    margin: getCorrectHeight(deviceContext, appContext.application.theme.images.margin),
                                    borderRadius: getCorrectWidth(deviceContext, appContext.application.theme.images.radius) / 2,

                                    backgroundColor: appContext.application.theme.images?.background,
                                    alignItems: 'center',
                                    // Don't remove this it will brake rails on web platforms including web tv entirely...
                                    height: (width - getCorrectWidth(deviceContext, appContext.application.theme.images.margin * 2) - focusWidth()) * 1.5,
                                }}
                            >
                                <View style={{ position: 'absolute', zIndex: 1, bottom: 0 }}>
                                    <Image_
                                        source={require('../../../../assets/images/rect.png')}
                                        resizeMethod={'scale'}
                                        resizeMode={'cover'}
                                        style={{
                                            opacity: styling.texts.placement_text == 'Above' ? 0.9 : 0,
                                            borderRadius: getPressableStyling(styling.components?.button._id, appContext).borderRadius / 3,
                                            height: height,
                                            width: width - appContext.application.theme.images.margin * 2,
                                        }}
                                    />
                                </View>
                                <Image
                                    isLocal={data?.images?.poster.indexOf('https://') > -1}
                                    isCache={true}
                                    deviceContext={deviceContext}
                                    source={{ uri: utils.getCorrectStoragePrefix(appContext, true, data?.images?.poster)}}
                                    resizeMethod={'resize'}
                                    resizeMode={'cover'}
                                    style={{
                                        borderRadius: getCorrectWidth(deviceContext, appContext.application.theme.images.radius) / 3,
                                        width: width - getCorrectWidth(deviceContext, appContext.application.theme.images.margin * 2) - focusWidth(),
                                        height: (width - getCorrectWidth(deviceContext, appContext.application.theme.images.margin * 2) - focusWidth()) * 1.5,
                                    }}
                                />

                                {favorite && appContext.application.theme.icons.show_favorite && (
                                    <View
                                        style={{
                                            position: 'absolute',
                                            top: 5,
                                            right: 5,
                                            width: '100%',
                                            justifyContent: 'flex-end',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        <FontAwesomeIcon size={width / 8} style={{ color: appContext.application.theme.icons.background }} icon={faHeart} />
                                    </View>
                                )}
                                {appContext.application.theme.icons.show_play && (
                                    <View
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            bottom: 0,
                                            right: 0,
                                            left: 0,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <View
                                            style={{
                                                borderRadius: 100,
                                                height: width / 3,
                                                width: width / 3,
                                                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <FontAwesomeIcon size={width / 6} style={{ color: appContext.application.theme.icons.background }} icon={faPlay} />
                                        </View>
                                    </View>
                                )}
                                {styling.texts.placement_text == 'Above' && (
                                    <View
                                        style={{
                                            position: 'absolute',
                                            zIndex: 999,
                                            bottom: getCorrectWidth(deviceContext, 7),
                                            left: getCorrectWidth(deviceContext, 2),
                                            right: appContext.application.theme.images.margin,
                                        }}
                                    >
                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                color: styling.texts.title_color == '' ? '#ffffff' : styling.texts.title_color,
                                                fontWeight: styling.texts.title_bold == true ? 'bold' : 'normal',
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                                textShadowOffset: { width: -1, height: 1 },
                                                textShadowRadius: 2,
                                            }}
                                        >
                                            {data?.name}
                                        </Text>
                                    </View>
                                )}
                                <View
                                    style={{
                                        position: 'absolute',
                                        bottom: getCorrectHeight(deviceContext, 1),
                                        left: getCorrectHeight(deviceContext, 2),
                                        right: getCorrectHeight(deviceContext, 2),
                                    }}
                                >
                                    {progress > 0 && (
                                        <View
                                            style={{
                                                marginVertical: getCorrectHeight(deviceContext, 2),
                                                backgroundColor: appContext.application.theme.progresses.base,
                                                height: getCorrectHeight(deviceContext, 2),
                                     
                                                width: '100%',
                                            }}
                                        >
                                            <View
                                                style={{
                                                    backgroundColor: appContext.application.theme.progresses.progress,
                                                    height: getCorrectHeight(deviceContext, 2),
                                                 
                                                    width: progress + '%',
                                                }}
                                            ></View>
                                        </View>
                                    )}
                                </View>
                            </View>
                        </View>
                    </Pressable>
                </View>
                {styling.texts.placement_text == 'Below' && (
                    <View
                        style={{
                            marginHorizontal: getCorrectHeight(deviceContext, appContext.application.theme.images.margin),
                            marginBottom: getCorrectHeight(deviceContext, 5),
                            height: getCorrectWidth(deviceContext, elementType == 'Grid' ? 15 : 25),
                            marginRight: getCorrectWidth(deviceContext, 10),
                            marginTop: getCorrectWidth(deviceContext, elementType == 'Grid' ? 0 : 5),
                            width: width - getCorrectWidth(deviceContext, 10),
                        }}
                    >
                        <Text
                            numberOfLines={1}
                            style={{
                                color: styling.texts.title_color == '' ? '#ffffff' : styling.texts.title_color,
                                fontWeight: styling.texts.title_bold == true ? 'bold' : 'normal',
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Normal'),
                                lineHeight: getFontSize(deviceContext, 'Normal'),
                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                textShadowOffset: { width: -1, height: 1 },
                                textShadowRadius: 2,
                            }}
                        >
                            {data?.name}{' '}
                        </Text>

                        {getTags(data?.tags, appContext, deviceContext, userContext)}
                    </View>
                )}
            </View>
        );
    }
};
export default Rectangle;
