import { FocusContext, setFocus, View } from '@scriptx-com/xtv-toolkit';
import moment from 'moment';
import * as React from 'react';
import { AppContext } from '../../../../context/appContext';
import { ContentContext } from '../../../../context/contentContext';
import { DeviceContext } from '../../../../context/deviceContext';
import { UserContext } from '../../../../context/userContext';
import { Channels } from '../../../../models/content/channels/channels';
import { filterAgeRatings } from '../../grids/helpers/functions';
import ProgramModal from './program';

type SharedEPGType = {
    channels: Channels[];
    channelListIndex: number;
    categoryIndex: number;
    programSelectedIndex: number;
    channelSelected: any;
    channelEpgSelected: any[];
    showProgramDetails: boolean;
    setShowProgramDetails: React.Dispatch<React.SetStateAction<boolean>>;
    programSelected: any;
    setProgramSelected: React.Dispatch<React.SetStateAction<any>>;
    onPressCategory: (index: number) => void;
    onPressChannel: (channel: any) => void;
    ProgramModal: JSX.Element;
};
type PropsType = {
    forceTvBehaviour?: boolean;
    playChannelIndex: (index: number, channel: any) => void;
    initialChannel?: any;
    focusContext: FocusContext;
    navigation: any;
    onPressPlay: (channel: any, program: any, programs: any[]) => void;
    onPressPurchase: () => void;
    onPressPlayRecording: (channel: any, program: any, programs: any) => void;
    onPressPlayCatchupTV: (channel: any, program: any, programs: any, startType: any) => void;
};

const useSharedEPG = (props: PropsType): SharedEPGType => {
    const { initialChannel, playChannelIndex, focusContext, navigation, onPressPlay, onPressPurchase, onPressPlayCatchupTV, onPressPlayRecording, forceTvBehaviour } = props;

    const contentContext = React.useContext(ContentContext);
    const userContext = React.useContext(UserContext);
    const appContext = React.useContext(AppContext);
    const deviceContext = React.useContext(DeviceContext);

    const [programSelected, setProgramSelected] = React.useState<any>();
    const [showProgramDetails, setShowProgramDetails] = React.useState(false);
    const [channelEpgSelected, setChannelEpgSelected] = React.useState([] as any);
    const [programSelectedIndex, setProgramSelectedIndex] = React.useState(0);
    const [channelSelected, setChannelSelected] = React.useState<any>(initialChannel);
    const [channelListIndex, setChannelListIndex] = React.useState(contentContext.channelData.channelIndex);
    const [categoryIndex, setCategoryIndex] = React.useState(contentContext.channelData.channelCategoryIndex);
    const [selectedCategoryChanelList, setSelectedCategoryChanelList] = React.useState(filterAgeRatings(contentContext.channelData.channelList, userContext, appContext));

    React.useEffect(() => {
        var epgAll = contentContext.epgTv?.find((e) => e.i == channelSelected?._id);
        if (epgAll != undefined) {
            setChannelEpgSelected(epgAll.epg);
            var currentTime = moment().unix();
            var currentProgramIndex = epgAll.epg.findIndex((e) => e.s <= currentTime && e.e > currentTime);
            const index = selectedCategoryChanelList.findIndex((i: any) => i._id === channelSelected._id);
            if (!deviceContext.isPhone || forceTvBehaviour) {
                setProgramSelectedIndex(currentProgramIndex);
                setChannelSelected(channelSelected);
                setChannelListIndex(index);
            } else {
                setSelectedCategoryChanelList(filterAgeRatings(contentContext.channels.categories[categoryIndex].channels, userContext, appContext));
                playChannelIndex(index, channelSelected);
            }
        } else {
            setChannelEpgSelected([]);
        }
    }, [channelSelected]);

    const onPressCategory = (index: number) => {
        const channels = contentContext.channels.categories[index].channels?.sort((a, b) => a?.number - b?.number);
        setSelectedCategoryChanelList(filterAgeRatings(channels, userContext, appContext));
        setCategoryIndex(index);
    };
    const onPressChannel = (channel: any) => {
        setChannelSelected(channel);
    };

    const getProgramStyling = () => {
        var screen = appContext.application.screens.find((s) => s.type == 'Program Details');
        if (screen) {
            var styling = screen.hero[0].details_top;
            if (styling) {
                return styling;
            }
        }
        return [];
    };

    const Modal = React.useMemo(() => {
        if (showProgramDetails && programSelected !== undefined)
            return (
                <View style={{ position: 'absolute', zIndex: 9999 }}>
                    <ProgramModal
                        focusContext={focusContext}
                        programs={channelEpgSelected}
                        programIndex={programSelectedIndex}
                        program={programSelected}
                        channel={channelSelected}
                        channels={selectedCategoryChanelList}
                        selectedCategoryIndex={categoryIndex}
                        closeModal={() => {
                            global.showProgramDetails = false;
                            setShowProgramDetails(false);
                            setFocus('channels-list-programs');
                        }}
                        styling={getProgramStyling()}
                        navigation={navigation}
                        onPressPlay={(channel, program, programs) => {
                            global.showProgramDetails = false;
                            setShowProgramDetails(false);
                            onPressPlay(channel, program, programs);
                        }}
                        onPressPurchase={() => {
                            global.showProgramDetails = false;
                            setShowProgramDetails(false);
                            onPressPurchase();
                        }}
                        onPressPlayRecording={(channel, program, programs) => {
                            global.showProgramDetails = false;
                            setShowProgramDetails(false);
                            onPressPlayRecording(channel, program, programs);
                        }}
                        onPressPlayCatchupTV={(channel, program, programs, startType) => {
                            global.showProgramDetails = false;
                            setShowProgramDetails(false);
                            onPressPlayCatchupTV(channel, program, programs, startType);
                        }}
                        isLandscape={deviceContext.isPhone}
                    ></ProgramModal>
                </View>
            );
    }, [channelEpgSelected, programSelectedIndex, programSelected, channelSelected, selectedCategoryChanelList, categoryIndex, showProgramDetails]);

    return {
        channels: selectedCategoryChanelList,
        channelListIndex,
        categoryIndex,
        programSelectedIndex,
        channelSelected,
        channelEpgSelected,
        showProgramDetails,
        setShowProgramDetails,
        programSelected,
        setProgramSelected,
        onPressCategory,
        onPressChannel,
        ProgramModal: Modal,
    };
};

export default useSharedEPG;
