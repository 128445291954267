import { ActivityIndicator, setFocus, View, ViewGroup, ScrollView, useTVRemoteHandler } from '@scriptx-com/xtv-toolkit';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { DeviceContext } from '../../../context/deviceContext';
import { UserContext } from '../../../context/userContext';
import lang from '../../../languages/languages';
import { Modal as Styling } from '../../../models/components/modal/modal';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { getFontSize } from '../../../styling/fontSizes';
import { getRealHeight, getRealWidth } from '../../../styling/realDeviceSizes';
import Text from '../text';
import { AppContext } from '../../../context/appContext';
import { getFocusStyling, getPressableStyling, getPressableTextStyling } from '../helpers/helper';
import { ScreenFadeIn } from '../../animations/screenFadeIn';
import Pressable from '../../../../application/builder/components/pressable/pressable';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { faTelevision } from '@fortawesome/pro-light-svg-icons';
import { BackHandler } from 'react-native';
import { isPlatformTvos } from '@rnv/renative';
import { NetworkContext } from '../../../context/networkContext';

export interface ModalProps {
    styling: Styling;
    type: string;
    setShowModal?: any;
    submitChoice: any;
    focusContext: any;
    submitChoice2?: any;
    timerTime?: number;
    message?: string;
    favorite?: boolean;
    watchlist?: boolean;
    data?: any;
    contentType?: string;
    program?: any;
    error?: string;
    rotated?: boolean;
}
const Modal = ({ styling, type, setShowModal, submitChoice, timerTime, message, submitChoice2, favorite, watchlist, focusContext, data, contentType, error, program, rotated }: ModalProps) => {
    const deviceContext = useContext(DeviceContext);
    const userContext = useContext(UserContext);
    const appContext = useContext(AppContext);
    const networkContext = useContext(NetworkContext);

    const [timerInternal, setTimerInteral] = useState(timerTime != undefined ? timerTime : 99999);
    const eventHandled = React.useRef(!isPlatformTvos);
    const getTitle = () => {
        switch (type) {
            case 'network_connection_changed':
                return 'Network Status';
                break;
            case 'device_login_request':
                return 'device_login_request';
                break;
            case 'reset_profiles':
                return 'reset_profiles';
                break;
            case 'recording':
                return 'record_program';
                break;
            case 'recording_waiting':
                return 'record_program';
                break;
            case 'recording_error':
                return 'record_program';
                break;
            case 'recording_success':
                return 'record_program';
                break;
            case 'reminder':
                return 'remind_program';
                break;
            case 'reminder_start':
                return 'reminder_start';
                break;
            case 'exit':
                return 'exit_app_title';
                break;
            case 'sleeptimer':
                return 'sleeptimer';
                break;
            case 'delete_profile':
                return 'delete_profile_title';
                break;
            case 'quickmenu':
                return 'quickmenu';
                break;
            case 'error':
                return 'login_error';
                break;
            case 'movie_ended':
                return 'movie_ended';
                break;
            case 'forgot':
                return 'forgot_password';
                break;
            case 'change':
                return 'change_password';
                break;
            default:
                break;
        }
    };

    const getSubText = () => {
        switch (type) {
            case 'network_connection_changed':
                return 'network status changed';
                break;
            case 'device_login_request':
                return 'device_login_request_info';
                break;
            case 'reset_profiles':
                return 'reset_profiles_info';
                break;
            case 'exit':
                return 'exit_app_sub';
                break;
            case 'sleeptimer':
                return 'sleeptimer_info';
                break;
            case 'delete_profile':
                return 'delete_profile_info';
                break;
            case 'movie_ended':
                return 'movie_ended_info';
                break;
            case 'forgot':
                return 'forget_email_send';
                break;
            case 'change':
                return 'change_email_send';
                break;
            default:
                break;
        }
    };

    const onBackPress = React.useCallback(() => {
        setShowModal?.(false);

        return true;
    }, []);

    React.useEffect(() => {
        const handler = BackHandler.addEventListener('hardwareBackPress', onBackPress);

        return () => {
            handler.remove();
        };
    }, []);

    const onPressClose = () => {
        if (eventHandled.current) setShowModal(false);
    };

    useEffect(() => {
        let timer;
        if (timerTime != 99999) {
            timer = setInterval(() => {
                var newTimer = timerInternal - 1;
                if (newTimer > 0) {
                    setTimerInteral(newTimer);
                } else {
                    clearInterval(timer);
                    submitChoice();
                }
            }, 3000);
        }
        return () => clearInterval(timer);
    }, [timerInternal]);

    //same event bubbles up from long press for tvos and on release triggers on press
    //capturing here if the event was started inside the modal
    useTVRemoteHandler((e) => {
        if (eventHandled.current === false && e.eventKeyAction === 'down') {
            eventHandled.current = true;
        }
    });

    useEffect(() => {
        setFocus('modal-group');
        return () => {
            setFocus('delete-profile-btn');
        };
    }, []);

    const getModalContents = React.useMemo(
        () => () => {
            return (
                <View>
                    {(type == 'sleeptimer' || type == 'exit' || type == 'change') && (
                        <View style={{ flex: 1, margin: getCorrectWidth(deviceContext, 20) }}>
                            <View style={{ flex: deviceContext.isPhone ? 0 : 1 }}>
                                <Text
                                    style={{
                                        color: styling.title_color,
                                        fontWeight: styling.title_bold ? 'bold' : 'normal',
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                        lineHeight: getFontSize(deviceContext, 'ExtraLarge'),
                                        marginBottom: type != 'exit' && type != 'sleeptimer' ? getCorrectHeight(deviceContext, 10) : 5,
                                    }}
                                >
                                    {lang.getTranslation(userContext, getTitle())}
                                </Text>
                                {type != 'exit' && (
                                    <Text
                                        style={{
                                            color: styling.subtext_color,
                                            fontWeight: 'bold',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                            marginBottom: getCorrectHeight(deviceContext, 5),
                                        }}
                                    >
                                        {lang.getTranslation(userContext, getSubText())}
                                    </Text>
                                )}
                                {type == 'exit' && (
                                    <View style={{ flexDirection: 'row', backgroundColor: '#d6f0ff', alignItems: 'center', padding: getCorrectHeight(deviceContext, 10), marginVertical: 5, borderRadius: 5 }}>
                                        <FontAwesomeIcon icon={faInfoCircle} color={'#3482ba'} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>
                                        <Text
                                            style={{
                                                marginLeft: 10,
                                                color: '#3482ba',
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'press_back_one_more')}
                                        </Text>
                                    </View>
                                )}
                                {type == 'sleeptimer' && (
                                    <View style={{ flexDirection: 'row', backgroundColor: '#d6f0ff', alignItems: 'center', padding: getCorrectHeight(deviceContext, 10), marginVertical: 5, borderRadius: 5 }}>
                                        <FontAwesomeIcon icon={faInfoCircle} color={'#3482ba'} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>
                                        <Text
                                            style={{
                                                marginLeft: 10,
                                                color: '#3482ba',
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'sleeptimer_infos')} {timerInternal} {lang.getTranslation(userContext, 'seconds')}
                                        </Text>
                                    </View>
                                )}
                            </View>
                        </View>
                    )}
                    {type == 'quickmenu' && (
                        <View style={{ flex: 1, margin: getCorrectWidth(deviceContext, 20) }}>
                            <View style={{ flex: deviceContext.isPhone ? 0 : 1, alignSelf: 'flex-start' }}>
                                <Text
                                    style={{
                                        color: styling.title_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                        fontWeight: styling.title_bold ? 'bold' : 'normal',
                                    }}
                                >
                                    {lang.getTranslation(userContext, getTitle())}
                                </Text>
                            </View>
                            <View style={{ flexDirection: 'column', justifyContent: 'flex-end', marginTop: getCorrectHeight(deviceContext, 10) }}>
                                {appContext.application.settings.allow_favorites && (
                                    <Pressable
                                        style={{
                                            margin: getCorrectWidth(deviceContext, 2),
                                            paddingHorizontal: getCorrectWidth(deviceContext, 20),
                                            height: getCorrectHeight(deviceContext, 30),
                                            ...getPressableStyling(styling.components?.button?._id, appContext),
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                        // hasTVPreferredFocus={true}
                                        animatorOptions={getFocusStyling('Buttons', appContext)}
                                        onPress={() => submitChoice2('favorite')}
                                    >
                                        <View>
                                            <Text
                                                style={{
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                }}
                                            >
                                                {lang.getTranslation(userContext, watchlist ? 'remove_from_watchlist' : 'add_to_watchlist')}
                                            </Text>
                                        </View>
                                    </Pressable>
                                )}
                                {appContext.application.settings.allow_watchlist && (
                                    <Pressable
                                        style={{
                                            margin: getCorrectWidth(deviceContext, 2),
                                            paddingHorizontal: getCorrectWidth(deviceContext, 20),
                                            height: getCorrectHeight(deviceContext, 30),
                                            ...getPressableStyling(styling.components?.button?._id, appContext),
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                        animatorOptions={getFocusStyling('Buttons', appContext)}
                                        onPress={() => submitChoice('watchlist')}
                                    >
                                        <View>
                                            <Text
                                                style={{
                                                    padding: 10,
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                }}
                                            >
                                                {lang.getTranslation(userContext, favorite ? 'remove_from_favorite' : 'add_to_favorite')}
                                            </Text>
                                        </View>
                                    </Pressable>
                                )}
                                <Pressable
                                    style={{
                                        margin: getCorrectWidth(deviceContext, 2),
                                        paddingHorizontal: getCorrectWidth(deviceContext, 20),
                                        height: getCorrectHeight(deviceContext, 30),
                                        ...getPressableStyling(styling.components?.button?._id, appContext),
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    // hasTVPreferredFocus={true}
                                    animatorOptions={getFocusStyling('Buttons', appContext)}
                                    onPress={onPressClose}
                                >
                                    <View>
                                        <Text
                                            style={{
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'cancel')}
                                        </Text>
                                    </View>
                                </Pressable>
                            </View>
                        </View>
                    )}
                    {type == 'error' && (
                        <View style={{ flex: 1, margin: getCorrectWidth(deviceContext, 20) }}>
                            <View style={{ flex: deviceContext.isPhone ? 0 : 1 }}>
                                <Text
                                    style={{
                                        color: '#fff',
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                        fontWeight: 'bold',
                                        marginBottom: getCorrectHeight(deviceContext, 10),
                                    }}
                                >
                                    {lang.getTranslation(userContext, getTitle())}
                                </Text>

                                <View style={{ flexDirection: 'row', backgroundColor: '#ffe1e3', alignItems: 'center', padding: getCorrectHeight(deviceContext, 10), marginVertical: 5, borderRadius: 5 }}>
                                    <FontAwesomeIcon icon={faExclamationCircle} color={'#f84d51'} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>
                                    <Text
                                        style={{
                                            marginLeft: 10,
                                            color: '#f84d51',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {error}
                                    </Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: getCorrectHeight(deviceContext, 10) }}>
                                <Pressable
                                    style={{
                                        margin: getCorrectWidth(deviceContext, 2),
                                        paddingHorizontal: getCorrectWidth(deviceContext, 20),
                                        height: getCorrectHeight(deviceContext, 30),
                                        ...getPressableStyling(styling.components?.button?._id, appContext),
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    // hasTVPreferredFocus={true}
                                    animatorOptions={getFocusStyling('Buttons', appContext)}
                                    onPress={onPressClose}
                                >
                                    <View>
                                        <Text
                                            style={{
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'close')}
                                        </Text>
                                    </View>
                                </Pressable>
                            </View>
                        </View>
                    )}
                    {type == 'forgot' && (
                        <View style={{ flex: 1, margin: getCorrectWidth(deviceContext, 20) }}>
                            <View style={{ flex: deviceContext.isPhone ? 0 : 1 }}>
                                <Text
                                    style={{
                                        color: '#fff',
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                        fontWeight: 'bold',
                                        marginBottom: getCorrectHeight(deviceContext, 10),
                                    }}
                                >
                                    {lang.getTranslation(userContext, getTitle())}
                                </Text>

                                <View style={{ flexDirection: 'row', backgroundColor: '#c3f2d6', alignItems: 'center', padding: getCorrectHeight(deviceContext, 10), marginVertical: 5, borderRadius: 5 }}>
                                    <FontAwesomeIcon icon={faExclamationCircle} color={'#28ac5c'} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>
                                    <Text
                                        style={{
                                            marginLeft: 10,
                                            color: '#28ac5c',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {lang.getTranslation(userContext, getSubText())}
                                    </Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: getCorrectHeight(deviceContext, 10) }}>
                                <Pressable
                                    style={{
                                        margin: getCorrectWidth(deviceContext, 2),
                                        paddingHorizontal: getCorrectWidth(deviceContext, 20),
                                        height: getCorrectHeight(deviceContext, 30),
                                        ...getPressableStyling(styling.components?.button?._id, appContext),
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    // hasTVPreferredFocus={true}
                                    animatorOptions={getFocusStyling('Buttons', appContext)}
                                    onPress={onPressClose}
                                >
                                    <View>
                                        <Text
                                            style={{
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'close')}
                                        </Text>
                                    </View>
                                </Pressable>
                            </View>
                        </View>
                    )}
                    {type == 'device_login_request' && (
                        <View style={{ flex: 1, margin: getCorrectWidth(deviceContext, 20) }}>
                            <View style={{ flex: 1, alignSelf: 'flex-start' }}>
                                <Text
                                    style={{
                                        color: '#fff',
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                        fontWeight: 'bold',
                                        marginBottom: getCorrectHeight(deviceContext, 10),
                                    }}
                                >
                                    {lang.getTranslation(userContext, getTitle())}
                                </Text>
                                <Text
                                    style={{
                                        color: '#fff',
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Large'),
                                        fontWeight: styling.title_bold ? 'bold' : 'normal',
                                        marginBottom: getCorrectHeight(deviceContext, 10),
                                    }}
                                >
                                    {lang.getTranslation(userContext, getSubText())}
                                </Text>
                            </View>
                            <View style={{ alignItems: 'center' }}>
                                <FontAwesomeIcon icon={faTelevision} color={'#ffffff'} size={getCorrectWidth(deviceContext, 100)}></FontAwesomeIcon>
                            </View>
                            <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: getCorrectHeight(deviceContext, 10) }}>
                                <Pressable
                                    style={{
                                        margin: getCorrectWidth(deviceContext, 2),
                                        paddingHorizontal: getCorrectWidth(deviceContext, 20),
                                        height: getCorrectHeight(deviceContext, 30),
                                        ...getPressableStyling(styling.components?.button?._id, appContext),
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    // hasTVPreferredFocus={true}
                                    animatorOptions={getFocusStyling('Buttons', appContext)}
                                    onPress={() => submitChoice2(false)}
                                >
                                    <View>
                                        <Text
                                            style={{
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'back')}
                                        </Text>
                                    </View>
                                </Pressable>
                                <Pressable
                                    style={{
                                        margin: getCorrectWidth(deviceContext, 2),
                                        paddingHorizontal: getCorrectWidth(deviceContext, 20),
                                        height: getCorrectHeight(deviceContext, 30),
                                        ...getPressableStyling(styling.components?.button?._id, appContext),
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    // hasTVPreferredFocus={true}
                                    animatorOptions={getFocusStyling('Buttons', appContext)}
                                    onPress={() => submitChoice()}
                                >
                                    <View>
                                        <Text
                                            style={{
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'allow')}
                                        </Text>
                                    </View>
                                </Pressable>
                            </View>
                        </View>
                    )}
                    {type == 'delete_profile' && (
                        <View style={{ flex: 1, margin: getCorrectWidth(deviceContext, 20) }}>
                            <View style={{ flex: deviceContext.isPhone ? 0 : 1 }}>
                                <Text
                                    style={{
                                        color: styling.title_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                        fontWeight: styling.title_bold ? 'bold' : 'normal',
                                        marginBottom: getCorrectHeight(deviceContext, 10),
                                    }}
                                >
                                    {lang.getTranslation(userContext, getTitle())}
                                </Text>
                                <Text
                                    style={{
                                        color: styling.title_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: styling.title_bold ? 'bold' : 'normal',
                                        marginBottom: getCorrectHeight(deviceContext, 10),
                                    }}
                                >
                                    {lang.getTranslation(userContext, getSubText())}
                                </Text>
                            </View>
                            <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: getCorrectHeight(deviceContext, 10) }}>
                                <Pressable
                                    style={{
                                        margin: getCorrectWidth(deviceContext, 2),
                                        paddingHorizontal: getCorrectWidth(deviceContext, 20),
                                        height: getCorrectHeight(deviceContext, 30),
                                        ...getPressableStyling(styling.components?.button?._id, appContext),
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    // hasTVPreferredFocus={true}
                                    animatorOptions={getFocusStyling('Buttons', appContext)}
                                    onPress={onPressClose}
                                >
                                    <View>
                                        <Text
                                            style={{
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'cancel')}
                                        </Text>
                                    </View>
                                </Pressable>
                                <Pressable
                                    style={{
                                        margin: getCorrectWidth(deviceContext, 2),
                                        paddingHorizontal: getCorrectWidth(deviceContext, 20),
                                        height: getCorrectHeight(deviceContext, 30),
                                        ...getPressableStyling(styling.components?.button?._id, appContext),
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    animatorOptions={getFocusStyling('Buttons', appContext)}
                                    onPress={() => submitChoice()}
                                >
                                    <View>
                                        <Text
                                            style={{
                                                padding: 10,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'allow')}
                                        </Text>
                                    </View>
                                </Pressable>
                            </View>
                        </View>
                    )}
                    {type == 'reminder_start' && (
                        <View style={{ flex: 1, margin: getCorrectWidth(deviceContext, 20) }}>
                            <View style={{ flex: deviceContext.isPhone ? 0 : 1 }}>
                                <Text
                                    numberOfLines={2}
                                    style={{
                                        color: styling.title_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                        lineHeight: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                        fontWeight: styling.title_bold ? 'bold' : 'normal',
                                        marginBottom: 5,
                                    }}
                                >
                                    {program?.n}
                                </Text>
                                <Text
                                    style={{
                                        color: styling.title_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        lineHeight: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: styling.title_bold ? 'bold' : 'normal',
                                        marginBottom: getCorrectHeight(deviceContext, 10),
                                    }}
                                >
                                    {moment.unix(program?.s).format('dddd ' + deviceContext.clockSetting)} - {moment.unix(program?.e).format(deviceContext.clockSetting)}
                                </Text>
                                <Text
                                    numberOfLines={3}
                                    style={{
                                        color: styling.title_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: styling.title_bold ? 'bold' : 'normal',
                                        marginBottom: getCorrectHeight(deviceContext, 5),
                                    }}
                                >
                                    {program?.d}
                                </Text>
                            </View>
                            <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: getCorrectHeight(deviceContext, 10) }}>
                                <Pressable
                                    style={{
                                        margin: getCorrectWidth(deviceContext, 2),
                                        paddingHorizontal: getCorrectWidth(deviceContext, 20),
                                        height: getCorrectHeight(deviceContext, 30),
                                        ...getPressableStyling(styling.components?.button?._id, appContext),
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    // hasTVPreferredFocus={true}
                                    animatorOptions={getFocusStyling('Buttons', appContext)}
                                    onPress={onPressClose}
                                >
                                    <View>
                                        <Text
                                            style={{
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'cancel')}
                                        </Text>
                                    </View>
                                </Pressable>
                                <Pressable
                                    style={{
                                        margin: getCorrectWidth(deviceContext, 2),
                                        paddingHorizontal: getCorrectWidth(deviceContext, 20),
                                        height: getCorrectHeight(deviceContext, 30),
                                        ...getPressableStyling(styling.components?.button?._id, appContext),
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    animatorOptions={getFocusStyling('Buttons', appContext)}
                                    onPress={() => submitChoice()}
                                >
                                    <View>
                                        <Text
                                            style={{
                                                padding: 10,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'watch')}
                                        </Text>
                                    </View>
                                </Pressable>
                            </View>
                        </View>
                    )}
                    {type == 'reminder' && (
                        <View style={{ flex: 1, margin: getCorrectWidth(deviceContext, 20) }}>
                            <View style={{ flex: deviceContext.isPhone ? 0 : 1 }}>
                                <Text
                                    numberOfLines={2}
                                    style={{
                                        color: styling.title_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                        lineHeight: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                        fontWeight: styling.title_bold ? 'bold' : 'normal',
                                        marginBottom: 5,
                                    }}
                                >
                                    {program?.n}
                                </Text>
                                <Text
                                    style={{
                                        color: styling.title_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        lineHeight: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: styling.title_bold ? 'bold' : 'normal',
                                        marginBottom: getCorrectHeight(deviceContext, 10),
                                    }}
                                >
                                    {moment.unix(program?.s).format('dddd ' + deviceContext.clockSetting)} - {moment.unix(program?.e).format(deviceContext.clockSetting)}
                                </Text>
                                <Text
                                    numberOfLines={3}
                                    style={{
                                        color: styling.title_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: styling.title_bold ? 'bold' : 'normal',
                                        marginBottom: getCorrectHeight(deviceContext, 5),
                                    }}
                                >
                                    {program?.d}
                                </Text>
                            </View>
                            <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: getCorrectHeight(deviceContext, 10) }}>
                                <Pressable
                                    style={{
                                        margin: getCorrectWidth(deviceContext, 2),
                                        paddingHorizontal: getCorrectWidth(deviceContext, 20),
                                        height: getCorrectHeight(deviceContext, 30),
                                        ...getPressableStyling(styling.components?.button?._id, appContext),
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    // hasTVPreferredFocus={true}
                                    animatorOptions={getFocusStyling('Buttons', appContext)}
                                    onPress={onPressClose}
                                >
                                    <View>
                                        <Text
                                            style={{
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'cancel')}
                                        </Text>
                                    </View>
                                </Pressable>
                                <Pressable
                                    style={{
                                        margin: getCorrectWidth(deviceContext, 2),
                                        paddingHorizontal: getCorrectWidth(deviceContext, 20),
                                        height: getCorrectHeight(deviceContext, 30),
                                        ...getPressableStyling(styling.components?.button?._id, appContext),
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    animatorOptions={getFocusStyling('Buttons', appContext)}
                                    onPress={() => submitChoice()}
                                >
                                    <View>
                                        <Text
                                            style={{
                                                padding: 10,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'set_reminder')}
                                        </Text>
                                    </View>
                                </Pressable>
                            </View>
                        </View>
                    )}
                    {type == 'reminder_success' && (
                        <View style={{ flex: 1, margin: getCorrectWidth(deviceContext, 20) }}>
                            <View style={{ flex: deviceContext.isPhone ? 0 : 1 }}>
                                <Text
                                    numberOfLines={2}
                                    style={{
                                        color: styling.title_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                        lineHeight: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                        fontWeight: styling.title_bold ? 'bold' : 'normal',
                                        marginBottom: 5,
                                    }}
                                >
                                    {program?.n}
                                </Text>
                                <Text
                                    style={{
                                        color: styling.title_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        lineHeight: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: styling.title_bold ? 'bold' : 'normal',
                                        marginBottom: getCorrectHeight(deviceContext, 10),
                                    }}
                                >
                                    {moment.unix(program?.s).format('dddd ' + deviceContext.clockSetting)} - {moment.unix(program?.e).format(deviceContext.clockSetting)}
                                </Text>
                                <Text
                                    numberOfLines={3}
                                    style={{
                                        color: styling.title_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: styling.title_bold ? 'bold' : 'normal',
                                        marginBottom: getCorrectHeight(deviceContext, 5),
                                    }}
                                >
                                    {program?.d}
                                </Text>
                                <View style={{ flexDirection: 'row', backgroundColor: '#c3f2d6', alignItems: 'center', padding: getCorrectHeight(deviceContext, 10), marginVertical: 5, borderRadius: 5 }}>
                                    <FontAwesomeIcon icon={faCheckCircle} color={'#28ac5c'} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>
                                    <Text
                                        style={{
                                            marginLeft: 10,
                                            color: '#28ac5c',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {lang.getTranslation(userContext, 'reminder_success')}
                                    </Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: getCorrectHeight(deviceContext, 10) }}>
                                <Pressable
                                    style={{
                                        margin: getCorrectWidth(deviceContext, 2),
                                        paddingHorizontal: getCorrectWidth(deviceContext, 20),
                                        height: getCorrectHeight(deviceContext, 30),
                                        ...getPressableStyling(styling.components?.button?._id, appContext),
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    // hasTVPreferredFocus={true}
                                    animatorOptions={getFocusStyling('Buttons', appContext)}
                                    onPress={() => submitChoice(false)}
                                >
                                    <View>
                                        <Text
                                            style={{
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'close')}
                                        </Text>
                                    </View>
                                </Pressable>
                            </View>
                        </View>
                    )}
                    {type == 'recording_removed' && (
                        <View style={{ flex: 1, margin: getCorrectWidth(deviceContext, 20) }}>
                            <View style={{ flex: deviceContext.isPhone ? 0 : 1 }}>
                                <Text
                                    numberOfLines={2}
                                    style={{
                                        color: styling.title_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                        lineHeight: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                        fontWeight: styling.title_bold ? 'bold' : 'normal',
                                        marginBottom: 5,
                                    }}
                                >
                                    {program?.n}
                                </Text>
                                <Text
                                    style={{
                                        color: styling.title_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        lineHeight: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: styling.title_bold ? 'bold' : 'normal',
                                        marginBottom: getCorrectHeight(deviceContext, 10),
                                    }}
                                >
                                    {moment.unix(program?.s).format('dddd ' + deviceContext.clockSetting)} - {moment.unix(program?.e).format(deviceContext.clockSetting)}
                                </Text>
                                <Text
                                    numberOfLines={3}
                                    style={{
                                        color: styling.title_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: styling.title_bold ? 'bold' : 'normal',
                                        marginBottom: getCorrectHeight(deviceContext, 5),
                                    }}
                                >
                                    {program?.d}
                                </Text>
                                <View style={{ flexDirection: 'row', backgroundColor: '#c3f2d6', alignItems: 'center', padding: getCorrectHeight(deviceContext, 10), marginVertical: 5, borderRadius: 5 }}>
                                    <FontAwesomeIcon icon={faInfoCircle} color={'#28ac5c'} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>
                                    <Text
                                        style={{
                                            marginLeft: 10,
                                            color: '#28ac5c',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {lang.getTranslation(userContext, 'recording_removed')}
                                    </Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: getCorrectHeight(deviceContext, 10) }}>
                                <Pressable
                                    style={{
                                        margin: getCorrectWidth(deviceContext, 2),
                                        paddingHorizontal: getCorrectWidth(deviceContext, 20),
                                        height: getCorrectHeight(deviceContext, 30),
                                        ...getPressableStyling(styling.components?.button?._id, appContext),
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    // hasTVPreferredFocus={true}
                                    animatorOptions={getFocusStyling('Buttons', appContext)}
                                    onPress={() => submitChoice(false)}
                                >
                                    <View>
                                        <Text
                                            style={{
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'close')}
                                        </Text>
                                    </View>
                                </Pressable>
                            </View>
                        </View>
                    )}
                    {type == 'reminder_removed' && (
                        <View style={{ flex: 1, margin: getCorrectWidth(deviceContext, 20) }}>
                            <View style={{ flex: deviceContext.isPhone ? 0 : 1 }}>
                                <Text
                                    numberOfLines={2}
                                    style={{
                                        color: styling.title_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                        lineHeight: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                        fontWeight: styling.title_bold ? 'bold' : 'normal',
                                        marginBottom: 5,
                                    }}
                                >
                                    {program?.n}
                                </Text>
                                <Text
                                    style={{
                                        color: styling.title_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        lineHeight: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: styling.title_bold ? 'bold' : 'normal',
                                        marginBottom: getCorrectHeight(deviceContext, 10),
                                    }}
                                >
                                    {moment.unix(program?.s).format('dddd ' + deviceContext.clockSetting)} - {moment.unix(program?.e).format(deviceContext.clockSetting)}
                                </Text>
                                <Text
                                    numberOfLines={3}
                                    style={{
                                        color: styling.title_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: styling.title_bold ? 'bold' : 'normal',
                                        marginBottom: getCorrectHeight(deviceContext, 5),
                                    }}
                                >
                                    {program?.d}
                                </Text>
                                <View style={{ flexDirection: 'row', backgroundColor: '#c3f2d6', alignItems: 'center', padding: getCorrectHeight(deviceContext, 10), marginVertical: 5, borderRadius: 5 }}>
                                    <FontAwesomeIcon icon={faInfoCircle} color={'#28ac5c'} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>
                                    <Text
                                        style={{
                                            marginLeft: 10,
                                            color: '#28ac5c',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {lang.getTranslation(userContext, 'reminder_removed')}
                                    </Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: getCorrectHeight(deviceContext, 10) }}>
                                <Pressable
                                    style={{
                                        margin: getCorrectWidth(deviceContext, 2),
                                        paddingHorizontal: getCorrectWidth(deviceContext, 20),
                                        height: getCorrectHeight(deviceContext, 30),
                                        ...getPressableStyling(styling.components?.button?._id, appContext),
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    // hasTVPreferredFocus={true}
                                    animatorOptions={getFocusStyling('Buttons', appContext)}
                                    onPress={() => submitChoice(false)}
                                >
                                    <View>
                                        <Text
                                            style={{
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'close')}
                                        </Text>
                                    </View>
                                </Pressable>
                            </View>
                        </View>
                    )}
                    {type == 'recording' && (
                        <View style={{ flex: 1, margin: getCorrectWidth(deviceContext, 20) }}>
                            <View style={{ flex: deviceContext.isPhone ? 0 : 1 }}>
                                <Text
                                    numberOfLines={2}
                                    style={{
                                        color: styling.title_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                        lineHeight: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                        fontWeight: styling.title_bold ? 'bold' : 'normal',
                                        marginBottom: 5,
                                    }}
                                >
                                    {program?.n}
                                </Text>
                                <Text
                                    style={{
                                        color: styling.title_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        lineHeight: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: styling.title_bold ? 'bold' : 'normal',
                                        marginBottom: getCorrectHeight(deviceContext, 10),
                                    }}
                                >
                                    {moment.unix(program?.s).format('dddd ' + deviceContext.clockSetting)} - {moment.unix(program?.e).format(deviceContext.clockSetting)}
                                </Text>
                                <Text
                                    numberOfLines={3}
                                    style={{
                                        color: styling.title_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: styling.title_bold ? 'bold' : 'normal',
                                        marginBottom: getCorrectHeight(deviceContext, 5),
                                    }}
                                >
                                    {program?.d}
                                </Text>
                            </View>
                            <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: getCorrectHeight(deviceContext, 10) }}>
                                <Pressable
                                    style={{
                                        margin: getCorrectWidth(deviceContext, 2),
                                        paddingHorizontal: getCorrectWidth(deviceContext, 20),
                                        height: getCorrectHeight(deviceContext, 30),
                                        ...getPressableStyling(styling.components?.button?._id, appContext),
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    // hasTVPreferredFocus={true}
                                    animatorOptions={getFocusStyling('Buttons', appContext)}
                                    onPress={onPressClose}
                                >
                                    <View>
                                        <Text
                                            style={{
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'cancel')}
                                        </Text>
                                    </View>
                                </Pressable>
                                <Pressable
                                    style={{
                                        margin: getCorrectWidth(deviceContext, 2),
                                        paddingHorizontal: getCorrectWidth(deviceContext, 20),
                                        height: getCorrectHeight(deviceContext, 30),
                                        ...getPressableStyling(styling.components?.button?._id, appContext),
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    animatorOptions={getFocusStyling('Buttons', appContext)}
                                    onPress={() => submitChoice()}
                                >
                                    <View>
                                        <Text
                                            style={{
                                                padding: 10,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'plan_recording')}
                                        </Text>
                                    </View>
                                </Pressable>
                            </View>
                        </View>
                    )}
                    {type == 'recording_waiting' && (
                        <View style={{ flex: 1, margin: getCorrectWidth(deviceContext, 20) }}>
                            <View style={{ flex: deviceContext.isPhone ? 0 : 1 }}>
                                <Text
                                    numberOfLines={2}
                                    style={{
                                        color: styling.title_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                        lineHeight: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                        fontWeight: styling.title_bold ? 'bold' : 'normal',
                                        marginBottom: 5,
                                    }}
                                >
                                    {program?.n}
                                </Text>
                                <Text
                                    style={{
                                        color: styling.title_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        lineHeight: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: styling.title_bold ? 'bold' : 'normal',
                                        marginBottom: getCorrectHeight(deviceContext, 10),
                                    }}
                                >
                                    {moment.unix(program?.s).format('dddd ' + deviceContext.clockSetting)} - {moment.unix(program?.e).format(deviceContext.clockSetting)}
                                </Text>
                                <Text
                                    numberOfLines={3}
                                    style={{
                                        color: styling.title_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: styling.title_bold ? 'bold' : 'normal',
                                        marginBottom: getCorrectHeight(deviceContext, 5),
                                    }}
                                >
                                    {program?.d}
                                </Text>

                                <View style={{ flexDirection: 'row', backgroundColor: '#d6f0ff', alignItems: 'center', padding: getCorrectHeight(deviceContext, 10), marginVertical: 5, borderRadius: 5 }}>
                                    <ActivityIndicator color={'#3482ba'} size={'small'}></ActivityIndicator>
                                    <Text
                                        style={{
                                            marginLeft: 10,
                                            color: '#3482ba',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {lang.getTranslation(userContext, 'recording_waiting')}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    )}
                    {type == 'recording_success' && (
                        <View style={{ flex: 1, margin: getCorrectWidth(deviceContext, 20) }}>
                            <View style={{ flex: deviceContext.isPhone ? 0 : 1 }}>
                                <Text
                                    numberOfLines={2}
                                    style={{
                                        color: styling.title_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                        lineHeight: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                        fontWeight: styling.title_bold ? 'bold' : 'normal',
                                        marginBottom: 5,
                                    }}
                                >
                                    {program?.n}
                                </Text>
                                <Text
                                    style={{
                                        color: styling.title_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        lineHeight: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: styling.title_bold ? 'bold' : 'normal',
                                        marginBottom: getCorrectHeight(deviceContext, 10),
                                    }}
                                >
                                    {moment.unix(program?.s).format('dddd ' + deviceContext.clockSetting)} - {moment.unix(program?.e).format(deviceContext.clockSetting)}
                                </Text>
                                <Text
                                    numberOfLines={3}
                                    style={{
                                        color: styling.title_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: styling.title_bold ? 'bold' : 'normal',
                                        marginBottom: getCorrectHeight(deviceContext, 5),
                                    }}
                                >
                                    {program?.d}
                                </Text>
                                <View style={{ flexDirection: 'row', backgroundColor: '#c3f2d6', alignItems: 'center', padding: getCorrectHeight(deviceContext, 10), marginVertical: 5, borderRadius: 5 }}>
                                    <FontAwesomeIcon icon={faInfoCircle} color={'#28ac5c'} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>
                                    <Text
                                        style={{
                                            marginLeft: 10,
                                            color: '#28ac5c',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {lang.getTranslation(userContext, 'recording_success')}
                                    </Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: getCorrectHeight(deviceContext, 10) }}>
                                <Pressable
                                    style={{
                                        margin: getCorrectWidth(deviceContext, 2),
                                        paddingHorizontal: getCorrectWidth(deviceContext, 20),
                                        height: getCorrectHeight(deviceContext, 30),
                                        ...getPressableStyling(styling.components?.button?._id, appContext),
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    // hasTVPreferredFocus={true}
                                    animatorOptions={getFocusStyling('Buttons', appContext)}
                                    onPress={onPressClose}
                                >
                                    <View>
                                        <Text
                                            style={{
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'close')}
                                        </Text>
                                    </View>
                                </Pressable>
                            </View>
                        </View>
                    )}
                    {type == 'recording_error' && (
                        <View style={{ flex: 1, margin: getCorrectWidth(deviceContext, 20) }}>
                            <View style={{ flex: deviceContext.isPhone ? 0 : 1 }}>
                                <Text
                                    numberOfLines={2}
                                    style={{
                                        color: styling.title_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                        lineHeight: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                        fontWeight: styling.title_bold ? 'bold' : 'normal',
                                        marginBottom: 5,
                                    }}
                                >
                                    {program?.n}
                                </Text>
                                <Text
                                    style={{
                                        color: styling.title_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        lineHeight: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: styling.title_bold ? 'bold' : 'normal',
                                        marginBottom: getCorrectHeight(deviceContext, 10),
                                    }}
                                >
                                    {moment.unix(program?.s).format('dddd ' + deviceContext.clockSetting)} - {moment.unix(program?.e).format(deviceContext.clockSetting)}
                                </Text>
                                <Text
                                    numberOfLines={3}
                                    style={{
                                        color: styling.title_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: styling.title_bold ? 'bold' : 'normal',
                                        marginBottom: getCorrectHeight(deviceContext, 5),
                                    }}
                                >
                                    {program?.d}
                                </Text>
                                <View style={{ flexDirection: 'row', backgroundColor: '#ffe1e3', alignItems: 'center', padding: getCorrectHeight(deviceContext, 10), marginVertical: 5, borderRadius: 5 }}>
                                    <FontAwesomeIcon icon={faInfoCircle} color={'#f84d51'} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>
                                    <Text
                                        style={{
                                            marginLeft: 10,
                                            color: '#f84d51',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {lang.getTranslation(userContext, error)}
                                    </Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: getCorrectHeight(deviceContext, 10) }}>
                                <Pressable
                                    style={{
                                        margin: getCorrectWidth(deviceContext, 2),
                                        paddingHorizontal: getCorrectWidth(deviceContext, 20),
                                        height: getCorrectHeight(deviceContext, 30),
                                        ...getPressableStyling(styling.components?.button?._id, appContext),
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    // hasTVPreferredFocus={true}
                                    animatorOptions={getFocusStyling('Buttons', appContext)}
                                    onPress={onPressClose}
                                >
                                    <View>
                                        <Text
                                            style={{
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'close')}
                                        </Text>
                                    </View>
                                </Pressable>
                            </View>
                        </View>
                    )}
                    {type == 'reset_profiles' && (
                        <View style={{ flex: 1, margin: getCorrectWidth(deviceContext, 20) }}>
                            <View style={{ flex: deviceContext.isPhone ? 0 : 1 }}>
                                <Text
                                    style={{
                                        color: styling.title_color,
                                        fontWeight: styling.title_bold ? 'bold' : 'normal',
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                        lineHeight: getFontSize(deviceContext, 'ExtraLarge'),
                                        marginBottom: getCorrectHeight(deviceContext, 10),
                                    }}
                                >
                                    {lang.getTranslation(userContext, getTitle())}
                                </Text>

                                <View style={{ flexDirection: 'row', backgroundColor: '#d6f0ff', alignItems: 'center', padding: getCorrectHeight(deviceContext, 10), marginVertical: 5, borderRadius: 5 }}>
                                    <FontAwesomeIcon icon={faInfoCircle} color={'#3482ba'} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>
                                    <Text
                                        style={{
                                            marginLeft: 10,
                                            color: '#3482ba',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {lang.getTranslation(userContext, getSubText())}
                                    </Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: getCorrectHeight(deviceContext, 10) }}>
                                <Pressable
                                    style={{
                                        margin: getCorrectWidth(deviceContext, 2),
                                        paddingHorizontal: getCorrectWidth(deviceContext, 20),
                                        height: getCorrectHeight(deviceContext, 30),
                                        ...getPressableStyling(styling.components?.button?._id, appContext),
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    // hasTVPreferredFocus={true}
                                    animatorOptions={getFocusStyling('Buttons', appContext)}
                                    onPress={onPressClose}
                                >
                                    <View>
                                        <Text
                                            style={{
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'cancel')}
                                        </Text>
                                    </View>
                                </Pressable>
                                <Pressable
                                    style={{
                                        margin: getCorrectWidth(deviceContext, 2),
                                        paddingHorizontal: getCorrectWidth(deviceContext, 20),
                                        height: getCorrectHeight(deviceContext, 30),
                                        ...getPressableStyling(styling.components?.button?._id, appContext),
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    animatorOptions={getFocusStyling('Buttons', appContext)}
                                    onPress={() => submitChoice()}
                                >
                                    <View>
                                        <Text
                                            style={{
                                                padding: 10,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'submit')}
                                        </Text>
                                    </View>
                                </Pressable>
                            </View>
                        </View>
                    )}
                    {type === 'network_status_changed' && (
                        <View style={{ flex: 1, margin: getCorrectWidth(deviceContext, 20) }}>
                            <View style={{ flex: deviceContext.isPhone ? 0 : 1 }}>
                                <Text
                                    style={{
                                        color: styling.title_color,
                                        fontWeight: styling.title_bold ? 'bold' : 'normal',
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                        lineHeight: getFontSize(deviceContext, 'ExtraLarge'),
                                        marginBottom: getCorrectHeight(deviceContext, 10),
                                    }}
                                >
                                    {/* {lang.getTranslation(userContext, getTitle())} */}
                                    {networkContext.connected ? 'Connected to network' : 'Connection to network lost'}
                                </Text>

                                <View style={{ flexDirection: 'row', backgroundColor: '#d6f0ff', alignItems: 'center', padding: getCorrectHeight(deviceContext, 10), marginVertical: 5, borderRadius: 5 }}>
                                    <FontAwesomeIcon icon={faInfoCircle} color={'#3482ba'} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>
                                    <Text
                                        style={{
                                            marginLeft: 10,
                                            color: '#3482ba',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {/* {lang.getTranslation(userContext, getSubText())} */}
                                        App reload is needed for further usage
                                    </Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: getCorrectHeight(deviceContext, 10) }}>
                                <Pressable
                                    style={{
                                        margin: getCorrectWidth(deviceContext, 2),
                                        paddingHorizontal: getCorrectWidth(deviceContext, 20),
                                        height: getCorrectHeight(deviceContext, 30),
                                        ...getPressableStyling(styling.components?.button?._id, appContext),
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    animatorOptions={getFocusStyling('Buttons', appContext)}
                                    onPress={() => submitChoice()}
                                >
                                    <View>
                                        <Text
                                            style={{
                                                padding: 10,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'restart')}
                                        </Text>
                                    </View>
                                </Pressable>
                            </View>
                        </View>
                    )}
                </View>
            );
        },
        [timerInternal]
    );

    return (
        <ScreenFadeIn focusContext={focusContext} style={{ position: 'absolute', zIndex: 99999 }}>
            <ViewGroup
                focusOptions={{ focusKey: 'modal-group', group: 'modal-group', modal: true }}
                style={{
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    overflow: 'visible',
                    height: getRealHeight(deviceContext, 'screen'),
                    position: 'absolute',
                    zIndex: 99999,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <View style={{ width: getRealWidth(deviceContext), ...(rotated && { transform: [{ rotate: '90deg' }] }) }}>
                    <ScrollView
                        style={{
                            width: deviceContext.isPhone || deviceContext.isPwa ? getRealWidth(deviceContext) * 0.9 : getCorrectHeight(deviceContext, type == 'quickmenu' ? 300 : 450),
                            borderColor: styling.border_color,
                            borderWidth: styling.border_width,
                            borderRadius: getCorrectWidth(deviceContext, styling.border_radius),
                            alignSelf: 'center',
                            backgroundColor: styling.background_color,
                        }}
                    >
                        {getModalContents()}
                    </ScrollView>
                </View>
            </ViewGroup>
        </ScreenFadeIn>
    );
};

export default Modal;
