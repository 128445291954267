import { CreateListRenderItemInfo, FlashList, FocusContext, setFocus, CoreManager, View } from '@scriptx-com/xtv-toolkit';
import { faRectangleHistory } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import * as React from 'react';
import { AppContext } from '../../../context/appContext';
import { ContentContext } from '../../../context/contentContext';
import { DeviceContext } from '../../../context/deviceContext';
import { UserContext } from '../../../context/userContext';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { getFontSize } from '../../../styling/fontSizes';
import { getRealWidth } from '../../../styling/realDeviceSizes';
import Pressable from '../../components/pressable/pressable';
import Text from '../../components/text';
import Square from '../shared/square';
import {
    checkIfAccessToContent,
    checkIfUserGeneratedListAndEmpty,
    EMPTY_DATA_ARRAY,
    EMPTY_DATA_ARRAY_SQUARE,
    extraTopBottomMargin,
    getAlbumsFromSubscriptionAndRating,
    getCamsFromSubscriptionAndRating,
    getChannelsFromSubscriptionAndRating,
    getDetailsScreen,
    getFavoritesForRails,
    getPodcastsFromSubscriptionAndRating,
    getRadiosFromSubscriptionAndRating,
    getRailsHeight,
    getRailsWidth,
    getSortingByRecommendation,
    getWatchlistForRails,
} from './helpers/helper';
import { getFocusStyling, getModalStyling, getPressableStyling } from '../../components/helpers/helper';
import { favoriteStatus, manageFavorite, manageWatching, watchingStatus } from '../../../data/account';
import Modal from '../../components/modal/modal';
import lang from '../../../../application/languages/languages';
import { getCurrentScreenName } from '../../../hooks/getScreenName';
import { isFactorMobile, isPlatformAndroid, isPlatformIos, isPlatformTvos } from '@rnv/renative';
import { getCorrectLeftMargin } from '../../../styling/appLeftMargin';
import { SettingsContext } from '../../../context/settingsContext';
import { GetScrollElement, GetTopPart, PressAndHold } from './helpers/views';
import useBaseRail from './useBaseRail';
import { useFocusEffect } from '@react-navigation/native';
import { filterAgeRatings } from '../grids/helpers/functions';
import utils from '../../../general/utils';

export interface Data {
    items: [
        {
            square_image: string;
            name: string;
            _id: string;
        }
    ];
}

export interface Props {
    styling: any;
    focusContext?: FocusContext;
    navigation: any;
    reloadElements: any;
    heroIsPlayer: boolean;
    railIndex?: number;
}

export const Square_Rails = React.memo(({ styling, focusContext, navigation, reloadElements, heroIsPlayer, railIndex }: Props) => {
    const screenName = getCurrentScreenName();

    const [dataRefreshed, setDataRefreshed] = React.useState<any>();
    const contentContext = React.useContext(ContentContext);
    const appContext = React.useContext(AppContext);
    const deviceContext = React.useContext(DeviceContext);
    const userContext = React.useContext(UserContext);
    const settingsContext = React.useContext(SettingsContext);
    const [favoriteCount, setFavoriteCount] = React.useState();
    const [data, setData] = React.useState(screenName === 'Home' && !checkIfUserGeneratedListAndEmpty(styling, userContext, contentContext, appContext) ? EMPTY_DATA_ARRAY_SQUARE : []);

    const currentFocusRailIndex = React.useRef<number | undefined>();

    let height = isPlatformAndroid || isPlatformIos ? React.useRef(getCorrectHeight(deviceContext, styling.placement.height)).current : getCorrectHeight(deviceContext, styling.placement.height);
    if (height == 0 || isNaN(height)) {
        height = getCorrectHeight(deviceContext, 100);
    }
    if (deviceContext.formFactor == 'Phone') {
        height = height * 0.8;
    }
    if (styling.texts.placement_text == 'Below') {
        height = height + getCorrectWidth(deviceContext, 25);
    }
    let width = height;

    useFocusEffect(() => {
        if (styling.content.type === 'Content List') {
            const favName =
                styling.content.list?.type === 'Radio' ? 'radios' : styling.content.list?.type === 'Channels' ? 'channels' : styling.content.list?.type === 'Cams' ? 'cams' : styling.content.list?.type === 'Podcasts' ? 'podcasts' : 'music';
            setFavoriteCount(userContext.profile.favorites[favName].length);
        }
    });

    //mobile width check
    if (width > getRealWidth(deviceContext) && deviceContext.formFactor == 'Phone') {
        height = getRealWidth(deviceContext) * 0.7;
        if (styling.texts.placement_text == 'Below') {
            height = height + getCorrectWidth(deviceContext, 25);
        }
        width = height;
    }

    const getData = async () => {
        if (styling.content.type === 'Search') {
            handleSearch();
            return;
        }
        if (styling.content.type == 'Watched') {
            var items = getWatchlistForRails(styling.content.list?.type, userContext, contentContext, appContext) as any;
            if (items != undefined && items.length > 0) {
                if (checkIfAccessToContent(appContext, styling.content.list?.type)) {
                    const filteredItems = filterAgeRatings(items, userContext, appContext);
                    setData(filteredItems);
                    setDataRefreshed(Date.now());
                    return;
                }
            }
        }
        if (styling.content.type == 'Favorites') {
            var items = (await getFavoritesForRails(styling.content.list?.type, userContext, contentContext, appContext)) as any;
            if (items != undefined && items.length > 0) {
                if (checkIfAccessToContent(appContext, styling.content.list?.type)) {
                    const filteredItems = filterAgeRatings(items, userContext, appContext);
                    setData(filteredItems);
                    setDataRefreshed(Date.now());
                    return;
                }
            }
        }
        if (styling.content.type == 'Content List') {
            var path = '';
            if (styling.content.list?.type == 'Channels') {
                path = utils.getCorrectStoragePrefix(appContext, true) + 'channels/lists/' + styling.content.list.list + '_list.json';
            }
            if (styling.content.list?.type == 'Cams') {
                path = utils.getCorrectStoragePrefix(appContext, true) + 'cams/lists/' + styling.content.list.list + '_list.json';
            }
            if (styling.content.list?.type == 'Radio') {
                path = utils.getCorrectStoragePrefix(appContext, true) + 'radios/lists/' + styling.content.list.list + '_list.json';
            }
            if (styling.content.list?.type == 'Music') {
                path = utils.getCorrectStoragePrefix(appContext, true) + 'albums/lists/' + styling.content.list.list + '_list.json';
            }
            if (styling.content.list?.type == 'Podcasts') {
                path = utils.getCorrectStoragePrefix(appContext, true) + 'podcasts/lists/' + styling.content.list.list + '_list.json';
            }
            try {
                let response = await fetch(path);
                let data = await response.json();
                if (data != undefined) {
                    if (styling.content.list?.type == 'Channels') {
                        var sortedByRecommended = getSortingByRecommendation(data[0].items, userContext.profile.recommendations);
                        var dataOut = getChannelsFromSubscriptionAndRating(contentContext, data[0].items);
                        if (appContext.application.settings.agerating?.ratings != undefined) {
                            dataOut = filterAgeRatings(dataOut, userContext, appContext);
                        }
                        setData(dataOut);
                        if (dataOut?.length) return;
                    }
                    if (styling.content.list?.type == 'Cams') {
                        var sortedByRecommended = getSortingByRecommendation(data[0].items, userContext.profile.recommendations);
                        var dataOut = getCamsFromSubscriptionAndRating(contentContext, sortedByRecommended);
                        if (appContext.application.settings.agerating?.ratings != undefined) {
                            dataOut = filterAgeRatings(dataOut, userContext, appContext);
                        }
                        setData(dataOut);
                        if (dataOut?.length) return;
                    }
                    if (styling.content.list?.type == 'Radio') {
                        var sortedByRecommended = getSortingByRecommendation(data[0].items, userContext.profile.recommendations);
                        var dataOut = getRadiosFromSubscriptionAndRating(contentContext, sortedByRecommended);
                        if (appContext.application.settings.agerating?.ratings != undefined) {
                            dataOut = filterAgeRatings(dataOut, userContext, appContext);
                        }
                        setData(dataOut);
                        if (dataOut?.length) return;
                    }
                    if (styling.content.list?.type == 'Music') {
                        var sortedByRecommended = getSortingByRecommendation(data[0].items, userContext.profile.recommendations);
                        var dataOut = getAlbumsFromSubscriptionAndRating(contentContext, sortedByRecommended);
                        if (appContext.application.settings.agerating?.ratings != undefined) {
                            dataOut = filterAgeRatings(dataOut, userContext, appContext);
                        }
                        setData(dataOut);
                        if (dataOut?.length) return;
                    }
                    if (styling.content.list?.type == 'Podcasts') {
                        var sortedByRecommended = getSortingByRecommendation(data[0].items, userContext.profile.recommendations);
                        var dataOut = getPodcastsFromSubscriptionAndRating(contentContext, sortedByRecommended);
                        if (appContext.application.settings.agerating?.ratings != undefined) {
                            dataOut = filterAgeRatings(dataOut, userContext, appContext);
                        }
                        setData(dataOut);
                        if (dataOut?.length) return;
                    }
                }
            } catch (error) {
                //
            }
        }
        if (styling.content.type == 'AppsAndroidTV') {
            const apps = await global.InstalledApps.getApps({ includeVersion: true, includeAccentColor: true });
            const dataOut = [] as any;
            for (let app of apps) {
                dataOut.push({
                    name: app.label,
                    images: {
                        square: app.icon,
                    },
                    color: app.accentColor,
                    package: app.packageName,
                });
            }
            setData(dataOut);

            if (dataOut?.length) return;
        }
        // setData(screenName === 'Home' ? EMPTY_DATA_ARRAY : []);
    };

    const { Loader, flashListProps, onViewableItemsChanged, scrollRight, scrollLeft, railsRef, viewableItemsCount } = useBaseRail({
        contentLoaderHeight: height,
        contentLoaderWidth: width,
        data: data,
        railIndex,
        railType: 'square',
        contentType: styling.content.type,
        getData,
        emptyItemRenderer: (props: any) => {
            return renderItem({
                item: EMPTY_DATA_ARRAY[0],
                index: props.index,
                target: 'Cell',
                emptyRailProps: props,
            });
        },
        animator: getFocusStyling('Rails', appContext),
        isUserGeneratedListAndEmpty: checkIfUserGeneratedListAndEmpty(styling, userContext, contentContext, appContext),
    });

    React.useEffect(() => {
        if (railIndex === currentFocusRailIndex.current) {
            if (data.length === 0) {
                setFocus(`empty-square-placeholder-${railIndex}`);
            } else {
                const focusIndex = data.length > 2 ? 2 : 0;
                setFocus(`square-${railIndex}-${focusIndex}`);
            }
        }
    }, [dataRefreshed]);

    React.useEffect(() => {
        if (isFactorMobile && styling.content.type === 'Search') handleSearch();
    }, [contentContext.search]);

    const handleSearch = () => {
        if (contentContext.searchRef.current.length <= 2) {
            setData([]);
            return;
        }
        if (styling.content.list?.type == 'Channels' && appContext.application.menus?.find((m) => m.name == 'Channels') != undefined) {
            var channels = [] as any;
            contentContext.channels?.categories?.forEach((category) => {
                category.channels.forEach((channel) => {
                    if (channel.name.toLowerCase().indexOf(contentContext.searchRef.current.toLowerCase()) > -1) {
                        if (!channels.some((_) => _._id === channel._id)) {
                            channels.push(channel);
                        }
                    }
                });
            });

            if (channels.length > 0) {
                if (appContext.application.settings.agerating?.ratings != undefined) {
                    channels = filterAgeRatings(channels, userContext, appContext);
                }
                setData(channels);
                return;
            }
        }
        if (styling.content.list?.type == 'Radio' && appContext.application.menus?.find((m) => m.name == 'Radio') != undefined) {
            var radios = [] as any;
            contentContext.radios?.categories?.forEach((category) => {
                category.radios.forEach((radio) => {
                    if (radio.name.toLowerCase().indexOf(contentContext.searchRef.current.toLowerCase()) > -1) {
                        if (!radios.some((_) => _._id === radio._id)) {
                            radios.push(radio);
                        }
                    }
                });
            });

            if (radios.length > 0) {
                if (appContext.application.settings.agerating?.ratings != undefined) {
                    radios = filterAgeRatings(radios, userContext, appContext);
                }
                setData(radios);
                return;
            }
        }
        if (styling.content.list?.type == 'Cams' && appContext.application.menus?.find((m) => m.name == 'Cams') != undefined) {
            var cams = [] as any;
            contentContext.cams?.categories?.forEach((category) => {
                category.cams.forEach((cam) => {
                    if (cam.name.toLowerCase().indexOf(contentContext.searchRef.current.toLowerCase()) > -1) {
                        if (!cams.some((_) => _._id === cam._id)) {
                            cams.push(cam);
                        }
                    }
                });
            });
            if (cams.length > 0) {
                if (appContext.application.settings.agerating?.ratings != undefined) {
                    cams = filterAgeRatings(cams, userContext, appContext);
                }
                setData(cams);
                return;
            }
        }
        if (styling.content.list?.type == 'Podcasts' && appContext.application.menus?.find((m) => m.name == 'Podcasts') != undefined) {
            var podcasts = [] as any;

            contentContext.podcasts?.categories?.forEach((category) => {
                category.podcasts.forEach((podcast) => {
                    if (podcast.name.toLowerCase().indexOf(contentContext.searchRef.current.toLowerCase()) > -1) {
                        if (!podcasts.some((_) => _._id === podcast._id)) {
                            podcasts.push(podcast);
                        }
                    }
                });
            });

            if (podcasts.length > 0) {
                if (appContext.application.settings.agerating?.ratings != undefined) {
                    podcasts = filterAgeRatings(podcasts, userContext, appContext);
                }
                setData(podcasts);
                return;
            }
        }
        if (styling.content.list?.type == 'Music' && appContext.application.menus?.find((m) => m.name == 'Music') != undefined) {
            var albums = [] as any;
            contentContext.albums?.categories?.forEach((category) => {
                category.albums.forEach((album) => {
                    if (album.name.toLowerCase().indexOf(contentContext.searchRef.current.toLowerCase()) > -1) {
                        if (!albums.some((_) => _._id === album._id)) {
                            albums.push(album);
                        }
                    }
                });
            });
            if (albums.length > 0) {
                if (appContext.application.settings.agerating?.ratings != undefined) {
                    albums = filterAgeRatings(albums, userContext, appContext);
                }
                setData(albums);
                return;
            }
        }
        setData([]);
    };

    const detailsItem = (item: any, index: any) => {
        getDetailsScreen(styling, userContext, contentContext, appContext, navigation, item, settingsContext);
    };

    const renderItem = ({ item, focusRepeatContext, index, emptyRailProps }: CreateListRenderItemInfo<any> & { emptyRailProps?: any }) => {
        return (
            <Square
                elementType={'Rails'}
                heroIsPlayer={heroIsPlayer}
                rails={true}
                type={styling.content.list?.type}
                styling={styling}
                data={item}
                height={height}
                width={width}
                focusOptions={{
                    focusKey: `square-${railIndex}-${index}`,
                }}
                focusRepeatContext={focusRepeatContext}
                onPress={() => {
                    if (item.package != undefined) {
                        global.RNLauncherKitHelper.launchApplication(item.package);
                    } else if (item.type !== 'empty') {
                        detailsItem(item, index);
                    }
                }}
                onLongPress={() => {
                    if (item.type !== 'empty') {
                        showLongpressModal(item, index);
                    }
                }}
                isFirst={index === 0}
                isLast={data.length - 1 === index}
                {...emptyRailProps}
            ></Square>
        );
    };

    const [showLongpress, setShowLongpress] = React.useState(false);
    const [favorite, setFavorite] = React.useState(false);
    const [watchlist, setWatchlist] = React.useState(false);
    const [longItem, setLongItem] = React.useState(false);

    const showLongpressModal = (item, index) => {
        const currentFocusParent = CoreManager.getCurrentFocus()?.getParent()?.getParent();
        const parentGroup = currentFocusParent;
        // @ts-ignore
        currentFocusRailIndex.current = Number(parentGroup?._focusKey?.split?.('-')?.[1]);

        if (styling.content.press_and_hold && (appContext.application.settings.allow_watchlist || appContext.application.settings.allow_favorites)) {
            setFavorite(favoriteStatus(styling.content.list?.type, item._id, userContext));
            setWatchlist(watchingStatus(styling.content.list?.type, item._id, userContext) == undefined ? false : true);
            setLongItem(item);
            setShowLongpress(true);
        }
    };
    const manageWatchlist_ = () => {
        setWatchlist(!watchlist);
        manageWatching(userContext, styling.content.list?.type, longItem, appContext.application, watchlist, 0, 0, [], [], [], [], contentContext);
        setShowLongpress(false);

        reloadElements();
    };
    const manageFavorite_ = () => {
        setFavorite(!favorite);
        manageFavorite(contentContext, styling.content.list?.type, longItem, appContext.application, favorite, userContext, deviceContext, appContext);
        setShowLongpress(false);
        reloadElements();
    };

    React.useEffect(() => {
        if (showLongpress) {
            appContext.setModal(
                <Modal
                    watchlist={watchlist}
                    favorite={favorite}
                    focusContext={focusContext}
                    styling={getModalStyling(appContext.application.theme.modals.parental, appContext)}
                    type={'quickmenu'}
                    submitChoice2={manageWatchlist_}
                    submitChoice={manageFavorite_}
                    setShowModal={() => setShowLongpress(false)}
                    data={longItem}
                    contentType={styling.content.list?.type}
                />
            );
        } else {
            appContext.setModal(null);
        }
    }, [showLongpress, watchlist, favorite, longItem]);

    const openOverviewPage = () => {
        navigation &&
            navigation.navigate(styling.content.list?.type, {
                railsItems: data,
            });
    };

    const getCorrectScaleStyling = () => {
        return getFocusStyling('Rails', appContext).type.indexOf('scale') > -1 && deviceContext.formFactor == 'Television';
    };

    if (styling.content.type === 'Search' && data.length === 0) {
        return null;
    }

    return (
        <View focusContext={focusContext}>
            {data != null && data.length > 0 && (
                <View
                    style={{
                        flex: 1,
                        marginLeft: getCorrectLeftMargin(deviceContext, appContext, screenName),
                        marginRight: styling.placement.background_color != '#00000000' && styling.placement.background_color != 'transparent' && deviceContext.formFactor != 'Phone' ? getCorrectWidth(deviceContext, 10) : 0,
                        marginTop: appContext.application.theme.rails_top_margin / (deviceContext.formFactor == 'Phone' ? 2 : 1) + extraTopBottomMargin(deviceContext, styling),
                        marginBottom: appContext.application.theme.rails_bottom_margin / (deviceContext.formFactor == 'Phone' ? 2 : 1) + extraTopBottomMargin(deviceContext, styling),
                    }}
                >
                    <View style={{ flex: 1 }}>
                        <View
                            style={{
                                backgroundColor: styling.placement.background_color,
                                height: styling.content.type === 'Search' && data.length === 0 ? 0 : getRailsHeight(deviceContext, appContext, styling, height),
                                borderRadius: getCorrectWidth(deviceContext, styling.placement.border_radius),
                                paddingTop: getCorrectHeight(deviceContext, styling.placement.padding),
                                paddingBottom: getCorrectHeight(deviceContext, styling.placement.padding),
                                paddingHorizontal:
                                    styling.placement.background_color != '#00000000' && styling.placement.background_color != 'transparent'
                                        ? deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs
                                            ? getCorrectWidth(deviceContext, 5)
                                            : getCorrectWidth(deviceContext, styling.placement.padding)
                                        : 0,
                            }}
                        >
                            <GetTopPart
                                screenName={screenName}
                                focusContext={focusContext}
                                openOverviewPage={openOverviewPage}
                                deviceContext={deviceContext}
                                appContext={appContext}
                                userContext={userContext}
                                styling={styling}
                                data={data.length >= 1 ? data : screenName === 'Home' ? [{ id: '', title: 'ok' }] : []}
                            ></GetTopPart>

                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                {(styling.content.type == 'Watched' || styling.content.type == 'Favorites' || data.length > 0) && styling.content.list?.enable_overview && (
                                    <View style={getCorrectScaleStyling() ? { justifyContent: 'center' } : { marginRight: getCorrectWidth(deviceContext, 10) }}>
                                        <Pressable
                                            style={[
                                                {
                                                    ...getPressableStyling(styling.components.button._id, appContext),
                                                    height: height - getCorrectHeight(deviceContext, appContext.application.theme.images.margin),
                                                    width: width,
                                                },
                                            ]}
                                            focusContext={focusContext}
                                            animatorOptions={getFocusStyling('Buttons', appContext)}
                                            onPress={() => openOverviewPage()}
                                            focusOptions={{
                                                focusKey: `rectangle-${railIndex}-X`,
                                                nextFocusLeft: ['menu-drawer'],
                                            }}
                                        >
                                            <View style={{ flex: 1, justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                                                <FontAwesomeIcon icon={faRectangleHistory} color={'#fff'} size={getFontSize(deviceContext, 'ExtraExtraLarge')}></FontAwesomeIcon>
                                                <Text
                                                    numberOfLines={1}
                                                    style={{
                                                        marginTop: 10,
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        color: styling.texts.title_color == '' ? '#ffffff' : styling.texts.title_color,
                                                    }}
                                                >
                                                    {lang.getTranslation(userContext, 'more').toUpperCase()}
                                                </Text>
                                            </View>
                                        </Pressable>
                                    </View>
                                )}
                                <View
                                    style={{ flex: 1, justifyContent: 'center', width: getRailsWidth(deviceContext, appContext, styling, screenName) }}
                                    focusContext={focusContext}
                                    focusOptions={{
                                        group: screenName === 'Search' ? `search-rail-${railIndex}` : `square-${railIndex}`,
                                        focusKey: screenName === 'Search' ? `search-rail-${railIndex}` : `square-${railIndex}`,
                                        allowFocusOutsideGroup: true,
                                    }}
                                >
                                    <View>{Loader()}</View>
                                    {data != null && data.length > 0 && (
                                        <FlashList
                                            ref={railsRef}
                                            onViewableItemsChanged={onViewableItemsChanged}
                                            type="row"
                                            data={data}
                                            extraData={favoriteCount}
                                            estimatedItemSize={height}
                                            horizontal={true}
                                            showsHorizontalScrollIndicator={false}
                                            renderItem={renderItem}
                                            initialScrollIndex={0}
                                            {...flashListProps}
                                            focusOptions={{
                                                autoLayoutScaleAnimation: getFocusStyling('Rails', appContext).type.indexOf('scale') > -1 && deviceContext.formFactor == 'Television' ? true : false,
                                                autoLayoutSize: getFocusStyling('Rails', appContext).type.indexOf('scale') > -1 && deviceContext.formFactor == 'Television' ? 10 : 0,
                                                ...flashListProps.focusOptions,
                                                ...(screenName === 'Search' && {
                                                    nextFocusDown: Array.from({ length: 9 }, (_, i) => railIndex + 1 + i).map((i) => `search-rail-${i}`),
                                                    nextFocusUp:
                                                        railIndex > 0
                                                            ? Array.from({ length: railIndex }, (_, i) => i)
                                                                  .map((i) => `search-rail-${i}`)
                                                                  .reverse()
                                                            : undefined,
                                                }),
                                            }}
                                            {...(isPlatformTvos && {
                                                contentInset: { left: 11 },
                                            })}
                                        />
                                    )}
                                    {deviceContext.isWebTV && !deviceContext.isPwa && data.length > 0 && data.length > viewableItemsCount && (
                                        <GetScrollElement height={height} scrollLeft={scrollLeft} scrollRight={scrollRight} deviceContext={deviceContext} appContext={appContext}></GetScrollElement>
                                    )}
                                </View>
                            </View>

                            {deviceContext.formFactor != 'Phone' && styling.content.press_and_hold && styling.content.press_and_hold_message && data != null && data.length > 0 && (
                                <PressAndHold deviceContext={deviceContext} appContext={appContext} userContext={userContext} lang={lang}></PressAndHold>
                            )}
                        </View>
                    </View>
                </View>
            )}
        </View>
    );
});
export default Square_Rails;
