import { useFocusEffect } from '@react-navigation/native';
import { View } from '@scriptx-com/xtv-toolkit';
import moment from 'moment';
import * as React from 'react';
import { BackHandler } from 'react-native';
import { sendActionReport } from '../../../reporting/apis/action';
import { sendPageReport } from '../../../reporting/apis/page';
import { actionType, pageType } from '../../../reporting/models/apps';
import { AppContext } from '../../context/appContext';
import { ContentContext } from '../../context/contentContext';
import { DeviceContext } from '../../context/deviceContext';
import { PlayerContext } from '../../context/playerContext';
import { SettingsContext } from '../../context/settingsContext';
import { UserContext } from '../../context/userContext';
import { favoriteStatus, manageFavorite, manageWatching } from '../../data/account';
import { getCurrentScreenName } from '../../hooks/getScreenName';
import { getRealHeight, getRealWidth } from '../../styling/realDeviceSizes';
import TheoPlayer from '../components/players/theoPlayer';
import { Player_Audio } from '../elements/players/audio_landscape';
import { Player_Audio_Vertical } from '../elements/players/audio_portrait';
import { getDrm, getToken } from '../elements/players/helpers/security';
import { playAlbumIndex, playPodcastIndex, playRadioIndex } from '../elements/rails/helpers/helper';
import Event from '../../events';

const Screen_ = ({ navigation, route, focusContext, type }: any): React.ReactElement => {
    const contentContext = React.useContext(ContentContext);
    const settingsContext = React.useContext(SettingsContext);
    const appContext = React.useContext(AppContext);
    const playerContext = React.useContext(PlayerContext);
    const userContext = React.useContext(UserContext);
    const deviceContext = React.useContext(DeviceContext);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [isFavorite, setIsFavorite] = React.useState(false);
    const screenName = getCurrentScreenName();
    const playerPositionRef = React.useRef(0);

    React.useEffect(() => {
        const eventListener = Event.subscribe('onPlayerTimeUpdate', (value) => {
            playerPositionRef.current = value;
        });

        return () => {
            eventListener();
        };
    }, []);

    const startPlayer = async (type: any) => {
        var stream = '' as any;
        global.playerInFocus = true;
        switch (type) {
            case 'Albums':
                {
                    const parts = contentContext.albumData.album.songs[contentContext.albumData.songIndex].streams.albums.url.split('.');
                    const extension = parts[parts.length - 1];
                    stream = await getToken(contentContext.albumData.album, playerContext.tk, contentContext.albumData.album.songs[contentContext.albumData.songIndex].streams.albums.url, deviceContext, userContext);
                    var drm = await getDrm(contentContext.albumData.album, contentContext.albumData.album._id, 'albums', appContext.application, deviceContext, playerContext, appContext);
                    if (drm != null) {
                        playerContext.setDrm(drm);
                    }
                    playerContext.setStream({ url: stream.endsWith(extension) ? stream : `${stream}.${extension}` });
                    playerContext.setPlayer({
                        ...playerContext.player,
                        paused: false,
                        isLive: false,
                        type: 'Albums',
                        error: '',
                    });
                    playerContext.setPaused(false);
                    setIsFavorite(favoriteStatus(type, contentContext.albumData.album._id, userContext));
                    setIsLoaded(true);
                }
                break;
            case 'Podcasts':
                {
                    const parts = contentContext.podcastData.podcast.episodes[contentContext.podcastData.episodeIndex].streams.podcasts.url.split('.');
                    const extension = parts[parts.length - 1];
                    stream = await getToken(contentContext.podcastData.podcast, playerContext.tk, contentContext.podcastData.podcast.episodes[contentContext.podcastData.episodeIndex].streams.podcasts.url, deviceContext, userContext);
                    var drm = await getDrm(contentContext.podcastData.podcast, contentContext.podcastData.podcast._id, 'podcasts', appContext.application, deviceContext, playerContext, appContext);
                    if (drm != null) {
                        playerContext.setDrm(drm);
                    }
                    playerContext.setStream({ url: stream.endsWith(extension) ? stream : `${stream}.${extension}` });
                    playerContext.setPlayer({
                        ...playerContext.player,
                        paused: false,
                        isLive: false,
                        type: 'Podcasts',
                        error: '',
                    });
                    playerContext.setPaused(false);
                    setIsFavorite(favoriteStatus(type, contentContext.podcastData.podcast._id, userContext));
                    setIsLoaded(true);
                }
                break;
            case 'Radio':
                {
                    if (deviceContext.isSmartTV || deviceContext.isWebTV) {
                        stream = await getToken(contentContext.radioData.radio, playerContext.tk, contentContext.radioData.radio.streams?.radios.urls.tizen_webos, deviceContext, userContext);
                    } else if (deviceContext.isIos || deviceContext.isAppleTV) {
                        stream = await getToken(contentContext.radioData.radio, playerContext.tk, contentContext.radioData.radio.streams.radios?.urls.ios_tvos, deviceContext, userContext);
                    } else {
                        stream = await getToken(contentContext.radioData.radio, playerContext.tk, contentContext.radioData.radio.streams.radios?.urls.standard_url, deviceContext, userContext);
                    }
                    var drm = await getDrm(contentContext.radioData.radio, contentContext.radioData.radio._id, 'radio', appContext.application, deviceContext, playerContext, appContext);
                    if (drm != null) {
                        playerContext.setDrm(drm);
                    }
                    playerContext.setStream({ url: stream });
                    playerContext.setPlayer({
                        ...playerContext.player,
                        paused: false,
                        isLive: true,
                        type: 'Radio',
                        error: '',
                    });
                    playerContext.setPaused(false);
                    setIsFavorite(favoriteStatus(type, contentContext.radioData.radio._id, userContext));
                    manageWatching(userContext, type, contentContext.radioData.radio, appContext.application, true, 0, 0, [], [], [], [], contentContext);
                    setIsLoaded(true);
                }
                break;
        }
    };

    const onPrevious = (screenName) => {
        if (screenName == 'Album_Player') {
            var newIndex = contentContext.albumData.albumIndex - 1;
            if (newIndex >= 0) {
                var album = contentContext.albumData.albumList[newIndex];
                playAlbumIndex(contentContext, album, navigation, newIndex);
                startPlayer('Albums');
            }
        }
        if (screenName == 'Podcast_Player') {
            var newIndex = contentContext.podcastData.podcastIndex - 1;
            if (newIndex >= 0) {
                var podcast = contentContext.podcastData.podcastList[newIndex];
                playPodcastIndex(contentContext, podcast, navigation, newIndex);
                startPlayer('Podcasts');
            }
        }
        if (screenName == 'Radio_Player') {
            var newIndex = contentContext.radioData.radioIndex - 1;
            if (newIndex >= 0) {
                var radio = contentContext.radioData.radioList[newIndex];
                playRadioIndex(contentContext, appContext, userContext, radio, newIndex, null);
                startPlayer('Radio');
            }
        }
    };
    const onNext = (screenName) => {
        if (screenName == 'Album_Player') {
            var newIndex = contentContext.albumData.albumIndex + 1;
            if (newIndex <= contentContext.albumData.albumList.length - 1) {
                var album = contentContext.albumData.albumList[newIndex];
                playAlbumIndex(contentContext, album, navigation, newIndex);
                startPlayer('Albums');
            }
        }
        if (screenName == 'Podcast_Player') {
            var newIndex = contentContext.podcastData.podcastIndex + 1;
            if (newIndex <= contentContext.podcastData.podcastList.length - 1) {
                var podcast = contentContext.podcastData.podcastList[newIndex];
                playPodcastIndex(contentContext, podcast, navigation, newIndex);
                startPlayer('Podcasts');
            }
        }
        if (screenName == 'Radio_Player') {
            var newIndex = contentContext.radioData.radioIndex + 1;
            if (newIndex <= contentContext.radioData.radioList.length - 1) {
                var radio = contentContext.radioData.radioList[newIndex];
                playRadioIndex(contentContext, appContext, userContext, radio, newIndex, null);
                startPlayer('Radio');
            }
        }
    };

    const onPreviousAudio = (screenName) => {
        if (screenName == 'Album_Player') {
            var newIndex = contentContext.albumData.songIndex - 1;
            if (newIndex >= 0) {
                var song = contentContext.albumData.songs[newIndex];
                contentContext.setAlbumData({
                    ...contentContext.albumData,
                    song: song,
                    songIndex: newIndex,
                });
                startPlayer('Albums');
            }
        }
        if (screenName == 'Podcast_Player') {
            var newIndex = contentContext.podcastData.episodeIndex - 1;
            if (newIndex >= 0) {
                var episode = contentContext.podcastData.episodes[newIndex];
                contentContext.setPodcastData({
                    ...contentContext.podcastData,
                    episode: episode,
                    episodeIndex: newIndex,
                });
                startPlayer('Podcasts');
            }
        }
    };
    const onNextAudio = (screenName) => {
        if (screenName == 'Album_Player') {
            var newIndex = contentContext.albumData.songIndex + 1;
            if (newIndex <= contentContext.albumData.songs.length - 1) {
                var song = contentContext.albumData.songs[newIndex];
                contentContext.setAlbumData({
                    ...contentContext.albumData,
                    song: song,
                    songIndex: newIndex,
                });
                startPlayer('Albums');
            }
        }
        if (screenName == 'Podcast_Player') {
            var newIndex = contentContext.podcastData.episodeIndex + 1;
            if (newIndex <= contentContext.podcastData.episodes.length - 1) {
                var episode = contentContext.podcastData.episodes[newIndex];
                contentContext.setPodcastData({
                    ...contentContext.podcastData,
                    episode: episode,
                    episodeIndex: newIndex,
                });
                startPlayer('Podcasts');
            }
        }
    };

    const getPlayer = (type: any, styling: any) => {
        switch (type) {
            case 'Audio':
                if (!deviceContext.isPhone) {
                    return (
                        <Player_Audio
                            onPreviousAudio={onPreviousAudio}
                            onNextAudio={onNextAudio}
                            onBack={onBack}
                            isFavorite={isFavorite}
                            focusContext={focusContext}
                            onScrubberDrag={onScrubberDrag}
                            onForward={onForward}
                            onRewind={onRewind}
                            styling={styling}
                            onPlayPause={onPlayPause}
                            onPrevious={onPrevious}
                            onNext={onNext}
                            navigation={navigation}
                            openAirplay={openAirplay}
                            openChromecast={openChromecast}
                            manageFavorite={manageFavoriteLocal}
                        ></Player_Audio>
                    );
                }
                if (deviceContext.isPhone) {
                    return (
                        <Player_Audio_Vertical
                            onPreviousAudio={onPreviousAudio}
                            onNextAudio={onNextAudio}
                            onBack={onBack}
                            isFavorite={isFavorite}
                            focusContext={focusContext}
                            onScrubberDrag={onScrubberDrag}
                            onForward={onForward}
                            onRewind={onRewind}
                            styling={styling}
                            onPlayPause={onPlayPause}
                            onPrevious={onPrevious}
                            onNext={onNext}
                            navigation={navigation}
                            openAirplay={openAirplay}
                            openChromecast={openChromecast}
                            manageFavorite={manageFavoriteLocal}
                        ></Player_Audio_Vertical>
                    );
                }
        }
    };

    const getElement = (type: string) => {
        switch (type) {
            case 'Albums':
                return getPlayer(appContext.application.players?.music.type, appContext.application.players?.music);
            case 'Podcasts':
                return getPlayer(appContext.application.players?.podcasts.type, appContext.application.players?.podcasts);
            case 'Radio':
                return getPlayer(appContext.application.players?.radio.type, appContext.application.players?.radio);
        }
    };

    React.useEffect(() => {
        settingsContext.setShowMainMenu(false);
        startPlayer(type);
        return () => {
            settingsContext.setShowMainMenu(true);
        };
    }, [contentContext.radioData, contentContext.albumData, contentContext.podcastData]);

    React.useEffect(() => {
        if (playerContext.player.ended == true) {
            if (screenName == 'Album_Player') {
                manageWatching(userContext, 'Albums', contentContext.albumData.album, appContext.application, true, 0, 0, [], [], [], contentContext.albumData.album.songs[contentContext.albumData.songIndex], contentContext);
                onNext(screenName);
            }
            if (screenName == 'Podcast_Player') {
                manageWatching(userContext, 'Podcasts', contentContext.podcastData.podcast, appContext.application, true, 0, 0, [], [], [], contentContext.podcastData.podcast.episodes[contentContext.podcastData.podcastIndex], contentContext);
                onNext(screenName);
            }
        }
    }, [playerContext.player.ended]);

    const onPlayPause = () => {
        sendActionReport(appContext, deviceContext, userContext, actionType.PLAYER_PAUSED);
        playerContext.setPaused(!playerContext.paused);
    };

    const onRewind = (value) => {
        sendActionReport(appContext, deviceContext, userContext, actionType.PLAYER_REW);
        global.preventControlsFromHiding = true;

        var seek = global.seekPosition - value;
        if (seek > 0) {
            playerContext.setSeekSetPosition(seek);
        } else {
            playerContext.setSeekSetPosition(0);
        }
    };
    const onForward = (value) => {
        sendActionReport(appContext, deviceContext, userContext, actionType.PLAYER_FWD);
        global.preventControlsFromHiding = true;

        var seek = global.seekPosition + value;
        if (seek < playerContext.duration) {
            playerContext.setSeekSetPosition(seek);
        }
    };
    const onRestart = () => {
        sendActionReport(appContext, deviceContext, userContext, actionType.PLAYER_RESTART);
        playerContext.setSeekSetPosition(0);
    };
    const onScrubberDrag = (value: any) => {
        if (screenName != 'Radio_Player' && type != 'Radio') {
            sendActionReport(appContext, deviceContext, userContext, actionType.PLAYER_SCRUBBER);
            playerContext.setSeekSetPosition(value);
            playerContext.setSeekControls(!playerContext.seekControls);
        }
    };

    const openChromecast = () => {
        sendActionReport(appContext, deviceContext, userContext, actionType.PLAYER_CHROMECAST);
        if (playerContext.casting.type != 'Chromecast') {
            playerContext.setCasting({
                type: 'Chromecast',
            });
        } else {
            playerContext.setCasting({
                type: '',
            });
        }
    };
    const openAirplay = () => {
        sendActionReport(appContext, deviceContext, userContext, actionType.PLAYER_AIRPLAY);
        if (playerContext.casting.type != 'Airplay') {
            playerContext.setCasting({
                type: 'Airplay',
            });
        } else {
            playerContext.setCasting({
                type: '',
            });
        }
    };

    const manageFavoriteLocal = () => {
        switch (screenName) {
            case 'Radio_Player':
                manageFavorite(contentContext, 'Radio', contentContext.radioData.radio, appContext.application, isFavorite, userContext, deviceContext, appContext);
                setIsFavorite(!isFavorite);
                break;
            case 'Album_Player':
                manageFavorite(contentContext, 'Album', contentContext.albumData.album, appContext.application, isFavorite, userContext, deviceContext, appContext);
                setIsFavorite(!isFavorite);
                break;
            case 'Podcast_Player':
                manageFavorite(contentContext, 'Podcast', contentContext.podcastData.podcast, appContext.application, isFavorite, userContext, deviceContext, appContext);
                setIsFavorite(!isFavorite);
                break;
            default:
                break;
        }
    };

    const onBack = () => {
        if (screenName == 'Album_Player') {
            manageWatching(
                userContext,
                'Album',
                contentContext.albumData.album,
                appContext.application,
                true,
                playerContext.duration,
                playerPositionRef.current,
                [],
                [],
                [],
                contentContext.albumData.album.songs[contentContext.albumData.songIndex],
                contentContext
            );
        }
        if (screenName == 'Podcast_Player') {
            manageWatching(
                userContext,
                'Podcast',
                contentContext.podcastData.podcast,
                appContext.application,
                true,
                playerContext.duration,
                playerPositionRef.current,
                [],
                contentContext.podcastData.podcast.episodes[contentContext.podcastData.podcastIndex],
                [],
                [],
                contentContext
            );
        }
        if (screenName == 'Radio_Player') {
            manageWatching(userContext, 'Radio', contentContext.radioData.radio, appContext.application, true, playerContext.duration, playerPositionRef.current, [], [], [], [], contentContext);
        }
        navigation.goBack(null);
    };

    const getCorrectPageType = () => {
        switch (type) {
            case 'Radio':
                return pageType.RADIO_PLAYER;
                break;
            case 'Podcasts':
                return pageType.PODCAST_PLAYER;
                break;
            case 'Albums':
                return pageType.MUSIC_PLAYER;
                break;
        }
    };

    React.useEffect(() => {
        const TVBackHandler = (event) => {
            const { key, keyCode } = event;
            if (keyCode == 413 || keyCode == 10009 || keyCode == 27 || keyCode == 461) {
                onBack();
            }
        };
        if (deviceContext.isTizen || deviceContext.isWebos) window.addEventListener('keydown', TVBackHandler);

        return () => {
            if (deviceContext.isTizen || deviceContext.isWebos) {
                {
                    window.removeEventListener('keydown', TVBackHandler);
                }
            }
        };
    }, []);

    useFocusEffect(
        React.useCallback(() => {
            const start = moment().unix();
            return () => {
                let duration = moment().unix() - start;
                if (duration > 60) {
                    sendPageReport(appContext, deviceContext, userContext, getCorrectPageType(), duration);
                }
            };
        }, [])
    );

    const disableNativeHardwareBackPress = () => {
        onBack();
        return true;
    };

    React.useEffect(() => {
        BackHandler.addEventListener('hardwareBackPress', disableNativeHardwareBackPress);
        return () => {
            BackHandler.removeEventListener('hardwareBackPress', disableNativeHardwareBackPress);
        };
    }, []);

    return (
        <View style={{ flex: 1 }} focusContext={focusContext}>
            <View>
                <View
                    style={{
                        position: 'absolute',
                        zIndex: 9999,
                        width: getRealWidth(deviceContext),
                        height: getRealHeight(deviceContext),
                    }}
                >
                    {getElement(type)}
                </View>
                {isLoaded && <TheoPlayer key={playerContext.stream.url}></TheoPlayer>}
            </View>
        </View>
    );
};
export default Screen_;
