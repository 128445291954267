import AsyncStorage from '@react-native-community/async-storage';
import moment from 'moment';
import NetInfo from '@react-native-community/netinfo';
import RNFetchBlob from 'rn-fetch-blob';
import { Platform } from 'react-native';
import { getGlobals } from '../../../globals';
import utils from '../../general/utils';

export const getApp = async (packageId) => {
    const isConnectedToNetwork = (await NetInfo.fetch()).isConnected;
    const fileExists = await RNFetchBlob.fs.exists(`${RNFetchBlob.fs.dirs.DocumentDir}/${packageId}-data.json`);
    if (isConnectedToNetwork) {
        try {
            var path = 'https://cloudtv.akamaized.net/donotremove/tvms/domains/' + packageId + '/app.json?' + moment().unix();
            const lastUpdate = await AsyncStorage.getItem('app-json-last-update');
            return fetch(path)
                .then((response) => response.json())
                .then((responseJson) => {
                    global.Application = responseJson;
                    if ((!Platform.isTV && (Platform.OS === 'android' || Platform.OS === 'ios') && lastUpdate === undefined) || responseJson.updatedAt !== lastUpdate) {
                        AsyncStorage.setItem('app-json-last-update', responseJson.updatedAt);
                        RNFetchBlob.fs.writeFile(`${RNFetchBlob.fs.dirs.DocumentDir}/${packageId}-data.json`, JSON.stringify(responseJson), 'utf8');
                    }
                    return { success: true };
                });
        } catch (err) {
            return { success: false };
        }
    } else if (fileExists) {
        return RNFetchBlob.fs.readFile(`${RNFetchBlob.fs.dirs.DocumentDir}/${packageId}-data.json`, 'utf8').then((data) => {
            global.Application = JSON.parse(data);
            return { success: true };
        });
    }
    return { success: false };
};
export const getAppNormal = async (packageId) => {
    try {
        var path = 'https://cloudtv.akamaized.net/donotremove/tvms/domains/' + packageId + '/app.json?' + moment().unix();
        return fetch(path)
            .then((response) => response.json())
            .then((responseJson) => {
                global.Application = responseJson;
                return { success: true };
            });
    } catch (err) {
        return { success: false };
    }
};

export const getCart = async (client, deployment, service, webshop_id, appContext) => {
    try {
        var pathCart = 'https://cloudtv.akamaized.net/' + client + '/' + deployment + '/' + service + '/carts/' + webshop_id + '_cart.json';
        return await fetch(pathCart)
            .then((responseCart) => responseCart.json())
            .then((responseCartJson) => {
                global.Cart = responseCartJson;
                appContext.setCart(responseCartJson);
                return { success: true };
            });
    } catch (err) {
        return { success: true };
    }
};

export const getLanguages = async (languages, client, deployment, appContext) => {
    const isConnectedToNetwork = (await NetInfo.fetch()).isConnected;
    if (isConnectedToNetwork) {
        try {
            var urls = [] as any;
            languages.forEach((language) => {
                urls.push({
                    url: utils.getCorrectStoragePrefix(appContext, true) + 'appbuilder/translations/' + language.name + '.json?t=' + moment().unix(),
                });
            });
            var translations = [] as any;
            try {
                var promises = urls.map((url) => fetch(url.url).then((y) => y.json()));
                return Promise.all(promises).then((results) => {
                    results.forEach((result, index) => {
                        translations.push({
                            language: languages[index].name,
                            translations: result.language,
                        });
                    });
                    AsyncStorage.setItem('languages', JSON.stringify(translations));
                    global.Translations = translations;
                    // deviceContext is not set yet at this stage
                    if (!Platform.isTV && (Platform.OS === 'android' || Platform.OS === 'ios')) {
                        RNFetchBlob.fs.writeFile(`${RNFetchBlob.fs.dirs.DocumentDir}/${client}-languages.json`, JSON.stringify(translations), 'utf8');
                    }
                    return { success: true };
                });
            } catch (e) {
                return { success: false };
            }
        } catch (err) {
            return { success: false };
        }
    } else {
        const fileExists = await RNFetchBlob.fs.exists(`${RNFetchBlob.fs.dirs.DocumentDir}/${client}-languages.json`);
        if (fileExists) {
            return RNFetchBlob.fs.readFile(`${RNFetchBlob.fs.dirs.DocumentDir}/${client}-languages.json`, 'utf8').then((data) => {
                global.Translations = JSON.parse(data);
                return { success: true };
            });
        }
        return { success: false };
    }
};
