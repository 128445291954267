import { View, Image as XTVImage } from '@scriptx-com/xtv-toolkit';
import * as React from 'react';
import { AppContext } from '../../../context/appContext';
import { ContentContext } from '../../../context/contentContext';
import { DeviceContext } from '../../../context/deviceContext';
import { UserContext } from '../../../context/userContext';
import { Images } from '../../../models/content/shared/images';
import { Hero_Slider as styling } from '../../../models/elements/heros/slider';
import { getRealHeight, getRealWidth } from '../../../styling/realDeviceSizes';
import Image from '../../components/image/image';
import { getChannelsFromSubscriptionAndRating, getCoursesFromSubscriptionAndRating, getGamesFromSubscriptionAndRating, getMoviesFromSubscriptionAndRating, getSeriesFromSubscriptionAndRating } from '../rails/helpers/helper';
import moment from 'moment';
import { BackgroundImage, ButtonRow, DescriptionRow, LogoRow, MetaRow, ProgressRow, StatusTimeRow } from './helpers/helpers_landscape';
import { onPressContinue, onPressFavorite, onPressInformation, onPressPlay, onPressPlayCatchupTV, onPressPlaySeason, onPressPurchase, onPressReplay, onPressTrailer, onPressWatchlist } from '../details/helpers/functions';
import { getCorrectWidth } from '../../../styling/correctSizes';
import { getCorrectLeftMargin } from '../../../styling/appLeftMargin';
import { getCurrentScreenName } from '../../../hooks/getScreenName';
import Swiper from '../../components/swiper/swiper';
import { SettingsContext } from '../../../context/settingsContext';
import { isFactorTv } from '@rnv/renative';
import { checkIfChannelHasPrograms } from './helpers/helper';
import PayPerView from '../../components/payperview/payperview';
import { getModalStyling } from '../../components/helpers/helper';
import { ScreenFadeIn } from '../../animations/screenFadeIn';
import utils from '../../../general/utils';

export interface Data {
    images: Images;
    name: string;
    tags: string;
    translations: any;
    _id: string;
}

export interface Props {
    styling: styling;
    navigation: any;
    focusContext: any;
}

export const Hero_Slider = ({ styling, navigation, focusContext }: Props) => {
    const contentContext = React.useContext(ContentContext);
    const appContext = React.useContext(AppContext);
    const deviceContext = React.useContext(DeviceContext);
    const userContext = React.useContext(UserContext);
    const settingsContext = React.useContext(SettingsContext);

    const screenName = getCurrentScreenName();

    const [data, setData] = React.useState([] as any);
    const [pageIndex, setPageIndex] = React.useState(0);
    const initialFocusSet = React.useRef(false);
    const [showPayPerView, setShowPayPerView] = React.useState(false);

    const [updateScreenFavorite, setUpdateScreenFavorite] = React.useState(0);
    const [updateScreenWatchlist, setUpdateScreenWatchlist] = React.useState(0);
    const [updateScreenPayPerView, setUpdatePayPerView] = React.useState(0);

    var navigatorType = 'Bottom Menu';
    if (deviceContext.isWebTV && !deviceContext.isPwaVertical) {
        navigatorType = appContext.application.navigators?.browser.type;
    } else if (deviceContext.isTablet) {
        navigatorType = appContext.application.navigators?.tablet.type;
    } else if (deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical) {
        navigatorType = appContext.application.navigators?.mobile.type;
    } else if (deviceContext.isTelevision) {
        navigatorType = appContext.application.navigators?.television.type;
    }

    const getData = async () => {
        var path = '';
        if (styling.content.type == 'Content List') {
            if (styling.content.list?.type == 'Movies') {
                path = utils.getCorrectStoragePrefix(appContext, true) + 'movies/lists/' + styling.content.list.list + '_list.json';
            }
            if (styling.content.list?.type == 'Series') {
                path = utils.getCorrectStoragePrefix(appContext, true) + 'series/lists/' + styling.content.list.list + '_list.json';
            }
            if (styling.content.list?.type == 'Courses') {
                path = utils.getCorrectStoragePrefix(appContext, true) + 'courses/lists/' + styling.content.list.list + '_list.json';
            }
            if (styling.content.list?.type == 'Channels') {
                path = utils.getCorrectStoragePrefix(appContext, true) + 'channels/lists/' + styling.content.list.list + '_list.json';
            }
            if (styling.content.list?.type == 'Games') {
                path = utils.getCorrectStoragePrefix(appContext, true) + 'games/lists/' + styling.content.list.list + '_list.json';
            }
            try {
                let response = await fetch(path);
                let data = (await response.json()) as any;
                if (data != undefined && data.length > 0) {
                    if (styling.content.list?.type == 'Channels') {
                        var dataOut = getChannelsFromSubscriptionAndRating(contentContext, data[0].items);
                        var dataOutNew = [] as any;
                        if (dataOut.length > 0) {
                            for (var channel of dataOut) {
                                var test = checkIfChannelHasPrograms(channel, contentContext);
                                if (test) {
                                    dataOutNew.push(channel);
                                }
                            }
                            setData(dataOutNew);
                        }
                    } else if (styling.content.list?.type == 'Movies') {
                        var dataOut = getMoviesFromSubscriptionAndRating(contentContext, data[0].items);
                        if (dataOut.length > 0) {
                            let newData = dataOut;
                            if (dataOut.length > 5) {
                                newData = dataOut.slice(0, 5);
                            }
                            setData(newData);
                        }
                    } else if (styling.content.list?.type == 'Courses') {
                        var dataOut = getCoursesFromSubscriptionAndRating(contentContext, data[0].items);
                        if (dataOut.length > 0) {
                            let newData = dataOut;
                            if (dataOut.length > 5) {
                                newData = dataOut.slice(0, 5);
                            }
                            setData(newData);
                        }
                    } else if (styling.content.list?.type == 'Series') {
                        var dataOut = getSeriesFromSubscriptionAndRating(contentContext, data[0].items);
                        if (dataOut.length > 0) {
                            let newData = dataOut;
                            if (dataOut.length > 5) {
                                newData = dataOut.slice(0, 5);
                            }
                            setData(newData);
                        }
                    } else if (styling.content.list?.type == 'Games') {
                        var dataOut = getGamesFromSubscriptionAndRating(contentContext, data[0].items);
                        if (dataOut.length > 0) {
                            let newData = dataOut;
                            if (dataOut.length > 5) {
                                newData = dataOut.slice(0, 5);
                            }
                        }
                    }
                }
            } catch (error) {
                setData(null);
            }
        }
    };

    React.useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            getData();
        });
        return unsubscribe;
    }, [navigation]);

    React.useEffect(() => {
        getData();
    }, [styling]);

    const HeroObject = React.useMemo(
        () =>
            ({ item, index }) => {
                var program = [] as any;
                if (styling.content.list?.type == 'Channels') {
                    if (contentContext.epgTv != undefined) {
                        var epgChannel = contentContext.epgTv?.find((e) => e.i == item._id);
                        if (epgChannel != undefined) {
                            var currentTime = moment().unix();
                            program = epgChannel.epg.find((e) => e.s <= currentTime && e.e >= currentTime);
                        }
                    }
                }

                if (item.type === 'empty') {
                    if (contentContext.epgTv != undefined) {
                        var epgChannel = contentContext.epgTv[0];
                        if (epgChannel != undefined) {
                            var currentTime = moment().unix();
                            program = epgChannel.epg.find((e) => e.s <= currentTime && e.e >= currentTime);
                        }
                    }
                }

                if (index > 0) {
                    initialFocusSet.current = true;
                }

                return (
                    <View key={index} style={{ flex: 1 }} focusContext={focusContext}>
                        {navigatorType == 'Split Menu' && (
                            <View
                                style={{
                                    position: 'absolute',
                                    width: getRealWidth(deviceContext) + getCorrectWidth(deviceContext, global.menuWidth),
                                    height: getRealHeight(deviceContext),
                                    zIndex: 2,
                                }}
                            >
                                <Image
                                    isLocal={true}
                                    deviceContext={deviceContext}
                                    source={require('../../../../assets/images/gradient-home-2.png')}
                                    resizeMode={'cover'}
                                    style={{ opacity: 0.3, transform: [{ rotate: '180deg' }], height: getRealHeight(deviceContext) + 20, width: getRealWidth(deviceContext) + getCorrectWidth(deviceContext, global.menuWidth) + 20 }}
                                ></Image>
                            </View>
                        )}
                        <View
                            style={{
                                position: 'absolute',
                                width: getRealWidth(deviceContext) + getCorrectWidth(deviceContext, global.menuWidth),
                                height: getRealHeight(deviceContext),
                                zIndex: 2,
                            }}
                        >
                            <Image
                                isLocal={true}
                                deviceContext={deviceContext}
                                source={require('../../../../assets/images/gradient-home-2.png')}
                                resizeMode={'cover'}
                                style={{ height: getRealHeight(deviceContext) + 20, width: getRealWidth(deviceContext) + getCorrectWidth(deviceContext, global.menuWidth) + 20 }}
                            ></Image>
                        </View>
                        <View
                            style={{
                                position: 'absolute',
                                width: getRealWidth(deviceContext) + getCorrectWidth(deviceContext, global.menuWidth),
                                height: getRealHeight(deviceContext),
                                zIndex: 2,
                            }}
                        >
                            <Image
                                isLocal={true}
                                deviceContext={deviceContext}
                                source={require('../../../../assets/images/gradient-home-2.png')}
                                resizeMode={'cover'}
                                style={{ height: getRealHeight(deviceContext) + 20, width: getRealWidth(deviceContext) + getCorrectWidth(deviceContext, global.menuWidth) + 20 }}
                            ></Image>
                        </View>
                        <ScreenFadeIn>
                            <BackgroundImage program={program} item={data[index]} type={styling.content.list?.type} deviceContext={deviceContext} appContext={appContext} contentContext={contentContext}></BackgroundImage>
                        </ScreenFadeIn>
                        <View
                            style={{
                                zIndex: 3,
                                flex: 1,
                                width: getRealWidth(deviceContext),
                                marginLeft: getCorrectWidth(deviceContext, global.menuWidth + 5) + getCorrectLeftMargin(deviceContext, appContext, screenName),
                                paddingRight: getCorrectLeftMargin(deviceContext, appContext, screenName),
                                paddingBottom: getRealHeight(deviceContext) * 0.35,
                                justifyContent: 'flex-end',
                            }}
                        >
                            <View>
                                <LogoRow program={program} item={data[index] ?? item} type={styling.content.list?.type} deviceContext={deviceContext} appContext={appContext} contentContext={contentContext} styling={styling}></LogoRow>
                                <View style={{ flexDirection: 'row' }}>
                                    <StatusTimeRow
                                        program={program}
                                        item={data[index] ?? item}
                                        type={styling.content.list?.type}
                                        deviceContext={deviceContext}
                                        appContext={appContext}
                                        userContext={userContext}
                                        contentContext={contentContext}
                                        styling={styling}
                                    ></StatusTimeRow>
                                    <MetaRow
                                        program={program}
                                        item={data[index] ?? item}
                                        type={styling.content.list?.type}
                                        deviceContext={deviceContext}
                                        appContext={appContext}
                                        userContext={userContext}
                                        contentContext={contentContext}
                                        styling={styling}
                                    ></MetaRow>
                                </View>
                                <DescriptionRow program={program} item={data[index] ?? item} type={styling.content.list?.type} deviceContext={deviceContext} userContext={userContext} contentContext={contentContext} styling={styling}></DescriptionRow>
                                <ProgressRow
                                    program={program}
                                    item={data[index] ?? item}
                                    type={styling.content.list?.type}
                                    deviceContext={deviceContext}
                                    appContext={appContext}
                                    userContext={userContext}
                                    contentContext={contentContext}
                                    styling={styling}
                                ></ProgressRow>
                            </View>

                            <View>
                                <ButtonRow
                                    focusable={index === pageIndex}
                                    hasPreferredFocus={index === 0 && !initialFocusSet.current}
                                    focusContext={focusContext}
                                    item={data[index] ?? item}
                                    program={program}
                                    type={styling.content.list?.type}
                                    deviceContext={deviceContext}
                                    appContext={appContext}
                                    userContext={userContext}
                                    styling={styling}
                                    onPressBack={() => navigation.goBack()}
                                    onPressPlay={(item) => onPressPlay(styling.content.list?.type, navigation, item, contentContext, appContext, userContext, 0, settingsContext)}
                                    onPressInformation={(item) => onPressInformation(styling.content.list?.type, contentContext, appContext, userContext, item, navigation, settingsContext)}
                                    onPressPlaySeason={(seasonIndex, episodeIndex) => onPressPlaySeason(contentContext, data, navigation, seasonIndex, episodeIndex, settingsContext)}
                                    onPressPlayCatchupTV={(startType, item, programIndex) => onPressPlayCatchupTV(navigation, startType, item, contentContext, settingsContext, programIndex)}
                                    onPressTrailer={(item) => onPressTrailer(styling.content.list?.type, navigation, item, settingsContext)}
                                    onPressPurchase={() => onPressPurchase()}
                                    onPressFavorite={(isFavorite, item) => onPressFavorite(isFavorite, contentContext, userContext, styling.content.list?.type, appContext, deviceContext, setUpdateScreenFavorite, item)}
                                    onPressWatchlist={(isWatchlist, item, seasonIndex, episodeIndex) =>
                                        onPressWatchlist(isWatchlist, userContext, styling.content.list?.type, appContext, contentContext, setUpdateScreenWatchlist, item, seasonIndex, episodeIndex)
                                    }
                                    onPressContinue={(item) => onPressContinue(styling.content.list?.type, navigation, item, settingsContext)}
                                    {...(screenName === 'Home' && {
                                        focusOptions: { scrollTarget: { x: 0, y: 0 } },
                                    })}
                                ></ButtonRow>
                            </View>
                        </View>
                    </View>
                );
            },
        [pageIndex, data?.length]
    );

    return (
        <View style={{ flex: 1 }} focusContext={focusContext}>
            {showPayPerView && (
                <PayPerView
                    focusContext={focusContext}
                    styling={getModalStyling(appContext.application.theme.modals.payperview, appContext)}
                    stylingContent={styling}
                    setShowModal={() => setShowPayPerView(false)}
                    type={styling.content.list?.type == 'Channels' ? 'Program' : styling.content.list?.type}
                    data={data}
                    startContent={() => {
                        setUpdatePayPerView(moment().unix());
                        setShowPayPerView(false);
                    }}
                ></PayPerView>
            )}
            <View
                style={{
                    width: getRealWidth(deviceContext) + (deviceContext.isWebTV ? 20 : 0),
                    height: getRealHeight(deviceContext),
                    flexDirection: 'row',
                    flexGrow: 1,
                    backgroundColor: '#000',
                }}
            >
                {data != null && data.length !== 0 && (
                    <Swiper
                        deviceContext={deviceContext}
                        paginationStyle={{
                            position: 'absolute',
                            bottom: getRealHeight(deviceContext) * 0.2,
                            left: 0,
                            right: 0,
                            flexDirection: 'row',
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: 'transparent',
                            width: getRealWidth(deviceContext),
                        }}
                        dotStyle={{ marginRight: 10 }}
                        activeDotStyle={{ marginRight: 10 }}
                        dotColor={appContext.application.theme.progresses.base}
                        activeDotColor={appContext.application.theme.progresses.progress}
                        onIndexChanged={(index) => {
                            if (isFactorTv) {
                                setPageIndex(index);
                            }
                        }}
                        focusContext={focusContext}
                        isPlaceholderSlide={undefined}
                        hideSwiper={false}
                    >
                        {data.map((item, index) => (
                            <HeroObject key={index} item={item} index={index} />
                        ))}
                    </Swiper>
                )}
            </View>
        </View>
    );
};

export default Hero_Slider;
