import { useFocusEffect } from '@react-navigation/native';
import { View } from '@scriptx-com/xtv-toolkit';
import moment from 'moment';
import React, { useEffect } from 'react';
import { BackHandler } from 'react-native';
import { sendPageReport } from '../../../../reporting/apis/page';
import { actionType, pageType } from '../../../../reporting/models/apps';
import { CreateListRenderItemInfo, FlashList, Image } from '@scriptx-com/xtv-toolkit';
import { ContentContext } from '../../../context/contentContext';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { getFontSize } from '../../../styling/fontSizes';
import { AppContext } from '../../../context/appContext';
import { DeviceContext } from '../../../context/deviceContext';
import { UserContext } from '../../../context/userContext';
import lang from '../../../languages/languages';
import { getRealHeight, getRealWidth } from '../../../styling/realDeviceSizes';
import { getFocusStyling, getPressableStyling } from '../../components/helpers/helper';
import Pressable_ from '../../components/pressable/pressable';
import Text from '../../components/text';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faEllipsisVertical } from '@fortawesome/pro-light-svg-icons';
import { recordStatus, remindStatus } from '../../../data/account';
import { ButtonRowTop } from '../../elements/details/helpers/helpers_portrait';
import { PlayerContext } from '../../../context/playerContext';
import { sendActionReport } from '../../../../reporting/apis/action';
import { useSafeAreaInsets } from '../../../../libs/react-native-safe-area-context';
import { filterAgeRatings } from '../grids/helpers/functions';
import utils from '../../../general/utils';

const Screen_ = ({ navigation, route, focusContext, type }: any): React.ReactElement => {
    const contentContext = React.useContext(ContentContext);
    const userContext = React.useContext(UserContext);
    const deviceContext = React.useContext(DeviceContext);
    const appContext = React.useContext(AppContext);
    const playerContext = React.useContext(PlayerContext);

    const [data, setData] = React.useState([] as any);
    const [channel, setChannel] = React.useState([] as any);
    const [scrollIndex, setScrollIndex] = React.useState([] as any);

    const insets = useSafeAreaInsets();

    const params = route.params;
    const styling = params.styling;
    const channelId = params.channel_id;
    const offsetDay = params.offsetDay;
    const channel_index = params.channel_index;
    const epg = params.epg;

    //For TV to exit with back button
    React.useEffect(() => {
        const TVBackHandler = (event) => {
            const { key, keyCode } = event;
            if (keyCode == 413 || keyCode == 10009 || keyCode == 27 || keyCode == 461) {
                navigation.goBack();
            }
        };
        if (deviceContext.isTizen || deviceContext.isWebos) window.addEventListener('keydown', TVBackHandler);

        return () => {
            if (deviceContext.isTizen || deviceContext.isWebos) {
                {
                    window.removeEventListener('keydown', TVBackHandler);
                }
            }
        };
    }, []);

    useEffect(() => {
        var channels = channelsCategoriesEpg(contentContext.epgTv, contentContext.channels.categories[contentContext.channelData.channelCategoryIndex].channels);
        if (channels != undefined) {
            var channel = channels.find((c) => c.i == channelId);
            if (channel != undefined) {
                var currentTime = moment().unix();
                var scrollIndex = epg.findIndex((p) => p.s < currentTime && p.e > currentTime);
                setScrollIndex(scrollIndex);
                setChannel(channel);
                setData(epg);
            }
        }
    }, [navigation]);

    const onBack = (skipHideControls?) => {
        setTimeout(() => {
            navigation.goBack();
        }, 100);
    };

    useFocusEffect(
        React.useCallback(() => {
            const start = moment().unix();
            return () => {
                let duration = moment().unix() - start;
                sendPageReport(appContext, deviceContext, userContext, pageType.GAMES_WEBVIEW, duration);
            };
        }, [])
    );

    const disableNativeHardwareBackPress = () => {
        onBack(true);
        return true;
    };
    React.useEffect(() => {
        BackHandler.addEventListener('hardwareBackPress', disableNativeHardwareBackPress);
        return () => BackHandler.removeEventListener('hardwareBackPress', disableNativeHardwareBackPress);
    }, []);

    const onPressChromecast = () => {
        sendActionReport(appContext, deviceContext, userContext, actionType.PLAYER_CHROMECAST);
        if (playerContext.casting?.type != 'Chromecast') {
            playerContext.setCasting({
                type: 'Chromecast',
            });
        } else {
            playerContext.setCasting({
                type: '',
            });
        }
    };
    const onPressAirplay = () => {
        sendActionReport(appContext, deviceContext, userContext, actionType.PLAYER_AIRPLAY);
        if (playerContext.casting?.type != 'Airplay') {
            playerContext.setCasting({
                type: 'Airplay',
            });
        } else {
            playerContext.setCasting({
                type: '',
            });
        }
    };

    const channelsCategoriesEpg = (epgtv, channels) => {
        var epg = [] as any;
        channels.forEach((channel) => {
            var epgFind = epgtv.find((e) => e.i == channel._id);
            if (epgFind != undefined) {
                epg.push(epgFind);
            }
        });
        return epg;
    };

    const showProgramDetails = (program_index, channel_index, program) => {
        const filteredChannels = filterAgeRatings(contentContext.channels.categories[contentContext.channelData.channelCategoryIndex].channels, userContext, appContext);
        var epg = channelsCategoriesEpg(contentContext.epgTv, filteredChannels);
        if (offsetDay != 0) {
            epg = channelsCategoriesEpg(contentContext.epgTvExtra, filteredChannels);
        }
        if (program != undefined) {
            contentContext.setChannelData({
                ...contentContext.channelData,
                channel: filteredChannels[channel_index],
                channelIndex: channel_index,
                channelList: contentContext.channels.categories[contentContext.channelData.channelCategoryIndex].channels,
                program: epg[channel_index]?.epg[program_index],
                programIndex: program_index,
                programList: epg[channel_index]?.epg,
                programListIndex: program_index,
            });

            navigation && navigation.navigate('Program');
        }
    };
    const StatusTimeRow = ({ program, deviceContext, appContext, userContext, contentContext, styling }) => {
        var currentTime = moment().unix();
        var isLive = program.s < currentTime && program.e > currentTime;
        var isPast = program.s < currentTime && program.e < currentTime;
        var isRecorded = recordStatus(program.i, userContext);
        var isReminded = remindStatus(program.i, userContext);
        return (
            <View style={{ flexDirection: 'column' }}>
                <View style={{ flexDirection: 'row' }}>
                    {isLive && (
                        <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                            <Text
                                style={{
                                    backgroundColor: 'crimson',
                                    color: appContext.application.theme.tags.color,
                                    borderRadius: appContext.application.theme.tags.radius / 2,
                                    fontSize: getFontSize(deviceContext, 'X-Small'),
                                    padding: getCorrectWidth(deviceContext, 2),
                                    paddingHorizontal: getCorrectWidth(deviceContext, 3),
                                    includeFontPadding: false,
                                }}
                            >
                                {lang.getTranslation(userContext, 'live').toUpperCase()}
                            </Text>
                        </View>
                    )}
                    {isRecorded && (
                        <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                            <Text
                                style={{
                                    backgroundColor: 'crimson',
                                    color: appContext.application.theme.tags.color,
                                    borderRadius: appContext.application.theme.tags.radius / 2,
                                    fontSize: getFontSize(deviceContext, 'X-Small'),
                                    padding: getCorrectWidth(deviceContext, 2),
                                    paddingHorizontal: getCorrectWidth(deviceContext, 3),
                                    includeFontPadding: false,
                                }}
                            >
                                {lang.getTranslation(userContext, 'recorded').toUpperCase()}
                            </Text>
                        </View>
                    )}
                    {isReminded && (
                        <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                            <Text
                                style={{
                                    backgroundColor: '#4169e1',
                                    color: appContext.application.theme.tags.color,
                                    borderRadius: appContext.application.theme.tags.radius / 2,
                                    fontSize: getFontSize(deviceContext, 'X-Small'),
                                    padding: getCorrectWidth(deviceContext, 2),
                                    paddingHorizontal: getCorrectWidth(deviceContext, 3),
                                    includeFontPadding: false,
                                }}
                            >
                                {lang.getTranslation(userContext, 'reminded').toUpperCase()}
                            </Text>
                        </View>
                    )}
                    {isPast && program.cu && (
                        <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                            <Text
                                style={{
                                    backgroundColor: '#4169e1',
                                    color: appContext.application.theme.tags.color,
                                    borderRadius: appContext.application.theme.tags.radius / 2,
                                    fontSize: getFontSize(deviceContext, 'X-Small'),
                                    padding: getCorrectWidth(deviceContext, 2),
                                    paddingHorizontal: getCorrectWidth(deviceContext, 3),
                                    includeFontPadding: false,
                                }}
                            >
                                {lang.getTranslation(userContext, 'replay').toUpperCase()}
                            </Text>
                        </View>
                    )}
                    {isLive && program.pau && (
                        <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                            <Text
                                style={{
                                    backgroundColor: '#4169e1',
                                    color: appContext.application.theme.tags.color,
                                    borderRadius: appContext.application.theme.tags.radius / 2,
                                    fontSize: getFontSize(deviceContext, 'X-Small'),
                                    padding: getCorrectWidth(deviceContext, 2),
                                    paddingHorizontal: getCorrectWidth(deviceContext, 3),
                                    includeFontPadding: false,
                                }}
                            >
                                {lang.getTranslation(userContext, 'replay').toUpperCase()}
                            </Text>
                        </View>
                    )}
                </View>
            </View>
        );
    };

    const renderDetailsItem = ({ item, focusRepeatContext, index }: CreateListRenderItemInfo<any>) => {
        var current = moment().unix();
        var totalProgram = item?.e - item?.s;
        var live = false;
        var left = 0;
        live = item.s <= current && item.e >= current ? true : false;
        left = totalProgram - (current - item?.s);

        var progress = ((current - item?.s) / totalProgram) * 100;

        return (
            <View
                style={{
                    borderRadius: getPressableStyling(styling.components?.button?._id, appContext).borderRadius,
                    marginRight: getCorrectWidth(deviceContext, 10),
                    flex: 1,
                    marginBottom: getCorrectHeight(deviceContext, 3),
                    flexDirection: 'row',
                    backgroundColor: 'rgba(0, 0, 0, 0.80)',
                }}
            >
                <Pressable_ animatorOptions={getFocusStyling('Buttons', appContext)} onPress={() => showProgramDetails(index, channel_index, item)}>
                    <View style={{ flexDirection: 'row' }}>
                        <View style={{ width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, 60), marginHorizontal: getCorrectWidth(deviceContext, 10), padding: getCorrectWidth(deviceContext, 10) }}>
                            <View style={{ flex: 1, justifyContent: 'center', alignContent: 'center', alignSelf: 'center' }}>
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, 60),
                                        fontWeight: 'bold',
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        color: '#ffffff',
                                    }}
                                >
                                    {item.n}
                                </Text>
                                <Text
                                    numberOfLines={2}
                                    style={{
                                        width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, 60),
                                        fontWeight: 'normal',
                                        fontSize: getFontSize(deviceContext, 'Small'),
                                        color: '#ffffff',
                                    }}
                                >
                                    {item.d}
                                </Text>

                                <View style={{ flexDirection: 'row', marginTop: getCorrectHeight(deviceContext, 5) }}>
                                    <StatusTimeRow program={item} deviceContext={deviceContext} appContext={appContext} userContext={userContext} contentContext={contentContext} styling={styling}></StatusTimeRow>
                                    <Text
                                        numberOfLines={1}
                                        style={{
                                            fontWeight: 'normal',
                                            fontSize: getFontSize(deviceContext, 'Small'),
                                            color: '#ffffff',
                                        }}
                                    >
                                        {moment.unix(item?.s).format(deviceContext.clockSetting) + ' - ' + moment.unix(item?.e).format(deviceContext.clockSetting)}
                                    </Text>
                                    {live && (
                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                fontWeight: 'normal',
                                                fontSize: getFontSize(deviceContext, 'Small'),
                                                color: '#999999',
                                            }}
                                        >
                                            {' '}
                                            {moment().startOf('day').add(left, 'seconds').format('m') + 'm ' + lang.getTranslation(userContext, 'left')}
                                        </Text>
                                    )}
                                </View>
                                {live && (
                                    <View style={{ marginTop: getCorrectHeight(deviceContext, 5) }}>
                                        <View
                                            style={{
                                                backgroundColor: appContext.application.theme.progresses.base,
                                                height: getCorrectHeight(deviceContext, 2),
                                                width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, 60),
                                            }}
                                        >
                                            <View
                                                style={{
                                                    backgroundColor: appContext.application.theme.progresses.progress,
                                                    height: getCorrectHeight(deviceContext, 2),
                                                    width: progress + '%',
                                                }}
                                            ></View>
                                        </View>
                                    </View>
                                )}
                            </View>
                        </View>
                        <View style={{ flexDirection: 'row', width: getCorrectWidth(deviceContext, 20), justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                            <FontAwesomeIcon icon={faEllipsisVertical} color={'#ffffff'}></FontAwesomeIcon>
                        </View>
                    </View>
                </Pressable_>
            </View>
        );
    };

    return (
        <View style={{ flex: 1, marginBottom: getCorrectHeight(deviceContext, global.menuHeight) }}>
            {data.length > 0 && (
                <View
                    style={{
                        flex: 1,
                    }}
                >
                    <View
                        style={{
                            height: (getRealHeight(deviceContext) - global.menuHeight) * 0.5,
                        }}
                    >
                        <Image
                            deviceContext={deviceContext}
                            source={{ uri: utils.getCorrectStoragePrefix(appContext, true, channel.m) }}
                            resizeMethod={'scale'}
                            resizeMode={'cover'}
                            style={{
                                height: getRealWidth(deviceContext),
                                width: getRealWidth(deviceContext),
                            }}
                        />
                        <ButtonRowTop
                            insets={insets}
                            item={undefined}
                            onPressShare={null}
                            onPressAirplay={() => onPressAirplay()}
                            onPressChromecast={() => onPressChromecast()}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            userContext={userContext}
                            playerContext={playerContext}
                            styling={styling}
                            onPressBack={() => navigation.goBack()}
                        ></ButtonRowTop>
                        <View
                            style={{
                                position: 'absolute',
                                zIndex: 5,
                                alignItems: 'center',
                                bottom: getCorrectWidth(deviceContext, 20),
                                left: getCorrectWidth(deviceContext, 20),
                            }}
                        >
                            <Text
                                numberOfLines={2}
                                style={{
                                    width: getRealWidth(deviceContext) * 0.8,
                                    fontWeight: 'bold',
                                    fontSize: getFontSize(deviceContext, 'ExtraExtraExtraLarge'),
                                    color: '#ffffff',
                                }}
                            >
                                {channel.d}. {channel.n}
                            </Text>
                        </View>
                        <View
                            style={{
                                position: 'absolute',
                                width: getRealWidth(deviceContext),
                                height: getRealWidth(deviceContext),
                                zIndex: 1,
                                alignItems: 'center',
                            }}
                        >
                            <Image
                                isLocal={true}
                                deviceContext={deviceContext}
                                source={require('../../../../assets/images/gradient-home-2.png')}
                                resizeMode={'cover'}
                                style={{
                                    width: getRealWidth(deviceContext),
                                    height: getRealWidth(deviceContext),
                                }}
                            ></Image>
                        </View>
                        <View
                            style={{
                                position: 'absolute',
                                width: getRealWidth(deviceContext),
                                height: getRealWidth(deviceContext),
                                zIndex: 1,
                                alignItems: 'center',
                            }}
                        >
                            <Image
                                isLocal={true}
                                deviceContext={deviceContext}
                                source={require('../../../../assets/images/gradient-home-2.png')}
                                resizeMode={'cover'}
                                style={{
                                    width: getRealWidth(deviceContext),
                                    height: getRealWidth(deviceContext),
                                }}
                            ></Image>
                        </View>
                    </View>

                    <Image
                        source={require('../../../../assets/images/gradient-home-top-bottom.png')}
                        resizeMethod={'scale'}
                        resizeMode={'stretch'}
                        style={{
                            top: getRealWidth(deviceContext),
                            bottom: 0,
                            left: 0,
                            right: 0,
                            position: 'absolute',
                            zIndex: 1,
                            opacity: 0.5,
                        }}
                    ></Image>
                    <View
                        style={{
                            zIndex: 2,

                            height: (getRealHeight(deviceContext) - getCorrectWidth(deviceContext, global.menuHeight)) * 0.5,
                            width: getRealWidth(deviceContext),
                            backgroundColor: styling.tvguide.backgroundColor,
                            marginLeft: getCorrectWidth(deviceContext, 5),
                            marginRight: getCorrectWidth(deviceContext, 5),
                            //marginTop: getCorrectHeight(deviceContext, 5),
                            paddingBottom: getCorrectWidth(deviceContext, 10),
                        }}
                        focusContext={focusContext}
                    >
                        <FlashList
                            scrollEnabled={!contentContext.disableScroll}
                            extraData={data}
                            data={data}
                            estimatedItemSize={getCorrectHeight(deviceContext, 60)}
                            horizontal={false}
                            numColumns={1}
                            showsHorizontalScrollIndicator={false}
                            renderItem={renderDetailsItem}
                            initialScrollIndex={scrollIndex}
                            type={'grid'}
                            style={{ flex: 1 }}
                        />
                    </View>
                </View>
            )}
        </View>
    );
};
export default Screen_;
