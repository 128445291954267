import { CreateListRenderItemInfo, FlashList, Screen, View } from '@scriptx-com/xtv-toolkit';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { CommonActions } from '@react-navigation/native';
import * as React from 'react';
import { useState } from 'react';
import Pressable from '../../builder/components/pressable/pressable';
import { AppContext } from '../../context/appContext';
import { DeviceContext } from '../../context/deviceContext';
import { SettingsContext } from '../../context/settingsContext';
import { UserContext } from '../../context/userContext';
import LANG from '../../languages/languages';
import { getCorrectHeight, getCorrectWidth } from '../../styling/correctSizes';
import { getFontSize } from '../../styling/fontSizes';
import { FlashList as FlashList_ } from '@scriptx-com/flash-list';
import { getRealWidth } from '../../styling/realDeviceSizes';
import Text from '../../builder/components/text';
import { ContentContext } from '../../context/contentContext';
import { getFocusStyling, getPressableStyling, getPressableTextStyling, getSelectedStyling } from '../../builder/components/helpers/helper';
import { AddCachingTaskEvent, CacheEventType, MediaCache } from 'react-native-theoplayer';
import { checkIfMenuHasWebview } from '../helpers/helpers';

const Tab = createMaterialTopTabNavigator();

function MyTabTopBar({ navigation }) {
    var menu = '';
    var styling = [] as any;
    var addTaskEvent = undefined;

    const settingsContext = React.useContext(SettingsContext);
    const appContext = React.useContext(AppContext);
    const userContext = React.useContext(UserContext);
    const deviceContext = React.useContext(DeviceContext);
    const contentContext = React.useContext(ContentContext);
    const [activeSettings, setActiveSettings] = useState('');
    const rails = React.useRef<FlashList_<number> | null>(null);
    const [viewIndex, setViewIndex] = React.useState(0);
    const [task, setTask] = useState([] as any);
    const [downloadStatus, setDownloadStatus] = useState('false');

    if (deviceContext.isWebTV && !deviceContext.isPwaVertical) {
        styling = appContext.application.navigators?.browser;
    } else if (deviceContext.isTablet) {
        styling = appContext.application.navigators?.tablet;
    } else if (deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical) {
        styling = appContext.application.navigators?.mobile;
    } else if (deviceContext.isTelevision) {
        styling = appContext.application.navigators?.television;
    }

    React.useEffect(() => {
        addTaskEvent = MediaCache.addEventListener(CacheEventType.addtask, (event: AddCachingTaskEvent) => {
            if (event.task.status == 'idle') {
                setTask(event.task);
                setDownloadStatus('loading');
            }
        });
        return () => {
            if (addTaskEvent) {
                MediaCache.removeEventListener(CacheEventType.addtask, addTaskEvent);
            }
        };
    }, []);

    const onPress = (menu: any) => {
        contentContext.setActiveMenu(menu);
        navigation.dispatch(
            CommonActions.reset({
                index: 1,
                routes: [{ name: menu }],
            })
        );
    };

    var menuArray = [] as any;
    if (activeSettings == '') {
        menuArray = [];
        appContext.application.menus.forEach((element) => {
            if (
                element.name != 'Logout' &&
                element.name != 'Services' &&
                element.name != 'Disclaimer' &&
                element.name != 'Signin' &&
                element.name != 'Forgot' &&
                element.name != 'Check' &&
                element.name != 'Player' &&
                element.name != 'Profiles' &&
                element.name != 'Languages' &&
                element.name != 'Settings' &&
                element.name != 'Messages' &&
                element.name != 'Loader' &&
                element.name != 'Audio Player' &&
                element.name != 'Video Player' &&
                element.name != 'Support' &&
                element.name != 'Home'
            ) {
                if (
                    element.name == 'Search' ||
                    element.name == 'Downloads' ||
                    element.name == 'Actors' ||
                    element.name == 'Favorites' ||
                    element.name == 'Recordings' ||
                    element.name == 'Watchlist' ||
                    (element.name == 'Games' && !deviceContext.isWebTV && !deviceContext.isPhone && !deviceContext.isTablet) ||
                    (checkIfMenuHasWebview(appContext, element.name) && !deviceContext.isWebTV && !deviceContext.isPhone && !deviceContext.isTablet)
                ) {
                    //do nothing
                } else {
                    var menu_ = { name: element.name, menu: element.name };
                    menuArray.push(menu_);
                }
            }
        });
    }
    if (menuArray.length > 1 && menu == '') {
        menu = menuArray[1].menu;
    }

    const renderItem = ({ item, focusRepeatContext, index }: CreateListRenderItemInfo<any>) => {
        return (
            <Pressable style={{ marginRight: 5 }} animatorOptions={getFocusStyling('Buttons', appContext)} key={index} onPress={() => onPress(item.menu)}>
                <>
                    {contentContext.activeMenu != item.menu && (
                        <View
                            style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: getCorrectHeight(deviceContext, 6),
                                paddingHorizontal: getCorrectHeight(deviceContext, 8),
                                flexDirection: 'row',
                                borderColor: '#fff',
                                ...getPressableStyling(styling.components?.button._id, appContext),
                            }}
                        >
                            <Text
                                numberOfLines={1}
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                    ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 2,
                                }}
                            >
                                {LANG.getTranslation(userContext, item.name)}
                            </Text>
                        </View>
                    )}
                    {contentContext.activeMenu == item.menu && (
                        <View
                            style={{
                                alignItems: 'center',
                                padding: getCorrectHeight(deviceContext, 6),
                                paddingHorizontal: getCorrectHeight(deviceContext, 8),
                                flexDirection: 'row',
                                ...getPressableStyling(styling.components?.button._id, appContext),
                            }}
                        >
                            <Text
                                numberOfLines={1}
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                    ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                    color: getSelectedStyling('Navigators', appContext).text_color,
                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 2,
                                }}
                            >
                                {LANG.getTranslation(userContext, item.name)}
                            </Text>
                        </View>
                    )}
                </>
            </Pressable>
        );
    };

    if (settingsContext.showMainMenu) {
        return (
            <Screen focusOptions={{ stealFocus: false }}>
                <View style={{ flex: 1, flexDirection: 'row', marginLeft: deviceContext.isPhone ? getCorrectWidth(deviceContext, 5) : getCorrectWidth(deviceContext, 0) }}>
                    <View
                        style={{
                            flexDirection: 'row',
                            overflow: 'hidden',
                            alignItems: 'center',
                            height: getCorrectWidth(deviceContext, 45),
                            width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, deviceContext.isPhone ? 0 : global.menuWidth),
                        }}
                    >
                        <FlashList
                            ref={rails}
                            type="row"
                            extraData={menuArray}
                            data={menuArray}
                            estimatedItemSize={getCorrectWidth(deviceContext, 75)}
                            numColumns={1}
                            horizontal={true}
                            showsHorizontalScrollIndicator={false}
                            renderItem={renderItem}
                            initialScrollIndex={0}
                            style={{ flex: 1 }}
                        />
                    </View>
                </View>
            </Screen>
        );
    }

    return null;
}
export default MyTabTopBar;
