import * as React from 'react';
import { DeviceContext } from '../../../context/deviceContext';
import Landscape from './Scrubber_Landscape';
import Portrait from './Scrubber_Portrait';
import { getCurrentScreenName } from '../../../hooks/getScreenName';
import { PlayerContext } from '../../../context/playerContext';

export const Details_Top = (props) => {
    const deviceContext = React.useContext(DeviceContext);
    const playerContext = React.useContext(PlayerContext);
    const screenName = getCurrentScreenName();

    return (
        <>
            {(deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical) && screenName != 'Album_Player' && screenName != 'Radio_Player' && screenName != 'Podcast_Player' && !deviceContext.isApple && (
                <Portrait deviceContext={deviceContext} {...props}></Portrait>
            )}
            {!deviceContext.isPhone && !deviceContext.isKaiOs && !deviceContext.isPwaVertical && <Landscape playerContext={playerContext} deviceContext={deviceContext} {...props}></Landscape>}
            {deviceContext.isPhone && deviceContext.isApple && screenName != 'Album_Player' && screenName != 'Radio_Player' && screenName != 'Podcast_Player' && (
                <Landscape playerContext={playerContext} deviceContext={deviceContext} {...props}></Landscape>
            )}
            {(screenName == 'Album_Player' || screenName == 'Radio_Player' || screenName == 'Podcast_Player') && deviceContext.isPhone && <Landscape playerContext={playerContext} deviceContext={deviceContext} {...props}></Landscape>}
        </>
    );
};

export default Details_Top;
