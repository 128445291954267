import { View, Screen } from '@scriptx-com/xtv-toolkit';
import { useFocusEffect } from '@react-navigation/native';
import moment from 'moment';
import * as React from 'react';
import { AppContext } from '../../../application/context/appContext';
import { DeviceContext } from '../../../application/context/deviceContext';
import { UserContext } from '../../../application/context/userContext';
import lang from '../../../application/languages/languages';
import { getFontSize } from '../../../application/styling/fontSizes';
import { useSafeAreaInsets } from '../../../libs/react-native-safe-area-context';
import { sendPageReport } from '../../../reporting/apis/page';
import { pageType } from '../../../reporting/models/apps';
import { getFocusOptions } from '../../wrapper/helpers/getFocusOptions';
import Text from '../../../application/builder/components/text';
import { getCorrectHeight, getCorrectWidth } from '../../../application/styling/correctSizes';
import { ContentContext } from '../../../application/context/contentContext';
import { getRealWidth } from '../../../application/styling/realDeviceSizes';

const UpdatesScreen = ({ navigation, route, focusContext }): React.ReactElement => {
    const appContext = React.useContext(AppContext);
    const deviceContext = React.useContext(DeviceContext);
    const userContext = React.useContext(UserContext);
    const contentContext = React.useContext(ContentContext);
    const insets = useSafeAreaInsets();
    const [show, setShow] = React.useState(false);

    React.useEffect(() => {
        setShow(true);
    }, [navigation, route]);

    useFocusEffect(
        React.useCallback(() => {
            contentContext.setActiveMenu(route.name);
            const start = moment().unix();
            return () => {
                let duration = moment().unix() - start;
                sendPageReport(appContext, deviceContext, userContext, pageType.UPDATES, duration);
            };
        }, [])
    );

    const focusOptions = getFocusOptions(deviceContext, appContext, 'settings-screen');

    return (
        <Screen focusContext={focusContext} focusOptions={{ ...focusOptions }} style={{ flex: 1, width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext,  global.settingsMenu) }}>
            <View
                style={{
                    height:getCorrectHeight(deviceContext,100),
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: appContext.application.theme.settings.background_color1,
                    borderRadius: appContext.application.theme.settings.border_radius,
                    margin: getCorrectWidth(deviceContext, 10),
                }}
            >
                <Text
                    style={{
                        fontFamily: deviceContext.fontType,
                        fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                        fontWeight: appContext.application.authentication?.services?.header.title_bold ? 'bold' : 'normal',
                        color: '#ffffff',
                    }}
                >
                    {lang.getTranslation(userContext, 'updates')}
                </Text>
                <Text
                    style={{
                        fontFamily: deviceContext.fontType,
                        fontSize: getFontSize(deviceContext, appContext.application.authentication?.services?.header.sub_size),
                        fontWeight: appContext.application.authentication?.services?.header.sub_bold ? 'bold' : 'normal',
                        color: '#ffffff',
                    }}
                >
                    {lang.getTranslation(userContext, 'update_info').toUpperCase()}
                </Text>
            </View>
            <View style={{ flex: 4, flexDirection: 'row' }}></View>
        </Screen>
    );
};
export default UpdatesScreen;
