import TVGuide from '@scriptx-com/tv-guide';
import type { TVGuideHandle } from '@scriptx-com/tv-guide';
import { MutableRefObject } from 'react';

const disableTVGuide = (tvGuideRef: MutableRefObject<TVGuideHandle>) => {
    tvGuideRef.current?.disableTVGuideView();
};

const enableTVGuide = (tvGuideRef: MutableRefObject<TVGuideHandle>) => {
    tvGuideRef.current?.enableTVGuideView();
};

export { TVGuideHandle, TVGuide, disableTVGuide, enableTVGuide };
